import { FC, ReactNode } from 'react';
import { useNavigate } from 'react-router-dom';
import { twMerge } from 'tailwind-merge';

import {
  CheckCircleIcon,
  CheckIcon,
  ChevronDownIcon,
  ChevronLeftIcon,
  EventsIcon,
  PoolPlansIcon,
  ShareClassesIcon,
  StakeholdersIcon,
} from '../../assets/icons';
import smilingFaceImage from '../../assets/images/smiling-face.png';
import { Company } from '../../hooks';
import { StringKey } from '../../lang';
import { showOnboardingPageState } from '../../storage/showOnboardingPageStorage';
import { PageRoute } from '../../types/pageTypes';
import { toRound } from '../../utils/getRoundedNumber';
import { percentage } from '../../utils/percentage';
import { AppFormattedMessage } from '../AppFormattedMessage';
import Button from '../Button';
import Loader from '../Loader';

type GetStartedItemProps = {
  title: string | ReactNode;
  description: string | ReactNode;
  icon: ReactNode;
  complete: boolean;
  onComplete: () => void;
  isNext?: boolean;
};

const GetStartedItem: FC<GetStartedItemProps> = ({
  complete,
  description,
  icon,
  onComplete,
  title,
  isNext = false,
}) => (
  <div
    className={twMerge(
      'flex w-full items-center justify-between py-2',
      !complete && 'cursor-pointer',
    )}
    onClick={!complete ? onComplete : () => ''}
  >
    <div className="flex gap-2">
      <div
        className={twMerge(
          'centered flex size-10 rounded-lg',
          complete ? 'border border-[#2FB487]' : 'shadow-sm',
        )}
      >
        {icon}
      </div>
      <div className="flex flex-col">
        <span className={twMerge('text-sm font-[550] text-[#172335]', complete && 'line-through')}>
          {title}
        </span>
        <span className="text-[10px] leading-4 text-gray-500">{description}</span>
      </div>
    </div>

    {complete ? (
      <CheckCircleIcon iconColor="#2FB487" />
    ) : (
      <ChevronLeftIcon className="rotate-180" iconColor={isNext ? '#2565C8' : '#344054'} />
    )}
  </div>
);
type GetStartedProps = {
  companyId: string;
  isOnboardingPage?: boolean;
};

export const GetStarted: FC<GetStartedProps> = ({ companyId, isOnboardingPage }) => {
  const navigate = useNavigate();

  const { companyProgress, isLoading } = Company.useFetStartedProgress({ companyId });

  if (isLoading || !companyProgress) return <Loader className="h-[440px]" />;

  const completedStepsMap = Object.values(companyProgress);

  const progress = percentage(
    completedStepsMap.length + 1,
    completedStepsMap.filter((state) => state).length + 1,
  );

  return (
    <div className="flex w-full flex-col gap-3">
      <div
        className={twMerge(
          'flex h-full w-full flex-col rounded-xl bg-gray-50 p-4 pb-2',
          isOnboardingPage && 'bg-transparent p-0',
        )}
      >
        <div className="flex flex-col gap-1">
          {!isOnboardingPage && (
            <span className="text-xl font-semibold text-gray-700">
              <AppFormattedMessage id={StringKey.GETTING_STARTED} />
            </span>
          )}
          <div className={twMerge('flex w-full items-center gap-4', isOnboardingPage && 'mt-6')}>
            <div
              className={twMerge(
                'relative flex h-1 w-full overflow-hidden rounded bg-gray-100',
                isOnboardingPage && 'h-2',
              )}
            >
              <div
                className="absolute h-full bg-gradient-to-r from-forest-500 to-forest-300 transition-all duration-700"
                style={{ width: `${progress}%` }}
              />
            </div>
            <span className="text-sm font-[550] text-forest-500">{toRound(progress, 0)}%</span>
          </div>
          <div
            className={twMerge(
              'mt-5 flex flex-col gap-1 divide-y divide-gray-200',
              isOnboardingPage && 'mt-8',
            )}
          >
            <GetStartedItem
              complete={true}
              description={<AppFormattedMessage id={StringKey.SUCCESSFUL_COMPANY_SETUP} />}
              icon={<CheckIcon iconColor="#2FB487" />}
              onComplete={() => ''}
              title={<AppFormattedMessage id={StringKey.COMPANY_SETUP} />}
            />
            <GetStartedItem
              complete={companyProgress.isCompleteStakeholders}
              description={<AppFormattedMessage id={StringKey.ADD_STAKEHOLDER_MESSAGE} />}
              icon={
                <StakeholdersIcon
                  iconColor={companyProgress.isCompleteStakeholders ? '#2FB487' : '#344054'}
                />
              }
              onComplete={() => {
                isOnboardingPage && showOnboardingPageState.set(false);
                navigate(PageRoute.STAKEHOLDERS);
              }}
              title={<AppFormattedMessage id={StringKey.ADD_STAKEHOLDER} />}
            />
            <GetStartedItem
              complete={companyProgress.isCompleteShareClass}
              description={<AppFormattedMessage id={StringKey.ADD_SHARE_CLASS_MESSAGE} />}
              icon={
                <ShareClassesIcon
                  iconColor={companyProgress.isCompleteShareClass ? '#2FB487' : '#344054'}
                />
              }
              onComplete={() => {
                isOnboardingPage && showOnboardingPageState.set(false);
                navigate(PageRoute.SHARE_CLASSES);
              }}
              title={<AppFormattedMessage id={StringKey.ADD_SHARE_CLASS} />}
            />
            <GetStartedItem
              complete={companyProgress.isCompletePool}
              description={<AppFormattedMessage id={StringKey.ADD_POOL_MESSAGE} />}
              icon={
                <PoolPlansIcon iconColor={companyProgress.isCompletePool ? '#2FB487' : '#344054'} />
              }
              onComplete={() => {
                isOnboardingPage && showOnboardingPageState.set(false);
                navigate(PageRoute.POOL_PLANS);
              }}
              title={<AppFormattedMessage id={StringKey.ADD_POOL} />}
            />
            <GetStartedItem
              complete={companyProgress.isCompletePlan}
              description={<AppFormattedMessage id={StringKey.ADD_PLAN_MESSAGE} />}
              icon={
                <PoolPlansIcon iconColor={companyProgress.isCompletePlan ? '#2FB487' : '#344054'} />
              }
              onComplete={() => {
                isOnboardingPage && showOnboardingPageState.set(false);
                navigate(PageRoute.POOL_PLANS);
              }}
              title={<AppFormattedMessage id={StringKey.ADD_NEW_PLAN} />}
            />
            <GetStartedItem
              complete={companyProgress.isCompleteEvent}
              description={<AppFormattedMessage id={StringKey.ADD_EVENT_MESSAGE} />}
              icon={
                <EventsIcon iconColor={companyProgress.isCompleteEvent ? '#2FB487' : '#344054'} />
              }
              onComplete={() => {
                isOnboardingPage && showOnboardingPageState.set(false);
                navigate(PageRoute.EVENTS);
              }}
              title={<AppFormattedMessage id={StringKey.ADD_EVENT} />}
            />
          </div>
        </div>
      </div>
      {!isOnboardingPage && (
        <div className="flex w-full items-center justify-between rounded-xl border-[1px] border-[#f2f2f2] bg-white px-6 py-3 shadow-buttonShadow">
          <div className="flex cursor-pointer items-center gap-4">
            <div className="flex !size-10 shrink-0 items-center justify-center rounded-lg border-[1px] border-gray-200">
              <img alt="" className="size-[20px]" src={smilingFaceImage} />
            </div>
            <a
              className="flex flex-col gap-2 text-start"
              href="https://calendly.com/marco-capquest/30min-capquest-intro"
              rel="noreferrer noopener nofollow"
              target="_blank"
            >
              <span className="text-sm font-[550] text-[#172335]">
                <AppFormattedMessage id={StringKey.HELP_ME_SETUP} />
              </span>
              <span className="text-label-md font-normal text-gray-600">
                Schedule a call, and we'll help you with your setup!
              </span>
            </a>
          </div>
          <Button
            className="h-fit w-fit -rotate-90 rounded p-1 transition-colors hover:bg-gray-100"
            styleType="NONE"
          >
            <ChevronDownIcon className="size-3" iconColor="#172335" />
          </Button>
        </div>
      )}
    </div>
  );
};
