import { loadStripe } from '@stripe/stripe-js';
import React, { FC, useCallback, useState } from 'react';
import { toast } from 'react-toastify';
import { twMerge } from 'tailwind-merge';

import { CloseCircleIcon, EditIcon, HeartIcon } from '../../../assets/icons';
import { AlertDialogWrapper } from '../../../components/AlertDialog';
import { AppFormattedMessage } from '../../../components/AppFormattedMessage';
import Button from '../../../components/Button';
import { HoverCard, HoverCardContent, HoverCardTrigger } from '../../../components/HoverCard';
import { FormInput } from '../../../components/Input';
import { DeprecatedModalWrapper } from '../../../components/Modal';
import { BackendRoute } from '../../../config';
import {
  AnalyticHooks,
  Company,
  Plan as PlanHook,
  useFormat,
  useLocale,
  useModalState,
  useReactForm,
} from '../../../hooks';
import { Locale, StringKey } from '../../../lang';
import { ApiService } from '../../../services';
import { companySettingsTabStore } from '../../../storage/companySettingsTabStore';
import { SubscriptionStatus } from '../../../types/companyTypes';
import { PossibleAction } from '../../../types/google-analytics-types';
import { Plan, SubscriptionDuration, SubscriptionType } from '../../../types/planTypes';
import { toUpperCaseWords } from '../../../utils/toUppercaseUtil';
import { EnterpriseModal } from '../../CompanySetup/CompanySetupForm/EnterpriseModal';
import { CompanySettingsCardComponentProps } from '../CompanySettings';
import { CompanySettingsCardTab } from '../types';
import { ChangeBillingPlanModal, ChangeBillingReportModal } from './ChangeBillingModal';
import SubscriptionCard from './SubscriptionCard';
import SubscriptionModal from './SubscriptionModal';
import SubscriptionReportModal from './SubscriptionReportModal';
import { FormSchema, formSchema } from './Validation';

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLIC_KEY || '');
export type PlanBillingProps = CompanySettingsCardComponentProps;

const PlanBilling: FC<PlanBillingProps> = ({ data, companyId, invalidateQuery, updateData }) => {
  const [isEditing, setEditing] = useState(false);
  const { format } = useFormat();

  const {
    control,
    handleSubmit,
    getValues,
    reset,
    formState: { isValid: isValidVatGstNumber, dirtyFields, defaultValues },
  } = useReactForm({
    schema: formSchema,
    defaultValues: {
      vatGst: data.vatGst,
    },
    values: {
      vatGst: data.vatGst || '',
    },
  });

  const submitHandler = useCallback(
    (data: FormSchema) => {
      updateData(
        { vatGst: data.vatGst || null },
        {
          onSuccess: () => {
            setEditing(false);
            invalidateQuery();
          },
        },
      );
    },
    [invalidateQuery, updateData],
  );

  const { paymentData } = Company.usePaymentDetails({ companyId: companyId || '' });

  const handleChangePaymentMethod = useCallback(async () => {
    companySettingsTabStore.set(CompanySettingsCardTab.COMPANY_PLAN_BILLING);

    const { sessionId } = await ApiService.patch<{ sessionId: string }, void>({
      endpoint: BackendRoute.COMPANIES,
      routePath: [companyId || '', 'payment-method'],
    });
    const stripe = await stripePromise;

    if (!stripe) return;

    const { error } = await stripe.redirectToCheckout({
      sessionId,
    });

    if (error.message) return toast(error.message);
  }, [companyId]);

  const isCompanySoonDeactivate = data.toCancelAt || data.inactivateAt;
  const { plan, latestSubscriptionData } = data;
  const [selectedPlan, setSelectedPlan] = useState<Plan | undefined>(plan);
  const stripePlanType = latestSubscriptionData.items?.data[0].price?.product?.metadata?.type;
  const stripePlanDuration =
    latestSubscriptionData?.plan?.interval === 'year'
      ? SubscriptionDuration.ANNUALY
      : SubscriptionDuration.MONTHLY;

  const companyDeactivateTracker = AnalyticHooks.useClick(PossibleAction.DEACTIVATE_COMPANY);
  const companyReactivateTracker = AnalyticHooks.useClick(PossibleAction.REACTIVATE_COMPANY);

  const { deactivate } = Company.useDeactivate();
  const { reactivate } = Company.useReactivate();

  const { plans } = PlanHook.usePlans();

  const companyPlanChangeTracker = AnalyticHooks.useClick(PossibleAction.COMPANY_PLAN_CHANGE);
  const { upgradePlan, isPending } = Company.useUpgradePlan();

  const {
    isOpen: isOpenSubscriptionModal,
    handleOpenModal: handleOpenSubscriptionModal,
    handleCloseModal: handleCloseSubscriptionModal,
    handleSuccessModal: handleSuccessSubscriptionModal,
  } = useModalState({
    onSuccess: () => {
      deactivate(
        { companyId: companyId || '' },
        {
          onSuccess: () => {
            invalidateQuery();
            companyDeactivateTracker();
          },
        },
      );
      handleOpenSubscriptionReportModal();
    },
  });

  const {
    isOpen: isOpenSubscriptionReportModal,
    handleSuccessModal: handleSuccessSubscriptionReportModal,
    handleCloseModal: handleCloseSubscriptionReportModal,
    handleOpenModal: handleOpenSubscriptionReportModal,
  } = useModalState({ onOpen: handleCloseSubscriptionModal });

  const {
    isOpen: isOpenChangeBillingModal,
    handleCloseModal: handleCloseChangeBillingModal,
    handleOpenModal: handleOpenChangeBillingModal,
    handleSuccessModal: handleSuccessChangeBillingModal,
  } = useModalState({});

  const {
    isOpen: isOpenChangeBillingReportModal,
    toggler: changeBillingReportModalToggler,
    handleOpenModal: handleOpenChangeBillingReportModal,
    handleCloseModal: handleCloseChangeBillingReportModal,
    handleSuccessModal: handleSuccessChangeBillingReportModal,
  } = useModalState({
    onSuccess: () =>
      upgradePlan(
        {
          companyId: companyId || '',
          data: {
            planId: selectedPlan?.id || '',
          },
        },
        {
          onSuccess: () => {
            companyPlanChangeTracker(selectedPlan?.title || undefined);
            toast.success('Plan successfully changed');
            handleSuccessChangeBillingModal();
            setTimeout(() => invalidateQuery(), 1000);
          },
        },
      ),
  });

  const handleChangeSubscription = useCallback(
    (plan: Plan) => {
      upgradePlan(
        { data: { planId: plan.id }, companyId: companyId || '' },
        {
          onSuccess: () => {
            toast.success('Plan successfully changed');
            invalidateQuery();
            setSelectedPlan(plan);
          },
        },
      );
    },
    [companyId, invalidateQuery, upgradePlan],
  );

  const {
    isOpen: isOpenEnterpriseModal,
    handleOpenModal: handleOpenEnterpriseModal,
    handleCloseModal: handleCloseEnterpriseModal,
  } = useModalState();

  const { locale } = useLocale();
  const sortedPlans = plans?.sort((a) => (a.duration === SubscriptionDuration.MONTHLY ? -1 : 1));

  const isEnterprise = stripePlanType === SubscriptionType.ENTERPRISE;

  return (
    <>
      <EnterpriseModal
        companyId={companyId || ''}
        handleClose={handleCloseEnterpriseModal}
        isOpenModal={isOpenEnterpriseModal}
        setShouldUnblock={() => {}}
      />
      <DeprecatedModalWrapper isOpen={isOpenSubscriptionModal}>
        <SubscriptionModal
          onClose={handleCloseSubscriptionModal}
          onSuccess={handleSuccessSubscriptionModal}
        />
      </DeprecatedModalWrapper>

      <DeprecatedModalWrapper isOpen={isOpenSubscriptionReportModal}>
        <SubscriptionReportModal
          expireDate={data.toCancelAt || data.inactivateAt || ''}
          onClose={handleCloseSubscriptionReportModal}
          onSuccess={handleSuccessSubscriptionReportModal}
        />
      </DeprecatedModalWrapper>
      <AlertDialogWrapper
        control={{
          onOpenChange: changeBillingReportModalToggler,
          open: isOpenChangeBillingReportModal,
        }}
      >
        <ChangeBillingReportModal
          currency={selectedPlan?.localCurrency || ''}
          onClose={handleCloseChangeBillingReportModal}
          onSuccess={handleSuccessChangeBillingReportModal}
          price={selectedPlan?.localPrice || 0}
          renewDate={paymentData?.periodEnd || 0}
        />
      </AlertDialogWrapper>

      <ChangeBillingPlanModal
        companyId={companyId || ''}
        currentPeriodEnd={data?.latestSubscriptionData?.current_period_end || 0}
        currentPeriodStart={data?.latestSubscriptionData?.current_period_start || 0}
        handleChangePlan={(newPlan) => {
          setSelectedPlan(newPlan);
          upgradePlan(
            {
              data: { planId: newPlan.id },
              companyId: companyId || '',
            },
            {
              onSuccess: () => {
                toast.success('Plan successfully changed');
                handleSuccessChangeBillingModal();
                setTimeout(() => invalidateQuery(), 1000);
              },
            },
          );
        }}
        handleOpenEnterpriseModal={handleOpenEnterpriseModal}
        handleSwitchPlan={(newPlan) => {
          setSelectedPlan(newPlan);
          handleOpenChangeBillingReportModal();
        }}
        isEnterprise={isEnterprise}
        isOpenModal={isOpenChangeBillingModal}
        isPending={isPending}
        onClose={handleCloseChangeBillingModal}
        onSuccess={handleSuccessChangeBillingModal}
        renewDate={paymentData?.periodEnd || 0}
        selectedPlan={plan}
        stripePlanDuration={stripePlanDuration}
        stripePlanType={stripePlanType}
        subscriptionStatus={data?.latestSubscriptionData?.status || SubscriptionStatus.ACTIVE}
      />

      <div className="flex max-w-[930px] flex-col gap-4">
        <div className="flex w-full flex-wrap items-center justify-between gap-4 rounded-md p-4 shadow-sm">
          <div className="flex flex-col gap-1">
            <span className="text-xl font-[550] text-black">
              {isEnterprise
                ? 'Enterprise'
                : stripePlanType === SubscriptionType.START_UP
                  ? 'Start Up'
                  : 'Growth'}
            </span>
            <div className="divide-x-1 flex flex-wrap items-center gap-[6px] divide-gray-100">
              <span className="text-sm font-medium text-gray-700">
                <AppFormattedMessage
                  id={StringKey.COUNT_STAKEHOLDERS}
                  values={{ count: data?.stakeholdersLimit }}
                />
              </span>
              {isCompanySoonDeactivate && (
                <>
                  <div className="h-4 w-[1px] bg-gray-100" />
                  <span className="text-sm font-[450] text-fireside-600">
                    <AppFormattedMessage
                      id={StringKey.CONTINUE_TO_USE_CAPQUEST_UNTIL}
                      values={{
                        date: format(
                          data.toCancelAt || data.inactivateAt || '',
                          locale === Locale.EN ? 'MMMM d, y' : 'd MMMM y',
                        ),
                      }}
                    />
                  </span>
                </>
              )}
            </div>
          </div>
          {!isCompanySoonDeactivate ? (
            <div className="flex h-11 gap-3">
              <Button
                className="h-full w-11 border-[1px] border-gray-300 transition-colors hover:bg-gray-50 lg:w-fit lg:px-4"
                onClick={handleOpenChangeBillingModal}
                styleType="NONE"
              >
                <EditIcon className="lg:hidden" height={'24'} iconColor="#2565C8" width={'24'} />
                <span className="text-sm font-medium text-gray-700 max-lg:hidden">
                  <AppFormattedMessage id={StringKey.CHANGE_PLAN} />
                </span>
              </Button>
              <HoverCard>
                <HoverCardTrigger>
                  <Button
                    className="flex h-full w-11 items-center justify-center border-[1px] border-gray-300 transition-colors hover:bg-gray-50"
                    onClick={handleOpenSubscriptionModal}
                    styleType="NONE"
                  >
                    {<CloseCircleIcon />}
                  </Button>
                </HoverCardTrigger>
                <HoverCardContent
                  className={twMerge('flex w-fit rounded-lg bg-[#101828] px-3 py-2 text-start')}
                >
                  <span className="text-xs font-semibold text-white">
                    <AppFormattedMessage id={StringKey.CANCEL_SUBSCRIPTION} />
                  </span>
                </HoverCardContent>
              </HoverCard>
            </div>
          ) : (
            <Button
              className="w-fit text-nowrap px-6 py-[10px]"
              onClick={() =>
                reactivate(
                  { companyId: companyId || '' },
                  {
                    onSuccess: () => {
                      invalidateQuery();
                      companyReactivateTracker();
                    },
                  },
                )
              }
            >
              <AppFormattedMessage id={StringKey.REACTIVATE_SUBSCRIPTION} />
            </Button>
          )}
        </div>
        {isCompanySoonDeactivate && (
          <div className="flex items-center gap-3 rounded-md bg-sunbeam-50 p-3">
            <HeartIcon />
            <span className="text-xs font-[450] text-gray-800">
              <AppFormattedMessage
                id={StringKey.DONT_WORRY_YOUR_DATA_IS_SAVED}
                values={{
                  timeDistance: '6 months',
                }}
              />
            </span>
          </div>
        )}
        {!isCompanySoonDeactivate && (
          <div className="flex w-full flex-col gap-2 rounded-md p-4 shadow-sm">
            <span className="text-base font-semibold text-gray-700">
              <AppFormattedMessage id={StringKey.SUBSCRIPTION_CYCLE} />
            </span>

            <div className="flex flex-wrap gap-4 pl-[1px]">
              {isEnterprise ? (
                <Button
                  className={twMerge(
                    'flex w-full min-w-[240px] max-w-[310px] flex-1 flex-col gap-2 rounded-2xl bg-transparent px-4 py-3 text-start ring-1 ring-brand-700 data-[checked]:ring-2 data-[checked]:ring-brand-700 lg:max-w-[275px] lg:px-4 lg:py-3',
                    (latestSubscriptionData?.plan?.amount || 0) / 100 >= 10000 &&
                      'max-w-fit lg:max-w-fit',
                  )}
                  styleType="NONE"
                >
                  <SubscriptionCard
                    duration={stripePlanDuration}
                    id={latestSubscriptionData.id}
                    localCurrency={latestSubscriptionData?.plan?.currency || ''}
                    localPrice={latestSubscriptionData?.plan?.amount || 0}
                    priceInCents={latestSubscriptionData?.plan?.amount || 0}
                    selected={true}
                    stakeholdersCount={data.stakeholdersLimit}
                    stripePriceId={data?.stripeSubscriptionId || ''}
                    subscriptionExp={(paymentData?.periodEnd || 0) * 1000}
                    title="Enterprise"
                    type={SubscriptionType.ENTERPRISE}
                  />
                </Button>
              ) : (
                sortedPlans
                  ?.filter((planVariant) => {
                    if (!plan) return false;
                    if (stripePlanDuration === SubscriptionDuration.ANNUALY)
                      return (
                        planVariant.duration === stripePlanDuration &&
                        planVariant.type === stripePlanType
                      );

                    return planVariant.type === stripePlanType;
                  })
                  .map((subscription) => {
                    return (
                      <Button
                        className={twMerge(
                          'flex w-full min-w-[240px] max-w-[310px] flex-1 flex-col gap-2 rounded-2xl bg-transparent px-4 py-3 text-start ring-1 ring-gray-200 data-[checked]:ring-2 data-[checked]:ring-brand-700 lg:max-w-[275px] lg:px-4 lg:py-3',
                          subscription.id === plan?.id && 'ring-brand-700',
                          subscription.localPrice / 100 >= 10000 && 'max-w-fit lg:max-w-fit',
                        )}
                        key={subscription.id}
                        onClick={() => {
                          if (subscription.id === plan?.id) return;

                          setSelectedPlan(subscription);

                          if (subscription.duration === SubscriptionDuration.MONTHLY)
                            return handleChangeSubscription(subscription);

                          handleOpenChangeBillingReportModal();
                        }}
                        styleType="NONE"
                      >
                        <SubscriptionCard
                          selected={subscription.id === plan?.id}
                          subscriptionExp={(paymentData?.periodEnd || 0) * 1000}
                          {...subscription}
                        />
                      </Button>
                    );
                  })
              )}
            </div>
          </div>
        )}
        <div className="flex w-full flex-col gap-4 rounded-md p-4 shadow-sm">
          <span className="text-base font-[550] text-gray-700">
            <AppFormattedMessage id={StringKey.PAYMENT_METHOD} />
          </span>
          <div className="flex w-full justify-between">
            <div className="flex items-center gap-2">
              <span className="text-sm font-[450] text-gray-700">
                <AppFormattedMessage
                  id={StringKey.CARD_ENDING_NUMBERS}
                  values={{
                    brand: toUpperCaseWords(paymentData?.defaultPaymentMethod?.card?.brand),
                    number: paymentData?.defaultPaymentMethod?.card?.last4,
                  }}
                />
              </span>
            </div>
            <Button
              className="size-8 rounded transition-colors hover:bg-gray-100"
              onClick={handleChangePaymentMethod}
              styleType="NONE"
            >
              <EditIcon height={'24'} iconColor="#2565C8" width="24" />
            </Button>
          </div>
        </div>
        <form
          className="flex w-full flex-col gap-4 rounded-md p-4 shadow-sm"
          onSubmit={handleSubmit(submitHandler)}
        >
          <span className="text-base font-[550] text-gray-700">
            <AppFormattedMessage id={StringKey.VAT_GST} />
          </span>
          <div className="flex w-full justify-between gap-4">
            {isEditing ? (
              <>
                <FormInput
                  control={control}
                  name="vatGst"
                  placeholder={<AppFormattedMessage id={StringKey.VAT_GST} />}
                  wrapperClassName="max-w-[400px] w-full"
                />
                <div className="flex gap-4">
                  <Button
                    className="h-11 w-fit rounded border-[1px] border-gray-100 px-3 text-sm font-[450] text-gray-700 shadow-xs"
                    onClick={() => {
                      setEditing(false);
                      reset();
                    }}
                    styleType="DEFAULT_ROUNDED"
                    type="button"
                  >
                    <AppFormattedMessage id={StringKey.CANCEL} />
                  </Button>
                  <Button
                    className="h-11 w-fit px-4 text-sm font-[550] text-gray-25"
                    disabled={!isValidVatGstNumber}
                    type="submit"
                  >
                    <AppFormattedMessage id={StringKey.UPDATE} />
                  </Button>
                </div>
              </>
            ) : (
              <>
                <span className="text-sm font-[450] text-gray-700">
                  {dirtyFields.vatGst || defaultValues?.vatGst ? getValues('vatGst') : '-'}
                </span>
                <Button
                  className="size-8 rounded transition-colors hover:bg-gray-100"
                  onClick={() => setEditing(true)}
                  styleType="NONE"
                >
                  <EditIcon height={'24'} iconColor="#2565C8" width="24" />
                </Button>
              </>
            )}
          </div>
        </form>
      </div>
    </>
  );
};

PlanBilling.displayName = 'PlanBilling';

export default PlanBilling;
