import { z } from 'zod';

import { StringKey } from '../../../lang';
import { IncorporatedIn, unitedArabEmiratesIso2 } from '../../../types/companyTypes';
import { getTranslation } from '../../../utils/getTranslation';

export const formSchema = z
  .object({
    incorporatedIn: z.nativeEnum(IncorporatedIn).nullable(),
    incorporationZoneName: z.string().nullable(),
    country: z.object(
      {
        name: z.string().min(1, { message: getTranslation(StringKey.REQUIRED) }),
        iso2Code: z.string(),
      },
      { message: getTranslation(StringKey.REQUIRED) },
    ),
    city: z
      .string({ message: getTranslation(StringKey.REQUIRED) })
      .trim()
      .min(1, { message: getTranslation(StringKey.REQUIRED) }),
    state: z.object({
      name: z.string({ message: getTranslation(StringKey.REQUIRED) }).min(1),
      iso2Code: z.string(),
    }),
    zipCode: z
      .string()
      .trim()
      .regex(/^(?!.*--)(?!.* -)(?!.*- )(?!.* {2})[a-zA-Z0-9 -]+$/, {
        message: getTranslation(StringKey.ENTER_VALID_ZIP_CODE),
      })
      .min(1, { message: getTranslation(StringKey.ENTER_VALID_ZIP_CODE) }),
    address: z
      .string({ message: getTranslation(StringKey.REQUIRED) })
      .trim()
      .min(1),
  })
  .refine(({ incorporatedIn, incorporationZoneName, country: { iso2Code: countryIso2Code } }) => {
    if (countryIso2Code !== unitedArabEmiratesIso2) {
      return true;
    }

    if (!incorporatedIn) {
      return false;
    }

    if (incorporatedIn === IncorporatedIn.OTHER) {
      return !!incorporationZoneName;
    }

    return true;
  });

export type FormSchema = z.infer<typeof formSchema>;
