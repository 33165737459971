import { FC, useState } from 'react';
import { useParams } from 'react-router-dom';

import { ArrowIcon } from '../../assets/icons';
import { AppFormattedMessage } from '../../components/AppFormattedMessage';
import Button from '../../components/Button';
import Loader from '../../components/Loader';
import { PaginationController } from '../../components/PaginationController';
import { Radio, RadioGroup } from '../../components/RadioGroup';
import { Company, SnapshotHooks, useFakePagination, useLocale, useNavigate } from '../../hooks';
import { StringKey } from '../../lang';
import { capTableStore } from '../../storage/capTableStore';
import { stakeholderTypeTranslation } from '../../translations/stakeholderTranslation';
import { PageRoute } from '../../types/pageTypes';
import { DetailsSortVariant, detailsSortVariantTitleMap } from '../../types/snapshot.types';
import { DetailsItem } from './DetailsItem';
import { latestSnapshotId } from './variables';

export const CapTableMobileDetailsPage: FC = () => {
  const [selectedDetailsSortVariant, setDetailsSortVariant] = useState<DetailsSortVariant>(
    DetailsSortVariant.BY_STAKEHOLDER,
  );
  const { id } = useParams();

  const [currentPage, setCurrentPage] = useState(1);
  const { selectedCompanyId } = Company.useSelected();

  const { isLoading, snapshots } = SnapshotHooks.useSnapshots({ companyId: selectedCompanyId });
  const { snapshot: currentSnapshot } = SnapshotHooks.useCurrent({ companyId: selectedCompanyId });

  const { snapshot: snapshotByDate, isLoading: isLoadingSnapshotByDate } =
    SnapshotHooks.useSnapshotByDate({
      companyId: selectedCompanyId,
      date: capTableStore.get()?.customCapTableDate,
    });

  const snapshot =
    latestSnapshotId === id
      ? currentSnapshot
      : capTableStore.get()?.customCapTableDate
        ? snapshotByDate
        : snapshots.find(({ valuation }) => valuation.id === id);

  const { data, totalPages } = useFakePagination({
    currentPage,
    take: 13,
    data:
      snapshot?.stats?.stakeholders.filter(
        (stakeholder) =>
          stakeholder.shareClasses.reduce((sharesTotal, { issued }) => sharesTotal + issued, 0) > 0,
      ) || [],
  });

  const { messagesLocale } = useLocale();

  const navigate = useNavigate(PageRoute.CAP_TABLE);

  if (isLoading || isLoadingSnapshotByDate) return <Loader />;

  return (
    <div className="flex w-full flex-col gap-4 rounded-md bg-white px-3 py-4 shadow-sm">
      <div className="flex cursor-pointer gap-2 pt-4 text-xs font-[450] text-gray-400">
        <span className="text-brand-700" onClick={() => navigate()}>
          <AppFormattedMessage id={StringKey.CAP_TABLE} />
        </span>
        <span>/</span>
        <span>
          <AppFormattedMessage id={StringKey.POSITIONS} />
        </span>
      </div>
      <div className="flex items-center justify-between border-y border-gray-100 py-3">
        <div className="flex items-center gap-2">
          <Button
            className="centered size-9 rounded-full"
            onClick={() => navigate()}
            style={{
              boxShadow: '0px 0px 24px 0px #00000014',
              border: '1px solid #F2F2F2',
            }}
            styleType="NONE"
          >
            <ArrowIcon />
          </Button>
          <div className="flex flex-col">
            <span className="text-base font-semibold text-gray-700">
              <AppFormattedMessage id={StringKey.CAP_TABLE} />
            </span>
            <span className="text-xs font-[450] text-gray-400">
              <AppFormattedMessage id={StringKey.DETAILS} />
            </span>
          </div>
        </div>
      </div>
      <div className="rounded-3xl border border-gray-200 p-1">
        <RadioGroup
          onChange={setDetailsSortVariant}
          value={selectedDetailsSortVariant}
          wrapperClassName="w-full grow flex-1"
        >
          {detailsSortVariantTitleMap.map(([graphKey, graphTitle]) => (
            <Radio
              className="h-8 rounded-[32px] border-transparent bg-transparent !px-3 !py-2 text-xs font-[450] text-gray-700 data-[checked]:font-[550] data-[checked]:text-brand-25"
              key={graphKey}
              value={graphKey}
            >
              <span className="text-nowrap">
                <AppFormattedMessage id={graphTitle} />
              </span>
            </Radio>
          ))}
        </RadioGroup>
      </div>

      {selectedDetailsSortVariant === DetailsSortVariant.BY_STAKEHOLDER && (
        <>
          {data?.map((stakeholder) => (
            <DetailsItem
              avatar
              key={stakeholder.id}
              {...stakeholder}
              description={messagesLocale[stakeholderTypeTranslation[stakeholder.type]]}
            />
          ))}
          <PaginationController
            currentPage={currentPage}
            onClick={setCurrentPage}
            totalPages={totalPages}
          />
        </>
      )}

      {selectedDetailsSortVariant === DetailsSortVariant.BY_SHARE_CLASS &&
        snapshot?.stats?.stakeholdersTypes
          ?.filter(
            (stakeholder) =>
              stakeholder.shareClasses.reduce(
                (sharesTotal, { issued }) => sharesTotal + issued,
                0,
              ) > 0,
          )
          ?.map(({ shareClasses, ...stakeholder }, i) =>
            shareClasses.map(({ name }) => (
              <DetailsItem
                className="pb-0"
                key={`${stakeholder.type}_${i}`}
                {...stakeholder}
                description={name}
                name={messagesLocale[stakeholderTypeTranslation[stakeholder.type]]}
                shareClasses={shareClasses.filter((shareClass) => shareClass.name === name)}
              >
                <Button
                  className="flex w-full items-center justify-between rounded-none bg-brand-25 px-4 py-3"
                  onClick={() => id && navigate([id, stakeholder.type])}
                  styleType="NONE"
                >
                  <span className="text-sm font-[450] text-brand-700">
                    <AppFormattedMessage
                      id={StringKey.VIEW_ASSOCIATED_STAKEHOLDERS}
                      values={{
                        stakeholdersCount: snapshot?.stats.stakeholders
                          .filter(
                            (stakeholder) =>
                              stakeholder.shareClasses.reduce(
                                (sharesTotal, { issued }) => sharesTotal + issued,
                                0,
                              ) > 0,
                          )
                          .filter(({ type }) => type === stakeholder.type).length,
                      }}
                    />
                  </span>
                  <ArrowIcon className="rotate-180" iconColor="#2565C8" />
                </Button>
              </DetailsItem>
            )),
          )}
    </div>
  );
};
