import { useQueryClient } from '@tanstack/react-query';
import { useCallback } from 'react';

import { Company } from '..';
import { BackendRoute } from '../../config';
import { QueryKey } from '../../constants';
import { ApiService } from '../../services';
import { ApproveRequest } from '../../types/notification.types';
import { useAppQuery } from '../useAppQuery';

export const useRequestApproveTasks = ({
  requestId,
  enabled,
}: {
  requestId: string;
  enabled?: boolean;
}) => {
  const { selectedCompanyId: companyId } = Company.useSelected();
  const queryClient = useQueryClient();

  const { data: approvalRequest, isLoading } = useAppQuery<ApproveRequest>({
    queryKey: [QueryKey.GET_REQUESTED_VESTING_TASKS, companyId, requestId],
    queryFn: () =>
      ApiService.get({
        endpoint: BackendRoute.COMPANIES,
        routePath: [companyId, 'request-approval', requestId],
      }),
    enabled: !!companyId && enabled && !!requestId,
  });

  const invalidateQuery = useCallback(() => {
    queryClient.invalidateQueries({ queryKey: [QueryKey.GET_REQUESTED_VESTING_TASKS] });
  }, [queryClient]);

  return {
    invalidateQuery,
    approvalRequest,
    isLoading,
  };
};
