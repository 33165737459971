import { useQueryClient } from '@tanstack/react-query';

import { BackendRoute } from '../../config';
import { QueryKey } from '../../constants';
import { ApiService } from '../../services';
import { useAppQuery } from '../useAppQuery';

export const useHaveVestingTasks = ({
  companyId,
  stakeholderId,
}: {
  companyId: string;
  stakeholderId: string;
}) => {
  const queryClient = useQueryClient();
  const { data: isHaveVestingTasks, isLoading } = useAppQuery({
    queryKey: [QueryKey.IS_HAVE_VESTING_TASKS, companyId, stakeholderId],
    queryFn: () =>
      ApiService.get<string>({
        endpoint: BackendRoute.COMPANIES,
        routePath: [companyId, 'stakeholders', stakeholderId, 'is-have-vesting-tasks'],
      }),
    enabled: !!companyId && !!stakeholderId,
  });

  const invalidateQuery = () => {
    queryClient.invalidateQueries({ queryKey: [QueryKey.IS_HAVE_VESTING_TASKS] });
  };

  return {
    isHaveVestingTasks: JSON.parse(isHaveVestingTasks || 'false'),
    isLoading,
    invalidateQuery,
  };
};
