import { FC } from 'react';
import { Control, UseFormResetField, UseFormSetValue, useWatch } from 'react-hook-form';
import { twMerge } from 'tailwind-merge';

import { ListIcon } from '../../../../../../assets/icons';
import { AppFormattedMessage } from '../../../../../../components/AppFormattedMessage';
import { EmptySearchList } from '../../../../../../components/EmptySearchList';
import Loader from '../../../../../../components/Loader';
import { SharePlan as SharePlanHooks, ValuationHooks } from '../../../../../../hooks';
import { StringKey } from '../../../../../../lang';
import { SharePlan } from '../../../../../../types/pool-plans.types';
import { toNumber } from '../../../../../../utils/toNumber';
import { toPlural } from '../../../../../../utils/toPlural';
import { ExerciseModalItem } from '../../../ExerciseModal';
import { FormSchema } from '../../validation';
import { Hurdle } from './Hurdle';

export type ApproveHurdlePlanListProps = {
  setFormData: UseFormSetValue<FormSchema>;
  control: Control<FormSchema>;
  companyId: string;
  resetField: UseFormResetField<FormSchema>;
};

type ApproveHurdlePlanListItemProps = {
  sharePlan: SharePlan;
  companyId: string;
  setSelectedHurdlePlanId: (id: string) => void;
};

const ApproveHurdlePlanListItem: FC<ApproveHurdlePlanListItemProps> = ({
  companyId,
  sharePlan: { id, hardleValue, name },
  setSelectedHurdlePlanId,
}) => {
  const { approveHurdleGrants: approveHurdleGrantsData } = SharePlanHooks.useSharePlanGrantItems({
    companyId,
    sharePlanId: id,
  });

  const approveHurdleGrants = approveHurdleGrantsData.filter(
    ({ exercisedCount, totalSharesCount }) => exercisedCount !== totalSharesCount,
  );

  return (
    <ExerciseModalItem
      description={
        <>
          <span className="text-nowrap">
            <AppFormattedMessage id={StringKey.HURDLE_VALUE} />: $
            {hardleValue?.toLocaleString('en-US')}
          </span>
          <span className="size-[3px] shrink-0 rounded-full bg-gray-300" />
          <span>
            {approveHurdleGrants.length} {toPlural('grant', approveHurdleGrants.length > 1)}{' '}
            <AppFormattedMessage id={StringKey.ASSOCIATED_TO_THIS_PLAN} />
          </span>
        </>
      }
      onClick={() => setSelectedHurdlePlanId(id)}
      title={name}
    >
      <ListIcon className="-scale-x-100" />
    </ExerciseModalItem>
  );
};

export const ApproveHurdlePlanList: FC<ApproveHurdlePlanListProps> = ({
  companyId,
  setFormData,
  control,
  resetField,
}) => {
  const { stepOne } = useWatch<FormSchema>({ control });
  const { approveHurdleSharePlans, isLoading } = SharePlanHooks.useApproveHurdleSharePlans({
    companyId,
  });
  const { valuation } = ValuationHooks.useCurrent({ companyId });

  if (isLoading || !valuation) return <Loader />;

  if (!stepOne?.hurdle?.planId)
    return (
      <>
        {approveHurdleSharePlans.length !== 0 && (
          <span className="pb-7 text-label-md font-medium text-gray-400">
            <AppFormattedMessage id={StringKey.CURRENT_VALUATION} />:{' '}
            {toNumber(
              toNumber(valuation?.issuedShares || valuation?.issuedSharesOnStart) *
                valuation.sharePrice,
            ).toLocaleString('en-US')}
          </span>
        )}
        <div
          className={twMerge(
            'flex h-fit max-h-[150px] flex-col divide-y divide-gray-200 overflow-y-auto rounded-xl bg-gray-50 px-4',
            approveHurdleSharePlans.length === 0 && 'centered max-h-[350px]',
          )}
        >
          {approveHurdleSharePlans.length !== 0 ? (
            approveHurdleSharePlans.map((sharePlan) => (
              <ApproveHurdlePlanListItem
                companyId={companyId}
                key={sharePlan.id}
                setSelectedHurdlePlanId={(id) => setFormData('stepOne.hurdle.planId', id)}
                sharePlan={sharePlan}
              />
            ))
          ) : (
            <EmptySearchList
              className="my-10"
              customDescriptionText=""
              customTitleText={<AppFormattedMessage id={StringKey.NO_PLANS_TO_APPROVE} />}
            />
          )}
        </div>
      </>
    );

  return (
    <Hurdle
      companyId={companyId}
      control={control}
      resetField={resetField}
      setFormData={setFormData}
      valuation={valuation}
    />
  );
};
