import React, { FC, useState } from 'react';
import { useWatch } from 'react-hook-form';

import { InfoCircle } from '../../../../assets/icons';
import { AppFormattedMessage } from '../../../../components/AppFormattedMessage';
import Button from '../../../../components/Button';
import { FormDatePicker } from '../../../../components/DatePicker';
import { FormInput } from '../../../../components/Input';
import { Switch } from '../../../../components/Switch';
import { useFormat } from '../../../../hooks';
import { StringKey } from '../../../../lang';
import { ChildrenFormProps } from '../type';
import { FormSchema, stepTwo as stepTwoFormSchema } from '../Validation';
export type StepTwoProps = ChildrenFormProps;

export const StepTwo: FC<StepTwoProps> = ({
  handleCloseModal,
  control,
  setFormData,
  nextFormStep,
  prevFormStep,
  lockMode,
  filedState,
}) => {
  const { stepTwo, stepOne } = useWatch<FormSchema>({ control });
  const { success } = stepTwoFormSchema.safeParse(stepTwo);
  const [isOpenCalendar, setOpenCalendar] = useState(false);
  const { format } = useFormat();

  const isValid = success && !filedState('stepTwo').invalid;

  stepTwo;

  const isDateValid =
    stepOne?.issueDate && stepTwo?.floor?.maturityDate
      ? new Date(stepOne?.issueDate).getTime() <= new Date(stepTwo?.floor?.maturityDate).getTime()
      : true;

  return (
    <>
      <div className="flex h-full flex-col gap-4 overflow-y-auto rounded-b-xl border-b border-gray-300 bg-white p-6 pt-0">
        <div className="rounded-md bg-gray-50">
          <div className="flex items-center gap-2 px-4 py-3">
            <Switch
              checked={stepTwo?.cap?.enabled}
              onChange={(state) => {
                if (state) return setFormData('stepTwo.cap.enabled', true);
                setFormData('stepTwo.cap', undefined);
              }}
            />
            <span className="text-sm font-[450] text-gray-700">Cap</span>
          </div>
          {stepTwo?.cap?.enabled && (
            <div className="flex items-center justify-between gap-3 rounded-md bg-white px-4 py-3 shadow-sm">
              <span className="text-nowrap text-sm font-[450] text-gray-700">Cap</span>
              <FormInput
                control={control}
                currencySign
                name="stepTwo.cap.capValue"
                numberOnly
                placeholder="Amount"
                shouldFormatNumber
                wrapperClassName="w-[224px]"
              />
            </div>
          )}
        </div>

        <div className="rounded-md bg-gray-50">
          <div className="flex items-center gap-2 px-4 py-3">
            <Switch
              checked={stepTwo?.discount?.enabled}
              onChange={(state) => {
                if (state) return setFormData('stepTwo.discount.enabled', true);
                setFormData('stepTwo.discount', undefined);
              }}
            />
            <span className="text-sm font-[450] text-gray-700">Discount</span>
          </div>
          {stepTwo?.discount?.enabled && (
            <div className="flex items-center justify-between gap-3 rounded-md bg-white px-4 py-3 shadow-sm">
              <span className="text-nowrap text-sm font-[450] text-gray-700">Discount</span>
              <FormInput
                control={control}
                name="stepTwo.discount.discountValue"
                placeholder="Percentage"
                signAfterValue="%"
                wrapperClassName="w-[224px]"
              />
            </div>
          )}
        </div>

        <div className="flex flex-col gap-2 rounded-lg p-2 shadow-sm">
          <span className="text-label-md font-medium text-gray-500">Optional</span>
          <div className="rounded-md bg-gray-50">
            <div className="flex items-center gap-2 px-4 py-3">
              <Switch
                checked={stepTwo?.floor?.enabled}
                onChange={(state) => {
                  if (state) return setFormData('stepTwo.floor.enabled', true);
                  setFormData('stepTwo.floor', undefined);
                }}
              />
              <span className="text-sm font-[450] text-gray-700">Floor & Maturity Date</span>
            </div>
            {stepTwo?.floor?.enabled && (
              <div className="flex w-full flex-col gap-3 rounded-md bg-white px-4 py-3 shadow-sm">
                <div className="flex items-center justify-between gap-3">
                  <span className="text-nowrap text-sm font-[450] text-gray-700">Floor</span>
                  <FormInput
                    control={control}
                    currencySign
                    name="stepTwo.floor.floorValue"
                    numberOnly
                    placeholder="Amount (Optional)"
                    shouldFormatNumber
                    wrapperClassName="w-[224px]"
                  />
                </div>
                <div className="flex w-full items-center justify-between gap-3">
                  <span className="text-nowrap text-sm font-[450] text-gray-700">
                    Maturity Date
                  </span>
                  <FormDatePicker
                    calendar={{ fromDate: stepOne?.issueDate }}
                    defaultMonth={stepTwo?.floor?.maturityDate || undefined}
                    inputValue={
                      stepTwo?.floor?.maturityDate
                        ? format(stepTwo?.floor?.maturityDate, 'dd/MM/yyyy')
                        : ''
                    }
                    inputWrapperClassName="ml-11"
                    isDefaultOpenCalendar={isOpenCalendar}
                    onSelect={(date) => {
                      setFormData('stepTwo.floor.maturityDate', date);
                      setOpenCalendar(false);
                    }}
                    placeholder="Date (Optional)"
                    value={stepTwo?.floor?.maturityDate}
                  />
                </div>
              </div>
            )}
          </div>
        </div>
        {!isDateValid && (
          <span className="flex gap-1 text-xs font-[450] text-fireside-600">
            <InfoCircle />
            Maturity Date must be later or equal Isuue Date
          </span>
        )}
      </div>

      <div className="flex h-9 w-full justify-between gap-3 px-6">
        <Button
          className="w-fit bg-transparent px-4 py-[6px] text-sm font-[450] text-gray-700"
          onClick={handleCloseModal}
          styleType="NONE"
        >
          <AppFormattedMessage id={StringKey.CANCEL} />
        </Button>
        <div className="flex gap-3">
          {!lockMode && (
            <Button
              className="h-full w-fit rounded border border-gray-100 bg-white px-3 py-[6px] text-sm font-[450] text-gray-700 shadow-xs"
              onClick={prevFormStep}
              styleType="NONE"
              type="button"
            >
              <AppFormattedMessage id={StringKey.BACK} />
            </Button>
          )}
          <Button
            className="h-full w-fit px-4 py-[6px] text-sm font-[550] text-white"
            disabled={!isValid || !isDateValid}
            onClick={nextFormStep}
            type="button"
          >
            {lockMode ? (
              <AppFormattedMessage id={StringKey.UPDATE} />
            ) : (
              <AppFormattedMessage id={StringKey.NEXT} />
            )}
          </Button>
        </div>
      </div>
    </>
  );
};
