type Data = {
  title: string;
};

export const modifySections: Data[] = [
  {
    title: 'Step 1: Open the Bulk Modify Blade',
  },
  {
    title: 'Step 2: Select Stakeholder Type',
  },
  {
    title: 'Step 3: Download the Excel Template',
  },
  {
    title: 'Step 4: Understanding the Excel File',
  },
  {
    title: 'Step 5: Editing the Excel File',
  },
  {
    title: 'Step 6: Upload the Updated File',
  },
  {
    title: 'Key Notes and Tips',
  },
];
