import { z } from 'zod';

import { StringKey } from '../../../../lang';
import { getTranslation } from '../../../../utils/getTranslation';
import { CapTableManagementTypes } from './types';

const phoneRegex = new RegExp(/^([+]?[\s0-9]+)?(\d{3}|[(]?[0-9]+[)])?([-]?[\s]?[0-9])+$/);

export const formSchema = z.object({
  capTableManagement: z.nativeEnum(CapTableManagementTypes),
  anotherSoftware: z.string().optional(),
  features: z
    .object({
      equity: z.boolean().optional().default(false),
      esop: z.boolean().optional().default(false),
      'venture-debt': z.boolean().optional().default(false),
      'fundraising-tracker': z.boolean().optional().default(false),
      'data-room': z.boolean().optional().default(false),
    })
    .optional(),
  additionalDetails: z
    .object({
      email: z.union([z.string().email(), z.literal('')]).optional(),
      phoneCountry: z
        .object({
          iso2Code: z.string(),
          name: z.string(),
          prefix: z.string(),
        })
        .optional(),
      phoneNumber: z
        .string()
        .regex(phoneRegex, getTranslation(StringKey.INVALID_NUMBER))
        .or(z.literal(''))
        .default('')
        .optional(),
      additionalNotes: z
        .string()
        .max(3000, getTranslation(StringKey.MAXIMUM_CHARACTERS, { count: 3000 }))
        .optional(),
    })
    .optional(),
});

export type FormSchema = z.infer<typeof formSchema>;
