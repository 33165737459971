import { StringKey } from '../../../../../../lang';

export enum SarColumn {
  STAKEHOLDER = 'stakeholder',
  GRANT = 'grant',
  EXERCISE_DATE = 'exercise-date',
  SAR_FOR_EXERCISING = 'sar-for-exercising',
  BASE_PRICE = 'base-price',
  SHARE_PRICE = 'share-price',
  NO_OF_SARS = 'no-of-sars',
  SAR_PAYOUT = 'sar-payout',
  ISSUED_SHARES = 'issued-shares',
  REMOVE_ROW = 'remove-row',
}
export const sarColumnMap = Object.values(SarColumn);

export const sarColumnTitle: Record<SarColumn, StringKey> = {
  [SarColumn.STAKEHOLDER]: StringKey.STAKEHOLDER,
  [SarColumn.GRANT]: StringKey.GRANT,
  [SarColumn.EXERCISE_DATE]: StringKey.EXERCISE_DATE,
  [SarColumn.SAR_FOR_EXERCISING]: StringKey.SAR_FOR_EXERCISING,
  [SarColumn.BASE_PRICE]: StringKey.BASE_PRICE,
  [SarColumn.SHARE_PRICE]: StringKey.SHARES_PRICE,
  [SarColumn.NO_OF_SARS]: StringKey.NO_OF_SARS,
  [SarColumn.SAR_PAYOUT]: StringKey.SAR_PAYOUT,
  [SarColumn.ISSUED_SHARES]: StringKey.ISSUED_SHARES,
  [SarColumn.REMOVE_ROW]: StringKey.EMPTY,
};
