import React, { FC, ReactNode } from 'react';
import { twMerge } from 'tailwind-merge';

import { HelpIcon } from '../../../../../assets/icons';
import { HelpIconVariant } from '../../../../../assets/icons/HelpIcon';

type TableHeaderItemProps = {
  text: string | ReactNode;
  showTooltip?: boolean;
  variant?: 'dark' | 'light';
  className?: string;
};
export const TableHeaderItem: FC<TableHeaderItemProps> = ({
  showTooltip = false,
  text,
  variant = 'light',
  className,
}) => {
  return (
    <div
      className={twMerge(
        'border-#F2F2F2 flex items-center gap-2 border-b-[1px] bg-gray-50 px-4 py-3',
        className,
      )}
    >
      <span
        className={twMerge(
          'font-500 whitespace-nowrap',
          variant === 'light' ? 'text-#172335 text-label-md' : 'text-#000000 text-label-md',
        )}
      >
        {text}
      </span>
      {showTooltip && <HelpIcon variant={HelpIconVariant.EXERCISE} />}
    </div>
  );
};
