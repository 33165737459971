import {
  FetchNextPageOptions,
  InfiniteData,
  InfiniteQueryObserverResult,
} from '@tanstack/react-query';
import React, { FC, useRef, useState } from 'react';
import { Control } from 'react-hook-form';
import InfiniteScroll from 'react-infinite-scroll-component';

import { ChevronDownIcon, SearchIcon } from '../../../../assets/icons';
import { AppFormattedMessage } from '../../../../components/AppFormattedMessage';
import {
  ComboboxButton,
  ComboboxOptions,
  FormCombobox,
  FormComboboxInput,
  FormComboboxOption,
} from '../../../../components/Combobox';
import Loader from '../../../../components/Loader';
import { useStakeholdersForExercise } from '../../../../hooks/stakeholder';
import { StringKey } from '../../../../lang';
import { SharePlanType } from '../../../../types/pool-plans.types';
import { Stakeholder } from '../../../../types/stakeholderTypes';
import { FormSchema } from '../validation';

export type StakeholderValue = {
  fullName: string;
  id: string;
};

export type StakeholdersItemsListProps = {
  data: Stakeholder[];
  control: Control<FormSchema>;
  name: string;
  hasNextPage: boolean;
  isLoading: boolean;
  fetchNextPage: (options?: FetchNextPageOptions) => Promise<
    InfiniteQueryObserverResult<
      InfiniteData<
        {
          stakeholders: Stakeholder[];
          totalPages: number;
        },
        unknown
      >,
      Error
    >
  >;
};

const StakeholdersItemsList: FC<StakeholdersItemsListProps> = ({
  data,
  control,
  name,
  hasNextPage,
  fetchNextPage,
  isLoading,
}) => {
  return (
    <InfiniteScroll
      className="flex w-full flex-col gap-2"
      dataLength={data.length || 0}
      hasMore={hasNextPage}
      loader={<Loader />}
      next={fetchNextPage}
      scrollableTarget="scrollbar-target"
    >
      {data.length > 0 ? (
        <>
          {data
            .sort((a, b) => a.fullName.toLowerCase().localeCompare(b.fullName.toLowerCase()))
            .map(({ fullName, id }, i) => (
              <FormComboboxOption
                className="w-full"
                control={control}
                key={`${fullName}_${i}`}
                name={name as keyof FormSchema}
                value={{ id, fullName } as unknown as FormSchema[keyof FormSchema]}
              >
                <span className="truncate text-sm text-gray-700">{fullName}</span>
              </FormComboboxOption>
            ))}
        </>
      ) : isLoading ? (
        <span className="py-[6px] text-sm font-normal text-gray-700">
          <AppFormattedMessage id={StringKey.LOADING} />
        </span>
      ) : (
        <span className="py-[6px] text-sm font-normal text-gray-700">
          <AppFormattedMessage id={StringKey.NO_SHAREHOLDER_FOUND} />
        </span>
      )}
    </InfiniteScroll>
  );
};

export type StakeholderComboboxProps = {
  control: Control<FormSchema>;
  companyId: string;
  name: string;
  handleStakeholderChange: () => void;
  planType: SharePlanType;
};

export const StakeholderCombobox: FC<StakeholderComboboxProps> = ({
  control,
  companyId,
  name,
  handleStakeholderChange,
  planType,
}) => {
  const [search, setSearch] = useState<string>('');

  const { stakeholders, fetchNextPage, hasNextPage, isLoading } = useStakeholdersForExercise({
    companyId,
    search: search || '',
    planType,
  });

  const filteredStakeholders = stakeholders.filter(
    (stakeholder) => stakeholder?.vestingTasks && stakeholder?.vestingTasks?.length > 0,
  );

  const ref = useRef<HTMLInputElement>(null);
  return (
    <>
      <FormCombobox
        className="w-full"
        control={control}
        name={name as keyof FormSchema}
        onFocus={() => setSearch('')}
        onSelect={() => {
          setSearch('');
          handleStakeholderChange();
        }}
      >
        <div className="relative">
          <FormComboboxInput
            className={'bg-gray-900'}
            control={control}
            customValue={(value) => (value as StakeholderValue)?.fullName}
            icon={<ChevronDownIcon />}
            name={name as keyof FormSchema}
            readOnly
            wrapperClassName="h-10"
          />
          <ComboboxButton className="absolute left-0 top-0 z-20 h-full w-full" />
        </div>
        <ComboboxOptions className="z-20 max-h-[157px] w-fit max-w-[156px] overflow-x-hidden">
          <div className="relative flex w-full items-center" onClick={() => ref.current?.focus()}>
            <SearchIcon className="absolute ml-[6px]" />
            <input
              className="w-full rounded border-[2px] border-transparent bg-gray-50 p-[6px] pl-8 text-gray-700 outline-none placeholder:text-gray-400 focus:border-brand-700 focus:bg-white"
              onChange={({ target: { value } }) => setSearch(value)}
              ref={ref}
              value={search}
            />
          </div>
          <StakeholdersItemsList
            control={control}
            data={filteredStakeholders || []}
            fetchNextPage={fetchNextPage}
            hasNextPage={hasNextPage}
            isLoading={isLoading}
            name={name}
          />
        </ComboboxOptions>
      </FormCombobox>
    </>
  );
};
