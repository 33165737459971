import { format } from 'date-fns';
import { FC, useMemo } from 'react';
import { twMerge } from 'tailwind-merge';

import { ChevronDownIcon, InfoCircle } from '../../../../../assets/icons';
import { AppFormattedMessage } from '../../../../../components/AppFormattedMessage';
import Button from '../../../../../components/Button';
import { HoverCard, HoverCardContent, HoverCardTrigger } from '../../../../../components/HoverCard';
import { Company, SharePlan } from '../../../../../hooks';
import { StringKey } from '../../../../../lang';
import { sharePlanTypeTitle, SharePlanUnionType } from '../../../../../types/pool-plans.types';

type StickyPlanItemProps = {
  plan: SharePlanUnionType;
  isSelected: boolean;
  setSelectedPlan: (id: string) => void;
  deselectPlan: (id: string) => void;
};

export const StickyPlanItem: FC<StickyPlanItemProps> = ({
  plan,
  isSelected,
  setSelectedPlan,
  deselectPlan,
}) => {
  const { selectedCompanyId } = Company.useSelected();

  const { stakeholders: planStakeholders } = SharePlan.useStakeholders({
    companyId: selectedCompanyId,
    planId: plan.id,
  });

  const handlePlanUnselected = () => {
    deselectPlan(plan.id);
  };

  const handlePlanSelected = () => {
    setSelectedPlan(plan.id);
  };

  const isTerminatedDatePassed = useMemo(() => {
    return plan?.terminationDate
      ? new Date(plan.terminationDate).getTime() < new Date().getTime()
      : false;
  }, [plan?.terminationDate]);

  return (
    <>
      <tr
        className={twMerge(
          'flex h-[72px] w-full border-l-[2px] border-l-brand-700 bg-white transition-colors',
          isSelected && 'bg-brand-25',
        )}
        key={plan?.id}
      >
        <td className="relative h-[72px] w-[49px] border-b-[1px] border-b-gray-100 bg-gray-50">
          <div className="flex h-full w-full items-center justify-center">
            <div className="w-[49px]">
              {planStakeholders.length > 0 && (
                <Button
                  className="h-[72px] cursor-pointer p-4 transition-colors"
                  onClick={isSelected ? handlePlanUnselected : handlePlanSelected}
                  styleType="NONE"
                >
                  <ChevronDownIcon
                    className={twMerge('transition-transform', isSelected ? 'rotate-180' : '')}
                    iconColor={isSelected ? '#2565C8' : '#344054'}
                  />
                </Button>
              )}
            </div>
          </div>
        </td>
        <td
          className={twMerge(
            'flex h-[72px] w-full items-center gap-4 text-nowrap border-b-[1px] border-b-gray-100 pb-4 pl-10 pr-6 pt-4',
            plan?.isTerminated && isTerminatedDatePassed && 'justify-between',
          )}
        >
          <div className="flex flex-col">
            <span className="max-w-[300px] truncate text-sm font-[450] text-gray-900">
              {plan.name}
            </span>
            <span className="max-w-[300px] truncate text-xs font-[450] text-gray-400">
              <AppFormattedMessage id={sharePlanTypeTitle[plan?.type]} />
            </span>
          </div>
          {plan.isTerminated && (
            <>
              {isTerminatedDatePassed ? (
                <span className="rounded-2xl bg-fireside-50 px-2 py-[2px] text-label-sm font-[450] text-fireside-600">
                  <AppFormattedMessage id={StringKey.TERMINATED} />
                </span>
              ) : (
                <HoverCard>
                  <HoverCardTrigger>
                    <InfoCircle className="size-4 cursor-pointer" />
                  </HoverCardTrigger>
                  <HoverCardContent
                    className="z-20 w-max rounded-lg bg-[#101828] px-3 py-2"
                    isShownTriangle
                    side="top"
                    triangleClassName="bottom-0 left-1/2 transform -translate-x-1/2 translate-y-1/2 ml-0"
                  >
                    <span className="text-label-md font-[550] text-white">
                      <AppFormattedMessage id={StringKey.TERMINATION_SET} />{' '}
                      {plan?.terminationDate && format(plan?.terminationDate, 'dd/MM/yyyy')}
                    </span>
                  </HoverCardContent>
                </HoverCard>
              )}
            </>
          )}
        </td>
      </tr>
      {isSelected && planStakeholders && (
        <>
          {planStakeholders.map((stakeholder) => (
            <tr
              className="h-[72px] w-full border-l-2 border-brand-700 bg-white transition-colors"
              key={stakeholder.id}
            >
              <td className="w-[49px] bg-gray-50">
                <div className="flex h-full w-full cursor-pointer items-center justify-center"></div>
              </td>
              <td className="flex w-fit items-center gap-4 overflow-hidden text-nowrap pb-4 pl-12 pr-4 pt-4">
                <div className="flex max-w-[300px] items-center gap-3">
                  <div className="flex size-10 shrink-0 items-center justify-center rounded-full bg-gray-200 text-sm font-[450] text-gray-700">
                    {stakeholder.fullName[0]}
                  </div>
                  <span className="truncate text-sm font-[450] text-gray-700">
                    {stakeholder.fullName}
                  </span>
                </div>
              </td>
            </tr>
          ))}
        </>
      )}
    </>
  );
};
