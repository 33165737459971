import { StringKey } from '../../lang';

export enum GraphVariant {
  VALUATION = 'valuation',
  OWNERSHIP = 'ownership',
  OWNERSHIP_PERCENT = 'ownership-percent',
}

export const graphVariantTitle: Record<GraphVariant, StringKey> = {
  [GraphVariant.VALUATION]: StringKey.VALUATION_HISTORY,
  [GraphVariant.OWNERSHIP]: StringKey.OWNERSHIP_HISTORY,
  [GraphVariant.OWNERSHIP_PERCENT]: StringKey.OWNERSHIP_HISTORY_PERCENT,
};

export const graphVariantTitleMobile: Record<GraphVariant, string> = {
  [GraphVariant.VALUATION]: 'Valuation',
  [GraphVariant.OWNERSHIP]: 'Ownership',
  [GraphVariant.OWNERSHIP_PERCENT]: 'Ownership %',
};

export const graphVariantMap = Object.entries(graphVariantTitle);
export const graphVariantMobileMap = Object.entries(graphVariantTitleMobile);
