import React, { FC } from 'react';
import { twMerge } from 'tailwind-merge';

import { CalendarIcon } from '../../../assets/icons';
import { AppFormattedMessage } from '../../../components/AppFormattedMessage';
import { Calendar } from '../../../components/Calendar';
import { Input } from '../../../components/Input';
import { InputStatus } from '../../../components/Input/constants';
import { Popover, PopoverContent, PopoverTrigger } from '../../../components/Popover';
import { useFormat } from '../../../hooks';
import { StringKey } from '../../../lang';

type PoolMobileDatePickerProps = {
  date: Date | undefined;
  setDate: React.Dispatch<React.SetStateAction<Date | undefined>>;
  className?: string;
};

export const PoolMobileDatePicker: FC<PoolMobileDatePickerProps> = ({
  date,
  setDate,
  className,
}) => {
  const [isPopoverOpen, setIsPopoverOpen] = React.useState(false);
  const { format } = useFormat();
  const handleDatePicked = (state: Date | undefined) => {
    setDate(state);
    setIsPopoverOpen(false);
  };

  return (
    <div className={twMerge('mb-4 flex w-full items-center justify-center', className)}>
      <Popover onOpenChange={setIsPopoverOpen} open={isPopoverOpen}>
        <PopoverTrigger className="w-full min-w-[300px] max-w-[440px]">
          <Input
            customStatus={InputStatus.WITHOUT_BORDER}
            icon={<CalendarIcon className="size-6" />}
            onChange={() => ''}
            placeholder={<AppFormattedMessage id={StringKey.VEST_BY} />}
            value={date ? format(date, 'dd/MM/yyyy') : ''}
          />
        </PopoverTrigger>
        <PopoverContent className="w-auto p-0">
          <Calendar initialFocus mode="single" onSelect={handleDatePicked} selected={date} />
        </PopoverContent>
      </Popover>
    </div>
  );
};
