import React, { FC } from 'react';

import { AppFormattedMessage } from '../../../../components/AppFormattedMessage';
import Button from '../../../../components/Button';
import { SummaryItem } from '../../../../components/SummaryItem';
import { useFormat } from '../../../../hooks';
import { StringKey } from '../../../../lang';
import {
  SharePlanType,
  sharePlanTypeTitle,
  SharePlanVestingType,
  terminationIntervalTitle,
  VestingOnTitle,
} from '../../../../types/pool-plans.types';
import { ChildrenFormProps } from '../type';

export type FinalStepProps = ChildrenFormProps;

export const formatSingular = (value: number | undefined, formatString: string) =>
  value ? (value > 1 ? `${value} ${formatString}s` : `${value} ${formatString}`) : '-';

export const FinalStep: FC<FinalStepProps> = ({
  handleCloseModal,
  nextFormStep,
  formData,
  prevFormStep,
}) => {
  const { format } = useFormat();
  const { stepOne, stepTwo, stepThree, stepFour } = formData();

  return (
    <>
      <div className="flex h-full flex-col gap-4 overflow-y-auto rounded-b-xl border-b border-gray-300 bg-white p-6 pt-0">
        <div className="flex h-fit flex-col gap-2 rounded-lg bg-gray-50 pt-2">
          <span className="px-4 text-sm font-[550] text-gray-700">
            <AppFormattedMessage id={StringKey.GENERAL} />
          </span>
          <div className="flex flex-col divide-y divide-gray-100 rounded-b-lg border border-gray-100 bg-white p-4 pb-0">
            <SummaryItem
              className="pt-0"
              title={<AppFormattedMessage id={StringKey.PLAN_NAME} />}
              value={stepOne.name}
            />
            <SummaryItem title="Pool" value={stepOne.pool.name} />
            <SummaryItem
              title={<AppFormattedMessage id={StringKey.DATE} />}
              value={format(stepOne.date, 'dd/MM/yyyy')}
            />
            <SummaryItem
              title={<AppFormattedMessage id={StringKey.EQUITY_PLAN_TYPE} />}
              value={<AppFormattedMessage id={sharePlanTypeTitle[stepOne.type]} />}
            />
            {stepOne.type === SharePlanType.GROWTH_HURDLE && (
              <SummaryItem
                title={<AppFormattedMessage id={StringKey.HURDLE_VALUE} />}
                value={
                  stepOne?.financialDetails['growth-hurdle']?.value &&
                  Number(stepOne?.financialDetails['growth-hurdle']?.value).toLocaleString('en-US')
                }
              />
            )}
            {stepOne.type === SharePlanType.STOCK_OPTIONS && (
              <>
                <SummaryItem
                  title={<AppFormattedMessage id={StringKey.STRIKE_PRICE_VALUE} />}
                  value={stepOne?.financialDetails['stock-options']?.value}
                />
                <SummaryItem
                  title={<AppFormattedMessage id={StringKey.CONVERSION_RATIO_X} />}
                  value={stepOne?.financialDetails['stock-options']?.conversionRatio || '-'}
                />
              </>
            )}
            {stepOne.type === SharePlanType.BSPCE && (
              <>
                <SummaryItem
                  title={<AppFormattedMessage id={StringKey.STRIKE_PRICE_VALUE} />}
                  value={stepOne?.financialDetails['bspce']?.value}
                />
                <SummaryItem
                  title={<AppFormattedMessage id={StringKey.CONVERSION_RATIO_X} />}
                  value={stepOne?.financialDetails['bspce']?.conversionRatio || '-'}
                />
              </>
            )}
            {stepOne.type === SharePlanType.WARRANTS && (
              <>
                <SummaryItem
                  title={<AppFormattedMessage id={StringKey.WARRANT_PRICE_VALUE} />}
                  value={stepOne?.financialDetails['warrants']?.value}
                />
                <SummaryItem
                  title={<AppFormattedMessage id={StringKey.CONVERSION_RATIO_X} />}
                  value={stepOne?.financialDetails['warrants']?.conversionRatio || '-'}
                />
              </>
            )}
            {stepOne.type === SharePlanType.SAR && (
              <SummaryItem
                title={<AppFormattedMessage id={StringKey.SAR_BASE_PRICE} />}
                value={stepOne?.financialDetails['sar']?.value}
              />
            )}
            {stepOne.type === SharePlanType.RSU && (
              <SummaryItem
                title={<AppFormattedMessage id={StringKey.CONVERSION_RATIO_X} />}
                value={stepOne?.financialDetails['rsu']?.conversionRatio || '-'}
              />
            )}
            {stepOne.type === SharePlanType.PHANTOM && (
              <SummaryItem
                title={<AppFormattedMessage id={StringKey.CONVERSION_RATIO_X} />}
                value={stepOne?.financialDetails['phantom']?.conversionRatio || '-'}
              />
            )}
            {stepOne.type === SharePlanType.RSA && (
              <>
                <SummaryItem
                  title={<AppFormattedMessage id={StringKey.RSA_PURCHASE_PRICE} />}
                  value={stepOne?.financialDetails['rsa']?.value || '-'}
                />
                <SummaryItem
                  title={<AppFormattedMessage id={StringKey.CONVERSION_RATIO_X} />}
                  value={stepOne?.financialDetails['rsa']?.conversionRatio || '-'}
                />
              </>
            )}
          </div>
        </div>
        {stepTwo?.vesting?.enabled && (
          <div className="flex h-fit flex-col gap-2 rounded-lg bg-gray-50 pt-2">
            <span className="px-4 text-sm font-[550] text-gray-700">
              <AppFormattedMessage id={StringKey.VESTING} />
            </span>
            <div className="flex flex-col divide-y divide-gray-100 rounded-b-lg border border-gray-100 bg-white p-4 pb-3">
              {stepTwo?.vesting?.vestingMechanism === SharePlanVestingType.TIME_BASED_STANDARD && (
                <>
                  <div>
                    <SummaryItem
                      className="pt-0"
                      title={<AppFormattedMessage id={StringKey.TYPE} />}
                      value={<AppFormattedMessage id={StringKey.TIME_BASED_STANDARD} />}
                    />
                    <SummaryItem
                      className="pt-0"
                      title={<AppFormattedMessage id={StringKey.VESTING_ON} />}
                      value={
                        stepTwo.vesting?.timeBasedStandard?.vestingOn && (
                          <AppFormattedMessage
                            id={VestingOnTitle[stepTwo.vesting?.timeBasedStandard?.vestingOn]}
                          />
                        )
                      }
                    />
                  </div>
                  <div>
                    <SummaryItem
                      className="pb-0"
                      title={<AppFormattedMessage id={StringKey.DURATION} />}
                      value={formatSingular(
                        stepTwo?.vesting?.timeBasedStandard?.vestingDuration,
                        'month',
                      )}
                    />
                    <SummaryItem
                      className="pb-0"
                      title={<AppFormattedMessage id={StringKey.VESTING_FREQUENCY_ABBR} />}
                      value={formatSingular(
                        stepTwo?.vesting?.timeBasedStandard?.vestingFrequency,
                        'month',
                      )}
                    />
                    <SummaryItem
                      className="pb-0"
                      title={<AppFormattedMessage id={StringKey.CLIFF} />}
                      value={formatSingular(
                        stepTwo?.vesting?.timeBasedStandard?.cliffPeriod,
                        'month',
                      )}
                    />
                    <SummaryItem
                      className={!stepTwo?.expiryDate?.enabled && 'pb-0'}
                      title={<AppFormattedMessage id={StringKey.ACCELERATION_DATE} />}
                      value={
                        stepTwo?.vesting?.timeBasedStandard?.accelerationDate
                          ? format(
                              stepTwo?.vesting?.timeBasedStandard?.accelerationDate,
                              'dd/MM/yyyy',
                            )
                          : '-'
                      }
                    />
                  </div>
                </>
              )}
              {stepTwo?.vesting?.vestingMechanism === SharePlanVestingType.TIME_BASED_DYNAMIC && (
                <>
                  <div>
                    <SummaryItem
                      className="pt-0"
                      title={<AppFormattedMessage id={StringKey.TYPE} />}
                      value={<AppFormattedMessage id={StringKey.TIME_BASED_DYNAMIC} />}
                    />
                    <SummaryItem
                      className="pt-0"
                      title={<AppFormattedMessage id={StringKey.VESTING_ON} />}
                      value={
                        stepTwo.vesting?.timeBasedDynamic?.vestingOn && (
                          <AppFormattedMessage
                            id={VestingOnTitle[stepTwo.vesting?.timeBasedDynamic?.vestingOn]}
                          />
                        )
                      }
                    />
                  </div>

                  <div className="flex flex-col divide-y divide-gray-100">
                    {stepTwo?.vesting?.timeBasedDynamic?.dynamicVestings.map((period, index) => (
                      <div className="pt-3" key={index}>
                        <span className="text-xs font-[550] text-gray-700">
                          <AppFormattedMessage id={StringKey.PERIOD} /> {index + 1}
                        </span>
                        <SummaryItem
                          className="pb-0"
                          title={<AppFormattedMessage id={StringKey.DURATION} />}
                          value={formatSingular(period.vestingDuration, 'month')}
                        />
                        <SummaryItem
                          className="pb-0"
                          title={<AppFormattedMessage id={StringKey.VESTING_FREQUENCY_ABBR} />}
                          value={formatSingular(period.vestingFrequency, 'month')}
                        />
                        <SummaryItem
                          title={<AppFormattedMessage id={StringKey.PLAN_ALLOCATION} />}
                          value={`${period.planAllocation} %`}
                        />
                      </div>
                    ))}
                  </div>
                  <SummaryItem
                    className="pb-0"
                    title={<AppFormattedMessage id={StringKey.ACCELERATION_DATE} />}
                    value={
                      stepTwo?.vesting?.timeBasedDynamic?.accelerationDate
                        ? format(stepTwo?.vesting?.timeBasedDynamic?.accelerationDate, 'dd/MM/yyyy')
                        : '-'
                    }
                  />
                </>
              )}
              {stepTwo?.vesting?.vestingMechanism === SharePlanVestingType.TARGET_BASED && (
                <>
                  <SummaryItem
                    className="pt-0"
                    title={<AppFormattedMessage id={StringKey.TYPE} />}
                    value={<AppFormattedMessage id={StringKey.TARGET_BASED} />}
                  />
                  {stepTwo?.vesting?.targetBasedStandard?.targetVestings.map((period, index) => (
                    <div className="pt-3" key={index}>
                      <span className="text-xs font-[550] text-gray-700">
                        <AppFormattedMessage id={StringKey.MILESTONE} /> {index + 1}
                      </span>
                      <SummaryItem
                        className="pb-0"
                        title={<AppFormattedMessage id={StringKey.MILESTONE_NAME} />}
                        value={period?.name}
                      />
                      <SummaryItem
                        className="pb-0"
                        title={<AppFormattedMessage id={StringKey.WEIGHT} />}
                        value={`${period?.weight} %`}
                      />
                      <SummaryItem
                        title={<AppFormattedMessage id={StringKey.TARGET_DATE} />}
                        value={period.targetDate ? format(period.targetDate, 'dd/MM/yyyy') : '-'}
                      />
                    </div>
                  ))}
                </>
              )}
            </div>
          </div>
        )}

        {stepTwo?.expiryDate?.enabled && (
          <div className="w-full rounded-lg border border-gray-100 bg-white px-4 py-2">
            <SummaryItem
              className="p-0"
              title={<AppFormattedMessage id={StringKey.EXPIRY_DATE} />}
              value={format(stepTwo.expiryDate.date, 'dd/MM/yyyy')}
            />
          </div>
        )}

        {stepThree?.terminationLogic?.enabled && (
          <div className="flex h-fit flex-col gap-2 rounded-lg bg-gray-50 pt-2">
            <span className="px-4 text-sm font-[550] text-gray-700">
              <AppFormattedMessage id={StringKey.TERMINATION_LOGIC} />
            </span>
            <div className="flex flex-col divide-y divide-gray-100 rounded-b-lg border border-gray-100 bg-white p-4 pb-0">
              {stepThree?.terminationLogic?.resignation?.enabled ? (
                <div>
                  <SummaryItem
                    className="pb-0"
                    title={<AppFormattedMessage id={StringKey.RESIGNATION} />}
                    value={<AppFormattedMessage id={StringKey.EXERCISE_EXPIRATION_PERIOD} />}
                  />
                  <SummaryItem
                    className="pb-0"
                    title={<AppFormattedMessage id={StringKey.VALUE} />}
                    value={stepThree?.terminationLogic.resignation.value}
                  />
                  <SummaryItem
                    title={<AppFormattedMessage id={StringKey.INTERVAL} />}
                    value={
                      <AppFormattedMessage
                        id={
                          terminationIntervalTitle[stepThree.terminationLogic.resignation.interval]
                        }
                      />
                    }
                  />
                </div>
              ) : (
                <SummaryItem
                  title={<AppFormattedMessage id={StringKey.RESIGNATION} />}
                  value={<AppFormattedMessage id={StringKey.NO_EXERCISE_EXPIRATION_PERIOD} />}
                />
              )}
              {stepThree?.terminationLogic?.termination?.enabled ? (
                <div>
                  <SummaryItem
                    className="pb-0"
                    title={<AppFormattedMessage id={StringKey.TERMINATION} />}
                    value={<AppFormattedMessage id={StringKey.EXERCISE_EXPIRATION_PERIOD} />}
                  />
                  <SummaryItem
                    className="pb-0"
                    title={<AppFormattedMessage id={StringKey.VALUE} />}
                    value={stepThree.terminationLogic.termination.value}
                  />
                  <SummaryItem
                    title={<AppFormattedMessage id={StringKey.INTERVAL} />}
                    value={
                      <AppFormattedMessage
                        id={
                          terminationIntervalTitle[stepThree.terminationLogic.termination.interval]
                        }
                      />
                    }
                  />
                </div>
              ) : (
                <SummaryItem
                  title={<AppFormattedMessage id={StringKey.TERMINATION} />}
                  value={<AppFormattedMessage id={StringKey.NO_EXERCISE_EXPIRATION_PERIOD} />}
                />
              )}
              {stepThree?.terminationLogic?.terminationWithCause?.enabled ? (
                <div>
                  <SummaryItem
                    className="pb-0"
                    title={<AppFormattedMessage id={StringKey.TERMINATION_WITH_CAUSE} />}
                    value={<AppFormattedMessage id={StringKey.EXERCISE_EXPIRATION_PERIOD} />}
                  />
                  <SummaryItem
                    className="pb-0"
                    title={<AppFormattedMessage id={StringKey.VALUE} />}
                    value={stepThree.terminationLogic.terminationWithCause.value}
                  />
                  <SummaryItem
                    title={<AppFormattedMessage id={StringKey.INTERVAL} />}
                    value={
                      <AppFormattedMessage
                        id={
                          terminationIntervalTitle[
                            stepThree.terminationLogic.terminationWithCause.interval
                          ]
                        }
                      />
                    }
                  />
                </div>
              ) : (
                <SummaryItem
                  title={<AppFormattedMessage id={StringKey.TERMINATION_WITH_CAUSE} />}
                  value={<AppFormattedMessage id={StringKey.NO_EXERCISE_EXPIRATION_PERIOD} />}
                />
              )}
              {stepThree?.terminationLogic?.retirement?.enabled ? (
                <div>
                  <SummaryItem
                    className="pb-0"
                    title={<AppFormattedMessage id={StringKey.RETIREMENT} />}
                    value={<AppFormattedMessage id={StringKey.EXERCISE_EXPIRATION_PERIOD} />}
                  />
                  <SummaryItem
                    className="pb-0"
                    title={<AppFormattedMessage id={StringKey.VALUE} />}
                    value={stepThree?.terminationLogic.retirement.value}
                  />
                  <SummaryItem
                    title={<AppFormattedMessage id={StringKey.INTERVAL} />}
                    value={
                      <AppFormattedMessage
                        id={
                          terminationIntervalTitle[stepThree.terminationLogic.retirement.interval]
                        }
                      />
                    }
                  />
                </div>
              ) : (
                <SummaryItem
                  title={<AppFormattedMessage id={StringKey.RETIREMENT} />}
                  value={<AppFormattedMessage id={StringKey.NO_EXERCISE_EXPIRATION_PERIOD} />}
                />
              )}
            </div>
          </div>
        )}

        <div className="flex w-full flex-col rounded-lg border border-gray-100 bg-white">
          <div className={'flex gap-3 text-nowrap px-4 py-2'}>
            <span className="w-[120px] flex-shrink-0 text-xs font-[450] text-gray-500">
              <AppFormattedMessage id={StringKey.DOCUMENTS} />:
            </span>
            <div className="flex max-w-[220px] flex-col gap-2">
              {stepFour?.files && stepFour?.files?.length > 0 ? (
                stepFour.files.map(({ id, doc }) => (
                  <span className="truncate text-xs font-[450] text-gray-700" key={id}>
                    {doc.name}
                  </span>
                ))
              ) : (
                <span className="truncate text-xs font-[450] text-gray-700">-</span>
              )}
            </div>
          </div>
        </div>
        <div className="flex w-full flex-col rounded-lg border border-gray-100 bg-white">
          <div className="flex w-full gap-3 px-4 py-2">
            <span className="w-[120px] flex-shrink-0 text-xs font-[450] text-gray-500">
              {<AppFormattedMessage id={StringKey.ADDITIONAL_NOTES} />}:
            </span>
            <div className="max-w-[250px] break-words text-xs font-[450] text-gray-700">
              {stepFour?.additionalNotes || '-'}
            </div>
          </div>
        </div>
      </div>

      <div className="flex h-9 w-full justify-between gap-3 px-6">
        <Button
          className="w-fit bg-transparent px-4 py-[6px] text-sm font-[450] text-gray-700"
          onClick={handleCloseModal}
          styleType="NONE"
          type="button"
        >
          <AppFormattedMessage id={StringKey.CANCEL} />
        </Button>
        <div className="flex gap-3">
          <Button
            className="h-full w-fit rounded border border-gray-100 bg-white px-3 py-[6px] text-sm font-[450] text-gray-700 shadow-xs"
            onClick={prevFormStep}
            styleType="NONE"
            type="button"
          >
            <AppFormattedMessage id={StringKey.BACK} />
          </Button>

          <Button
            className="h-full w-fit px-4 py-[6px] text-sm font-[550] text-white"
            onClick={nextFormStep}
            type="button"
          >
            <AppFormattedMessage id={StringKey.ADD_NEW_PLAN} />
          </Button>
        </div>
      </div>
    </>
  );
};
