import React, { FC, useState } from 'react';
import { twMerge } from 'tailwind-merge';

import { ChevronDownIcon } from '../../../assets/icons';
import { AlertDialogWrapper } from '../../../components/AlertDialog';
import { AppFormattedMessage } from '../../../components/AppFormattedMessage';
import Button from '../../../components/Button';
import { EmptySearchList } from '../../../components/EmptySearchList';
import { useModalState } from '../../../hooks';
import { StringKey } from '../../../lang';
import { ShareClass, ShareClassOrder, ShareClassSortBy } from '../../../types/share-classes.types';
import { DeleteShareClassModal } from '../Modals/DeleteShareClassModal';
import { ShareClassTableItem } from './TableItem';

export type ShareClassTableProps = {
  data: ShareClass[];
  className?: string;
  onSortClick: (field: ShareClassSortBy) => void;
  handleEdit: (id: string) => void;
  handleDelete: (id: string) => void;
  sortMode: ShareClassOrder | null;
  sortField: ShareClassSortBy | null;
  searchValue: string;
  isDemo: boolean;
};

export const ShareClassTable: FC<ShareClassTableProps> = ({
  data,
  className,
  onSortClick,
  sortMode,
  sortField,
  searchValue,
  handleEdit,
  handleDelete,
  isDemo,
}) => {
  const [selectedId, setSelectedId] = useState<string | null>(null);
  const handleDocumentClick = (link: string) => {
    window.open(link);
  };

  const { handleCloseModal, handleSuccessModal, handleOpenModal, isOpen, toggler } = useModalState({
    onSuccess: () => {
      if (selectedId) {
        handleDelete(selectedId);
      }
    },
  });

  const openDeleteModal = (id: string) => {
    setSelectedId(id);
    handleOpenModal();
  };

  return (
    <>
      <AlertDialogWrapper control={{ onOpenChange: toggler, open: isOpen }}>
        <DeleteShareClassModal onClose={handleCloseModal} onSuccess={handleSuccessModal} />
      </AlertDialogWrapper>
      <div className="w-full shrink-0 overflow-x-auto *:text-nowrap">
        <table
          className={twMerge(
            'h-fit w-full',
            searchValue && data?.length === 0
              ? 'divide-y-0 bg-white'
              : 'divide-y-[1px] divide-[#F2F2F2] border-b-[1px]',
            className,
          )}
        >
          <thead>
            <tr className="h-11 bg-gray-50">
              <td className="pl-4">
                <div
                  className="flex items-center gap-1"
                  onClick={() => onSortClick(ShareClassSortBy.NAME)}
                >
                  <span className="text-label-md font-[450] text-[#172335]">
                    <AppFormattedMessage id={StringKey.NAME} />
                  </span>
                  <Button
                    className="relative flex size-4 justify-center rounded hover:bg-gray-200"
                    styleType="NONE"
                  >
                    {sortField === ShareClassSortBy.NAME ? (
                      sortMode == null ? (
                        <>
                          <ChevronDownIcon className="absolute -top-[3px] w-2 rotate-180" />
                          <ChevronDownIcon className="absolute -bottom-[3px] w-2" />
                        </>
                      ) : (
                        <>
                          {sortMode === ShareClassOrder.ASC ? (
                            <ChevronDownIcon className="w-2" />
                          ) : (
                            <ChevronDownIcon className="w-2 rotate-180" />
                          )}
                        </>
                      )
                    ) : (
                      <>
                        <ChevronDownIcon className="absolute -top-[3px] w-2 rotate-180" />
                        <ChevronDownIcon className="absolute -bottom-[3px] w-2" />
                      </>
                    )}
                  </Button>
                </div>
              </td>

              <td className="pl-4">
                <div
                  className="flex items-center gap-1"
                  onClick={() => onSortClick(ShareClassSortBy.CREATION_DATE)}
                >
                  <span className="text-label-md font-[450] text-[#172335]">
                    {' '}
                    <AppFormattedMessage id={StringKey.CREATION_DATE} />
                  </span>
                  <Button
                    className="relative flex size-4 justify-center rounded hover:bg-gray-200"
                    styleType="NONE"
                  >
                    {sortField === ShareClassSortBy.CREATION_DATE ? (
                      sortMode == null ? (
                        <>
                          <ChevronDownIcon className="absolute -top-[3px] w-2 rotate-180" />
                          <ChevronDownIcon className="absolute -bottom-[3px] w-2" />
                        </>
                      ) : (
                        <>
                          {sortMode === ShareClassOrder.ASC ? (
                            <ChevronDownIcon className="w-2" />
                          ) : (
                            <ChevronDownIcon className="w-2 rotate-180" />
                          )}
                        </>
                      )
                    ) : (
                      <>
                        <ChevronDownIcon className="absolute -top-[3px] w-2 rotate-180" />
                        <ChevronDownIcon className="absolute -bottom-[3px] w-2" />
                      </>
                    )}
                  </Button>
                </div>
              </td>

              <td className="pl-4">
                <div
                  className="flex items-center gap-1"
                  onClick={() => onSortClick(ShareClassSortBy.STAKEHOLDERS)}
                >
                  <span className="text-label-md font-[450] text-[#172335]">
                    <AppFormattedMessage id={StringKey.STAKEHOLDERS} />
                  </span>
                  <Button
                    className="relative flex size-4 justify-center rounded hover:bg-gray-200"
                    styleType="NONE"
                  >
                    {sortField === ShareClassSortBy.STAKEHOLDERS ? (
                      sortMode == null ? (
                        <>
                          <ChevronDownIcon className="absolute -top-[3px] w-2 rotate-180" />
                          <ChevronDownIcon className="absolute -bottom-[3px] w-2" />
                        </>
                      ) : (
                        <>
                          {sortMode === ShareClassOrder.ASC ? (
                            <ChevronDownIcon className="w-2" />
                          ) : (
                            <ChevronDownIcon className="w-2 rotate-180" />
                          )}
                        </>
                      )
                    ) : (
                      <>
                        <ChevronDownIcon className="absolute -top-[3px] w-2 rotate-180" />
                        <ChevronDownIcon className="absolute -bottom-[3px] w-2" />
                      </>
                    )}
                  </Button>
                </div>
              </td>

              <td className="pl-4">
                <div className="flex items-center gap-1">
                  <span className="text-label-md font-[450] text-[#172335]">
                    <AppFormattedMessage id={StringKey.RIGHTS} />
                  </span>
                </div>
              </td>

              <td className="pl-4">
                <div
                  className="flex items-center gap-1"
                  onClick={() => onSortClick(ShareClassSortBy.VOTING_RIGHT)}
                >
                  <span className="text-label-md font-[450] text-[#172335]">
                    <AppFormattedMessage id={StringKey.VOTING_RIGHT} />
                  </span>
                  <Button
                    className="relative flex size-4 justify-center rounded hover:bg-gray-200"
                    styleType="NONE"
                  >
                    {sortField === ShareClassSortBy.VOTING_RIGHT ? (
                      sortMode == null ? (
                        <>
                          <ChevronDownIcon className="absolute -top-[3px] w-2 rotate-180" />
                          <ChevronDownIcon className="absolute -bottom-[3px] w-2" />
                        </>
                      ) : (
                        <>
                          {sortMode === ShareClassOrder.ASC ? (
                            <ChevronDownIcon className="w-2" />
                          ) : (
                            <ChevronDownIcon className="w-2 rotate-180" />
                          )}
                        </>
                      )
                    ) : (
                      <>
                        <ChevronDownIcon className="absolute -top-[3px] w-2 rotate-180" />
                        <ChevronDownIcon className="absolute -bottom-[3px] w-2" />
                      </>
                    )}
                  </Button>
                </div>
              </td>

              <td className="pl-4">
                <div
                  className="flex items-center gap-1"
                  onClick={() => onSortClick(ShareClassSortBy.CONVERSION_RATIO)}
                >
                  <span className="text-label-md font-[450] text-[#172335]">
                    <AppFormattedMessage id={StringKey.CONVERSION_RATIO} />
                  </span>
                  <Button
                    className="justifyShareClassSortBy.CONVERSION_RATIO relative flex size-4 hover:bg-gray-200"
                    styleType="NONE"
                  >
                    {sortField === ShareClassSortBy.CONVERSION_RATIO ? (
                      sortMode == null ? (
                        <>
                          <ChevronDownIcon className="absolute -top-[3px] w-2 rotate-180" />
                          <ChevronDownIcon className="absolute -bottom-[3px] w-2" />
                        </>
                      ) : (
                        <>
                          {sortMode === ShareClassOrder.ASC ? (
                            <ChevronDownIcon className="w-2" />
                          ) : (
                            <ChevronDownIcon className="w-2 rotate-180" />
                          )}
                        </>
                      )
                    ) : (
                      <>
                        <ChevronDownIcon className="absolute -top-[3px] w-2 rotate-180" />
                        <ChevronDownIcon className="absolute -bottom-[3px] w-2" />
                      </>
                    )}
                  </Button>
                </div>
              </td>
              <td className="pl-4">
                <div className="flex items-center gap-1">
                  <span className="text-label-md font-[450] text-[#172335]">
                    <AppFormattedMessage id={StringKey.SENIORITY} />
                  </span>
                </div>
              </td>

              <td className="pl-4">
                <div className="flex items-center gap-1">
                  <span className="text-label-md font-[450] text-[#172335]">
                    <AppFormattedMessage id={StringKey.LIQUIDITY_PREF} />
                  </span>
                </div>
              </td>
              <td className="pl-4">
                <div className="flex items-center gap-1">
                  <span className="text-label-md font-[450] text-[#172335]">
                    <AppFormattedMessage id={StringKey.DOC} />
                  </span>
                </div>
              </td>

              <td className="pl-4">
                <div className="flex items-center gap-1">
                  <span className="text-label-md font-[450] text-[#172335]">
                    <AppFormattedMessage id={StringKey.STATUS} />
                  </span>
                </div>
              </td>
            </tr>
          </thead>

          <tbody
            className={twMerge(
              'w-full bg-gray-600',
              searchValue && data?.length === 0
                ? '!divide-y-0 bg-white'
                : 'divide-y-[1px] divide-[#F2F2F2]',
            )}
          >
            {searchValue && data?.length === 0 && (
              <tr>
                <td colSpan={100}>
                  <div className="flex w-full items-center justify-center">
                    <EmptySearchList className="mt-20" />
                  </div>
                </td>
              </tr>
            )}
            {data.map((shareClass) => (
              <ShareClassTableItem
                isDemo={isDemo}
                key={shareClass.id}
                {...shareClass}
                handleDelete={openDeleteModal}
                handleDocumentClick={handleDocumentClick}
                handleEdit={handleEdit}
              />
            ))}
          </tbody>
        </table>
      </div>
    </>
  );
};
