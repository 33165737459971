import { StringKey } from '../lang';
import { Company } from './companyTypes';
import { Country } from './countryTypes';
import { SharePlanType } from './pool-plans.types';
import { VestingTask } from './vestingTasks.types';

export enum StakeholderRole {
  STAKEHOLDER = 'stakeholder',
  CONTRIBUTOR = 'contributor',
  ADMIN = 'admin',
}

export enum StakeholderType {
  ANGEL = 'angel',
  EMPLOYEE = 'employee',
  FOUNDER = 'founder',
  ADVISOR = 'advisor',
  BOARD_MEMBERS = 'board-members',
  VCS = 'vcs',
  INVESTMENT_BANKS = 'investment-banks',
  PES = 'pes',
  FAMILY_OFFICE = 'family-office',
  OTHER_INST = 'other-inst',
}

export const stakeholderTypeTitle: Record<StakeholderType, StringKey> = {
  [StakeholderType.ADVISOR]: StringKey.ADVISOR,
  [StakeholderType.ANGEL]: StringKey.ANGELS,
  [StakeholderType.EMPLOYEE]: StringKey.EMPLOYEE,
  [StakeholderType.FOUNDER]: StringKey.FOUNDER,
  [StakeholderType.BOARD_MEMBERS]: StringKey.BOARD_MEMBERS,
  [StakeholderType.VCS]: StringKey.VENTURE_CAPITAL,
  [StakeholderType.INVESTMENT_BANKS]: StringKey.INVESTMENT_BANKS,
  [StakeholderType.PES]: StringKey.PRIVATE_EQUITY,
  [StakeholderType.FAMILY_OFFICE]: StringKey.FAMILY_OFFICE,
  [StakeholderType.OTHER_INST]: StringKey.OTHER_INST,
};

export const stakeholderSingularTypeTitle: Record<StakeholderType, string> = {
  [StakeholderType.ADVISOR]: 'Advisor',
  [StakeholderType.ANGEL]: 'Angel',
  [StakeholderType.EMPLOYEE]: 'Employee',
  [StakeholderType.FOUNDER]: 'Founder',
  [StakeholderType.BOARD_MEMBERS]: 'Board Member',
  [StakeholderType.VCS]: 'Venture Capital',
  [StakeholderType.INVESTMENT_BANKS]: 'Investment Bank',
  [StakeholderType.PES]: 'Private Eq Firm',
  [StakeholderType.FAMILY_OFFICE]: 'Family Office',
  [StakeholderType.OTHER_INST]: 'Other',
};

export const stakeholderExTypeTitle: Record<StakeholderType, string> = {
  [StakeholderType.ADVISOR]: 'Ex-Advisor',
  [StakeholderType.ANGEL]: '',
  [StakeholderType.EMPLOYEE]: 'Ex-Employee',
  [StakeholderType.FOUNDER]: 'Ex-Founder',
  [StakeholderType.BOARD_MEMBERS]: '',
  [StakeholderType.VCS]: '',
  [StakeholderType.INVESTMENT_BANKS]: '',
  [StakeholderType.PES]: '',
  [StakeholderType.FAMILY_OFFICE]: '',
  [StakeholderType.OTHER_INST]: '',
};

export const b2bStakeholderTypes: StakeholderType[] = [
  StakeholderType.ANGEL,
  StakeholderType.FOUNDER,
  StakeholderType.ADVISOR,
  StakeholderType.EMPLOYEE,
  StakeholderType.BOARD_MEMBERS,
];
export const b2cStakeholderTypes: StakeholderType[] = [
  StakeholderType.INVESTMENT_BANKS,
  StakeholderType.PES,
  StakeholderType.FAMILY_OFFICE,
  StakeholderType.OTHER_INST,
  StakeholderType.VCS,
];

export enum AmountOfFetchedItems {
  FIVE = '5',
  TEN = '10',
  TWENTY = '20',
  FIFTY = '50',
  HUNDRED = '100',
}

export enum StakeholderTypeGroup {
  HUMAN = 'human',
  COMPANY = 'company',
}

export enum StakeholderOrder {
  ASC = 'asc',
  DESC = 'desc',
}

export enum StakeholderSortBy {
  FULL_NAME = 'fullName',
  TYPE = 'type',
  VOTING_RIGHTS = 'voting-rights',
}

export interface Stakeholder {
  address?: string;
  birthday?: string;
  capTableAccess: boolean;
  capitalChangeAccess: boolean;
  company?: Company;
  companyTableAccess: boolean;
  countryOfInc?: Country;
  createdAt: string;
  email: string;
  fullName: string;
  id: string;
  incDate?: string;
  isAccessRevoked: boolean;
  isActive: boolean;
  isInvited: boolean;
  lastActive: string;
  nationalityCountry?: Country;
  passportExpDate?: string;
  passportNo?: string;
  phone?: string;
  phoneCountryIso2?: string;
  positionAccess: boolean;
  role: StakeholderRole;
  stakeholderCompanyId?: string;
  type: StakeholderType;
  votingRightsPercentage?: number;
  isTerminated: boolean;
  terminationDate?: string;
  lastVestingDate?: string;
  terminationCause?: TerminationCause;
  hasShares?: boolean;
  vestingTasks?: VestingTask[];
  filesLinks: string[];
  additionalNotes?: string;
}

export type PlanStakeholder = Stakeholder & {
  granted: number;
  sharesVested: number;
  exercisable: number;
  exercised: number;
  expired: number;
  totalGrantVested: number;
};

export enum StakeholderFilter {
  ACTIVE = 'active',
  INACTIVE = 'inactive',
}

export type FilterVariant = 'all' | StakeholderFilter;

export const filterVariant: Record<FilterVariant, StringKey> = {
  all: StringKey.FILTER_ALL,
  [StakeholderFilter.ACTIVE]: StringKey.FILTER_ACTIVE,
  [StakeholderFilter.INACTIVE]: StringKey.FILTER_INACTIVE,
};

export const filterVariantMap = Object.entries(filterVariant);

export type HumanStakeholderDto = {
  fullName: string;
  email: string;
  type: StakeholderType;
  passportNo?: string;
  passportExpDate?: string;
  nationality?: string;
  birthday?: string;
  phone?: string;
  phoneCountryIso2?: string;
  address?: string;
  capTableAccess?: boolean;
  positionAccess?: boolean;
  capitalChangeAccess?: boolean;
  companyTableAccess?: boolean;
  filesLinks: string[];
  additionalNotes?: string;
};

export type CompanyStakeholderDto = {
  fullName: string;
  email: string;
  type: StakeholderType;
  countryOfInc?: string;
  stakeholderCompanyId?: string;
  incDate?: string;
  phone?: string;
  phoneCountryIso2?: string;
  address?: string;
  capTableAccess?: boolean;
  positionAccess?: boolean;
  capitalChangeAccess?: boolean;
  companyTableAccess?: boolean;
  filesLinks: string[];
  additionalNotes?: string;
};

export enum StakeholderBulkAction {
  INVITE = 'invite',
  CANCEL_INVITATION = 'cancel-invitation',
  TOGGLE_CONTRIBUTOR = 'toggle-contributor',
  TOGGLE_ACCESS = 'toggle-access',
  DELETE = 'delete',
}

export type StakeholderOwnershipSummary = {
  companyOwned: number;
  votingPercentage: number;
  numberOfShares: number;
  valueOfShares: number;
  sharePrice: number;
  investment: number;
  roi: number;
};

export type InvestmentSummary = {
  companyTotalRounds: number;
  currentValuationValue: number;
  totalAmountRaise: number;
  latestSharePrice: number;
  stakeholderTotalRounds: number;
  stakeholderTotalInvestment: number;
  stakeholderEstimatedValue: number;
  stakeholderAverageInvestment: number;
  equityChange: number;
  averageSharePrice: number;
};

export type StakeholderBulkActionDto = {
  stakeholderIds: string[];
};

export enum TerminationCause {
  RESIGNATION = 'resignation',
  TERMINATION = 'termination',
  TERMINATION_WITH_CAUSE = 'termination-with-cause',
  RETIREMENT = 'retirement',
}

export const TerminationCauseTitle: Record<TerminationCause, StringKey> = {
  [TerminationCause.RESIGNATION]: StringKey.RESIGNATION,
  [TerminationCause.TERMINATION]: StringKey.TERMINATION,
  [TerminationCause.TERMINATION_WITH_CAUSE]: StringKey.TERMINATION_WITH_CAUSE,
  [TerminationCause.RETIREMENT]: StringKey.RETIREMENT,
};

export type TerminateGrantItemDto = {
  id: string;
  finalExerciseDate?: string;
  planType: SharePlanType;
  numbersOfGrants: number | null;
  vestedSharesCount: number | null;
  unvestedSharesCount: number | null;
  exercisedCount: number | null;
  unexercisedSharesCount: number | null;
};

export type TerminateStakeholderItemDto = {
  id: string;
  terminationDate: string;
  terminationCause: TerminationCause;
  lastVestingDate?: string;
  grantItems?: TerminateGrantItemDto[];
};

export type TerminateStakeholderDto = {
  stakeholders: TerminateStakeholderItemDto[];
  sendEmails: boolean;
};
