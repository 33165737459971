import { ReactNode } from 'react';
import { DateRange } from 'react-day-picker';

import { StringKey } from '../../../../../lang';
import {
  EventExercisedTypeBackend,
  EventTypeBackend,
  EventUnion,
  SortByDate,
} from '../../../../../types/events.types';

export enum AmountOfFetchedItems {
  THREE = '3',
  SIX = '6',
  TEN = '10',
}
export const numberOfFetchedItemsSelectVariant = Object.values(AmountOfFetchedItems);

export type TableBodyProps = EventUnion & {
  onView: (id: string, transactionId: string) => void;
  onDelete: (event: EventUnion, transactionId: string) => void;
  onEdit: (id: string, transactionId: string) => void;
  companyId: string;
  selectedDateFilter: SortByDate;
  selectedCustomDateFilter: DateRange;
  name: string | ReactNode;
};

export const tableHeadVariant: Record<EventTypeBackend | EventExercisedTypeBackend, StringKey[]> = {
  [EventExercisedTypeBackend.WARRANTS]: [
    StringKey.TRANSACTION_DATE,
    StringKey.TRANSACTION_TYPE,
    StringKey.STAKEHOLDER,
    StringKey.EXERCISED_OPTIONS,
    StringKey.SHARE_CLASS,
    StringKey.ISSUED_SHARES,
  ],

  [EventExercisedTypeBackend.SAR]: [
    StringKey.TRANSACTION_DATE,
    StringKey.TRANSACTION_TYPE,
    StringKey.STAKEHOLDER,
    StringKey.SAR_EXERCISED,
    StringKey.SHARE_CLASS,
    StringKey.SAR_PAYOUT,
    StringKey.ISSUED_SHARES,
  ],

  [EventExercisedTypeBackend.RSA]: [
    StringKey.TRANSACTION_DATE,
    StringKey.TRANSACTION_TYPE,
    StringKey.STAKEHOLDER,
    StringKey.EXERCISED_OPTIONS,
    StringKey.SHARE_CLASS,
    StringKey.PURCHASE_PRICE,
    StringKey.ISSUED_SHARES,
  ],

  [EventExercisedTypeBackend.STOCK_OPTIONS]: [
    StringKey.TRANSACTION_DATE,
    StringKey.TRANSACTION_TYPE,
    StringKey.STAKEHOLDER,
    StringKey.EXERCISED_WARRANTS,
    StringKey.SHARE_CLASS,
    StringKey.ISSUED_SHARES,
  ],

  [EventTypeBackend.EXERCISE]: [
    StringKey.TRANSACTION_DATE,
    StringKey.TRANSACTION_TYPE,
    StringKey.STAKEHOLDER,
    StringKey.EXERCISED_OPTIONS,
    StringKey.SHARE_CLASS,
    StringKey.ISSUED_SHARES,
  ],

  [EventTypeBackend.APPROVAL]: [
    StringKey.TRANSACTION_DATE,
    StringKey.TRANSACTION_TYPE,
    StringKey.STAKEHOLDER,
    StringKey.SHARE_CLASS,
    StringKey.HURDLE_VALUE,
    StringKey.ISSUED_SHARES,
  ],

  [EventTypeBackend.PAYOUT]: [
    StringKey.TRANSACTION_DATE,
    StringKey.TRANSACTION_TYPE,
    StringKey.STAKEHOLDER,
    StringKey.SHARES_PAID_OUT,
    StringKey.TOTAL_PAYOUT,
  ],

  [EventTypeBackend.EXPIRE]: [
    StringKey.TRANSACTION_DATE,
    StringKey.TRANSACTION_TYPE,
    StringKey.STAKEHOLDER,
    StringKey.EXPIRED_WARRANTS,
    StringKey.SHARE_CLASS,
  ],

  [EventTypeBackend.CONVERSION]: [
    StringKey.TRANSACTION_DATE,
    StringKey.TRANSACTION_TYPE,
    StringKey.FROM_SHARE_CLASS,
    StringKey.TO_SHARE_CLASS,
    StringKey.STAKEHOLDER,
    StringKey.NO_OF_SHARES,
    StringKey.EMPTY,
  ],
  [EventTypeBackend.GRANT]: [
    StringKey.TRANSACTION_DATE,
    StringKey.TRANSACTION_TYPE,
    StringKey.STAKEHOLDER,
    StringKey.GRANT_ID,
    StringKey.NO_OF_SHARES,
    StringKey.EQUITY_PLAN,
    StringKey.FILE,
    StringKey.EMPTY,
  ],
  [EventTypeBackend.BUYBACK]: [
    StringKey.TRANSACTION_DATE,
    StringKey.TRANSACTION_TYPE,
    StringKey.STAKEHOLDER,
    StringKey.SHARE_CLASS,
    StringKey.NO_OF_SHARES,
    StringKey.FILE,
    StringKey.EMPTY,
  ],
  [EventTypeBackend.FUNDRAISING_ROUND]: [
    StringKey.TRANSACTION_DATE,
    StringKey.TRANSACTION_TYPE,
    StringKey.STAKEHOLDER,
    StringKey.INVESTMENTS,
    StringKey.NO_OF_SHARES,
    StringKey.SHARE_CLASS,
    StringKey.FILE,
    StringKey.EMPTY,
  ],
  [EventTypeBackend.NEW_PLAN]: [
    StringKey.TRANSACTION_DATE,
    StringKey.TRANSACTION_TYPE,
    StringKey.NAME,
    StringKey.EQUITY_POOL,
    StringKey.EQUITY_PLAN_TYPE,
    StringKey.EMPTY,
  ],
  [EventTypeBackend.NEW_POOL]: [
    StringKey.TRANSACTION_DATE,
    StringKey.TRANSACTION_TYPE,
    StringKey.NAME,
    StringKey.FULLY_DILUTED_SHARES,
    StringKey.SHARE_CLASS,
    StringKey.EMPTY,
  ],
  [EventTypeBackend.SHARE_ISSUANCE]: [
    StringKey.TRANSACTION_DATE,
    StringKey.TRANSACTION_TYPE,
    StringKey.STAKEHOLDER,
    StringKey.VALUATION_EVENT,
    StringKey.SHARE_CLASS,
    StringKey.SHARES_PRICE,
    StringKey.NO_OF_SHARES,
    StringKey.EMPTY,
  ],
  [EventTypeBackend.SECONDARIES]: [
    StringKey.TRANSACTION_DATE,
    StringKey.TRANSACTION_TYPE,
    StringKey.FROM_STAKEHOLDER,
    StringKey.TO_STAKEHOLDER,
    StringKey.SHARE_CLASS,
    StringKey.NO_OF_SHARES,
    StringKey.SHARE_VALUE,
    StringKey.TRANSFER_AMOUNT,
    StringKey.FILE,
    StringKey.EMPTY,
  ],
  [EventTypeBackend.VALUATION]: [
    StringKey.TRANSACTION_DATE,
    StringKey.TRANSACTION_TYPE,
    StringKey.SHARES_PRICE,
    StringKey.PRE_MONEY_VALUATION,
    StringKey.EMPTY,
  ],
};
