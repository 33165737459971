import { FC } from 'react';

import { AppFormattedMessage } from '../../../../components/AppFormattedMessage';
import { useFormat } from '../../../../hooks';
import { StringKey } from '../../../../lang';
import { eventFormType, EventTypeBackend } from '../../../../types/events.types';
import { TransactionOverviewItem } from './TransactionOverviewItem';
import { TransactionTitleItem } from './TransactionTitleItem';
import { CardProps } from './type';

export const Valuation: FC<CardProps> = ({ event }) => {
  const { format } = useFormat();
  if (event.type !== EventTypeBackend.VALUATION) return <></>;
  const { type, date, name, sharePrice, issuedSharesOnStart } = event;

  return (
    <div className="flex flex-col divide-y divide-gray-100 rounded-md shadow-sm">
      <TransactionTitleItem createdAt={format(date, 'dd/MM/yyyy')} name={name} />
      <TransactionOverviewItem
        title={<AppFormattedMessage id={StringKey.TRANSACTION_TYPE} />}
        value={<AppFormattedMessage id={eventFormType[type]} />}
      />
      <TransactionOverviewItem
        title={<AppFormattedMessage id={StringKey.SHARES_PRICE} />}
        value={sharePrice}
      />
      <TransactionOverviewItem
        title={<AppFormattedMessage id={StringKey.PRE_MONEY_VALUATION} />}
        value={(issuedSharesOnStart * sharePrice).toLocaleString('en-US')}
      />
    </div>
  );
};
