import React, { FC, ReactNode } from 'react';

import { EditIcon, EyeIconSmall, ThreeDotsIcon, TrashBinIcon } from '../../../assets/icons';
import { AlertDialogWrapper } from '../../../components/AlertDialog';
import { AppFormattedMessage } from '../../../components/AppFormattedMessage';
import { Popover, PopoverClose, PopoverContent, PopoverTrigger } from '../../../components/Popover';
import { Company, useModalState, useSharePlans } from '../../../hooks';
import { StringKey } from '../../../lang';
import { SharePlanUnionType } from '../../../types/pool-plans.types';
import { DeleteBlockModal } from '../DeleteBlockModal';
import { DeleteModal } from '../DeleteModal';

export type PoolPlansPopoverProps = {
  text: string | ReactNode;
  onEditClick: (id: string) => void;
  onViewClick: (id: string) => void;
  onDeleteClick: (id: string) => void;
  onEditTerminationDateClick?: (id: string) => void;
  id: string;
  isTerminated?: boolean;
  isCanDeleted?: boolean;
} & (
  | { companyId?: string; sharePlans?: never }
  | { sharePlans?: SharePlanUnionType[]; companyId?: never }
);

export const PoolPlansPopover: FC<PoolPlansPopoverProps> = ({
  text,
  onEditClick,
  onViewClick,
  onDeleteClick,
  onEditTerminationDateClick,
  id,
  isTerminated = false,
  isCanDeleted = true,
  companyId,
  sharePlans,
}) => {
  const { selectedCompany } = Company.useSelected();
  const { handleCloseModal, handleSuccessModal, handleOpenModal, isOpen, toggler } = useModalState({
    onSuccess: () => onDeleteClick(id),
  });
  const { sharePlans: fetchedSharePlans } = useSharePlans({
    companyId: companyId || '',
    enabled: !!sharePlans,
    poolId: id || '',
    numberOfFetchedItems: 500,
  });

  const isDemo = selectedCompany?.isDemo;
  return (
    <>
      <AlertDialogWrapper control={{ onOpenChange: toggler, open: isOpen }}>
        {isCanDeleted ? (
          <DeleteModal onClose={handleCloseModal} onSuccess={handleSuccessModal} text={text} />
        ) : (
          <DeleteBlockModal
            onClose={handleCloseModal}
            sharePlans={sharePlans || fetchedSharePlans}
          />
        )}
      </AlertDialogWrapper>

      <div className="flex cursor-pointer items-center justify-center">
        <Popover>
          <PopoverTrigger className="h-fit w-fit rounded data-[state=open]:shadow-md">
            <div className="flex size-6 rotate-90 items-center justify-center rounded bg-transparent transition-colors hover:bg-gray-100">
              <ThreeDotsIcon />
            </div>
          </PopoverTrigger>
          <PopoverContent className="flex w-fit flex-col gap-[2px] rounded p-2 font-normal shadow-2xl [&>*]:font-inter [&>*]:font-normal">
            <PopoverClose>
              {isTerminated && onEditTerminationDateClick && !isDemo ? (
                <div
                  className="flex w-full items-center gap-2 p-[6px] text-sm text-gray-700 hover:bg-gray-50"
                  onClick={() => onEditTerminationDateClick(id)}
                >
                  <EditIcon className="size-4" />
                  <span className="text-sm font-normal text-gray-700">
                    <AppFormattedMessage id={StringKey.EDIT_TERMINATION_DATE} />
                  </span>
                </div>
              ) : (
                <>
                  <div
                    className="flex w-full items-center gap-2 p-[6px] pr-8 text-sm text-gray-700 hover:bg-gray-50"
                    onClick={() => onViewClick(id)}
                  >
                    <EyeIconSmall className="size-4" />
                    <span className="text-sm font-normal text-gray-700">
                      <AppFormattedMessage
                        id={StringKey.VIEW_POOL}
                        values={{
                          text,
                        }}
                      />
                    </span>
                  </div>
                  {!isDemo && (
                    <>
                      <div
                        className="flex w-full items-center gap-2 p-[6px] pr-8 text-sm text-gray-700 hover:bg-gray-50"
                        onClick={() => onEditClick(id)}
                      >
                        <EditIcon className="size-4" />
                        <span className="text-sm font-normal text-gray-700">
                          <AppFormattedMessage
                            id={StringKey.EDIT_POOL}
                            values={{
                              text,
                            }}
                          />
                        </span>
                      </div>
                      <div
                        className="flex w-full items-center gap-2 p-[6px] pr-8 text-sm text-fireside-500 hover:bg-gray-50"
                        onClick={handleOpenModal}
                      >
                        <TrashBinIcon className="size-4" iconColor="#F04438" />
                        <span className="text-sm font-normal text-fireside-500">
                          <AppFormattedMessage
                            id={StringKey.DELETE_POOL}
                            values={{
                              text,
                            }}
                          />
                        </span>
                      </div>
                    </>
                  )}
                </>
              )}
            </PopoverClose>
          </PopoverContent>
        </Popover>
      </div>
    </>
  );
};
