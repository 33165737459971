import { z } from 'zod';

import { StringKey } from '../../../lang';
import { getTranslation } from '../../../utils/getTranslation';

export const MAX_FILE_SIZE = 1024 * 1024 * 10;

export const ACCEPTED_FILE_MIME_TYPES = ['application/pdf', 'image/png', 'image/jpeg'];
export const fileSchemaMessage = getTranslation(StringKey.FILE_SIZE_TOO_LARGE);

export const stepOne = z.object({
  stakeholder: z.object({
    fullName: z
      .string()
      .trim()
      .min(1, { message: getTranslation(StringKey.REQUIRED) }),
    id: z
      .string()
      .trim()
      .min(1, { message: getTranslation(StringKey.REQUIRED) }),
  }),
  investment: z.coerce
    .number()
    .min(1, { message: 'Investment Amount is required and must be numeric' }),
  issueDate: z.coerce.date(),
});

export const stepTwo = z
  .object({
    cap: z
      .object({
        enabled: z.boolean(),
        capValue: z.coerce.number().min(1, { message: getTranslation(StringKey.REQUIRED) }),
      })
      .optional(),
    discount: z
      .object({
        enabled: z.boolean(),
        discountValue: z.coerce
          .number()
          .min(1, { message: 'Minimum 1%' })
          .max(100, { message: 'Maximum 100%' }),
      })
      .optional(),
    floor: z
      .object({
        enabled: z.boolean(),
        floorValue: z
          .union([
            z.coerce.number().min(1, { message: getTranslation(StringKey.REQUIRED) }),
            z.literal(''),
          ])
          .optional(),
        maturityDate: z.coerce.date().optional(),
      })
      .optional()
      .refine(
        (data) => {
          if (data?.enabled) {
            return (
              (data.floorValue !== '' && data.floorValue !== undefined) ||
              data.maturityDate !== undefined
            );
          }
          return true;
        },
        {
          message: 'Either floor value or maturity date must be filled.',
          path: ['floor'],
        },
      ),
  })
  .refine(
    (data) => {
      return data.cap?.enabled || data.discount?.enabled;
    },
    {
      message: 'Either cap or discount must be enabled.',
      path: ['cap'],
    },
  );

export const stepThree = z
  .object({
    additionalNotes: z
      .string()
      .max(3000, getTranslation(StringKey.MAXIMUM_CHARACTERS, { count: 3000 }))
      .optional(),
    files: z
      .object({
        docLink: z.string(),
        loadProgress: z.number().max(100),
        abort: z.function(),
        id: z.string(),
        doc: z
          .custom<File>()
          .or(
            z.object({
              size: z.number(),
              type: z.string(),
              name: z.string(),
            }),
          )
          .refine(
            ({ type }) => ACCEPTED_FILE_MIME_TYPES.includes(type),
            getTranslation(StringKey.FILE_FORMAT_NOT_SUPPORTED),
          )
          .refine(({ size }) => size <= MAX_FILE_SIZE, fileSchemaMessage),
      })
      .array()

      .optional(),
  })
  .optional();

export const formSchema = z.object({
  stepOne,
  stepTwo,
  stepThree,
});

export type FormSchema = z.infer<typeof formSchema>;
