import React, { FC, useMemo, useState } from 'react';
import { Cell, Pie, PieChart, ResponsiveContainer } from 'recharts';
import { twMerge } from 'tailwind-merge';

import { AppFormattedMessage } from '../../../components/AppFormattedMessage';
import { StakeholderType, stakeholderTypeTitle } from '../../../types/stakeholderTypes';
import { GraphVariant } from '../variables';

export const strokeColors = [
  '#B4D1FE',
  '#A6F4C5',
  '#FECDCA',
  '#FEDF89',
  '#D0D5DD',
  '#D2a9FA',
  '#efa1ef',
  '#d5f492',
  '#98f4e2',
  '#faf194',
];
export const fillColors = [
  '#D1E3FF',
  '#D1FADF',
  '#FEE4E2',
  '#FEF0C7',
  '#EAECF0',
  '#E9D8FA',
  '#fad8fa',
  '#effad8',
  '#d8faf3',
  '#faf7d8',
];
export const activeColors = [
  '#2565C8',
  '#039855',
  '#D92D20',
  '#DC6803',
  '#475467',
  '#6717b6',
  '#c52ec5',
  '#8dc21d',
  '#2cc9aa',
  '#dbcb23',
];

type CapTableGraphProps = {
  data: GraphData[];
  variant: GraphVariant;
  isDesktop: boolean;
  isTablet: boolean;
};

type GraphData = {
  type: StakeholderType;
  issuedPercentage: number;
  votingPercentage: number;
};

export const CapTableGraph: FC<CapTableGraphProps> = ({ variant, data, isDesktop, isTablet }) => {
  const [activeIndex, setActiveIndex] = useState<number | null>(null);

  const isMobile = !isDesktop && !isTablet;

  const sortedData = useMemo(() => {
    return variant === GraphVariant.OWNERSHIP
      ? data
          .sort((a, b) => b.issuedPercentage - a.issuedPercentage)
          .filter((item) => item.issuedPercentage !== 0)
      : data
          .sort((a, b) => b.votingPercentage - a.votingPercentage)
          .filter((item) => item.votingPercentage !== 0);
  }, [data, variant]);

  const handleMouseEnter = (index: number) => {
    setActiveIndex(index);
  };

  const handleMouseLeave = () => {
    setActiveIndex(null);
  };

  return (
    <div
      className={twMerge(
        'flex h-full w-full rounded-md bg-white shadow-sm',
        isDesktop ? 'px-6 py-[30px]' : isTablet ? 'px-4 py-5' : 'p-3 pt-8',
      )}
    >
      <div
        className={twMerge('flex w-full items-center justify-center gap-8', isMobile && 'flex-col')}
      >
        <div
          className={twMerge(
            isDesktop ? 'size-[294px]' : isTablet ? 'size-[188px]' : 'size-[280px]',
          )}
        >
          <ResponsiveContainer height="100%" width="100%">
            <PieChart>
              <Pie
                cx="50%"
                cy="50%"
                data={sortedData}
                dataKey={
                  variant === GraphVariant.OWNERSHIP ? 'issuedPercentage' : 'votingPercentage'
                }
                innerRadius="50%"
                isAnimationActive={false}
                outerRadius="100%"
              >
                {sortedData.map((entry, index) => (
                  <Cell
                    fill={
                      activeIndex === index
                        ? activeColors[index % activeColors.length]
                        : fillColors[index % fillColors.length]
                    }
                    fillOpacity={1}
                    key={entry.type}
                    onMouseEnter={() => handleMouseEnter(index)}
                    onMouseLeave={handleMouseLeave}
                    stroke={strokeColors[index % strokeColors.length]}
                    style={{
                      transition: 'all 0.5s ease, stroke 0.5s ease',
                    }}
                  />
                ))}
              </Pie>
            </PieChart>
          </ResponsiveContainer>
        </div>
        <div
          className={twMerge(
            'flex max-w-[232px] flex-wrap gap-x-4 gap-y-2 pt-4',
            isMobile && 'max-w-full justify-center',
          )}
        >
          {sortedData.map((item, index) => (
            <div
              className={twMerge('flex w-[108px] flex-col gap-2 pb-2', isMobile && 'items-center')}
              key={`${item.type}_${index}`}
            >
              <span
                className="text-label-md font-[500] text-gray-600"
                style={{
                  color: activeIndex !== null && activeIndex !== index ? '#98A2B3' : '#344054',
                  transition: 'color 0.5s ease',
                }}
              >
                <AppFormattedMessage id={stakeholderTypeTitle[item.type]} />
              </span>
              <div className="flex items-center gap-2">
                <div
                  className="h-[5px] w-3 rounded"
                  style={{
                    backgroundColor:
                      activeIndex === index
                        ? activeColors[index % activeColors.length]
                        : fillColors[index % fillColors.length],
                    border:
                      activeIndex === index
                        ? `1px solid ${activeColors[index % activeColors.length]}`
                        : `1px solid ${fillColors[index % fillColors.length]}`,
                    transition: 'background-color 0.5s ease, border 0.5s ease',
                  }}
                />
                <span
                  className="whitespace-nowrap text-sm font-[450]"
                  style={{
                    color:
                      activeIndex !== null
                        ? activeIndex === index
                          ? activeColors[index % activeColors.length]
                          : '#98A2B3'
                        : '#344054',
                    transition: 'color 0.5s ease',
                  }}
                >
                  {variant === GraphVariant.OWNERSHIP
                    ? item.issuedPercentage.toFixed(2)
                    : item.votingPercentage.toFixed(2)}{' '}
                  %
                </span>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};
