import React, { FC, useMemo } from 'react';
import {
  Area,
  AreaChart,
  ResponsiveContainer,
  Tooltip,
  TooltipProps,
  XAxis,
  YAxis,
} from 'recharts';

import { AppFormattedMessage } from '../../../../components/AppFormattedMessage';
import { SelectedCompanyCurrency } from '../../../../components/SelectedCompanyCurrency';
import { Company, useFormat, useLocale } from '../../../../hooks';
import { StringKey } from '../../../../lang';
import { Snapshot } from '../../../../types/snapshot.types';
import { activeColors, fillColors, strokeColors } from '../../../CapTable/Graphs';
import { GraphVariant } from '../../variables';

type EventGraphProps = {
  snapshots: Snapshot[];
  currentSnapshot?: Snapshot;
  variant: GraphVariant;
};

interface GraphData {
  date: string;
  postMoneyValuation: number;
  name: string;
  stakeholders: {
    id: string;
    name: string;
    shares: number;
    percentage: number;
    value: number;
  }[];
}

interface Stakeholder {
  id: string;
  name: string;
  shares: number;
  percentage: number;
  value: number;
}

interface CustomTickProps {
  x: number;
  y: number;
  payload: { value: string };
  isActive: boolean;
}

const CustomTick: FC<CustomTickProps> = ({ x, y, payload, isActive }) => {
  const isHighlighted = isActive ? '#1151B4' : '#475467';

  return (
    <text fill={isHighlighted} fontSize={10} fontWeight={500} textAnchor="middle" x={x} y={y}>
      {payload.value}
    </text>
  );
};

interface CustomToolTipProps extends TooltipProps<number | string, keyof GraphData> {
  variant: GraphVariant;
}

export const CustomToolTip: FC<CustomToolTipProps> = ({ active, payload, variant }) => {
  const { selectedCompany: { currency } = {} } = Company.useSelected();
  if (active && payload && payload.length) {
    const { date, postMoneyValuation, name, stakeholders } = payload[0].payload;
    return (
      <div className="flex w-[364px] flex-col gap-2 rounded-lg bg-gray-25 p-1 pt-2 shadow-2xl">
        <div className="flex justify-between gap-1 border-b-[1px] border-gray-200 px-4 pb-2">
          <span className="text-label-sm font-semibold uppercase text-gray-700">{name}</span>
          <span className="text-label-sm font-semibold text-gray-700">{date}</span>
        </div>
        <div className="flex justify-between gap-1 px-4">
          <span className="text-label-sm font-semibold uppercase text-gray-700">
            <AppFormattedMessage id={StringKey.POST_MONEY_VALUATION} />
          </span>
          <span className="text-label-sm font-semibold text-forest-600">
            <SelectedCompanyCurrency /> {postMoneyValuation.toLocaleString('en-US')}
          </span>
        </div>
        <div className="grid grid-cols-3">
          <div className="border-b-[1px] border-[#F2F2F2] bg-gray-50 px-4 py-2 text-label-sm font-[500] text-[#172335]">
            <AppFormattedMessage id={StringKey.STAKEHOLDER} />
          </div>
          <div className="border-b-[1px] border-[#F2F2F2] bg-gray-50 px-4 py-2 text-center text-label-sm font-[500] text-[#172335]">
            <AppFormattedMessage id={StringKey.SHARES} />
          </div>
          <div className="border-b-[1px] border-[#F2F2F2] bg-gray-50 px-4 py-2 text-center text-label-sm font-[500] text-[#172335]">
            {variant === GraphVariant.VALUATION ? (
              <AppFormattedMessage id={StringKey.VALUE} />
            ) : (
              <AppFormattedMessage id={StringKey.OWNERSHIP_PERCENTAGE} />
            )}
          </div>

          {stakeholders
            .filter(
              (stakeholder: Stakeholder) =>
                stakeholder.name !== 'notExisting' && stakeholder.shares !== 0,
            )
            .slice(0, 10)
            .map(({ name, percentage, shares, value }: Stakeholder, index: number) => (
              <React.Fragment key={`${name}_${index}`}>
                <div className="truncate border-b border-gray-50 px-4 py-1 text-label-sm font-[500] text-[#172335]">
                  {name}
                </div>
                <div className="border-b border-gray-50 px-4 py-1 text-center text-label-sm font-[500] text-[#172335]">
                  {(shares * 1000).toLocaleString('en-US')}
                </div>
                <div className="border-b border-gray-50 px-4 py-1 text-center text-label-sm font-[500] text-[#172335]">
                  {currency?.symbol}{' '}
                  {variant === GraphVariant.VALUATION
                    ? (value * 1000000).toLocaleString('en-US')
                    : `${percentage}%`}
                </div>
              </React.Fragment>
            ))}
        </div>
      </div>
    );
  }
};

export const EventGraph: FC<EventGraphProps> = ({ variant, snapshots, currentSnapshot }) => {
  const { format } = useFormat();

  const { messagesLocale } = useLocale();
  const [activeIndex, setActiveIndex] = React.useState<number | null>(null);

  const sortedByDateSnapshots = useMemo(() => {
    return snapshots.sort((a, b) => {
      const dateA = new Date(a.valuation.date);
      const dateB = new Date(b.valuation.date);

      dateA.setHours(0, 0, 0, 0);
      dateB.setHours(0, 0, 0, 0);

      if (dateA.getTime() === dateB.getTime()) {
        return a.postMoneyValuation - b.postMoneyValuation;
      }

      return dateA.getTime() - dateB.getTime();
    });
  }, [snapshots]);

  const data = useMemo(() => {
    return sortedByDateSnapshots.map(({ stats, valuation, postMoneyValuation }) => {
      const totalPercentage = stats.stakeholders.reduce((total, stakeholder) => {
        return total + stakeholder.issuedPercentage;
      }, 0);

      return {
        date: format(valuation.date, 'dd/MM/yyyy'),
        postMoneyValuation,
        name: valuation.name,
        stakeholders: stats.stakeholders.map((stakeholder) => ({
          id: stakeholder.id,
          name: stakeholder.name,
          shares: stakeholder.issued / 1000,
          percentage:
            totalPercentage > 0
              ? Math.round(((stakeholder.issuedPercentage || 0) * 100) / totalPercentage) || 0
              : 0,
          value: (stakeholder.issued * valuation?.sharePrice) / 1000000,
        })),
      };
    });
  }, [sortedByDateSnapshots, format]);

  const yAxisDomain = variant === GraphVariant.OWNERSHIP_PERCENT ? [0, 100] : ['auto', 'auto'];

  const allStakeholders = useMemo(() => {
    return Array.from(
      new Set(
        data.flatMap((snapshot) => snapshot.stakeholders.map((stakeholder) => stakeholder.id)),
      ),
    );
  }, [data]);

  const fullyDilutedPoint = useMemo(() => {
    return {
      date: messagesLocale[StringKey.FULLY_DILUTED],
      postMoneyValuation: currentSnapshot
        ? currentSnapshot?.dilutedShares * currentSnapshot?.valuation?.sharePrice
        : 0,
      name: messagesLocale[StringKey.FULLY_DILUTED],
      stakeholders: currentSnapshot
        ? currentSnapshot.stats.stakeholders.map((stakeholder) => {
            return {
              id: stakeholder.id,
              name: stakeholder?.name,
              shares: stakeholder?.diluted / 1000,
              percentage: Math.round(stakeholder?.dilutedPercentage * 100),
              value: (stakeholder?.diluted * currentSnapshot?.valuation?.sharePrice) / 1000000,
            };
          })
        : allStakeholders.map((id) => ({
            id,
            name: 'notExisting',
            shares: 0,
            percentage: 0,
            value: 0,
          })),
    };
  }, [allStakeholders, currentSnapshot, messagesLocale]);

  const normalizedData = useMemo(() => {
    return [...data, fullyDilutedPoint].map((snapshot, index) => {
      const stakeholders = allStakeholders
        .map((id) => {
          const existing = snapshot.stakeholders.find((stakeholder) => stakeholder.id === id);
          return existing
            ? existing
            : {
                id,
                name: 'notExisting',
                shares: 0,
                percentage: 0,
                value: 0,
              };
        })
        .sort((a, b) => b.shares - a.shares)
        .slice(0, 10);

      return {
        ...snapshot,
        stakeholders,
        dateIndex: index,
      };
    });
  }, [allStakeholders, data, fullyDilutedPoint]);

  const handleMouseMove = (e: any) => {
    const { activePayload } = e;
    if (activePayload && activePayload.length) {
      const index = activePayload[0].payload.dateIndex;
      setActiveIndex(index);
    }
  };

  const handleMouseLeave = () => {
    setActiveIndex(null);
  };

  return (
    <ResponsiveContainer height="100%" width="100%">
      <AreaChart
        data={normalizedData}
        margin={{
          top: 0,
          right: 0,
          left: 8,
          bottom: 8,
        }}
        onMouseLeave={handleMouseLeave}
        onMouseMove={handleMouseMove}
      >
        <XAxis
          dataKey="date"
          stroke="#475467"
          tick={({ x, y, payload }) => (
            <CustomTick isActive={activeIndex === payload.index} payload={payload} x={x} y={y} />
          )}
          tickLine={false}
          tickMargin={16}
        />
        <YAxis
          domain={yAxisDomain}
          stroke="#475467"
          tick={{ fontSize: 10, fontWeight: 500 }}
          tickFormatter={(value) =>
            variant === GraphVariant.OWNERSHIP_PERCENT ? `${value}%` : value
          }
          tickLine={false}
          tickMargin={16}
        />
        {allStakeholders.map((_, index) => (
          <Area
            activeDot={{
              strokeWidth: 1,
              fill: activeColors[index % activeColors.length],
            }}
            dataKey={
              variant === GraphVariant.VALUATION
                ? `stakeholders[${index}].value`
                : variant === GraphVariant.OWNERSHIP
                  ? `stakeholders[${index}].shares`
                  : `stakeholders[${index}].percentage`
            }
            fill={fillColors[index % fillColors.length]}
            fillOpacity={1}
            key={index}
            stackId={variant === GraphVariant.OWNERSHIP_PERCENT ? '1' : undefined}
            stroke={strokeColors[index % strokeColors.length]}
            type="monotone"
          />
        ))}
        <Tooltip
          content={<CustomToolTip variant={variant} />}
          cursor={{ stroke: '#D0D5DD', strokeDasharray: '5 3', strokeWidth: 1 }}
        />
      </AreaChart>
    </ResponsiveContainer>
  );
};
