import { FC, useState } from 'react';

import {
  AddDocumentIcon,
  EditIcon,
  EyeIconSmall,
  ThreeDotsIcon,
  TrashBinIcon,
} from '../../../../../assets/icons';
import { AlertDialogWrapper } from '../../../../../components/AlertDialog';
import { AppFormattedMessage } from '../../../../../components/AppFormattedMessage';
import { HoverCard, HoverCardContent, HoverCardTrigger } from '../../../../../components/HoverCard';
import {
  Popover,
  PopoverClose,
  PopoverContent,
  PopoverTrigger,
} from '../../../../../components/Popover';
import { SelectedCompanyCurrency } from '../../../../../components/SelectedCompanyCurrency';
import { EventHooks, useFormat, useModalState } from '../../../../../hooks';
import { StringKey } from '../../../../../lang';
import {
  eventFormType,
  EventTypeBackend,
  SecondariesEventItem,
} from '../../../../../types/events.types';
import { getS3FileOriginalName } from '../../../../../utils/getS3FileOriginalName';
import { FilesModal } from '../../../Modals';
import { BasicTable } from './BasicTable';
import { PaginationItem } from './PaginationItem';
import { Td } from './TableTd';
import { AmountOfFetchedItems, TableBodyProps } from './types';

export const Secondaries: FC<TableBodyProps> = (props) => {
  const { format } = useFormat();
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsToFetch, setItemsToFetch] = useState(AmountOfFetchedItems.THREE);
  const { items, totalPages } = EventHooks.useTransactions<SecondariesEventItem>({
    eventId: props.id,
    eventType: EventTypeBackend.SECONDARIES,
    companyId: props.companyId,
    currentPage,
    take: Number(itemsToFetch),
    dateFilter: props.selectedDateFilter,
    customDateFilter: props.selectedCustomDateFilter,
  });

  const {
    isOpen: isOpenFilesModal,
    handleCloseModal: handleCloseFilesModal,
    handleOpenModal: handleOpenFilesModal,
    toggler: filesModalToggler,
  } = useModalState();

  const handleDocumentClick = (link: string) => {
    window.open(link);
  };

  const handleDownloadAllDocuments = async (links: string[]) => {
    for (let i = 0; i < links.length; i++) {
      await new Promise<void>((resolve) => {
        const link = links[i];
        const a = document.createElement('a');
        a.href = link;
        a.download = '';
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);

        setTimeout(() => {
          resolve();
        }, 1000);
      });
    }
  };

  if (props.type !== EventTypeBackend.SECONDARIES) return <></>;

  const { type, eventId, onDelete, onView, onEdit, name } = props;

  return (
    <>
      <BasicTable eventType={EventTypeBackend.SECONDARIES}>
        {items.map(
          (
            {
              date,
              fromStakeholder,
              sharesValue,
              toStakeholder,
              sharesCount,
              shareClass,
              id: transactionId,
              filesLinks,
            },
            index,
          ) => (
            <tr key={`${index}_${fromStakeholder}`}>
              <AlertDialogWrapper
                control={{ onOpenChange: filesModalToggler, open: isOpenFilesModal }}
              >
                <FilesModal
                  createdAt={format(date, 'dd/MM/yyyy')}
                  filesLinks={filesLinks}
                  name={name}
                  onClose={handleCloseFilesModal}
                  onDownloadFile={handleDocumentClick}
                  onSuccess={() => handleDownloadAllDocuments(filesLinks)}
                  stakeholderName={fromStakeholder.fullName}
                />
              </AlertDialogWrapper>
              <Td value={format(date, 'dd/MM/yyyy')} />
              <Td value={eventFormType[type]} />
              <Td value={fromStakeholder.fullName} />
              <Td value={toStakeholder.fullName} />
              <Td value={shareClass.name} />
              <Td value={sharesCount.toLocaleString('en-US')} />
              <Td
                value={
                  <>
                    <SelectedCompanyCurrency />
                    {sharesValue.toLocaleString('en-US')}
                  </>
                }
              />
              <Td value={(sharesCount * sharesValue).toLocaleString('en-US')} />
              <Td
                value={
                  <HoverCard>
                    <HoverCardTrigger>
                      <AddDocumentIcon
                        className="size-6 cursor-pointer p-[2px]"
                        onClick={() =>
                          filesLinks.length > 0 &&
                          (filesLinks.length === 1
                            ? handleDocumentClick(filesLinks[0])
                            : handleOpenFilesModal())
                        }
                      />
                    </HoverCardTrigger>
                    <HoverCardContent className="max-w-[180px] rounded-lg bg-[#101828] px-3 py-2">
                      <span className="block w-full truncate p-0 text-label-md font-[550] text-white">
                        {filesLinks.length === 1
                          ? getS3FileOriginalName(filesLinks[0])
                          : `${filesLinks.length} files attached to event`}
                      </span>
                    </HoverCardContent>
                  </HoverCard>
                }
              />
              <Td
                className="w-12"
                value={
                  <Popover>
                    <PopoverTrigger className="h-fit w-fit rounded data-[state=open]:shadow-md">
                      <div className="flex size-6 rotate-90 items-center justify-center rounded bg-transparent transition-colors hover:bg-gray-100">
                        <ThreeDotsIcon />
                      </div>
                    </PopoverTrigger>
                    <PopoverContent className="flex w-fit flex-col gap-[2px] rounded p-2 font-normal shadow-2xl [&>*]:font-inter [&>*]:font-normal">
                      <PopoverClose>
                        <div
                          className="flex w-full items-center gap-2 rounded p-[6px] pr-8 text-sm text-gray-700 hover:bg-gray-50"
                          onClick={() => onView(eventId, transactionId)}
                        >
                          <EyeIconSmall />
                          <span className="text-sm font-normal text-gray-700">
                            <AppFormattedMessage id={StringKey.VIEW_DETAILS} />
                          </span>
                        </div>
                        <div
                          className="flex w-full items-center gap-2 rounded p-[6px] pr-8 text-sm text-gray-700 hover:bg-gray-50"
                          onClick={() => onEdit(eventId, transactionId)}
                        >
                          <EditIcon />
                          <span className="text-sm font-normal text-gray-700">
                            <AppFormattedMessage id={StringKey.EDIT_DETAILS} />
                          </span>
                        </div>
                        {(totalPages !== 1 || items.length > 1) && (
                          <div
                            className="flex w-full items-center gap-2 rounded p-[6px] pr-8 text-sm text-gray-700 hover:bg-gray-50"
                            onClick={() => onDelete(props, transactionId)}
                          >
                            <TrashBinIcon iconColor="#F04438" />
                            <span className="text-sm font-normal text-fireside-500">
                              <AppFormattedMessage id={StringKey.DELETE_TRANSACTION} />
                            </span>
                          </div>
                        )}
                      </PopoverClose>
                    </PopoverContent>
                  </Popover>
                }
              />
            </tr>
          ),
        )}
      </BasicTable>
      <PaginationItem
        currentPage={currentPage}
        handleChangeCurrentPage={setCurrentPage}
        handleFetchAmountChange={setItemsToFetch}
        itemsCount={items.length}
        itemsToFetch={itemsToFetch}
        setCurrentPage={setCurrentPage}
        totalPages={totalPages}
      />
    </>
  );
};
