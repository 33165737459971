import React, { FC, useCallback, useEffect } from 'react';
import { toast } from 'react-toastify';
import { v4 as uuidv4 } from 'uuid';

import { AlertDialogWrapper } from '../../../../components/AlertDialog';
import { AppFormattedMessage } from '../../../../components/AppFormattedMessage';
import { CloseModalButton } from '../../../../components/CloseModalButton';
import { Sheet, SheetContent } from '../../../../components/Sheet';
import { SafeHooks, useModalState, useReactForm } from '../../../../hooks';
import { StringKey } from '../../../../lang';
import { getS3FileOriginalName } from '../../../../utils/getS3FileOriginalName';
import { toBackendDateFormat } from '../../../../utils/toBackendDateFormat';
import { UpdateConfirmationModal } from '../../../PoolPlans/UpdateConfirmationModal';
import { EditPreview, StepOne, StepThree, StepTwo } from '../Steps';
import { ChildrenFormProps, EditSafeStepVariant } from '../type';
import { FormSchema, formSchema } from '../Validation';

type ModalProps = {
  isOpenModal: boolean;
  handleClose: () => void;
  companyId: string;
  invalidateQuery: () => void;
  onEditClick: (props: { id: string; step: EditSafeStepVariant }) => void;
  step: EditSafeStepVariant;
  safeId: string;
  previewMode?: true;
};

const formSteps: ((
  props: {
    onEditClick: (step: EditSafeStepVariant) => void;
  } & ChildrenFormProps,
) => JSX.Element)[] = [
  (props) => <StepOne {...props} />,
  (props) => <StepTwo {...props} />,
  (props) => <StepThree {...props} />,
  (props) => <EditPreview {...props} />,
];

export const EditSafeModal: FC<ModalProps> = ({
  companyId,
  isOpenModal,
  handleClose,
  invalidateQuery,
  onEditClick,
  safeId,
  step,
  previewMode,
}) => {
  const { safe } = SafeHooks.useSafe({ companyId, safeId });
  const { update } = SafeHooks.useUpdate();

  const {
    control,
    reset,
    setValue,
    getValues,
    getFieldState,
    handleSubmit,
    setError,
    clearErrors,
    formState: { errors },
  } = useReactForm({
    schema: formSchema,
  });

  useEffect(() => {
    if (!safe) return;

    const files: {
      docLink: string;
      loadProgress: 100;
      abort: () => void;
      id: string;
      doc: {
        size: number;
        type: string;
        name: string;
      };
    }[] = safe?.filesLinks?.map((link) => {
      const originalFileName = getS3FileOriginalName(link);

      return {
        abort: () => '',
        loadProgress: 100,
        doc: {
          name: originalFileName || '',
          size: 10,
          type: 'application/pdf',
        },
        docLink: link,
        id: uuidv4(),
      };
    });

    reset({
      stepOne: {
        investment: safe?.investment,
        issueDate: new Date(safe?.issueDate),
        stakeholder: {
          fullName: safe?.stakeholder?.fullName,
          id: safe?.stakeholder?.id,
        },
      },
      stepTwo: {
        cap: safe?.capValue ? { enabled: true, capValue: safe?.capValue } : undefined,
        discount: safe?.discount
          ? { enabled: true, discountValue: safe?.discount * 100 }
          : undefined,
        floor: safe?.floorValue
          ? {
              enabled: true,
              floorValue: safe?.floorValue,
              maturityDate: safe?.maturityDate ? new Date(safe?.maturityDate) : undefined,
            }
          : safe?.maturityDate
            ? {
                enabled: true,
                maturityDate: new Date(safe?.maturityDate),
              }
            : undefined,
      },
      stepThree: {
        additionalNotes: safe?.additionalNotes || undefined,
        files: files,
      },
    });
  }, [safe, reset]);

  const handleCloseModal = useCallback(() => {
    handleClose();
    invalidateQuery();
    reset();
  }, [handleClose, invalidateQuery, reset]);

  const handleUpdateData = useCallback(
    (data: FormSchema) => {
      const { stepOne, stepTwo, stepThree } = data;
      update(
        {
          safeId,
          companyId,
          data: {
            capValue: stepTwo?.cap?.capValue || null,
            discount: stepTwo?.discount?.discountValue || null,
            floorValue: stepTwo?.floor?.floorValue || null,
            investment: stepOne.investment,
            issueDate: toBackendDateFormat(stepOne.issueDate),
            maturityDate: stepTwo?.floor?.maturityDate
              ? toBackendDateFormat(stepTwo?.floor?.maturityDate)
              : null,
            stakeholderId: stepOne.stakeholder.id,
            additionalNotes: stepThree?.additionalNotes,
            filesLinks:
              stepThree?.files?.reduce<string[]>((prev, curr) => [...prev, curr.docLink], []) || [],
          },
        },
        {
          onSuccess: () => {
            toast.success('SAFE successfully updated');
            handleCloseModal();
            invalidateQuery();
            handleClose();
          },
        },
      );
    },
    [companyId, handleClose, handleCloseModal, invalidateQuery, safeId, update],
  );

  const {
    toggler: confirmationModalToggler,
    isOpen: isOpenConfirmationModal,
    handleOpenModal: handleOpenConfirmationModal,
    handleCloseModal: handleCloseConfirmationModal,
    handleSuccessModal: handleSuccessConfirmationModal,
  } = useModalState({
    onSuccess: () => {
      handleSubmit(handleUpdateData)();
      handleCloseModal();
    },
  });

  return (
    <Sheet open={isOpenModal}>
      <SheetContent
        className="w-full max-w-[485px] border-transparent bg-transparent p-2 shadow-none"
        onInteractOutside={handleCloseModal}
        side="RIGHT"
      >
        <AlertDialogWrapper
          control={{ onOpenChange: confirmationModalToggler, open: isOpenConfirmationModal }}
        >
          <UpdateConfirmationModal
            onClose={handleCloseConfirmationModal}
            onSuccess={handleSuccessConfirmationModal}
            text="SAFE"
          />
        </AlertDialogWrapper>
        <div className="flex h-full w-full flex-col overflow-hidden rounded-lg border-[1px] border-gray-300 bg-gray-100 pb-4">
          <div className="flex h-fit w-full items-center justify-between bg-white px-6 py-3">
            <span className="truncate text-xl font-[550] text-gray-700">
              {previewMode ? (
                <AppFormattedMessage
                  id={StringKey.VIEW_POOL}
                  values={{
                    text: 'SAFE',
                  }}
                />
              ) : (
                <AppFormattedMessage
                  id={StringKey.EDIT_POOL}
                  values={{
                    text: 'SAFE',
                  }}
                />
              )}
            </span>
            <CloseModalButton onClose={handleCloseModal} />
          </div>
          <div className="flex h-full flex-col gap-4 overflow-hidden">
            {formSteps[step - 1]({
              companyId,
              setError,
              formData: getValues,
              nextFormStep: handleOpenConfirmationModal,
              prevFormStep: () => {},
              setFormData: setValue,
              control,
              handleCloseModal,
              filedState: getFieldState,
              errors,
              clearErrors,
              lockMode: true,
              previewMode,
              onEditClick: (step) => onEditClick({ id: safeId, step }),
            })}
          </div>
        </div>
      </SheetContent>
    </Sheet>
  );
};
