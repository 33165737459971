import React, { FC } from 'react';

import { AppFormattedMessage } from '../../../../../components/AppFormattedMessage';
import { StakeholderPoolsPlansTableColumnTitle } from '../../../../../types/pool-plans.types';
import { PlanStakeholder } from '../../../../../types/stakeholderTypes';
import { ScrollableStakeholderItem } from './ScrollableStakeholderItem';

export type ScrollableStakeholderTableProps = {
  poolsByStakeholders: PlanStakeholder[] | undefined;
  selectedPool: string[];
  selectedPlan: string[];
  onEditClick: (id: string) => void;
  onViewClick: (id: string) => void;
  onDeleteClick: (id: string) => void;
  onEditPlanClick: (id: string) => void;
  onViewPlanClick: (id: string) => void;
  onDeletePlanClick: (id: string) => void;
  onEditTerminationDateClick: (id: string) => void;
};

const tableColumns = Object.values(StakeholderPoolsPlansTableColumnTitle);

export const ScrollableStakeholderTable: FC<ScrollableStakeholderTableProps> = ({
  poolsByStakeholders,
  selectedPlan,
  selectedPool,
  onEditClick,
  onViewClick,
  onDeleteClick,
  onEditPlanClick,
  onViewPlanClick,
  onDeletePlanClick,
  onEditTerminationDateClick,
}) => {
  return (
    <table className="w-full transition-all">
      <thead>
        <tr className="border-y-[1px] border-[#F2F2F2] bg-gray-50">
          {tableColumns.map((column) => (
            <td className="px-4 py-3" key={column}>
              <div className="flex items-center">
                <span className="whitespace-nowrap text-label-md font-[450] text-[#172335]">
                  <AppFormattedMessage id={column} />
                </span>
              </div>
            </td>
          ))}
          <td className="relative">
            <div className="flex h-full w-full items-center justify-center px-6"></div>
          </td>
        </tr>
      </thead>
      <tbody>
        {poolsByStakeholders && (
          <>
            {poolsByStakeholders.map((stakeholder) => (
              <ScrollableStakeholderItem
                isSelected={selectedPool.includes(stakeholder.id)}
                key={stakeholder.id}
                onDeleteClick={onDeleteClick}
                onDeletePlanClick={onDeletePlanClick}
                onEditClick={onEditClick}
                onEditPlanClick={onEditPlanClick}
                onEditTerminationDateClick={onEditTerminationDateClick}
                onViewClick={onViewClick}
                onViewPlanClick={onViewPlanClick}
                selectedPlan={selectedPlan}
                stakeholder={stakeholder}
              />
            ))}
          </>
        )}
      </tbody>
    </table>
  );
};
