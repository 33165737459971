import React, { FC } from 'react';
import { twMerge } from 'tailwind-merge';

import { EditIcon } from '../../../../assets/icons';
import { AppFormattedMessage } from '../../../../components/AppFormattedMessage';
import Button from '../../../../components/Button';
import { SummaryItem } from '../../../../components/SummaryItem';
import { useFormat } from '../../../../hooks';
import { StringKey } from '../../../../lang';
import { ChildrenFormProps, EditSafeStepVariant } from '../type';

export type EditPreviewProps = {
  onEditClick: (step: EditSafeStepVariant) => void;
  previewMode?: true;
} & ChildrenFormProps;

export const EditPreview: FC<EditPreviewProps> = ({
  handleCloseModal,
  formData,
  onEditClick,
  previewMode,
}) => {
  const { stepOne, stepTwo, stepThree } = formData();
  const { format } = useFormat();
  const handleDocumentClick = (link: string) => {
    window.open(link);
  };

  return (
    <>
      <div className="flex h-full flex-col gap-4 overflow-y-auto rounded-b-xl border-b border-gray-300 bg-white p-6 pt-0">
        <div className="flex h-fit flex-col gap-2 rounded-lg bg-gray-50 pt-2">
          <div className="flex w-full justify-between px-4">
            <span className="text-sm font-[550] text-gray-700">
              <AppFormattedMessage id={StringKey.GENERAL} />
            </span>
            {!previewMode && (
              <Button className="w-fit" onClick={() => onEditClick(1)} styleType="NONE">
                <EditIcon iconColor="#2565C8" />
              </Button>
            )}
          </div>
          <div className="flex flex-col divide-y divide-gray-100 rounded-b-lg border border-gray-100 bg-white p-4 pb-3">
            <SummaryItem
              className="pt-0"
              title={<AppFormattedMessage id={StringKey.SHAREHOLDER} />}
              value={stepOne?.stakeholder?.fullName}
            />

            <SummaryItem
              title={<AppFormattedMessage id={StringKey.INVESTMENT} />}
              value={stepOne?.investment && Number(stepOne?.investment).toLocaleString('en-US')}
            />
            <SummaryItem
              className="pb-0"
              title="Issue Date"
              value={format(stepOne?.issueDate, 'dd/MM/yyyy')}
            />
          </div>
        </div>
        <div className="flex h-fit flex-col gap-2 rounded-lg bg-gray-50 pt-2">
          <div className="flex w-full justify-between px-4">
            <span className="text-sm font-[550] text-gray-700">Cap or Discount Details</span>
            {!previewMode && (
              <Button className="w-fit" onClick={() => onEditClick(2)} styleType="NONE">
                <EditIcon iconColor="#2565C8" />
              </Button>
            )}
          </div>
          <div className="flex flex-col divide-y divide-gray-100 rounded-b-lg border border-gray-100 bg-white p-4 pb-3">
            <SummaryItem
              className="pt-0"
              title="Cap"
              value={
                stepTwo?.cap?.capValue
                  ? Number(stepTwo?.cap?.capValue).toLocaleString('en-US')
                  : '-'
              }
            />

            <SummaryItem
              className="pb-0"
              title="Discount"
              value={
                stepTwo?.discount?.discountValue ? `${stepTwo?.discount?.discountValue}%` : '-'
              }
            />
          </div>
        </div>
        {stepTwo?.floor?.enabled && (
          <div className="flex h-fit flex-col gap-2 rounded-lg bg-gray-50 pt-2">
            <div className="flex w-full justify-between px-4">
              <span className="text-sm font-[550] text-gray-700">Floor & Maturity Date</span>
              {!previewMode && (
                <Button className="w-fit" onClick={() => onEditClick(2)} styleType="NONE">
                  <EditIcon iconColor="#2565C8" />
                </Button>
              )}
            </div>
            <div className="flex flex-col divide-y divide-gray-100 rounded-b-lg border border-gray-100 bg-white p-4 pb-3">
              <SummaryItem
                className="pt-0"
                title="Floor"
                value={
                  stepTwo?.floor?.floorValue
                    ? Number(stepTwo?.floor?.floorValue).toLocaleString('en-US')
                    : '-'
                }
              />
              <SummaryItem
                className="pb-0"
                title="Maturity Date"
                value={
                  stepTwo?.floor?.maturityDate
                    ? format(stepTwo?.floor?.maturityDate, 'dd/MM/yyyy')
                    : '-'
                }
              />
            </div>
          </div>
        )}

        <div className="flex w-full items-center justify-between rounded-lg border border-gray-100 bg-white px-4">
          <div className={'flex gap-3 text-nowrap py-2'}>
            <span className="w-[120px] flex-shrink-0 text-xs font-[450] text-gray-500">
              <AppFormattedMessage id={StringKey.DOCUMENTS} />:
            </span>
            <div className="flex max-w-[220px] flex-col gap-2">
              {(stepThree?.files?.length || 0) > 0 ? (
                stepThree?.files?.map(({ doc, docLink }) => (
                  <div
                    className={twMerge(
                      'truncate text-xs font-[450] text-gray-700',
                      previewMode && 'cursor-pointer text-brand-700 underline',
                    )}
                    key={docLink}
                    onClick={() => previewMode && handleDocumentClick(docLink)}
                  >
                    {doc?.name}
                  </div>
                ))
              ) : (
                <span className="truncate text-xs font-[450] text-gray-700">-</span>
              )}
            </div>
          </div>
          {!previewMode && (
            <Button className="w-fit" onClick={() => onEditClick(3)} styleType="NONE">
              <EditIcon iconColor="#2565C8" />
            </Button>
          )}
        </div>
        <div className="flex w-full items-start justify-between rounded-lg border border-gray-100 bg-white px-4 py-2">
          <div className="flex w-full gap-3">
            <span className="w-[120px] flex-shrink-0 text-xs font-[450] text-gray-500">
              {<AppFormattedMessage id={StringKey.ADDITIONAL_NOTES} />}:
            </span>
            <div
              className={twMerge(
                'max-w-[250px] break-words text-xs font-[450] text-gray-700',
                !previewMode ? 'max-w-[220px]' : 'max-w-[250px]',
              )}
            >
              {stepThree?.additionalNotes || '-'}
            </div>
          </div>
          {!previewMode && (
            <Button className="w-fit" onClick={() => onEditClick(3)} styleType="NONE">
              <EditIcon iconColor="#2565C8" />
            </Button>
          )}
        </div>
      </div>

      <div
        className={twMerge(
          'flex h-9 w-full justify-start gap-3 px-6',
          previewMode && 'justify-end',
        )}
      >
        <Button
          className={twMerge(
            'w-fit bg-transparent px-4 py-[6px] text-sm font-[450] text-gray-700',
            previewMode && 'bg-brand-700 text-white',
          )}
          onClick={handleCloseModal}
          styleType="NONE"
          type="button"
        >
          {previewMode ? (
            <AppFormattedMessage id={StringKey.CLOSE} />
          ) : (
            <AppFormattedMessage id={StringKey.CANCEL} />
          )}
        </Button>
      </div>
    </>
  );
};
