import { ReactNode } from 'react';

import { AppFormattedMessage } from '../../../components/AppFormattedMessage';
import { StringKey } from '../../../lang';
import { SubscriptionDuration, SubscriptionType } from '../../../types/planTypes';

export enum SubscriptionVariant {
  MONTHLY = 'monthly',
  ANNUAL = 'annual',
}

export const SubscriptionVariantTranslation: Record<SubscriptionDuration, ReactNode> = {
  [SubscriptionDuration.ANNUALY]: <AppFormattedMessage id={StringKey.ANNUAL} />,
  [SubscriptionDuration.MONTHLY]: 'Monthly',
};

export enum SubscriptionDurationVariant {
  MONTHLY = 'monthly',
  ANNUALY = 'annualy',
}

export const SubscriptionDurationTranslation: Record<SubscriptionDurationVariant, string> = {
  [SubscriptionDurationVariant.ANNUALY]: 'month',
  [SubscriptionDurationVariant.MONTHLY]: 'month',
};

export type SubscriptionVariantType = {
  name: string;
  price: number;
  currencyMark: string;
  tag?: string;
  paymentInterval: SubscriptionDuration;
  description: string;
  type: SubscriptionType;
};
