import { UseMutateFunction } from '@tanstack/react-query';
import React, { FC, useCallback, useEffect } from 'react';
import { v4 as uuidv4 } from 'uuid';

import { HttpException } from '../../../../exceptions';
import { useReactForm } from '../../../../hooks';
import {
  CompanyStakeholderDto,
  HumanStakeholderDto,
  Stakeholder,
} from '../../../../types/stakeholderTypes';
import { getS3FileOriginalName } from '../../../../utils/getS3FileOriginalName';
import { transformDatetoISOString } from '../../Stakeholders';
import { EditPreview, StepOne, StepTwo } from '../Steps';
import { ChildrenFormProps, EditStakeholderVariant } from '../type';
import { FormSchema, formSchema } from '../Validation';

export type EditStakeholderFormProps = {
  isEditing: boolean;
  stakeholderId: string;
  defaultData: Stakeholder | undefined;
  setCloseModal: () => void;
  isDemo: boolean;
  onEditClick: (props: { id: string; step: EditStakeholderVariant }) => void;
  step: EditStakeholderVariant;
  mutateStakeholder: UseMutateFunction<
    unknown,
    HttpException,
    CompanyStakeholderDto | HumanStakeholderDto
  >;
};

const formSteps: ((
  props: {
    onEditClick: (step: EditStakeholderVariant) => void;
  } & ChildrenFormProps,
) => JSX.Element)[] = [
  (props) => <StepOne {...props} />,
  (props) => <StepTwo {...props} />,
  (props) => <EditPreview {...props} />,
];

const EditStakeholderForm: FC<EditStakeholderFormProps> = ({
  isEditing,
  mutateStakeholder,
  defaultData,
  setCloseModal,
  isDemo,
  onEditClick,
  stakeholderId,
  step,
}) => {
  const {
    control,
    setValue,
    handleSubmit,
    resetField,
    reset,
    getValues,
    setError,
    getFieldState,
    clearErrors,
    formState: { disabled, errors },
  } = useReactForm({
    schema: formSchema,
    disabled: !isEditing,
  });

  const submitHandler = useCallback(
    (data: FormSchema) => {
      const { stepOne, stepTwo } = data;
      const {
        address,
        email,
        fullName,
        stakeholderType,
        phoneNumber,
        access,
        phoneCountry,
        human,
        company,
      } = stepOne;
      if (!human || !company) return;
      const { birthdayDate, nationality, passportExpiryDate, passportNo } = human;

      const { identifier, incorporationCountry, incorporationDate } = company;

      mutateStakeholder(
        {
          countryOfInc: incorporationCountry?.iso2Code || undefined,
          stakeholderCompanyId: identifier,
          address: address,
          incDate: incorporationDate
            ? transformDatetoISOString(
                incorporationDate.year,
                incorporationDate.month + 1,
                incorporationDate.day,
              )
            : undefined,
          birthday: birthdayDate
            ? transformDatetoISOString(birthdayDate.year, birthdayDate.month + 1, birthdayDate.day)
            : undefined,
          email,
          fullName,
          nationality: nationality?.iso2Code || undefined,
          passportExpDate: passportExpiryDate
            ? transformDatetoISOString(
                passportExpiryDate.year,
                passportExpiryDate.month + 1,
                passportExpiryDate.day,
              )
            : undefined,
          passportNo: passportNo.toString(),
          type: stakeholderType,
          phone: phoneCountry?.prefix ? phoneCountry?.prefix + phoneNumber : undefined,
          phoneCountryIso2: phoneCountry?.iso2Code || undefined,
          capTableAccess: access?.capTable,
          positionAccess: access?.position,
          capitalChangeAccess: access?.equity,
          companyTableAccess: access?.companyTable,
          additionalNotes: stepTwo?.additionalNotes,
          filesLinks:
            stepTwo?.files?.reduce<string[]>((prev, curr) => [...prev, curr.docLink], []) || [],
        },
        {
          onSuccess: () => setCloseModal(),
        },
      );

      return;
    },
    [mutateStakeholder, setCloseModal],
  );

  useEffect(() => {
    if (!defaultData) return;

    const files: {
      docLink: string;
      loadProgress: 100;
      abort: () => void;
      id: string;
      doc: {
        size: number;
        type: string;
        name: string;
      };
    }[] = defaultData?.filesLinks?.map((link) => {
      const originalFileName = getS3FileOriginalName(link);

      return {
        abort: () => '',
        loadProgress: 100,
        doc: {
          name: originalFileName || '',
          size: 10,
          type: 'application/pdf',
        },
        docLink: link,
        id: uuidv4(),
      };
    });

    reset({
      stepOne: {
        email: defaultData?.email,
        fullName: defaultData?.fullName,
        address: defaultData?.address || undefined,
        phoneNumber: defaultData?.phone || undefined,
        phoneCountry: {
          iso2Code: defaultData?.phoneCountryIso2 || '',
          name: '',
          prefix: '',
        },
        access: {
          capTable: defaultData?.capTableAccess,
          position: defaultData?.positionAccess,
          equity: defaultData?.capitalChangeAccess,
          companyTable: defaultData?.companyTableAccess,
        },
        stakeholderType: defaultData?.type,
        human: {
          birthdayDate: defaultData?.birthday
            ? {
                day: new Date(defaultData?.birthday).getDate(),
                month: new Date(defaultData?.birthday).getMonth(),
                year: new Date(defaultData?.birthday).getFullYear(),
              }
            : undefined,
          nationality: defaultData?.nationalityCountry
            ? {
                iso2Code: defaultData?.nationalityCountry.iso2,
                name: defaultData?.nationalityCountry.name,
              }
            : undefined,
          passportExpiryDate: defaultData?.passportExpDate
            ? {
                day: new Date(defaultData?.passportExpDate).getDate(),
                month: new Date(defaultData?.passportExpDate).getMonth(),
                year: new Date(defaultData?.passportExpDate).getFullYear(),
              }
            : undefined,
          passportNo: defaultData?.passportNo || undefined,
        },
        company: {
          identifier: defaultData?.stakeholderCompanyId || undefined,
          incorporationCountry: defaultData?.countryOfInc
            ? {
                iso2Code: defaultData.countryOfInc.iso2,
                name: defaultData?.countryOfInc.name,
              }
            : undefined,
          incorporationDate: defaultData?.incDate
            ? {
                day: new Date(defaultData?.incDate).getDate(),
                month: new Date(defaultData?.incDate).getMonth(),
                year: new Date(defaultData?.incDate).getFullYear(),
              }
            : undefined,
        },
      },
      stepTwo: {
        additionalNotes: defaultData?.additionalNotes || undefined,
        files: files,
      },
    });
  }, [defaultData, reset]);

  return (
    <div className="flex h-full flex-col gap-4 overflow-hidden">
      {formSteps[step - 1]({
        setError,
        formData: getValues,
        nextFormStep: () => handleSubmit(submitHandler)(),
        prevFormStep: () => {},
        setFormData: setValue,
        control,
        handleCloseModal: setCloseModal,
        filedState: getFieldState,
        errors,
        clearErrors,
        lockMode: true,
        previewMode: !isEditing,
        resetField,
        disabled,
        onEditClick: (step) => onEditClick({ id: stakeholderId, step }),
        isDemo,
      })}
    </div>
  );
};

export default EditStakeholderForm;
