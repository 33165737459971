import { FC, ReactNode } from 'react';

import { PlusIcon } from '../../assets/icons';
import calendar from '../../assets/images/calendar.png';
import { AppFormattedMessage } from '../../components/AppFormattedMessage';
import Button from '../../components/Button';
import { StringKey } from '../../lang';

type EmptyEventHistoryProps = { text?: string | ReactNode; title?: string | ReactNode } & (
  | {
      onAddEventClick: () => void;
      isDisplayedAddEvent?: boolean;
    }
  | { onAddEventClick?: never; isDisplayedAddEvent: boolean }
);

export const EmptyEventHistory: FC<EmptyEventHistoryProps> = ({
  onAddEventClick,
  isDisplayedAddEvent = true,
  text,
  title,
}) => (
  <div className="mb-10 flex h-fit w-fit flex-col items-center justify-center text-center">
    <div
      className="rounded-full p-[12px]"
      style={{
        boxShadow: '0px 0px 16px 0px #0985F721 inset',
      }}
    >
      <div
        className="flex size-[112px] items-center justify-center rounded-full"
        style={{
          boxShadow: '0px 0px 8px 0px #0985F721 inset',
          background:
            'linear-gradient(180deg, rgba(34, 200, 253, 0.13) 0%, rgba(3, 77, 220, 0.13) 100%)',
        }}
      >
        <img alt="calendar" className="size-14" src={calendar} />
      </div>
    </div>
    <div className="flex flex-col gap-2">
      <span
        className="!bg-clip-text text-xl font-semibold text-transparent"
        style={{
          background: 'linear-gradient(132.59deg, #475467 29.58%, #101828 84.27%)',
        }}
      >
        {title || <AppFormattedMessage id={StringKey.NO_EVENTS} />}
      </span>
      <span className="line-clamp-2 w-full max-w-[230px] overflow-hidden text-xs font-[450] text-gray-500">
        {text ? text : <AppFormattedMessage id={StringKey.ADD_FIRST_EVENT} />}
      </span>
    </div>
    {isDisplayedAddEvent && (
      <Button
        className="mt-6 h-9 w-fit items-center gap-1 rounded bg-brand-700 px-4 py-[6px] text-sm font-[550] text-white"
        onClick={onAddEventClick}
      >
        <PlusIcon />
        <AppFormattedMessage id={StringKey.ADD_EVENT} />
      </Button>
    )}
  </div>
);
