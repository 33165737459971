import { HoverCard, HoverCardTrigger } from '@radix-ui/react-hover-card';
import React, { FC } from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';
import { twMerge } from 'tailwind-merge';

import { ChevronDownIcon, InfoCircle } from '../../../../../assets/icons';
import { AppFormattedMessage } from '../../../../../components/AppFormattedMessage';
import Button from '../../../../../components/Button';
import { HoverCardContent } from '../../../../../components/HoverCard';
import Loader from '../../../../../components/Loader';
import { SharePlan, useFormat } from '../../../../../hooks';
import { StringKey } from '../../../../../lang';
import { Pool, sharePlanTypeTitle } from '../../../../../types/pool-plans.types';

type StickyPoolPlanItemProps = {
  companyId: string;
  pool: Pool;
  isSelected: boolean;
  setSelectedPlan: (id: string) => void;
  stakeholderId: string;
};

export const StickyPoolPlanItem: FC<StickyPoolPlanItemProps> = ({
  pool,
  isSelected,
  setSelectedPlan,
  companyId,
  stakeholderId,
}) => {
  const { format } = useFormat();
  const handlePlanUnselected = () => {
    setSelectedPlan('');
  };

  const handlePlanSelected = () => {
    setSelectedPlan(pool.id);
  };

  const { sharePlans, fetchNextPage, hasNextPage, numberOfFetchedItems } =
    SharePlan.useSharePlansByStakeholder({
      companyId,
      poolId: pool?.id || '',
      stakeholderId,
    });

  return (
    <>
      <tr
        className={twMerge(
          'flex h-[72px] w-full border-l-[2px] border-l-brand-700 bg-white transition-colors',
          isSelected && 'bg-brand-25',
        )}
      >
        <td className="relative h-[72px] w-[49px] border-b-[1px] border-b-gray-100 bg-gray-50">
          <div className="flex h-full w-full items-center justify-center">
            <div className="w-[49px]">
              {sharePlans && (
                <Button
                  className="h-[72px] p-4 transition-colors"
                  onClick={isSelected ? handlePlanUnselected : handlePlanSelected}
                  styleType="NONE"
                >
                  <ChevronDownIcon
                    className={twMerge('transition-transform', isSelected ? 'rotate-180' : '')}
                    iconColor={isSelected ? '#2565C8' : '#344054'}
                  />
                </Button>
              )}
            </div>
          </div>
        </td>
        <td
          className={twMerge(
            'flex h-[72px] w-full items-center gap-4 overflow-hidden text-nowrap border-b-[1px] border-b-gray-100 pb-4 pl-10 pr-4 pt-4',
          )}
        >
          <div className="flex max-w-[300px] flex-col">
            <span className="truncate text-sm font-[450] text-gray-900">{pool?.name}</span>
          </div>
          {pool.isTerminated && (
            <HoverCard>
              <HoverCardTrigger>
                <InfoCircle className="size-4 cursor-pointer" />
              </HoverCardTrigger>
              <HoverCardContent
                className="z-20 w-max rounded-lg bg-[#101828] px-3 py-2"
                isShownTriangle
                side="top"
                triangleClassName="bottom-0 left-1/2 transform -translate-x-1/2 translate-y-1/2 ml-0"
              >
                <span className="text-label-md font-[550] text-white">
                  <AppFormattedMessage id={StringKey.TERMINATION_SET} />{' '}
                  {pool?.terminationDate && format(pool?.terminationDate, 'dd/MM/yyyy')}
                </span>
              </HoverCardContent>
            </HoverCard>
          )}
        </td>
      </tr>
      {isSelected && sharePlans && (
        <>
          <InfiniteScroll
            dataLength={numberOfFetchedItems}
            hasMore={hasNextPage}
            loader={<Loader />}
            next={fetchNextPage}
            scrollableTarget="scrollbar-target"
          >
            {sharePlans.map((plan) => (
              <tr
                className="h-[72px] w-full border-l-2 border-brand-700 bg-white transition-colors"
                key={plan.id}
              >
                <td className="relative">
                  <div className="flex h-full w-full cursor-pointer items-center justify-center"></div>
                </td>
                <td className="flex w-fit items-center gap-4 overflow-hidden text-nowrap pb-4 pl-24 pr-4 pt-4">
                  <div className="flex max-w-[300px] flex-col">
                    <span className="truncate text-sm font-[450] text-gray-900">{plan?.name}</span>
                    <span className="text-xs font-[450] text-gray-400">
                      <AppFormattedMessage id={sharePlanTypeTitle[plan?.type]} />
                    </span>
                  </div>
                  {plan.isTerminated && (
                    <HoverCard>
                      <HoverCardTrigger>
                        <InfoCircle className="size-4 cursor-pointer" />
                      </HoverCardTrigger>
                      <HoverCardContent
                        className="z-20 w-max rounded-lg bg-[#101828] px-3 py-2"
                        isShownTriangle
                        side="top"
                        triangleClassName="bottom-0 left-1/2 transform -translate-x-1/2 translate-y-1/2 ml-0"
                      >
                        <span className="text-label-md font-[550] text-white">
                          <AppFormattedMessage id={StringKey.TERMINATION_SET} />{' '}
                          {plan?.terminationDate && format(plan?.terminationDate, 'dd/MM/yyyy')}
                        </span>
                      </HoverCardContent>
                    </HoverCard>
                  )}
                </td>
              </tr>
            ))}
          </InfiniteScroll>
        </>
      )}
    </>
  );
};
