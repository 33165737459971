import { FC } from 'react';

import { AppFormattedMessage } from '../../../../../components/AppFormattedMessage';
import { useFormat } from '../../../../../hooks';
import { eventFormType, EventTypeBackend } from '../../../../../types/events.types';
import { sharePlanTypeTitle } from '../../../../../types/pool-plans.types';
import { BasicTable } from './BasicTable';
import { Td } from './TableTd';
import { TableBodyProps } from './types';

export const NewPlan: FC<TableBodyProps> = (props) => {
  const { format } = useFormat();
  if (props.type !== EventTypeBackend.NEW_PLAN) return <></>;

  const { pool, sharePlan, type } = props;

  return (
    <BasicTable eventType={EventTypeBackend.NEW_PLAN}>
      <tr>
        <Td value={format(sharePlan.date, 'dd/MM/yyyy')} />
        <Td value={eventFormType[type]} />
        <Td value={sharePlan.name} />
        <Td value={pool.name} />
        <Td value={<AppFormattedMessage id={sharePlanTypeTitle[sharePlan?.type]} />} />
        <Td className="w-12" value="" />
      </tr>
    </BasicTable>
  );
};
