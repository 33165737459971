import { FC, ReactNode } from 'react';
import { twMerge } from 'tailwind-merge';

import { AppFormattedMessage } from '../../components/AppFormattedMessage';
import { SelectedCompanyCurrency } from '../../components/SelectedCompanyCurrency';
import { StringKey } from '../../lang';
import { ShareClassStats } from '../../types/snapshot.types';
import { toRound } from '../../utils/getRoundedNumber';
import { toNumber } from '../../utils/toNumber';

export type DetailsItemProps = {
  name: string;
  description: string;
  shareClasses: ShareClassStats[];
  issuedPercentage: number;
  dilutedPercentage: number;
  votingPercentage: number;
  diluted: number;
  issued: number;
  className?: string;
  children?: ReactNode;
  avatar?: boolean;
};

export const DetailsItem: FC<DetailsItemProps> = ({
  description,
  diluted,
  dilutedPercentage,
  issued,
  issuedPercentage,
  name,
  shareClasses,
  votingPercentage,
  className,
  children,
  avatar,
}) => (
  <div className={twMerge('flex w-full flex-col gap-2 rounded-md py-3 shadow-sm', className)}>
    <div className="flex w-full items-center gap-4 border-b-[2px] border-b-gray-100 px-3 pb-3">
      {avatar && (
        <div className="flex size-10 shrink-0 items-center justify-center rounded-full bg-gray-200 text-sm font-[450] text-gray-700">
          {name[0]}
        </div>
      )}
      <div className="flex flex-col overflow-hidden">
        <span className="truncate text-base font-semibold text-gray-700">{name}</span>
        <span className="text-xs font-[450] text-gray-400">{description}</span>
      </div>
    </div>
    <div className="grid grid-flow-row grid-cols-2">
      <div className="centered flex w-full flex-col gap-3 border border-t-0 border-gray-100 py-3">
        <span className="text-label-md font-medium">
          <AppFormattedMessage id={StringKey.ISSUED_PERCENTAGE} />
        </span>
        <span>{toRound(toNumber(issuedPercentage) * 100)}</span>
      </div>
      <div className="centered flex w-full flex-col gap-3 border border-t-0 border-gray-100 py-3">
        <span className="text-label-md font-medium">
          <AppFormattedMessage id={StringKey.DILUTED_PERCENTAGE} />
        </span>
        <span>{toRound(toNumber(dilutedPercentage) * 100)}</span>
      </div>
      <div className="centered flex w-full flex-col gap-3 border border-gray-100 py-3">
        <span className="text-label-md font-medium">
          <AppFormattedMessage id={StringKey.VOTING_PERCENTAGE} />
        </span>
        <span>{toRound(toNumber(votingPercentage) * 100)}</span>
      </div>
      <div className="centered flex w-full flex-col gap-3 border border-gray-100 py-3">
        <span className="text-label-md font-medium">
          <AppFormattedMessage id={StringKey.NO_ISSUED} />
        </span>
        <span>{issued.toLocaleString('en-US')}</span>
      </div>
      <div className="centered flex w-full flex-col gap-3 border border-b-0 border-gray-100 py-3">
        <span className="text-label-md font-medium">
          <AppFormattedMessage id={StringKey.DILUTED_SHARES} />
        </span>
        <span>{diluted.toLocaleString('en-US')}</span>
      </div>
      <div className="centered flex w-full flex-col gap-3 border border-b-0 border-gray-100 py-3">
        <span className="text-label-md font-medium">
          <AppFormattedMessage id={StringKey.INVESTMENT} /> (<SelectedCompanyCurrency />)
        </span>
        <span>{toRound(0)}</span>
      </div>
    </div>
    <div className="flex flex-col gap-2 px-2">
      {shareClasses.map(({ name, issued }) => (
        <div className="centered flex w-full flex-col gap-1 rounded-lg bg-gray-50 px-4 py-2 text-gray-700">
          <span className="text-label-md font-medium">{name}</span>
          <span className="text-sm font-[450]">{issued.toLocaleString('en-US')}</span>
        </div>
      ))}
    </div>
    {children}
  </div>
);
