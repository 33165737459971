import React, { FC, useCallback, useContext } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { twMerge } from 'tailwind-merge';

import {
  CapQuestLogo,
  CapTableIcon,
  CircleArrowUpIcon,
  CoinSwapIcon,
  CompaniesIcon,
  DashboardIcon,
  DoubleChevronIcon,
  EventsIcon,
  HoldingsIcon,
  LogoutIcon,
  PoolPlansIcon,
  ProfileIcon,
  SettingsIcon,
  ShareClassesIcon,
  StakeholdersIcon,
} from '../../assets/icons';
import { IconProps } from '../../assets/icons/type';
import { Company, User, useScreenSize } from '../../hooks';
import { StringKey } from '../../lang';
import { sideBarContext } from '../../storage/sideBarContext';
import { pageTitleTranslation } from '../../translations/pageTranslation';
import { PageRoute } from '../../types/pageTypes';
import { StakeholderRole } from '../../types/stakeholderTypes';
import { AppFormattedMessage } from '../AppFormattedMessage';
import Button from '../Button';
import CompanySelect from '../CompanySelect';
import { HoverCard, HoverCardContent, HoverCardTrigger } from '../HoverCard';
import { Sheet, SheetContent, SheetFooter } from '../Sheet';
import { SideBarItem } from './SideBarItem';

export type SideBarOption = {
  IconComponent: FC<IconProps>;
  link: string;
  title: StringKey;
};

export const sideBarOptions: Record<StakeholderRole, SideBarOption[]> = {
  [StakeholderRole.ADMIN]: [
    {
      link: PageRoute.DASHBOARD,
      title: pageTitleTranslation[PageRoute.DASHBOARD],
      IconComponent: DashboardIcon,
    },
    {
      link: PageRoute.STAKEHOLDERS,
      title: pageTitleTranslation[PageRoute.STAKEHOLDERS],
      IconComponent: StakeholdersIcon,
    },
    {
      link: PageRoute.SHARE_CLASSES,
      title: pageTitleTranslation[PageRoute.SHARE_CLASSES],
      IconComponent: ShareClassesIcon,
    },
    {
      link: PageRoute.POOL_PLANS,
      title: pageTitleTranslation[PageRoute.POOL_PLANS],
      IconComponent: PoolPlansIcon,
    },
    {
      link: PageRoute.EVENTS,
      title: pageTitleTranslation[PageRoute.EVENTS],
      IconComponent: EventsIcon,
    },
    {
      link: PageRoute.CAP_TABLE,
      title: pageTitleTranslation[PageRoute.CAP_TABLE],
      IconComponent: CapTableIcon,
    },
    {
      link: PageRoute.SAFES,
      title: pageTitleTranslation[PageRoute.SAFES],
      IconComponent: CoinSwapIcon,
    },
    {
      link: PageRoute.MY_HOLDINGS,
      title: pageTitleTranslation[PageRoute.MY_HOLDINGS],
      IconComponent: HoldingsIcon,
    },
  ],
  [StakeholderRole.CONTRIBUTOR]: [
    {
      link: PageRoute.DASHBOARD,
      title: pageTitleTranslation[PageRoute.DASHBOARD],
      IconComponent: DashboardIcon,
    },
    {
      link: PageRoute.STAKEHOLDERS,
      title: pageTitleTranslation[PageRoute.STAKEHOLDERS],
      IconComponent: StakeholdersIcon,
    },
    {
      link: PageRoute.SHARE_CLASSES,
      title: pageTitleTranslation[PageRoute.SHARE_CLASSES],
      IconComponent: ShareClassesIcon,
    },
    {
      link: PageRoute.POOL_PLANS,
      title: pageTitleTranslation[PageRoute.POOL_PLANS],
      IconComponent: PoolPlansIcon,
    },
    {
      link: PageRoute.EVENTS,
      title: pageTitleTranslation[PageRoute.EVENTS],
      IconComponent: EventsIcon,
    },
    {
      link: PageRoute.CAP_TABLE,
      title: pageTitleTranslation[PageRoute.CAP_TABLE],
      IconComponent: CapTableIcon,
    },
    {
      link: PageRoute.SAFES,
      title: pageTitleTranslation[PageRoute.SAFES],
      IconComponent: CoinSwapIcon,
    },
    {
      link: PageRoute.MY_HOLDINGS,
      title: pageTitleTranslation[PageRoute.MY_HOLDINGS],
      IconComponent: HoldingsIcon,
    },
  ],
  [StakeholderRole.STAKEHOLDER]: [
    {
      link: PageRoute.MY_HOLDINGS,
      title: pageTitleTranslation[PageRoute.MY_HOLDINGS],
      IconComponent: HoldingsIcon,
    },
    {
      link: PageRoute.CAP_TABLE,
      title: pageTitleTranslation[PageRoute.CAP_TABLE],
      IconComponent: CapTableIcon,
    },
  ],
};

const SideBar: FC = () => {
  const navigate = useNavigate();
  const { width: screenWidth } = useScreenSize();
  const { selectedCompany } = Company.useSelected();
  const { logoutUser } = User.useLogout();

  const { closeSideBar, isOpen: isExpanded, openSideBar } = useContext(sideBarContext);

  const location = useLocation();
  const isMobile = screenWidth <= 1023;

  const handleLogout = useCallback(() => {
    logoutUser();
  }, [logoutUser]);

  return (
    <>
      <div className={twMerge('bg-gray-50 px-4 py-3 max-lg:hidden')}>
        <div className={twMerge('flex h-full flex-col justify-between')}>
          <div
            className={twMerge(
              'flex w-10 flex-col justify-stretch gap-3 transition-all duration-300',
              isExpanded && 'w-[230px]',
            )}
          >
            <Button className="size-10" onClick={() => navigate(PageRoute.HOME)} styleType="NONE">
              <CapQuestLogo className="mb-3 mt-1" size={32} />
            </Button>
            {selectedCompany ? (
              <div className={twMerge('flex w-full flex-col gap-3')}>
                {sideBarOptions[selectedCompany.roleInCompany].map((props, i) => {
                  if (
                    props.link === PageRoute.CAP_TABLE &&
                    !selectedCompany?.stakeholder?.capTableAccess
                  )
                    return <></>;

                  return (
                    <HoverCard key={`${props.title}_${i}`}>
                      <HoverCardTrigger>
                        <SideBarItem
                          Icon={props.IconComponent}
                          isExpanded={isExpanded}
                          isSelected={location.pathname.includes(props.link)}
                          onClick={() => {
                            navigate(props.link);
                          }}
                          {...props}
                        />
                      </HoverCardTrigger>
                      {!isExpanded && (
                        <HoverCardContent
                          className="z-20 w-max rounded-lg bg-[#101828] px-3 py-2"
                          isShownTriangle
                          side="right"
                          triangleClassName="left-0 top-1/2 transform -translate-y-1/2 "
                        >
                          <span className="text-label-md font-[550] text-white">
                            <AppFormattedMessage id={props.title} />
                          </span>
                        </HoverCardContent>
                      )}
                    </HoverCard>
                  );
                })}

                <div className="h-[1px] w-full bg-gray-200" />
                <HoverCard>
                  <HoverCardTrigger>
                    <SideBarItem
                      Icon={CompaniesIcon}
                      isExpanded={isExpanded}
                      isSelected={location.pathname === PageRoute.MY_COMPANIES}
                      onClick={() => {
                        navigate(PageRoute.MY_COMPANIES);
                      }}
                      title={StringKey.MY_COMPANIES}
                    />
                  </HoverCardTrigger>
                  {!isExpanded && (
                    <HoverCardContent
                      className="z-20 w-max rounded-lg bg-[#101828] px-3 py-2"
                      isShownTriangle
                      side="right"
                      triangleClassName="left-0 top-1/2 transform -translate-y-1/2 "
                    >
                      <span className="text-label-md font-[550] text-white">
                        <AppFormattedMessage id={StringKey.MY_COMPANIES} />
                      </span>
                    </HoverCardContent>
                  )}
                </HoverCard>

                {selectedCompany.roleInCompany === StakeholderRole.ADMIN && (
                  <HoverCard>
                    <HoverCardTrigger>
                      <SideBarItem
                        Icon={SettingsIcon}
                        isExpanded={isExpanded}
                        isSelected={location.pathname.includes(PageRoute.COMPANY_SETTINGS)}
                        onClick={() => {
                          navigate(PageRoute.COMPANY_SETTINGS);
                        }}
                        title={StringKey.SETTINGS}
                      />
                    </HoverCardTrigger>
                    {!isExpanded && (
                      <HoverCardContent
                        className="z-20 w-max rounded-lg bg-[#101828] px-3 py-2"
                        isShownTriangle
                        side="right"
                        triangleClassName="left-0 top-1/2 transform -translate-y-1/2 "
                      >
                        <span className="text-label-md font-[550] text-white">
                          <AppFormattedMessage id={StringKey.SETTINGS} />
                        </span>
                      </HoverCardContent>
                    )}
                  </HoverCard>
                )}
              </div>
            ) : (
              <div className={twMerge('flex w-full flex-col gap-3')}>
                {sideBarOptions[StakeholderRole.ADMIN].map((props, i) => {
                  return (
                    <HoverCard key={`${props.title}_${i}`}>
                      <HoverCardTrigger>
                        <SideBarItem
                          className="cursor-default"
                          Icon={props.IconComponent}
                          isExpanded={isExpanded}
                          isSelected={location.pathname.includes(props.link)}
                          {...props}
                        />
                      </HoverCardTrigger>
                      {!isExpanded && (
                        <HoverCardContent
                          className="z-20 w-max rounded-lg bg-[#101828] px-3 py-2"
                          isShownTriangle
                          side="right"
                          triangleClassName="left-0 top-1/2 transform -translate-y-1/2 "
                        >
                          <span className="text-label-md font-[550] text-white">
                            <AppFormattedMessage id={props.title} />
                          </span>
                        </HoverCardContent>
                      )}
                    </HoverCard>
                  );
                })}

                <div className="h-[1px] w-full bg-gray-200" />
                <HoverCard>
                  <HoverCardTrigger>
                    <SideBarItem
                      className="cursor-default"
                      Icon={CompaniesIcon}
                      isExpanded={isExpanded}
                      isSelected={location.pathname === PageRoute.MY_COMPANIES}
                      title={StringKey.MY_COMPANIES}
                    />
                  </HoverCardTrigger>
                  {!isExpanded && (
                    <HoverCardContent
                      className="z-20 w-max rounded-lg bg-[#101828] px-3 py-2"
                      isShownTriangle
                      side="right"
                      triangleClassName="left-0 top-1/2 transform -translate-y-1/2 "
                    >
                      <span className="text-label-md font-[550] text-white">
                        <AppFormattedMessage id={StringKey.MY_COMPANIES} />
                      </span>
                    </HoverCardContent>
                  )}
                </HoverCard>
                <HoverCard>
                  <HoverCardTrigger>
                    <SideBarItem
                      className="cursor-default"
                      Icon={SettingsIcon}
                      isExpanded={isExpanded}
                      isSelected={location.pathname.includes(PageRoute.COMPANY_SETTINGS)}
                      title={StringKey.SETTINGS}
                    />
                  </HoverCardTrigger>
                  {!isExpanded && (
                    <HoverCardContent
                      className="z-20 w-max rounded-lg bg-[#101828] px-3 py-2"
                      isShownTriangle
                      side="right"
                      triangleClassName="left-0 top-1/2 transform -translate-y-1/2 "
                    >
                      <span className="text-label-md font-[550] text-white">
                        <AppFormattedMessage id={StringKey.SETTINGS} />
                      </span>
                    </HoverCardContent>
                  )}
                </HoverCard>
              </div>
            )}
          </div>
          <Button
            className={twMerge(
              'relative flex size-10 shrink-0 items-center justify-start overflow-hidden transition-all duration-300',
              isExpanded && 'w-full',
            )}
            onClick={isExpanded ? closeSideBar : openSideBar}
            styleType="NONE"
          >
            <div className="z-1 flex size-10 shrink-0 items-center justify-center">
              <DoubleChevronIcon
                className={twMerge('transition-all', !isExpanded && 'rotate-180')}
                iconColor={isExpanded ? '#344054' : '#98A2B3'}
              />
            </div>
            <span
              className={twMerge(
                'absolute left-3 z-0 w-0 overflow-hidden text-xs font-[450] text-gray-700',
                isExpanded && 'w-[100px]',
              )}
            >
              <AppFormattedMessage id={StringKey.COLLAPSE} />
            </span>
          </Button>
        </div>

        {isMobile && (
          <Sheet modal={false} open={isExpanded}>
            <SheetContent className="mt-[64px] flex w-full gap-0 border-transparent bg-transparent p-0 shadow-none">
              <div
                className="flex h-full min-w-full flex-col gap-3 overflow-y-auto rounded-lg bg-gray-50 p-3"
                style={{ height: 'calc(100% - 64px)' }}
              >
                <div className="flex w-full items-center gap-4 rounded-lg bg-brand-50 px-3 py-2">
                  <div className="flex size-8 shrink-0 items-center justify-center rounded-full bg-brand-700">
                    <CircleArrowUpIcon />
                  </div>
                  <span className="text-sm font-[550] text-brand-700">Upgrade account</span>
                </div>

                {selectedCompany ? (
                  <>
                    <div className="flex h-fit w-full flex-col gap-3 rounded-lg bg-white px-3 py-4">
                      <CompanySelect className="h-10 max-w-none" noAdaptive />
                      {sideBarOptions[selectedCompany.roleInCompany].map((props, i) => (
                        <SideBarItem
                          Icon={props.IconComponent}
                          isExpanded={isExpanded}
                          isSelected={location.pathname.includes(props.link)}
                          key={`${props.title}_${i}`}
                          onClick={() => {
                            closeSideBar();
                            navigate(props.link);
                          }}
                          {...props}
                        />
                      ))}

                      <div className="h-[1px] w-full bg-gray-200" />
                      <SideBarItem
                        Icon={CompaniesIcon}
                        isExpanded={isExpanded}
                        isSelected={location.pathname === PageRoute.MY_COMPANIES}
                        onClick={() => {
                          closeSideBar();
                          navigate(PageRoute.MY_COMPANIES);
                        }}
                        title={StringKey.MY_COMPANIES}
                      />
                      {selectedCompany.roleInCompany === StakeholderRole.ADMIN && (
                        <SideBarItem
                          Icon={SettingsIcon}
                          isExpanded={isExpanded}
                          isSelected={location.pathname.includes(PageRoute.COMPANY_SETTINGS)}
                          onClick={() => {
                            closeSideBar();
                            navigate(PageRoute.COMPANY_SETTINGS);
                          }}
                          title={StringKey.SETTINGS}
                        />
                      )}
                    </div>
                    <SheetFooter className="flex h-fit w-full flex-col rounded-lg bg-white px-3 py-4">
                      <SideBarItem
                        Icon={ProfileIcon}
                        isExpanded={isExpanded}
                        isSelected={location.pathname.includes(PageRoute.PROFILE)}
                        onClick={() => {
                          closeSideBar();
                          navigate(PageRoute.PROFILE);
                        }}
                        title={StringKey.MY_PROFILE}
                      />
                      <div className="text-fire h-[1px] w-full bg-gray-200" />
                      <SideBarItem
                        Icon={LogoutIcon}
                        isExpanded={isExpanded}
                        isSelected={false}
                        onClick={() => {
                          closeSideBar();
                          handleLogout();
                        }}
                        specialIconColor="#D92D20"
                        specialTitleColor="text-fireside-600"
                        title={StringKey.LOG_OUT}
                      />
                    </SheetFooter>
                  </>
                ) : (
                  <>
                    <div className="flex h-fit w-full flex-col gap-3 rounded-lg bg-white px-3 py-4">
                      <CompanySelect className="h-10 max-w-none" noAdaptive />
                      {sideBarOptions[StakeholderRole.ADMIN].map((props, i) => (
                        <SideBarItem
                          Icon={props.IconComponent}
                          isExpanded={isExpanded}
                          isSelected={location.pathname.includes(props.link)}
                          key={`${props.title}_${i}`}
                          {...props}
                        />
                      ))}

                      <div className="h-[1px] w-full bg-gray-200" />
                      <SideBarItem
                        Icon={CompaniesIcon}
                        isExpanded={isExpanded}
                        isSelected={location.pathname === PageRoute.MY_COMPANIES}
                        title={StringKey.MY_COMPANIES}
                      />
                      <SideBarItem
                        Icon={SettingsIcon}
                        isExpanded={isExpanded}
                        isSelected={location.pathname.includes(PageRoute.COMPANY_SETTINGS)}
                        title={StringKey.SETTINGS}
                      />
                    </div>
                    <SheetFooter className="flex h-fit w-full flex-col rounded-lg bg-white px-3 py-4">
                      <SideBarItem
                        Icon={ProfileIcon}
                        isExpanded={isExpanded}
                        isSelected={location.pathname.includes(PageRoute.PROFILE)}
                        onClick={() => {
                          closeSideBar();
                          navigate(PageRoute.PROFILE);
                        }}
                        title={StringKey.MY_PROFILE}
                      />
                      <div className="text-fire h-[1px] w-full bg-gray-200" />
                      <SideBarItem
                        Icon={LogoutIcon}
                        isExpanded={isExpanded}
                        isSelected={false}
                        onClick={() => {
                          closeSideBar();
                          handleLogout();
                        }}
                        specialIconColor="#D92D20"
                        specialTitleColor="text-fireside-600"
                        title={StringKey.LOG_OUT}
                      />
                    </SheetFooter>
                  </>
                )}
              </div>
              <div className="h-full w-full shrink-0" onClick={closeSideBar} />
            </SheetContent>
          </Sheet>
        )}
      </div>
    </>
  );
};

SideBar.displayName = 'SideBar';

export default SideBar;
