import Intercom from '@intercom/messenger-js-sdk';
import { enc, HmacSHA256 } from 'crypto-js';
import React, { FC, ReactNode, useEffect } from 'react';

import { HeaderContextProvider } from '../../context/headerContext';
import { useLocaleContext } from '../../context/locale.context';
import { NotificationsProvider } from '../../context/notifications.context';
import { AnalyticHooks, User } from '../../hooks';
import InviteModal from '../../pages/MyCompanies/InviteModal';
import { localeStorage } from '../../storage/localeStorage';
import ChatButton from '../ChatButton/ChatButton';
import HeaderLayout from '../HeaderLayout';
import SideBarLayout from '../SideBarLayout';

export type AppLayoutProps = {
  children: ReactNode;
  className?: string;
};
const secretKey = process.env.REACT_APP_SECRET_HASH_KEY;

const AppLayout: FC<AppLayoutProps> = ({ children, className }) => {
  AnalyticHooks.usePageView();
  AnalyticHooks.useScrollDepth();
  AnalyticHooks.useTrackTimeOnPage();

  const { user } = User.useUser();
  const { setLocale } = useLocaleContext();

  useEffect(() => {
    if (!user || !secretKey) return;
    const userHash = HmacSHA256(user.id, secretKey).toString(enc.Hex);

    Intercom({
      app_id: process.env.REACT_APP_INTERCOM_ID || '',
      user_id: user.id,
      name: user.fullName,
      email: user.email,
      created_at: new Date(user.createdAt).getTime(),
      user_hash: userHash,
      hide_default_launcher: true,
    });
  }, [user]);

  useEffect(() => {
    if (!user) return;
    localeStorage.set(user.language);
    setLocale(user.language);
  }, [setLocale, user]);

  return (
    <>
      <NotificationsProvider>
        <SideBarLayout>
          <HeaderContextProvider>
            <ChatButton />
            <HeaderLayout className={className}>{children}</HeaderLayout>
          </HeaderContextProvider>
        </SideBarLayout>
        <InviteModal />
      </NotificationsProvider>
    </>
  );
};

AppLayout.displayName = 'AppLayout';

export default AppLayout;
