import { BackendRoute } from '../../config';
import { QueryKey } from '../../constants';
import { HttpException } from '../../exceptions';
import { ApiService } from '../../services';
import { ConversionMethod, ConvertToSharesSummaryDto } from '../../types/safes.types';
import { Stakeholder } from '../../types/stakeholderTypes';
import { useAppMutation } from '../useAppMutation';

export type SafeConversionSummaryResponse = {
  investment: number;
  capitalOwned: number;
  sharesConverted: number;
  conversionMethod: ConversionMethod;
  stakeholder: Stakeholder;
  discount: number;
  floor: number;
  'valuation-cap': number;
};

export const useSafeConversionSummary = () => {
  const {
    mutate: getSafeConversionSummary,
    isPending,
    data,
  } = useAppMutation<
    SafeConversionSummaryResponse,
    HttpException,
    { companyId: string; safeId: string; data: ConvertToSharesSummaryDto }
  >([QueryKey.GET_SAFE_CONVERSION_SUMMARY], {
    mutationFn: ({
      companyId,
      safeId,
      data,
    }: {
      companyId: string;
      safeId: string;
      data: ConvertToSharesSummaryDto;
    }) =>
      ApiService.post(
        {
          endpoint: BackendRoute.COMPANIES,
          routePath: [companyId, 'safes', safeId, 'convert-summary'],
        },
        { body: data },
      ),
  });

  return { getSafeConversionSummary, isPending, data };
};
