import React, { FC, useCallback, useEffect } from 'react';
import {
  Control,
  useFieldArray,
  UseFormResetField,
  UseFormSetValue,
  useWatch,
} from 'react-hook-form';

import { CalendarIcon, CloseIcon } from '../../../../../assets/icons';
import { AppFormattedMessage } from '../../../../../components/AppFormattedMessage';
import Button from '../../../../../components/Button';
import { FormInput } from '../../../../../components/Input';
import { useFormat } from '../../../../../hooks';
import { StringKey } from '../../../../../lang';
import { SharePlanType } from '../../../../../types/pool-plans.types';
import { DatePicker } from '../../../PoolForm/DatePicker';
import { GrantCombobox, StakeholderCombobox, TableHeaderItem } from '../FormComponents';
import { FormSchema } from '../validation';

export type RsaProps = {
  setFormData: UseFormSetValue<FormSchema>;
  control: Control<FormSchema>;
  companyId: string;
  resetField: UseFormResetField<FormSchema>;
};

export const Rsa: FC<RsaProps> = ({ control, setFormData, companyId, resetField }) => {
  const { stepOne } = useWatch<FormSchema>({ control });
  const { format } = useFormat();

  const {
    fields: rsaItemsFields,
    append: rsaItemsAppend,
    remove: rsaItemsRemove,
  } = useFieldArray({
    control,
    name: 'stepOne.rsa.rsaItems',
  });

  const handleRemoveRsaItems = useCallback(
    (index: number) => {
      rsaItemsRemove(index);
    },
    [rsaItemsRemove],
  );

  const handleAppendRsaItems = useCallback(() => {
    const defaultDate = new Date();
    rsaItemsAppend({
      stakeholder: { id: '', fullName: '' },
      grant: {
        id: '',
        grantItem: {
          plan: { rsaPurchasePrice: 0, conversionRatio: 1, pool: { shareClass: { name: '' } } },
        },
        exercisedCount: 0,
        vestedSharesCount: 0,
      },
      date: defaultDate,
      exercised: 0,
    });
  }, [rsaItemsAppend]);

  const handleStakeholderChange = (index: number) => {
    resetField(`stepOne.rsa.rsaItems.${index}.grant`);
  };

  const calculateBalance = useCallback(
    (index: number) => {
      const conversionRatio =
        stepOne?.rsa?.rsaItems?.[index]?.grant?.grantItem?.plan?.conversionRatio || 1;
      const vestedShares = stepOne?.rsa?.rsaItems?.[index]?.grant?.vestedSharesCount || 0;
      const exercisedShares = stepOne?.rsa?.rsaItems?.[index]?.grant?.exercisedCount || 0;
      const value = stepOne?.rsa?.rsaItems?.[index]?.exercised || 0;
      const balance = Math.max(
        vestedShares / conversionRatio - exercisedShares / conversionRatio - value,
        0,
      );

      return balance.toLocaleString('en-US');
    },
    [stepOne?.rsa?.rsaItems],
  );

  useEffect(() => {
    if (rsaItemsFields.length === 0) {
      handleAppendRsaItems();
    }
  }, [handleAppendRsaItems, rsaItemsFields.length]);

  return (
    <>
      <div className="flex w-[1340px] flex-col rounded-lg border-[1px] border-gray-100 bg-white">
        <div className="grid grid-cols-[repeat(3,_184px)_repeat(4,_minmax(164px,1fr))_repeat(1,48px)]">
          <TableHeaderItem showTooltip text={<AppFormattedMessage id={StringKey.STAKEHOLDER} />} />
          <TableHeaderItem showTooltip text={<AppFormattedMessage id={StringKey.GRANT} />} />
          <TableHeaderItem
            showTooltip
            text={<AppFormattedMessage id={StringKey.EXERCISE_DATE} />}
          />
          <TableHeaderItem text={<AppFormattedMessage id={StringKey.SHARE_CLASS} />} />
          <TableHeaderItem text={<AppFormattedMessage id={StringKey.PURCHASE_PRICE} />} />
          <TableHeaderItem
            text={<AppFormattedMessage id={StringKey.EXERCISED_OPTIONS} />}
            variant="dark"
          />
          <TableHeaderItem
            text={<AppFormattedMessage id={StringKey.ISSUED_SHARES} />}
            variant="dark"
          />
          <TableHeaderItem text="" />
        </div>
        {rsaItemsFields.map((field, index) => (
          <div
            className="grid grid-cols-[repeat(3,_184px)_repeat(4,_minmax(164px,1fr))_repeat(1,48px)] border-b-[1px] border-b-gray-100"
            key={field.id}
          >
            <div className="px-4 pb-10 pt-7">
              <StakeholderCombobox
                companyId={companyId}
                control={control}
                handleStakeholderChange={() => handleStakeholderChange(index)}
                name={`stepOne.rsa.rsaItems.${index}.stakeholder`}
                planType={SharePlanType.RSA}
              />
            </div>
            <div className="px-4 pb-10 pt-7">
              <GrantCombobox
                companyId={companyId}
                control={control}
                name={`stepOne.rsa.rsaItems.${index}.grant`}
                sharePlanType={SharePlanType.RSA}
                stakeholderId={stepOne?.rsa?.rsaItems?.[index]?.stakeholder?.id || ''}
              />
            </div>
            <div className="px-4 pb-10 pt-7">
              <DatePicker
                defaultMonth={stepOne?.rsa?.rsaItems?.[index]?.date || undefined}
                onSelect={(date) => setFormData(`stepOne.rsa.rsaItems.${index}.date`, date)}
                value={stepOne?.rsa?.rsaItems?.[index]?.date}
                wrapperClassName="w-full"
              >
                <FormInput
                  autoComplete="off"
                  control={control}
                  customValue={(value) => {
                    return value && format(value, 'dd/MM/yyyy');
                  }}
                  disabledInput
                  icon={<CalendarIcon className="size-5" iconColor="#344054" />}
                  inputClassName="h-10"
                  name={`stepOne.rsa.rsaItems.${index}.date`}
                  wrapperClassName="w-full"
                />
              </DatePicker>
            </div>
            <div className="flex items-center px-4 pb-10 pt-7">
              <span className="text-sm font-[450] text-gray-700">
                {stepOne?.rsa?.rsaItems?.[index]?.grant?.grantItem?.plan?.pool?.shareClass?.name}
              </span>
            </div>
            <div className="flex flex-col gap-2 px-4 pb-4 pt-7">
              <FormInput
                control={control}
                disabled={true}
                inputClassName="h-10"
                name={`stepOne.rsa.rsaItems.${index}.grant.grantItem.plan.rsaPurchasePrice`}
                shouldFormatNumber
                wrapperClassName="w-full"
              />
              <span className="text-xs font-[450] text-gray-600">
                <AppFormattedMessage id={StringKey.TOTAL_COST} /> $
                {(
                  (stepOne?.rsa?.rsaItems?.[index]?.grant?.grantItem?.plan?.rsaPurchasePrice || 0) *
                  (stepOne?.rsa?.rsaItems?.[index]?.exercised || 1)
                ).toLocaleString('en-US')}
              </span>
            </div>
            <div className="flex flex-col gap-2 px-4 pb-4 pt-7">
              <FormInput
                control={control}
                inputClassName="h-10"
                name={`stepOne.rsa.rsaItems.${index}.exercised`}
                numberOnly
                shouldFormatNumber
                wrapperClassName="w-full"
              />
              <span className="text-xs font-[450] text-gray-600">
                <AppFormattedMessage id={StringKey.AVAILABLE} /> {calculateBalance(index)}
              </span>
            </div>
            <div className="flex flex-col gap-2 px-4 pb-4 pt-7">
              <div className="rounded-t-[4px] bg-gray-50 px-3 py-2">
                <span className="text-sm font-[450] text-gray-700">
                  {(
                    (stepOne?.rsa?.rsaItems?.[index]?.exercised || 0) *
                    (stepOne?.rsa?.rsaItems?.[index]?.grant?.grantItem?.plan?.conversionRatio || 1)
                  ).toLocaleString('en-US')}
                </span>
              </div>
              <span className="text-xs font-[450] text-gray-600">
                <AppFormattedMessage id={StringKey.CONVERSION} />{' '}
                {stepOne?.rsa?.rsaItems?.[index]?.grant?.grantItem?.plan?.conversionRatio || 1}x
              </span>
            </div>
            <div>
              {rsaItemsFields.length > 1 && (
                <Button
                  className="flex h-full w-full items-center justify-center"
                  onClick={() => handleRemoveRsaItems(index)}
                  styleType="NONE"
                >
                  <CloseIcon />
                </Button>
              )}
            </div>
          </div>
        ))}
      </div>

      {rsaItemsFields.length < 10 && (
        <div className="sticky bottom-[76px] z-10 w-full bg-white">
          <Button
            className="w-fit px-4 py-2 text-sm font-[450] text-brand-700 underline disabled:border-transparent disabled:bg-transparent"
            onClick={handleAppendRsaItems}
            styleType="NONE"
          >
            + <AppFormattedMessage id={StringKey.ADD_EXERCISING} />
          </Button>
        </div>
      )}
    </>
  );
};
