import { FC, Fragment, useState } from 'react';

import { AppFormattedMessage } from '../../../components/AppFormattedMessage';
import { Table } from '../../../components/Table';
import { VestingTaskHooks } from '../../../hooks';
import { StringKey } from '../../../lang';
import { SharePlanType } from '../../../types/pool-plans.types';
import { ExerciseForm } from '../ExerciseForm';
import { table, VestingPlanType } from './tableVariant';
import { VestingTableItem } from './VestingTableItem';

type AutomaticVestingTableProps = {
  vestingType: VestingPlanType;
  companyId: string;
  stakeholderId: string;
  sharePlanType: SharePlanType;
};

export const AutomaticVestingTable: FC<AutomaticVestingTableProps> = ({
  companyId,
  stakeholderId,
  vestingType,
  sharePlanType,
}) => {
  const [requestedSharePlanExercise, setSharePlanExercise] = useState<null | {
    id: string;
    type: SharePlanType;
  }>(null);

  const { vestingTasks } = VestingTaskHooks.useVestingTasks({
    companyId,
    stakeholderId,
    exercisable: vestingType === VestingPlanType.EXERCISABLE,
    sharePlanType,
  });

  if (vestingTasks.length === 0) return null;

  return (
    <>
      {requestedSharePlanExercise && (
        <ExerciseForm
          companyId={companyId}
          handleClose={() => setSharePlanExercise(null)}
          invalidateQuery={() => ''}
          isOpen={!!requestedSharePlanExercise}
          sharePlanId={requestedSharePlanExercise.id}
          type={requestedSharePlanExercise.type}
        />
      )}
      <div className="max flex w-full flex-col gap-3 rounded-lg bg-gray-50 p-1 !pt-3 lg:p-[2px]">
        <span className="pl-4 text-[10px] font-bold uppercase leading-4 tracking-[1px] text-gray-700">
          <p className="max-lg:hidden">
            {vestingType === VestingPlanType.AUTOMATIC ? (
              <>
                <AppFormattedMessage id={StringKey.AUTOMATIC_VESTING} /> -
                <AppFormattedMessage id={StringKey.VESTING_SCHEDULE_AND_PLAN} />
              </>
            ) : (
              <AppFormattedMessage id={StringKey.VESTING_PLAN} />
            )}
          </p>
          <p className="lg:hidden">
            {vestingType === VestingPlanType.AUTOMATIC ? (
              <>
                <AppFormattedMessage id={StringKey.AUTOMATIC_VESTING} /> -
                <AppFormattedMessage id={StringKey.VESTING_SCHEDULE_AND_PLAN} />
              </>
            ) : (
              <AppFormattedMessage id={StringKey.VESTING_PLAN} />
            )}
          </p>
        </span>

        <div className="flex gap-2 overflow-hidden bg-white px-2 max-lg:hidden lg:rounded-md lg:shadow-sm">
          <div className="flex h-full w-full flex-col gap-3 overflow-hidden rounded-md py-1">
            <span className="pl-2 pt-3 text-[10px] font-semibold uppercase leading-4 tracking-[1px] text-gray-500">
              <AppFormattedMessage id={StringKey.ABOUT_PLAN} />
            </span>
            <div className="h-full w-full overflow-x-auto rounded-md border border-gray-100">
              <Table
                columns={table[sharePlanType].planColumns}
                columnsTitle={table[sharePlanType].planColumnsTitle}
              >
                {vestingTasks.map((vestingTask) => (
                  <VestingTableItem
                    columns={table[sharePlanType].planColumns}
                    companyId={companyId}
                    key={vestingTask.id}
                    onRequestExercise={setSharePlanExercise}
                    stakeholderId={stakeholderId}
                    type="planInfo"
                    vestingTask={vestingTask}
                  />
                ))}
              </Table>
            </div>
          </div>

          <div className="flex h-full w-full flex-col gap-3 overflow-hidden rounded-md py-1">
            <span className="pl-4 pt-3 text-[10px] font-semibold uppercase leading-4 tracking-[1px] text-gray-500">
              <AppFormattedMessage id={StringKey.YOUR_VESTING_STRUCTURE} />
            </span>
            <div className="h-full w-full overflow-x-auto rounded-md border border-gray-100">
              <Table
                columns={table[sharePlanType].vestingColumns}
                columnsTitle={table[sharePlanType].vestingColumnsTitle}
              >
                {vestingTasks.map((vestingTask) => (
                  <VestingTableItem
                    columns={table[sharePlanType].vestingColumns}
                    companyId={companyId}
                    key={vestingTask.id}
                    onRequestExercise={setSharePlanExercise}
                    stakeholderId={stakeholderId}
                    vestingTask={vestingTask}
                  />
                ))}
              </Table>
            </div>
          </div>
        </div>

        <div className="flex w-full flex-col gap-3 rounded-md bg-white pt-3 lg:hidden">
          <span className="pl-4 text-[10px] font-semibold uppercase leading-4 tracking-[1px] text-gray-500">
            <AppFormattedMessage id={StringKey.ABOUT_PLAN} />
          </span>
          <div className="flex flex-col gap-4 rounded-md p-3 shadow-sm">
            <div className="flex w-full flex-col divide-y divide-gray-100 rounded-md bg-white shadow-sm">
              {vestingTasks.map((vestingTask) => (
                <Fragment key={vestingTask.id}>
                  <div className="flex flex-col p-4 text-xl font-semibold text-gray-700">
                    {vestingTask?.grantItem.plan.pool.name}
                    <span className="text-xs font-[450] text-gray-700">
                      {vestingTask?.grantItem.plan.name}
                    </span>
                  </div>
                  <VestingTableItem
                    columns={table[sharePlanType].vestingColumns}
                    companyId={companyId}
                    key={vestingTask.id}
                    mode="phoneView"
                    onRequestExercise={setSharePlanExercise}
                    sharePlanType={sharePlanType}
                    stakeholderId={stakeholderId}
                    type="planInfo"
                    vestingTask={vestingTask}
                  />
                </Fragment>
              ))}
            </div>
            <span className="pl-2 text-[10px] font-semibold uppercase leading-4 tracking-[1px] text-gray-500">
              <AppFormattedMessage id={StringKey.YOUR_VESTING_STRUCTURE} />
            </span>
            <div className="flex w-full flex-col divide-y divide-gray-100 rounded-md bg-white shadow-sm">
              {vestingTasks.map((vestingTask) => (
                <VestingTableItem
                  columns={table[sharePlanType].vestingColumns}
                  companyId={companyId}
                  key={vestingTask.id}
                  mode="phoneView"
                  onRequestExercise={setSharePlanExercise}
                  sharePlanType={sharePlanType}
                  stakeholderId={stakeholderId}
                  vestingTask={vestingTask}
                />
              ))}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
