import React, { FC, useRef, useState } from 'react';
import { Control, useController } from 'react-hook-form';
import { twMerge } from 'tailwind-merge';

import { ChevronDownIcon, SearchIcon } from '../../../assets/icons';
import { AppFormattedMessage } from '../../../components/AppFormattedMessage';
import {
  ComboboxButton,
  ComboboxOptions,
  FormCombobox,
  FormComboboxInput,
  FormComboboxOption,
} from '../../../components/Combobox';
import { Flag, FlagCode } from '../../../components/Flag';
import { BackendRoute } from '../../../config';
import { QueryKey } from '../../../constants';
import { useAppQuery, useLocale } from '../../../hooks';
import { StringKey } from '../../../lang';
import { ApiService } from '../../../services';
import { Country } from '../../../types/countryTypes';
import { FormSchema } from './Validation';

export type SelectedNationality = {
  iso2Code: string;
  name: string;
};

export type NationalityItemsListProps = {
  data: Country[];
  control: Control<FormSchema>;
  isLoading: boolean;
  isFetching: boolean;
};

const NationalityItemsList: FC<NationalityItemsListProps> = ({
  data,
  control,
  isFetching,
  isLoading,
}) => {
  if (!data || isFetching || isLoading) return <span>Loading ...</span>;

  return (
    <>
      {data
        .sort((a, b) => a.nationality.localeCompare(b.nationality))
        .map(({ iso2, nationality }) => (
          <FormComboboxOption
            control={control}
            key={iso2}
            name="stepOne.human.nationality"
            value={{ iso2Code: iso2, name: nationality }}
          >
            <Flag countryCode={iso2.toUpperCase() as FlagCode} />
            <span className="text-sm text-gray-700">{nationality}</span>
          </FormComboboxOption>
        ))}
    </>
  );
};

export type NationalityComboboxProps = {
  control: Control<FormSchema>;
  selectedNationality?: SelectedNationality;
};

const NationalityCombobox: FC<NationalityComboboxProps> = ({ control, selectedNationality }) => {
  const [search, setSearch] = useState('');
  const { formState } = useController({
    control,
    name: 'stepOne.human.nationality',
  });
  const { locale } = useLocale();
  const {
    data: countryData,
    isLoading,
    isFetching,
  } = useAppQuery<Country[]>({
    queryKey: [QueryKey.GET_COUNTRY, { search }],
    queryFn: () =>
      ApiService.get({ endpoint: BackendRoute.COUNTRIES }, { queryParams: { search }, locale }),
  });
  const ref = useRef<HTMLInputElement>(null);
  return (
    <FormCombobox
      className="relative"
      control={control}
      name={'stepOne.human.nationality'}
      onClose={() => setSearch('')}
      onSelect={() => {
        setSearch('');
      }}
    >
      <div className="relative">
        <FormComboboxInput
          className={'bg-gray-900'}
          control={control}
          customValue={(value) => value?.name}
          icon={<ChevronDownIcon className={twMerge('mt-3', formState.disabled && 'hidden')} />}
          iconBeforeInput={
            <Flag
              className="mt-5 w-4 shrink-0"
              countryCode={selectedNationality?.iso2Code as FlagCode}
            />
          }
          name={'stepOne.human.nationality'}
          placeholder={<AppFormattedMessage id={StringKey.NATIONALITY_OPTIONAL} />}
          readOnly
          wrapperClassName={formState.disabled && 'border-none'}
        />
        <ComboboxButton className="absolute left-0 top-0 z-1 h-full w-full" />
      </div>
      <ComboboxOptions className="w-full">
        <div className="relative flex items-center" onClick={() => ref.current?.focus()}>
          <SearchIcon className="absolute ml-[6px]" />
          <input
            className="w-full rounded border-[2px] border-transparent bg-gray-50 p-[6px] pl-8 text-gray-700 outline-none placeholder:text-gray-400 focus:border-brand-700 focus:bg-white"
            onChange={({ target: { value } }) => setSearch(value)}
            ref={ref}
            value={search}
          />
        </div>

        <NationalityItemsList
          control={control}
          data={countryData || []}
          isFetching={isFetching}
          isLoading={isLoading}
        />
      </ComboboxOptions>
    </FormCombobox>
  );
};

export default NationalityCombobox;
