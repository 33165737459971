import React, { FC, ReactNode, useCallback, useState } from 'react';
import { toast } from 'react-toastify';
import { twMerge } from 'tailwind-merge';

import { AppFormattedMessage } from '../../../components/AppFormattedMessage';
import { CloseModalButton } from '../../../components/CloseModalButton';
import { AnalyticHooks, SharePlan, useReactForm } from '../../../hooks';
import { StringKey } from '../../../lang';
import { PossibleAction } from '../../../types/google-analytics-types';
import { CreateSharePlanDto, SharePlanVestingType } from '../../../types/pool-plans.types';
import { toBackendDateFormat } from '../../../utils/toBackendDateFormat';
import { FinalStep, StepFour, StepOne, StepThree, StepTwo } from './Steps';
import { ChildrenFormProps } from './type';
import { FormSchema, formSchema } from './Validation';

export type AddPlanFormProps = {
  companyId: string;
  invalidateQuery: () => void;
  closeModal: () => void;
};

const formSteps: ((props: ChildrenFormProps) => ReactNode)[] = [
  (props) => <StepOne {...props} />,
  (props) => <StepTwo {...props} />,
  (props) => <StepThree {...props} />,
  (props) => <StepFour {...props} />,
  (props) => <FinalStep {...props} />,
];

export const AddPlanForm: FC<AddPlanFormProps> = ({ companyId, invalidateQuery, closeModal }) => {
  const [currentStep, setCurrentStep] = useState<number>(1);
  const {
    control,
    handleSubmit,
    setValue,
    getValues,
    getFieldState,
    resetField,
    setError,
    clearErrors,
    formState: { errors },
  } = useReactForm({
    mode: 'onChange',
    schema: formSchema,
  });

  const addSharePlanTracker = AnalyticHooks.useClick(PossibleAction.ADD_PLAN);
  const { create } = SharePlan.useCreate();

  const submitHandler = useCallback(
    (data: FormSchema) => {
      const { stepOne, stepTwo, stepThree, stepFour } = data;

      const timeBasedDynamicVesting = stepTwo?.vesting &&
        stepTwo.vesting.vestingMechanism === SharePlanVestingType.TIME_BASED_DYNAMIC && {
          accelarationDate: stepTwo.vesting.timeBasedDynamic?.accelerationDate
            ? toBackendDateFormat(stepTwo.vesting.timeBasedDynamic?.accelerationDate)
            : undefined,
          vestingOn: stepTwo.vesting?.timeBasedDynamic?.vestingOn,
          periods: stepTwo.vesting?.timeBasedDynamic?.dynamicVestings?.map(
            ({ planAllocation, vestingDuration, vestingFrequency }) => ({
              durationMonths: vestingDuration,
              frequencyMonths: vestingFrequency,
              planAllocation,
            }),
          ),
        };

      const timeBasedStandardVesting = stepTwo?.vesting &&
        stepTwo.vesting.vestingMechanism === SharePlanVestingType.TIME_BASED_STANDARD && {
          vestingOn: stepTwo.vesting?.timeBasedStandard?.vestingOn,
          durationMonths: stepTwo.vesting?.timeBasedStandard?.vestingDuration,
          frequencyMonths: stepTwo.vesting?.timeBasedStandard?.vestingFrequency,
          cliffMonths: stepTwo.vesting?.timeBasedStandard?.cliffPeriod,
          accelarationDate: stepTwo.vesting.timeBasedStandard?.accelerationDate
            ? toBackendDateFormat(stepTwo.vesting.timeBasedStandard?.accelerationDate)
            : undefined,
        };

      const targetBasedStandardVesting = stepTwo?.vesting &&
        stepTwo.vesting.vestingMechanism === SharePlanVestingType.TARGET_BASED && {
          milestones: stepTwo.vesting?.targetBasedStandard?.targetVestings?.map(
            ({ targetDate, name, weight }) => ({
              weight: weight / 100,
              name,
              targetDate: toBackendDateFormat(targetDate as Date),
            }),
          ),
        };

      const vestingChecked =
        targetBasedStandardVesting || timeBasedStandardVesting || timeBasedDynamicVesting;

      const vesting: CreateSharePlanDto['vesting'] =
        (stepTwo?.vesting && {
          type: stepTwo?.vesting?.vestingMechanism,
          ...vestingChecked,
        }) ||
        null;

      const conversionRatio = Object.values(stepOne.financialDetails).find(
        ({ enabled }) => enabled,
      ) as { conversionRatio?: number } | undefined;

      const terminationLogic: CreateSharePlanDto['terminationLogic'] = stepThree?.terminationLogic
        ? {
            'termination-with-cause':
              stepThree.terminationLogic?.terminationWithCause &&
              stepThree.terminationLogic?.terminationWithCause.enabled
                ? {
                    value: stepThree.terminationLogic?.terminationWithCause?.value,
                    interval: stepThree.terminationLogic?.terminationWithCause?.interval,
                  }
                : null,
            resignation:
              stepThree.terminationLogic?.resignation &&
              stepThree.terminationLogic?.resignation.enabled
                ? {
                    interval: stepThree.terminationLogic?.resignation?.interval,
                    value: stepThree.terminationLogic?.resignation?.value,
                  }
                : null,
            retirement:
              stepThree.terminationLogic?.retirement &&
              stepThree.terminationLogic?.retirement.enabled
                ? {
                    interval: stepThree.terminationLogic?.retirement?.interval,
                    value: stepThree.terminationLogic?.retirement?.value,
                  }
                : null,
            termination:
              stepThree.terminationLogic?.termination &&
              stepThree.terminationLogic?.termination.enabled
                ? {
                    interval: stepThree.terminationLogic?.termination?.interval,
                    value: stepThree.terminationLogic?.termination?.value,
                  }
                : null,
          }
        : null;

      create(
        {
          companyId,
          data: {
            poolId: stepOne.pool.id,
            filesLinks:
              stepFour?.files?.reduce<string[]>((prev, curr) => [...prev, curr.docLink], []) || [],
            name: stepOne.name,
            date: toBackendDateFormat(stepOne.date),
            type: stepOne.type,
            hardleValue: stepOne.financialDetails['growth-hurdle']?.value || undefined,
            strikePrice:
              stepOne.financialDetails['stock-options']?.value ||
              stepOne.financialDetails['bspce']?.value ||
              undefined,
            rsaPurchasePrice: stepOne.financialDetails['rsa']?.value || undefined,
            sarBasePrice: stepOne.financialDetails['sar']?.value || undefined,
            additionalNotes: stepFour?.additionalNotes,
            terminationLogic,
            expiryDate: stepTwo?.expiryDate?.date
              ? toBackendDateFormat(stepTwo?.expiryDate?.date)
              : null,
            conversionRatio: conversionRatio?.conversionRatio,
            warrantPrice: stepOne.financialDetails.warrants?.value,
            vesting,
          },
        },
        {
          onSuccess: () => {
            addSharePlanTracker();
            toast.success(<AppFormattedMessage id={StringKey.NEW_PLAN_CREATED_SUCCESSFULLY} />);
            invalidateQuery();
            closeModal();
          },
        },
      );
    },
    [addSharePlanTracker, closeModal, companyId, create, invalidateQuery],
  );

  const handleCloseModal = useCallback(() => {
    closeModal();
  }, [closeModal]);

  const handleNextStep = useCallback(() => {
    const nextStep = currentStep + 1;
    if (nextStep === Object.keys(formSteps).length + 1) {
      handleSubmit(submitHandler)();
      return;
    }
    if (nextStep > Object.keys(formSteps).length) return;

    setCurrentStep(nextStep);
  }, [currentStep, handleSubmit, submitHandler]);

  const handlePrevStep = useCallback(() => {
    const prevStep = currentStep - 1;
    if (prevStep < 1) return;
    setCurrentStep(prevStep);
  }, [currentStep]);

  return (
    <div className="h-screen w-[500px] border-transparent bg-transparent p-2 shadow-none">
      <div className="flex h-full w-full flex-col overflow-hidden rounded-lg border-[1px] border-gray-300 bg-gray-100 pb-4">
        <div className="flex h-fit w-full items-center justify-between bg-white px-6 py-3">
          <span className="text-xl font-[550] text-gray-700">
            <AppFormattedMessage id={StringKey.ADD_NEW_PLAN} />
          </span>
          <CloseModalButton onClose={handleCloseModal} />
        </div>
        <div className="flex w-full items-center gap-[5px] bg-white px-6 pb-6">
          {formSteps.map((_, i) => (
            <div
              className={twMerge(
                'h-1 w-full rounded-[25px]',
                i + 1 <= currentStep ? 'bg-[#12B76A]' : 'bg-gray-100',
                i + 1 === currentStep &&
                  currentStep === formSteps.length &&
                  'bg-gradient-to-r from-forest-500 to-forest-300',
              )}
              key={`${i}_${companyId}`}
            />
          ))}
        </div>

        <div className="flex h-full flex-col gap-4 overflow-hidden">
          {formSteps[currentStep - 1]({
            companyId,
            formData: getValues,
            nextFormStep: handleNextStep,
            prevFormStep: handlePrevStep,
            setFormData: setValue,
            control,
            handleCloseModal,
            filedState: getFieldState,
            errors,
            resetField,
            setError,
            clearErrors,
          })}
        </div>
      </div>
    </div>
  );
};
