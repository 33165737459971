import React, { FC, useState } from 'react';

import { ChevronDownIcon } from '../../../assets/icons';
import { AppFormattedMessage } from '../../../components/AppFormattedMessage';
import {
  Combobox,
  ComboboxButton,
  ComboboxInput,
  ComboboxOption,
  ComboboxOptions,
} from '../../../components/Combobox';
import { useLocale } from '../../../hooks';
import { StringKey } from '../../../lang';
import {
  AntiDilutionBase,
  antiDilutionBaseTitle,
  antiDilutionBaseTitleMap,
} from '../../../types/share-classes.types';

const AntiDilutionBaseItemsList: FC = () => (
  <>
    {antiDilutionBaseTitleMap.map(([baseKey, base]) => (
      <ComboboxOption key={base} value={baseKey}>
        <span className="text-sm font-normal text-gray-700">
          <AppFormattedMessage id={base} />
        </span>
      </ComboboxOption>
    ))}
  </>
);
export type AntiDilutionBaseComboboxProps = {
  onSelect?: (base: AntiDilutionBase) => void;
  selectedBase?: AntiDilutionBase;
};

export const AntiDilutionBaseCombobox: FC<AntiDilutionBaseComboboxProps> = ({
  onSelect,
  selectedBase: defaultSelectedBase,
}) => {
  const { messagesLocale } = useLocale();
  const [selectedBase, setSelectedBase] = useState<AntiDilutionBase | null>(
    defaultSelectedBase ?? null,
  );
  return (
    <Combobox
      as={'div'}
      className="relative"
      onChange={(base: AntiDilutionBase) => {
        setSelectedBase(base);
        onSelect?.(base);
      }}
    >
      <div className="relative">
        <ComboboxInput
          className={'absolute z-0 bg-gray-900'}
          icon={<ChevronDownIcon className="mt-3" />}
          onChange={() => ''}
          placeholder={<AppFormattedMessage id={StringKey.BASE} />}
          readOnly
          value={
            selectedBase ? messagesLocale[antiDilutionBaseTitle[selectedBase]] : selectedBase || ''
          }
        />
        <ComboboxButton className="absolute left-0 top-0 z-1 h-full w-full" />
      </div>
      <ComboboxOptions className="absolute z-4 w-full translate-y-3 rounded-md bg-white p-2 shadow-sm">
        <AntiDilutionBaseItemsList />
      </ComboboxOptions>
    </Combobox>
  );
};
