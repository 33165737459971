import { FC, ReactNode } from 'react';
import { twMerge } from 'tailwind-merge';

import { AppFormattedMessage } from '../../components/AppFormattedMessage';
import { useSelectedCompany } from '../../hooks';
import { StringKey } from '../../lang';
import { StakeholderOwnershipSummary } from '../../types/stakeholderTypes';
import { toRound } from '../../utils/getRoundedNumber';
import { toNumber } from '../../utils/toNumber';

type SummaryItemProps = {
  title: string | ReactNode;
  value: string;
  className?: string;
};

const SummaryItem: FC<SummaryItemProps> = ({ title, value, className }) => (
  <div
    className={twMerge(
      'flex flex-grow basis-[40%] flex-col gap-2 rounded-lg bg-gray-50 px-2 py-3',
      className,
    )}
  >
    <span className="text-nowrap text-[10px] font-bold uppercase leading-4 tracking-[1px] text-gray-500">
      {title}
    </span>
    <span className="text-base font-semibold text-gray-600">{value}</span>
  </div>
);

export type OwnershipSummaryProps = Partial<StakeholderOwnershipSummary>;

export const OwnershipSummary: FC<OwnershipSummaryProps> = ({
  companyOwned,
  investment,
  sharePrice,
  numberOfShares,
  roi,
  valueOfShares,
  votingPercentage,
}) => {
  const { selectedCompany } = useSelectedCompany();
  const currency = selectedCompany?.currency;

  return (
    <div className="flex w-[30%] min-w-[350px] flex-grow flex-col gap-3 rounded-lg bg-gray-50 p-1 pt-3">
      <span className="pl-4 text-[10px] font-bold uppercase leading-4 tracking-[1px] text-gray-700">
        <AppFormattedMessage id={StringKey.OWNERSHIP_SUMMARY} />
      </span>
      <div className="flex w-full flex-wrap gap-3 rounded-md bg-white p-3 shadow-sm">
        <SummaryItem
          title={<AppFormattedMessage id={StringKey.NUMBER_OF_ISSUED_SHARES} />}
          value={toNumber(numberOfShares).toLocaleString('en-US')}
        />
        <SummaryItem
          title={<AppFormattedMessage id={StringKey.COMPANY_OWNED} />}
          value={`${toRound(toNumber(companyOwned) * 100)}%`}
        />
        <SummaryItem
          title={<AppFormattedMessage id={StringKey.VOTING} />}
          value={`${toRound(toNumber(votingPercentage) * 100)}%`}
        />
        <SummaryItem
          title={<AppFormattedMessage id={StringKey.SHARES_PRICE} />}
          value={`${currency?.symbol || currency?.iso3?.toUpperCase()}${toNumber(sharePrice).toLocaleString('en-US')}`}
        />
        <SummaryItem
          title={<AppFormattedMessage id={StringKey.VALUE_OF_SHARES} />}
          value={`${currency?.symbol || currency?.iso3?.toUpperCase()}${toNumber(valueOfShares).toLocaleString('en-US')}`}
        />
        <SummaryItem
          title={
            <>
              <AppFormattedMessage id={StringKey.INVESTMENT} />
              {` (${currency?.symbol || currency?.iso3?.toUpperCase()})`}
            </>
          }
          value={`${currency?.symbol || currency?.iso3?.toUpperCase()}${toNumber(investment).toLocaleString('en-US')}`}
        />
        <SummaryItem
          className="basis-[50%]"
          title={<AppFormattedMessage id={StringKey.ROI} />}
          value={`${toRound(toNumber(roi))}x`}
        />
      </div>
    </div>
  );
};
