import { Field, Label } from '@headlessui/react';
import React, { FC } from 'react';
import { toast } from 'react-toastify';
import { twMerge } from 'tailwind-merge';

import { EditIcon, EyeIconSmall, ThreeDotsIcon, TrashBinIcon } from '../../../assets/icons';
import { AlertDialogWrapper } from '../../../components/AlertDialog';
import { Checkbox } from '../../../components/Checkbox';
import { Popover, PopoverClose, PopoverContent, PopoverTrigger } from '../../../components/Popover';
import { useFormat, useModalState } from '../../../hooks';
import { conversionMethodTitle, DetailsTab, Safe } from '../../../types/safes.types';
import { toNumber } from '../../../utils/toNumber';
import { DeleteSafeModal } from '../Modals/Delete';

export type SafeTableItemProps = {
  isSelected: (id: string) => boolean;
  onSelect: (id: string) => void;
  onRemoveSelect: (id: string) => void;
  handleView: (id: string) => void;
  handleEdit: (id: string) => void;
  handleConvert: (id: string) => void;
  handleDelete: (id: string) => void;
  safe: Safe;
  selectedTab: DetailsTab;
};

export const SafeTableItem: FC<SafeTableItemProps> = ({
  isSelected,
  onSelect,
  onRemoveSelect,
  handleView,
  handleEdit,
  handleConvert,
  handleDelete,
  safe,
  selectedTab,
}) => {
  const {
    id,
    stakeholder,
    investment,
    conversionDate,
    conversionMethod,
    convertedShares,
    floorValue,
    issueDate,
    maturityDate,
    discount,
    capValue,
  } = safe;

  const { format } = useFormat();

  const { handleCloseModal, handleSuccessModal, handleOpenModal, isOpen, toggler } = useModalState({
    onSuccess: () => {
      handleDelete(id);
      toast.success(`SAFE have been successfully deleted`);
    },
  });

  return (
    <>
      <AlertDialogWrapper control={{ onOpenChange: toggler, open: isOpen }}>
        <DeleteSafeModal onClose={handleCloseModal} onSuccess={handleSuccessModal} />
      </AlertDialogWrapper>
      <tr className={twMerge('w-full bg-white', isSelected(id) && 'bg-gray-50')}>
        {selectedTab === DetailsTab.PENDING_CONVERSION && (
          <td className="relative">
            <div className="flex h-full w-full cursor-pointer items-center justify-center">
              <Field className="flex items-center">
                <Checkbox
                  checked={isSelected(id)}
                  id={id}
                  onChange={(checked) => {
                    checked ? onSelect(id) : onRemoveSelect(id);
                  }}
                />
                <Label
                  className="absolute left-0 top-0 h-full w-full cursor-pointer"
                  htmlFor={id}
                />
              </Field>
            </div>
          </td>
        )}
        <td className="flex min-w-[120px] max-w-[308px] items-center gap-3 p-4">
          <div className="flex size-10 shrink-0 items-center justify-center rounded-full bg-gray-200 text-sm font-[450] text-gray-700">
            {stakeholder.fullName[0]}
          </div>
          <span className="truncate text-nowrap text-sm font-[450] text-gray-700">
            {stakeholder.fullName}
          </span>
        </td>
        <td className="max-w-[151px] truncate p-4 text-sm text-gray-700">
          {toNumber(investment)?.toLocaleString('en-US')}
        </td>
        <td className="max-w-[113px] truncate p-4 text-sm text-gray-700">
          {issueDate ? format(issueDate, 'dd/MM/yyyy') : '-'}
        </td>
        {selectedTab === DetailsTab.PENDING_CONVERSION ? (
          <>
            <td className="max-w-[149px] truncate p-4 text-sm text-gray-700">
              {capValue ? toNumber(capValue)?.toLocaleString('en-US') : '-'}
            </td>
            <td className="max-w-[164px] truncate p-4 text-sm text-gray-700">
              {discount ? `${discount * 100}%` : '-'}
            </td>
            <td className="max-w-[100px] truncate p-4 text-sm text-gray-700">
              {floorValue ? toNumber(floorValue)?.toLocaleString('en-US') : '-'}
            </td>
            <td className="max-w-[113px] truncate p-4 text-sm text-gray-700">
              {maturityDate ? format(maturityDate, 'dd/MM/yyyy') : '-'}
            </td>
          </>
        ) : (
          <>
            <td className="p-4 text-sm text-gray-700">
              {conversionMethod ? conversionMethodTitle[conversionMethod] : '-'}
            </td>
            <td className="max-w-[113px] truncate p-4 text-sm text-gray-700">
              {conversionDate ? format(conversionDate, 'dd/MM/yyyy') : '-'}
            </td>
            <td className="max-w-[100px] truncate p-4 text-sm text-gray-700">
              {convertedShares ? toNumber(convertedShares)?.toLocaleString('en-US') : '-'}
            </td>
          </>
        )}
        <td className="px-4 py-[22px]">
          <Popover>
            <PopoverTrigger className="h-fit w-fit rounded data-[state=open]:shadow-md">
              <div className="flex size-6 rotate-90 items-center justify-center rounded bg-transparent transition-colors hover:bg-gray-100">
                <ThreeDotsIcon />
              </div>
            </PopoverTrigger>
            <PopoverContent className="flex w-fit flex-col gap-[2px] rounded p-2 font-normal shadow-2xl [&>*]:font-inter [&>*]:font-normal">
              <PopoverClose>
                <div
                  className="flex w-full items-center gap-2 p-[6px] pr-8 text-sm text-gray-700 hover:bg-gray-50"
                  onClick={() => handleView(id)}
                >
                  <EyeIconSmall />
                  <span className="text-sm font-normal text-gray-700">View SAFE</span>
                </div>

                {selectedTab === DetailsTab.PENDING_CONVERSION && (
                  <>
                    <div
                      className="flex w-full items-center gap-2 p-[6px] pr-8 text-sm text-gray-700 hover:bg-gray-50"
                      onClick={() => handleEdit(id)}
                    >
                      <EditIcon />
                      <span className="text-sm font-normal text-gray-700">Edit SAFE</span>
                    </div>

                    <div
                      className="flex w-full items-center gap-2 p-[6px] pr-8 text-sm text-gray-700 hover:bg-gray-50"
                      onClick={() => handleConvert(id)}
                    >
                      <EditIcon />
                      <span className="text-sm font-normal text-gray-700">Convert SAFE</span>
                    </div>

                    <div
                      className="flex w-full items-center gap-2 p-[6px] pr-8 text-sm text-gray-700 hover:bg-gray-50"
                      onClick={handleOpenModal}
                    >
                      <TrashBinIcon className="size-4" iconColor="#F04438" />
                      <span className="text-sm font-normal text-fireside-500">Delete SAFE</span>
                    </div>
                  </>
                )}
              </PopoverClose>
            </PopoverContent>
          </Popover>
        </td>
      </tr>
    </>
  );
};
