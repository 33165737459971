import {
  FetchNextPageOptions,
  InfiniteData,
  InfiniteQueryObserverResult,
} from '@tanstack/react-query';
import React, { FC } from 'react';
import { Control, useController } from 'react-hook-form';
import InfiniteScroll from 'react-infinite-scroll-component';

import { ChevronDownIcon } from '../../../../assets/icons';
import { AppFormattedMessage } from '../../../../components/AppFormattedMessage';
import {
  ComboboxButton,
  ComboboxInput,
  ComboboxOptions,
  FormCombobox,
  FormComboboxOption,
} from '../../../../components/Combobox';
import Loader from '../../../../components/Loader';
import { EventHooks } from '../../../../hooks';
import { EventsResponse } from '../../../../hooks/event';
import { StringKey } from '../../../../lang';
import { EventTypeBackend, SortByType, ValuationEvent } from '../../../../types/events.types';
import { defaultSharePrice, FormSchema } from '../validation';

type EventType = {
  id: string;
  name: string;
  sharePrice: number;
  issuedSharesOnStart: number;
};

export type EventsItemsListProps = {
  data: ValuationEvent[];
  control: Control<FormSchema>;
  fieldName: string;
  search: string;
  lockMode: boolean;
  hasNextPage: boolean;
  fetchNextPage: (
    options?: FetchNextPageOptions,
  ) => Promise<InfiniteQueryObserverResult<InfiniteData<EventsResponse, unknown>, Error>>;
};

const EventsItemsList: FC<EventsItemsListProps> = ({
  data,
  control,
  fieldName,
  search,
  lockMode,
  hasNextPage,
  fetchNextPage,
}) => {
  return (
    <InfiniteScroll
      className="flex w-full flex-col gap-2"
      dataLength={10}
      hasMore={hasNextPage}
      loader={<Loader />}
      next={fetchNextPage}
      scrollableTarget="scrollbar-target"
    >
      {data.length > 0 ? (
        <>
          {data
            .sort((a, b) => a.name.toLowerCase().localeCompare(b.name.toLowerCase()))
            .map(({ name, id, sharePrice, issuedSharesOnStart }, i) => (
              <FormComboboxOption
                className="w-full"
                control={control}
                key={`${name}_${i}`}
                name={fieldName as keyof FormSchema}
                value={
                  {
                    id,
                    name,
                    sharePrice,
                    issuedSharesOnStart,
                  } as unknown as FormSchema[keyof FormSchema]
                }
              >
                <span className="truncate text-sm text-gray-700">{name}</span>
              </FormComboboxOption>
            ))}
        </>
      ) : (
        <>
          <span className="ml-2 px-2 py-[6px] text-sm font-normal text-gray-700">
            <AppFormattedMessage id={StringKey.NO_RESULTS_FOUND} />
          </span>
          {!lockMode && (
            <FormComboboxOption
              className="rounded bg-brand-25 px-2 py-[6px]"
              control={control}
              name={fieldName as keyof FormSchema}
              value={
                {
                  id: 'new',
                  name: search,
                  sharePrice: defaultSharePrice,
                } as FormSchema[keyof FormSchema]
              }
            >
              <span className="truncate text-start text-sm font-[450] text-brand-700">
                <AppFormattedMessage
                  id={StringKey.CHOOSE_VALUATION_NAME}
                  values={{ valuationName: search }}
                />
              </span>
            </FormComboboxOption>
          )}
        </>
      )}
    </InfiniteScroll>
  );
};

export type EventComboboxProps = {
  control: Control<FormSchema>;
  companyId: string;
  fieldName: string;
  lockMode: boolean;
  disabled?: boolean;
  onlyWithSharePrice?: boolean;
};

export const EventCombobox: FC<EventComboboxProps> = ({
  control,
  companyId,
  fieldName,
  lockMode,
  disabled = false,
  onlyWithSharePrice = false,
}) => {
  const {
    events: backendEvents,
    fetchNextPage,
    hasNextPage,
  } = EventHooks.useEvents({
    companyId,
    typesFilter: [SortByType.VALUATION],
  });

  const { field, fieldState } = useController({
    control,
    name: fieldName as keyof FormSchema,
  });

  const value = (field?.value as EventType)?.name || '';

  if (!backendEvents) return;
  const filteredEvents = backendEvents
    .filter((event): event is ValuationEvent => event.type === EventTypeBackend.VALUATION)
    .filter((event) => event.name.includes(value.trim()));

  const onChange = (value: string) => {
    field.onChange({ name: value });
  };

  const handleBlur = () => {
    if (!lockMode && value && !filteredEvents.some((event) => event.name === value)) {
      field.onChange({
        id: 'new',
        name: value,
        sharePrice: defaultSharePrice,
      });
    }
  };

  return (
    <>
      <FormCombobox
        className="relative w-full"
        control={control}
        name={fieldName as keyof FormSchema}
      >
        <div className="relative">
          <ComboboxInput
            {...field}
            disabled={disabled}
            errorMessage={(fieldState.error as any)?.name?.message}
            icon={<ChevronDownIcon className="mt-3" />}
            onBlur={handleBlur}
            onChange={onChange}
            placeholder={
              fieldName === 'stepOne.eventDetails.fundraising-round.event' ? (
                <AppFormattedMessage id={StringKey.ROUND_NAME} />
              ) : (
                <AppFormattedMessage id={StringKey.EVENT_NAME} />
              )
            }
            value={value}
          />
          <ComboboxButton className="absolute top-0 z-1 h-full w-full" disabled={disabled} />
        </div>

        <ComboboxOptions className="w-full overflow-x-hidden">
          <EventsItemsList
            control={control}
            data={
              filteredEvents.filter(({ sharePrice }) =>
                onlyWithSharePrice ? sharePrice : true,
              ) as ValuationEvent[]
            }
            fetchNextPage={fetchNextPage}
            fieldName={fieldName}
            hasNextPage={hasNextPage}
            lockMode={lockMode}
            search={value}
          />
        </ComboboxOptions>
      </FormCombobox>
    </>
  );
};
