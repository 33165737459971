import { FC } from 'react';

import { CloseIcon } from '../../assets/icons';
import { AppFormattedMessage } from '../../components/AppFormattedMessage';
import Button from '../../components/Button';
import { StringKey } from '../../lang';
import { toUpperCaseWords } from '../../utils/toUppercaseUtil';

export type TerminationModalProps = {
  onSuccess: () => void;
  onClose: () => void;
  text: string;
};

export const TerminationModal: FC<TerminationModalProps> = ({ onClose, onSuccess, text }) => {
  return (
    <div className="m-2 flex w-full max-w-[453px] flex-col gap-8 rounded bg-white p-4">
      <div className="flex flex-col gap-6">
        <div className="flex flex-col gap-1">
          <span className="text-cl font-[550] text-gray-700">
            <AppFormattedMessage id={StringKey.TERMINATE} /> {toUpperCaseWords(text)}?
          </span>
          <span className="text-xs font-[450] text-gray-500">
            <AppFormattedMessage id={StringKey.TERMINATE_POOL_CONFIRMATION} /> {text}?
          </span>
        </div>
        <div className="flex flex-col gap-3 rounded bg-gray-50 p-3">
          <span className="text-base font-[550] text-gray-700">
            <AppFormattedMessage id={StringKey.IF_YOU_TERMINATE} />:
          </span>
          <div className="flex items-center gap-2">
            <CloseIcon className="size-[12px]" iconColor="#D92D20" />
            <span className="text-xs font-[450] text-gray-800">
              <AppFormattedMessage
                id={StringKey.TERMINATION_WARNING}
                values={{
                  text,
                }}
              />
            </span>
          </div>
          <div className="flex items-center gap-2">
            <CloseIcon className="size-[12px]" iconColor="#D92D20" />
            <span className="text-xs font-[450] text-gray-800">
              {text === 'pool' ? (
                <AppFormattedMessage id={StringKey.POOL_NO_LINK_AFTER_TERMINATION} />
              ) : (
                <AppFormattedMessage id={StringKey.PLAN_NO_GRANTS_AFTER_TERMINATION} />
              )}
            </span>
          </div>
        </div>
      </div>
      <div className="flex h-11 w-full justify-end gap-4">
        <Button
          className="h-full w-fit rounded border border-gray-300 px-6 py-[10px] text-sm font-[450] text-gray-700"
          onClick={onClose}
          styleType="NONE"
        >
          <AppFormattedMessage id={StringKey.NO_KEEP_3} />
        </Button>
        <Button
          className="h-full w-fit bg-fireside-600 px-6 py-[10px] text-sm font-[550] text-white"
          onClick={onSuccess}
          styleType="NONE"
        >
          <AppFormattedMessage id={StringKey.YES_TERMINATE} />
        </Button>
      </div>
    </div>
  );
};
