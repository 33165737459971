import { Field, Label } from '@headlessui/react';
import { FC, useState } from 'react';

import { CloseIcon } from '../../../assets/icons';
import MinusIcon from '../../../assets/icons/MinusIcon';
import { SharePlan, useFormat, useModalState } from '../../../hooks';
import { StringKey } from '../../../lang';
import { ApproveMilestoneDto } from '../../../types/vestingTasks.types';
import { toNumber } from '../../../utils/toNumber';
import { AlertDialogWrapper } from '../../AlertDialog';
import { AppFormattedMessage } from '../../AppFormattedMessage';
import Button from '../../Button';
import { Checkbox } from '../../Checkbox';
import { Table } from '../../Table';
import { TargetBasedTableRow } from './TargetBasedTableRow';
import {
  ApproveHurdleColumn,
  approveTargetBasedColumnMap,
  approveTargetBasedColumnTitle,
} from './types';

export type ApproveTargetBasedPlanProps = {
  companyId: string;
  milestoneIndex: number | null;
};

export const ApproveTargetBasedPlan: FC<ApproveTargetBasedPlanProps> = ({
  companyId,
  milestoneIndex,
}) => {
  const {
    isOpen,
    toggler,
    handleCloseModal,
    paramsValue: sharePlanId,
  } = useModalState('target-based-approve-modal');

  const [selectedVestingTasks, setSelectedVestingTasks] = useState<ApproveMilestoneDto[]>([]);

  const { sharePlan } = SharePlan.useSharePlan({ companyId, planId: sharePlanId });
  const { format } = useFormat();

  const { approveTargetBasedSharePlans } = SharePlan.useTargetBasedVestingTasks({
    companyId,
    sharePlanId: sharePlanId,
  });

  const milestone =
    milestoneIndex !== null
      ? approveTargetBasedSharePlans[0]?.targetBasedVestingMilestones?.[milestoneIndex]
      : undefined;

  const onDeselectAll = () => setSelectedVestingTasks([]);

  const onSelectAll = (data: ApproveMilestoneDto[]) => {
    setSelectedVestingTasks(data);
  };

  const { approve } = SharePlan.useApproveTargetBasedVestingTasks({ companyId });

  if (milestoneIndex === null) return <></>;

  return (
    <AlertDialogWrapper control={{ onOpenChange: toggler, open: isOpen }}>
      <div className="flex flex-col gap-8 rounded-2xl bg-white p-8">
        <div className="flex w-full justify-between">
          <span className="text-xl font-semibold text-gray-700">Target Based Vesting Approval</span>
          <Button
            className="centered flex size-7 rounded-full border border-gray-100 shadow-sm"
            onClick={handleCloseModal}
            styleType="NONE"
          >
            <CloseIcon />
          </Button>
        </div>
        <div className="flex flex-col text-sm text-gray-700">
          <span>Plan: {sharePlan?.name}</span>
          <span>
            <AppFormattedMessage id={StringKey.PLAN_TYPE} />: {sharePlan?.pool?.shareClass?.name}
          </span>
          <span>
            Vesting Weight: {milestone?.weight}% ( cumulative{' '}
            {approveTargetBasedSharePlans[0]?.targetBasedVestingMilestones?.reduce(
              (acc, { isCompleted, weight }) => acc + toNumber(isCompleted && weight),
              0,
            )}
            %)
          </span>
          <span>
            <AppFormattedMessage id={StringKey.MILESTONE_NAME} />: {milestone?.name}
          </span>
          <span>Milestone Date: {format(milestone?.targetDate, 'dd/MM/yyyy')}</span>
        </div>
        <div className="max-h-[400px] overflow-y-auto bg-white">
          <Table
            columns={approveTargetBasedColumnMap}
            columnsTitle={{
              ...approveTargetBasedColumnTitle,
              [ApproveHurdleColumn.SELECT]: (
                <Field className="flex cursor-pointer items-center">
                  <Checkbox
                    checked={selectedVestingTasks.length > 0}
                    id="select-all-hurdle-plans"
                    onChange={(checked) =>
                      checked
                        ? onSelectAll(
                            approveTargetBasedSharePlans?.map(
                              ({ id, grantItem: { stakeholder, numbersOfGrants } }) => ({
                                stakeholderId: stakeholder.id,
                                vestingTaskId: id,
                                value: numbersOfGrants,
                                milestoneIndex,
                              }),
                            ) || [],
                          )
                        : onDeselectAll()
                    }
                    selectIcon={<MinusIcon />}
                  />
                  <Label
                    className="absolute left-0 top-0 h-full w-full cursor-pointer"
                    htmlFor="select-all-hurdle-plans"
                  />
                </Field>
              ),
            }}
            tHeadClassNames={{ thClassName: 'pl-4' }}
          >
            {approveTargetBasedSharePlans.map(({ id, ...grantItem }) => (
              <TargetBasedTableRow
                id={id}
                isSelected={selectedVestingTasks.some(({ vestingTaskId }) => vestingTaskId === id)}
                key={id}
                milestone={milestone}
                {...grantItem}
                onRemoveSelect={(selectedId) =>
                  setSelectedVestingTasks((prev) =>
                    prev.filter(({ vestingTaskId }) => vestingTaskId !== selectedId),
                  )
                }
                onSelect={(item) =>
                  setSelectedVestingTasks((prev) => [...prev, { ...item, milestoneIndex }])
                }
              />
            ))}
          </Table>
        </div>
        <div className="flex h-11 w-full justify-between overflow-x-auto text-sm">
          <Button className="w-fit font-[450] text-gray-700" styleType="NONE">
            <AppFormattedMessage id={StringKey.CANCEL} />
          </Button>
          <Button
            className="w-[260px] font-[550] text-white"
            disabled={selectedVestingTasks.length === 0}
            onClick={() =>
              approve(selectedVestingTasks, {
                onSuccess: () => {
                  handleCloseModal();
                },
              })
            }
          >
            Approve Target
          </Button>
        </div>
      </div>
    </AlertDialogWrapper>
  );
};
