import { BackendRoute } from '../../config';
import { QueryKey } from '../../constants';
import { ApiService } from '../../services';
import { CreateSafeDto } from '../../types/safes.types';
import { useAppMutation } from '../useAppMutation';

export const useCreate = () => {
  const { mutate: create, isPending } = useAppMutation([QueryKey.CREATE_SAFE], {
    mutationFn: ({ companyId, data }: { data: CreateSafeDto; companyId: string }) =>
      ApiService.post(
        { endpoint: BackendRoute.COMPANIES, routePath: [companyId, 'safes'] },
        { body: data },
      ),
  });
  return { create, isPending };
};
