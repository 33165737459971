import { FC } from 'react';
import { useNavigate } from 'react-router-dom';
import { twMerge } from 'tailwind-merge';

import { CapQuestLogo, CrossIcon } from '../../assets/icons';
import img1 from '../../assets/images/modify-guide-1.png';
import img2 from '../../assets/images/modify-guide-2.png';
import Button from '../../components/Button';
import { modifySections } from './info';

export const ModifyGuide: FC = () => {
  const navigate = useNavigate();

  const scrollToSection = (id: string) => {
    const element = document.getElementById(id);
    if (element) {
      element.scrollIntoView({ behavior: 'smooth' });
    }
  };

  return (
    <div className="relative flex h-screen w-full flex-col gap-2 bg-brand-25">
      <div className="flex w-full justify-between rounded-b-3xl bg-white px-8 py-3">
        <div className="flex items-center gap-2" onClick={() => navigate('/')}>
          <CapQuestLogo size={32} />
          <span className="text-xl font-semibold text-gray-700">CapQuest</span>
        </div>
        <Button
          className="shadow-xxs size-9 rounded-full border-[1px] border-[#f2f2f2] bg-white"
          onClick={() => navigate('/')}
        >
          <CrossIcon className="size-4" />
        </Button>
      </div>
      <div className="flex h-full w-full flex-col gap-8 rounded-t-3xl bg-white px-8 py-4">
        <div className="flex h-[38px] w-full gap-2 border-b-[1px] border-gray-200">
          <Button
            className={twMerge(
              'flex h-full w-fit rounded-none border-b-2 border-transparent pb-4 text-sm font-[450] text-gray-400',
              'border-brand-700 font-[550] text-brand-700',
            )}
            styleType="NONE"
          >
            Modification Guide
          </Button>
        </div>
        <div className="flex w-full gap-6">
          <div className="flex h-fit max-h-[80vh] w-max flex-col gap-2 rounded-md py-4 shadow-sm">
            <span
              className="text-nowrap px-6 py-2 text-label-sm font-bold uppercase text-gray-400"
              style={{ letterSpacing: '1px' }}
            >
              On this page
            </span>
            <div className="flex h-full w-full flex-col gap-[2px] overflow-y-auto">
              {modifySections.map(({ title }, i) => (
                <div
                  className="w-full cursor-pointer text-nowrap p-2 pl-6 pr-12 text-xs font-[450] text-gray-600 transition-all duration-300 hover:bg-brand-25 hover:font-semibold hover:text-brand-700"
                  key={`${title}-${i + 1}`}
                  onClick={() => scrollToSection(`step-${i + 1}`)}
                >
                  {title}
                </div>
              ))}
            </div>
          </div>
          <div className="flex h-[80vh] w-full flex-col gap-4 overflow-y-auto rounded-md p-4 shadow-sm">
            <div className="flex flex-col gap-2" id="heading">
              <span className="text-xs font-[450] text-gray-700">
                The <span className="font-semibold">Bulk Modify Stakeholders</span> mechanism allows
                Admins and Contributors to update multiple stakeholder records simultaneously,
                saving time and reducing manual errors. By downloading a preformatted Excel sheet,
                users can make changes to stakeholder information in bulk and re-upload the updated
                file to apply modifications.
              </span>
              <span className="text-xl font-semibold text-gray-700">Step-by-Step Guide</span>
            </div>
            <div className="flex flex-col gap-2" id={`step-1`}>
              <h2 className="text-[16px] font-semibold leading-[2] text-gray-700">
                Step 1: Open the Bulk Modify Blade
              </h2>
              <ol className="flex list-decimal flex-col gap-2 pl-10">
                <li className="text-xs font-[450] text-gray-700">
                  Navigate to the <strong>Stakeholders Page</strong> in the Admin Panel.
                </li>
                <li className="text-xs font-[450] text-gray-700">
                  Click on the <strong>Bulk Modify</strong> option to open the Bulk Modify
                </li>
              </ol>
            </div>

            <div className="flex flex-col gap-2" id={`step-2`}>
              <h2 className="text-[16px] font-semibold leading-[2] text-gray-700">
                Step 2: Select Stakeholder Type
              </h2>
              <ol className="flex list-decimal flex-col gap-2 pl-10">
                <li className="text-xs font-[450] text-gray-700">
                  Within the Bulk Modify blade, select either <strong>Natural Persons</strong> or{' '}
                  <strong>Legal Entity</strong> from the dropdown menu.
                </li>
              </ol>
              <img src={img1} width={320} />
            </div>

            <div className="flex flex-col gap-2" id={`step-3`}>
              <h2 className="text-[16px] font-semibold leading-[2] text-gray-700">
                Step 3: Download the Excel Template
              </h2>
              <ol className="flex list-decimal flex-col gap-2 pl-10">
                <li className="text-xs font-[450] text-gray-700">
                  Click the <strong>Download Stakeholder Info</strong> button.
                </li>
                <li className="text-xs font-[450] text-gray-700">
                  The downloaded file will include:
                  <ul className="flex list-disc flex-col gap-2 pl-4 pt-4">
                    <li className="text-xs font-[450] text-gray-700">
                      <strong>Sheet 1:</strong> "Bulk Upload (Natural)" or "Bulk Upload (Legal),"
                      depending on your selection.
                    </li>
                    <li className="text-xs font-[450] text-gray-700">
                      <strong>Sheet 2:</strong> "Dropdown" (Read-only).
                    </li>
                  </ul>
                </li>
              </ol>
              <img src={img2} width={320} />
            </div>

            <div className="flex flex-col gap-2" id={`step-4`}>
              <h2 className="text-[16px] font-semibold leading-[2] text-gray-700">
                Step 4: Understanding the Excel File
              </h2>
              <span className="text-xs font-[550] text-gray-700">
                Sheet 1: "Bulk Upload (Natural)"
              </span>
              <span className="text-xs font-[450] text-gray-700">
                This sheet contains editable fields for stakeholder information:
              </span>
              <ul className="flex list-disc flex-col gap-2 pl-10">
                <li className="text-xs font-[450] text-gray-700">
                  <strong>Editable Fields:</strong> Full Name, Email, Stakeholder Type, Passport
                  Number, Passport Expiry Date (Day, Month, Year), etc.
                </li>
                <li className="text-xs font-[450] text-gray-700">
                  <strong>Mandatory Fields:</strong> Ensure the following fields are always filled
                  out:
                  <ul className="flex list-disc flex-col gap-2 pl-4 pt-4">
                    <li className="text-xs font-[450] text-gray-700">Full Name</li>
                    <li className="text-xs font-[450] text-gray-700">Email</li>
                    <li className="text-xs font-[450] text-gray-700">Stakeholder Type</li>
                  </ul>
                </li>
              </ul>

              <span className="pt-4 text-xs font-[550] text-gray-700">
                Sheet 1: "Bulk Upload (Legal)"
              </span>
              <span className="text-xs font-[450] text-gray-700">
                This sheet contains editable fields for stakeholder information:
              </span>
              <ul className="flex list-disc flex-col gap-2 pl-10">
                <li className="text-xs font-[450] text-gray-700">
                  <strong>Editable Fields:</strong> Full Name, Email, Stakeholder Type, Country of
                  Incorporation, Company Identifier, Date of Incorporation (Day), etc.
                </li>
                <li className="text-xs font-[450] text-gray-700">
                  <strong>Mandatory Fields:</strong> Ensure the following fields are always filled
                  out:
                  <ul className="flex list-disc flex-col gap-2 pl-4 pt-4">
                    <li className="text-xs font-[450] text-gray-700">Full Name</li>
                    <li className="text-xs font-[450] text-gray-700">Email</li>
                    <li className="text-xs font-[450] text-gray-700">Stakeholder Type</li>
                  </ul>
                </li>
              </ul>

              <span className="pt-4 text-xs font-[550] text-gray-700">Sheet 2: "Dropdown"</span>
              <ul className="flex list-disc flex-col gap-2 pl-10">
                <li className="text-xs font-[450] text-gray-700">
                  This sheet includes prefilled values for dropdown options used in Sheet 1.
                </li>
                <li className="text-xs font-[450] text-gray-700">
                  <strong>Important:</strong> Do not edit this sheet. It is used to populate
                  dropdown menus in Sheet 1.
                </li>
              </ul>

              <span className="pt-4 text-xs font-[550] text-gray-700">Dropdown-linked Fields:</span>
              <span className="text-xs font-[450] text-gray-700">
                Ensure values match exactly with the options listed in Sheet 2 for the following
                fields:
              </span>
              <span className="pt-4 text-xs font-[550] text-gray-700">For Natural Persons:</span>
              <ul className="flex list-disc flex-col gap-2 pl-10">
                <li className="text-xs font-[450] text-gray-700">Stakeholder Type</li>
                <li className="text-xs font-[450] text-gray-700">Nationality</li>
                <li className="text-xs font-[450] text-gray-700">Passport Expiry Date (Month)</li>
                <li className="text-xs font-[450] text-gray-700">Birthday (Month)</li>
                <li className="text-xs font-[450] text-gray-700">
                  Access & Permissions (Cap Table, Your Position, Equity & Capital Change, Company
                  Table)
                </li>
              </ul>
              <span className="pt-4 text-xs font-[550] text-gray-700">For Legal Entities:</span>
              <ul className="flex list-disc flex-col gap-2 pl-10">
                <li className="text-xs font-[450] text-gray-700">Country of Incorporation</li>
                <li className="text-xs font-[450] text-gray-700">Date of Incorporation (Month)</li>
                <li className="text-xs font-[450] text-gray-700">Phone Number (Prefix)</li>
                <li className="text-xs font-[450] text-gray-700">
                  Access & Permissions (Cap Table, Your Position, Equity & Capital Change, Company
                  Table)
                </li>
              </ul>
            </div>

            <div className="flex flex-col gap-2" id={`step-5`}>
              <h2 className="text-[16px] font-semibold leading-[2] text-gray-700">
                Step 5: Editing the Excel File
              </h2>
              <ol className="flex list-decimal flex-col gap-2 pl-10">
                <li className="text-xs font-[450] text-gray-700">
                  Open <strong>Sheet 1</strong> ("Bulk Upload (Natural)" or "Bulk Upload (Legal)").
                </li>
                <li className="text-xs font-[450] text-gray-700">
                  Update the necessary stakeholder information.
                </li>
                <li className="text-xs font-[450] text-gray-700">
                  Ensure values for dropdown-linked fields match exactly with the options in Sheet
                  2.
                </li>
                <li className="text-xs font-[450] text-gray-700">
                  Save the Excel file after completing the edits.
                </li>
              </ol>
            </div>

            <div className="flex flex-col gap-2" id={`step-6`}>
              <h2 className="text-[16px] font-semibold leading-[2] text-gray-700">
                Step 6: Upload the Updated File
              </h2>
              <ol className="flex list-decimal flex-col gap-2 pl-10">
                <li className="text-xs font-[450] text-gray-700">
                  Return to the <strong>Bulk Modify</strong> Stakeholders section.
                </li>
                <li className="text-xs font-[450] text-gray-700">
                  Ensure <strong>Natural Persons</strong> (or <strong>Legal Entity</strong>, as
                  applicable) is selected in the dropdown.
                </li>
                <li className="text-xs font-[450] text-gray-700">
                  <strong>Upload Excel</strong> (or drag and drop)
                </li>
                <li className="text-xs font-[450] text-gray-700">
                  Verify the uploaded file’s content during the system’s validation process.
                </li>
              </ol>
            </div>

            <div className="flex flex-col gap-2" id={`step-7`}>
              <h2 className="text-[16px] font-semibold leading-[2] text-gray-700">
                Key Notes and Tips
              </h2>
              <ol className="flex list-decimal flex-col gap-2 pl-10">
                <li className="text-xs font-[450] text-gray-700">
                  Ensure all <strong>mandatory fields</strong> are filled; missing information will
                  result in upload errors.
                </li>
                <li className="text-xs font-[450] text-gray-700">
                  Do not modify <strong>Sheet 2: Dropdown.</strong>
                </li>
                <li className="text-xs font-[450] text-gray-700">
                  Values in dropdown-linked fields must match exactly with the options in Sheet 2 to
                  avoid errors.
                </li>
                <li className="text-xs font-[450] text-gray-700">
                  Save a backup copy of the original file before making changes.
                </li>
              </ol>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
