import { FC } from 'react';

import { SafeGraph } from './SafeGraph';

type GraphWrapperProps = {
  companyId: string;
};

export const GraphWrapper: FC<GraphWrapperProps> = ({ companyId }) => {
  return (
    <div className="flex min-h-full w-full flex-col items-center rounded-lg bg-gray-50 p-1">
      <div className="flex w-full items-start justify-between gap-4 px-4 py-3">
        <span className="w-full text-left text-[10px] font-bold uppercase leading-4 tracking-[1px] text-gray-700">
          SAFE RECAP
        </span>
      </div>
      <div className="flex h-full w-full flex-wrap content-start gap-3 rounded-md bg-white p-6 shadow-sm">
        <SafeGraph companyId={companyId} />
      </div>
    </div>
  );
};
