import { useMemo } from 'react';
import { useLocation } from 'react-router-dom';

export const useQueryParams = <T extends string = string>() => {
  const { search } = useLocation();

  const params = useMemo(() => new URLSearchParams(search), [search]);

  const get = (key: string) => (params.get(key) as T) || undefined;

  const deleteParam = (key: string) => params.delete(key);

  const set = (key: string, value: T) => params.set(key, value);

  return { get, deleteParam, set };
};
