import { useQueryClient } from '@tanstack/react-query';
import { useCallback } from 'react';

import { BackendRoute } from '../../config';
import { QueryKey } from '../../constants';
import { HttpException } from '../../exceptions';
import { ApiService } from '../../services';
import { SafeOrder, SafeSortBy } from '../../types/safes.types';
import { useAppQuery } from '../useAppQuery';
import { GetSafesResponse } from './useSafes';

export const useStakeholderSafes = ({
  companyId,
  stakeholderId,
  numberOfFetchedItems = 10,
  sortBy,
  currentPage,
  search,
  sortOrder,
  convertedOnly,
}: {
  companyId: string;
  stakeholderId: string;
  numberOfFetchedItems?: number;
  currentPage: number;
  search: string;
  sortOrder: SafeOrder | null;
  sortBy: SafeSortBy | null;
  convertedOnly?: boolean;
}) => {
  const queryClient = useQueryClient();
  const skip = (currentPage - 1 <= 0 ? 0 : currentPage - 1) * numberOfFetchedItems;
  const {
    data: { data, totalPages, totalSafesCount } = {},
    isLoading,
    refetch,
  } = useAppQuery<GetSafesResponse, HttpException>({
    queryKey: [
      QueryKey.GET_STAKEHOLDER_SAFES,
      companyId,
      stakeholderId,
      {
        page: currentPage,
        sortBy,
        sortOrder,
        skip,
        numberOfFetchedItems,
        convertedOnly,
      },
    ],
    queryFn: () =>
      ApiService.get(
        {
          endpoint: BackendRoute.COMPANIES,
          routePath: [companyId, 'stakeholders', stakeholderId, 'safes'],
        },
        {
          queryParams: {
            take: numberOfFetchedItems,
            skip,
            search,
            convertedOnly: convertedOnly,
            ...(sortOrder && sortBy ? { sortBy, sortOrder } : {}),
          },
        },
      ),
    enabled: !!companyId && !!stakeholderId,
  });

  const invalidateQuery = useCallback(() => {
    queryClient.invalidateQueries({ queryKey: [QueryKey.GET_STAKEHOLDER_SAFES] });
  }, [queryClient]);

  return {
    invalidateQuery,
    safes: data,
    totalPages,
    totalSafesCount,
    isLoading,
    refetch,
  };
};
