import { FC } from 'react';

import { AppFormattedMessage } from '../../components/AppFormattedMessage';
import { SafeHooks, useSelectedCompany } from '../../hooks';
import { StringKey } from '../../lang';
import { toNumber } from '../../utils/toNumber';
import { SummaryItem } from '../CapTable/SummaryItem';

type OverallStatusProps = {
  companyId: string;
};

export const OverallStatus: FC<OverallStatusProps> = ({ companyId }) => {
  const { selectedCompany } = useSelectedCompany();
  const currency = selectedCompany?.currency;

  const { overallStatus } = SafeHooks.useSafeOverallStatus({ companyId });

  return (
    <div className="flex min-h-full w-full flex-col items-center rounded-lg bg-gray-50 p-1">
      <div className="flex w-full items-start justify-between gap-4 px-4 py-3">
        <span className="w-full text-left text-[10px] font-bold uppercase leading-4 tracking-[1px] text-gray-700">
          <AppFormattedMessage id={StringKey.OVERALL_STATUS} />
        </span>
      </div>

      <div className="flex h-full w-full flex-wrap content-start gap-3 rounded-md bg-white p-3 shadow-sm max-lg:flex-col">
        <SummaryItem
          title="INVESTMENT THROUGH SAFE"
          value={`${currency?.symbol || currency?.iso3?.toLocaleUpperCase()}${overallStatus?.totalConvertedInvestment ? toNumber(overallStatus?.totalConvertedInvestment).toLocaleString('en-US') : 0}`}
        />
        <SummaryItem
          title="Shares Converted from SAFE"
          value={
            overallStatus?.totalConvertedShares
              ? toNumber(overallStatus?.totalConvertedShares).toLocaleString('en-US')
              : 0
          }
        />

        <SummaryItem title="SAFE holders" value={overallStatus?.safeHolders || 0} />
        <SummaryItem
          title="CONVERTED SAFE HOLDERS"
          value={overallStatus?.convertedSafeHolders || 0}
        />

        <SummaryItem
          title="INVESTMENT PENDING CONVERSION"
          value={`${currency?.symbol || currency?.iso3?.toLocaleUpperCase()}${overallStatus?.totalPendingInvestment ? toNumber(overallStatus?.totalPendingInvestment).toLocaleString('en-US') : 0}`}
        />
      </div>
    </div>
  );
};
