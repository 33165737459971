import React, { FC, useEffect, useState } from 'react';
import { useWatch } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';

import warningImg from '../../../../assets/images/warning.png';
import { AppFormattedMessage } from '../../../../components/AppFormattedMessage';
import Button from '../../../../components/Button';
import { SafeHooks } from '../../../../hooks';
import { StringKey } from '../../../../lang';
import { EventFormType } from '../../../../types/events.types';
import { PageRoute } from '../../../../types/pageTypes';
import {
  BulkGrantStepOne,
  BuyBackStepOne,
  ClassConversionStepOne,
  FundraisingRoundStepOne,
  GrantStepOne,
  SecondariesStepOne,
  ShareIssuanceStepOne,
  ValuationStepOne,
} from '../StepComponents';
import { ChildrenFormProps } from '../type';
import { FormSchema, stepOne as stepOneFormSchema } from '../validation';

export type StepOneProps = ChildrenFormProps;

export const StepOne: FC<StepOneProps> = ({
  handleCloseModal,
  nextFormStep,
  control,
  setFormData,
  formData,
  companyId,
  issuedShares,
  lockMode,
  filedState,
  setError,
  clearErrors,
  errors,
}) => {
  const { stepOne, initialStep } = useWatch<FormSchema>({ control });
  const { success } = stepOneFormSchema.safeParse(stepOne);
  const isValid = success;
  const { isCompanyHaveSafes } = SafeHooks.useIsCompanyHaveSafes({ companyId });
  const [showWarning, setShowWarning] = useState<boolean>(isCompanyHaveSafes || false);

  const navigate = useNavigate();

  useEffect(() => {
    if (initialStep?.type) {
      const eventTypeKey = `stepOne.eventDetails.${initialStep?.type}.enabled` as const;
      setFormData(eventTypeKey, true);
    }
  }, [initialStep?.type, setFormData]);

  return (
    <>
      <div className="flex h-full flex-col gap-6 overflow-y-auto rounded-b-xl border-b border-gray-300 bg-white p-6 pt-0">
        {stepOne?.eventDetails?.grant?.enabled && (
          <GrantStepOne
            clearErrors={clearErrors}
            companyId={companyId}
            control={control}
            errors={errors}
            formData={formData}
            setError={setError}
            setFormData={setFormData}
          />
        )}
        {stepOne?.eventDetails?.['bulk-import-grant']?.enabled && (
          <BulkGrantStepOne
            companyId={companyId}
            control={control}
            filedState={filedState}
            setFormData={setFormData}
          />
        )}
        {stepOne?.eventDetails?.['buyback']?.enabled && (
          <BuyBackStepOne
            clearErrors={clearErrors}
            companyId={companyId}
            control={control}
            errors={errors}
            formData={formData}
            setError={setError}
            setFormData={setFormData}
          />
        )}
        {stepOne?.eventDetails?.['secondaries']?.enabled && (
          <SecondariesStepOne
            clearErrors={clearErrors}
            companyId={companyId}
            control={control}
            errors={errors}
            formData={formData}
            setError={setError}
            setFormData={setFormData}
          />
        )}
        {stepOne?.eventDetails?.['conversion']?.enabled && (
          <ClassConversionStepOne
            companyId={companyId}
            control={control}
            setFormData={setFormData}
          />
        )}
        {stepOne?.eventDetails?.['valuation']?.enabled && (
          <ValuationStepOne
            control={control}
            issuedShares={issuedShares}
            lockMode={lockMode}
            setFormData={setFormData}
          />
        )}
        {stepOne?.eventDetails?.['fundraising-round']?.enabled && (
          <>
            {showWarning ? (
              <div className="w-full rounded border-[1px] border-gray-100 bg-gray-25">
                <div className="flex w-full flex-col items-center justify-center gap-6 py-10">
                  <div
                    className="rounded-full bg-white p-3"
                    style={{
                      boxShadow: '0px 0px 8px 0px #F7900921 inset',
                    }}
                  >
                    <div
                      className="centered flex size-28 items-center gap-3 rounded-full"
                      style={{
                        background:
                          'linear-gradient(180deg, rgba(253, 176, 34, 0.13) 0%, rgba(220, 104, 3, 0.13) 100%)',
                      }}
                    >
                      <div className="size-[56px] object-cover">
                        <img alt="" src={warningImg} />
                      </div>
                    </div>
                  </div>
                  <span className="max-w-[291px] text-center text-sm font-[450] text-gray-700">
                    You have active SAFEs which will impact the share price a new Equity Round.
                    Convert these before opening a new round.
                  </span>
                  <div className="flex w-full items-center justify-center gap-4">
                    <Button
                      className="w-fit text-nowrap border-[1px] border-gray-100 bg-gray-25 px-4 py-2 font-[450] text-brand-700 shadow-xs"
                      onClick={() => setShowWarning(false)}
                    >
                      Continue Anyway
                    </Button>
                    <Button
                      className="w-fit text-nowrap px-4 py-2"
                      onClick={() => navigate(PageRoute.SAFES)}
                    >
                      Convert SAFEs
                    </Button>
                  </div>
                </div>
              </div>
            ) : (
              <FundraisingRoundStepOne
                companyId={companyId}
                control={control}
                handleCloseModal={handleCloseModal}
                issuedShares={issuedShares}
                lockMode={lockMode ?? false}
                setFormData={setFormData}
              />
            )}
          </>
        )}
        {stepOne?.eventDetails?.['share-issuance']?.enabled && (
          <ShareIssuanceStepOne
            companyId={companyId}
            control={control}
            issuedShares={issuedShares}
            lockMode={lockMode ?? false}
            setFormData={setFormData}
          />
        )}
      </div>

      <div className="flex h-9 w-full justify-between gap-3 px-6">
        <Button
          className="w-fit bg-transparent px-4 py-[6px] text-sm font-[450] text-gray-700"
          onClick={handleCloseModal}
          styleType="NONE"
          type="button"
        >
          <AppFormattedMessage id={StringKey.CANCEL} />
        </Button>
        {(!showWarning || !stepOne?.eventDetails?.['fundraising-round']?.enabled) && (
          <div className="flex gap-3">
            <Button
              className="h-full w-fit px-4 py-[6px] text-sm font-[550] text-white"
              disabled={!isValid}
              onClick={nextFormStep}
              type="button"
            >
              {initialStep?.type === EventFormType.BULK_IMPORT_GRANT ? (
                <AppFormattedMessage id={StringKey.SAVE} />
              ) : lockMode ? (
                <AppFormattedMessage id={StringKey.UPDATE} />
              ) : (
                <AppFormattedMessage id={StringKey.NEXT} />
              )}
            </Button>
          </div>
        )}
      </div>
    </>
  );
};
