import React, { FC } from 'react';
import { twMerge } from 'tailwind-merge';

import { IconProps } from './type';

export type AttachmentIconProps = IconProps;

const AttachmentIcon: FC<AttachmentIconProps> = ({
  iconColor = '#344054',
  className,
  ...props
}) => (
  <svg
    className={twMerge('shrink-0', className)}
    fill="none"
    height="32"
    viewBox="0 0 32 32"
    width="32"
    {...props}
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M28.2032 14.5326L16.1824 26.5534C13.4487 29.2871 9.01659 29.2871 6.28292 26.5534C3.54925 23.8197 3.54925 19.3876 6.28292 16.6539L18.3037 4.63307C20.1262 2.81062 23.081 2.81062 24.9034 4.63307C26.7258 6.45552 26.7258 9.41029 24.9034 11.2327L13.354 22.7821C12.4428 23.6934 10.9654 23.6934 10.0542 22.7821C9.14293 21.8709 9.14293 20.3935 10.0542 19.4823L20.1894 9.34712"
      stroke={iconColor}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="1.73333"
    />
  </svg>
);

export default AttachmentIcon;
