import { BackendRoute } from '../../config';
import { QueryKey } from '../../constants';
import { ApiService } from '../../services';
import { useAppMutation } from '../useAppMutation';

export const useDelete = () => {
  const { mutate: deleteSafe, isPending } = useAppMutation([QueryKey.DELETE_SAFE], {
    mutationFn: ({ companyId, safeId }: { companyId: string; safeId: string }) =>
      ApiService.delete({
        endpoint: BackendRoute.COMPANIES,
        routePath: [companyId, 'safes', safeId],
      }),
  });
  return { deleteSafe, isPending };
};
