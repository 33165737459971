import React, { FC, ReactNode } from 'react';

import { ChevronDownIcon } from '../../../assets/icons';
import Button from '../../../components/Button';
import { SafeOrder, SafeSortBy } from '../../../types/safes.types';

export type TableSortingCellProps = {
  onSortClick: (field: SafeSortBy) => void;
  sortMode: SafeOrder | null;
  sortField: SafeSortBy | null;
  title: string | ReactNode;
  sortByCell: SafeSortBy;
};

export const TableSortingCell: FC<TableSortingCellProps> = ({
  onSortClick,
  sortField,
  sortMode,
  title,
  sortByCell,
}) => {
  return (
    <div className="flex items-center gap-1" onClick={() => onSortClick(sortByCell)}>
      <span className="text-label-md font-[450] text-[#172335]">{title}</span>
      <Button
        className="relative flex size-4 justify-center rounded hover:bg-gray-200"
        styleType="NONE"
      >
        {sortField === sortByCell ? (
          sortMode == null ? (
            <>
              <ChevronDownIcon className="absolute -top-[3px] w-2 rotate-180" />
              <ChevronDownIcon className="absolute -bottom-[3px] w-2" />
            </>
          ) : (
            <>
              {sortMode === SafeOrder.ASC ? (
                <ChevronDownIcon className="w-2" />
              ) : (
                <ChevronDownIcon className="w-2 rotate-180" />
              )}
            </>
          )
        ) : (
          <>
            <ChevronDownIcon className="absolute -top-[3px] w-2 rotate-180" />
            <ChevronDownIcon className="absolute -bottom-[3px] w-2" />
          </>
        )}
      </Button>
    </div>
  );
};
