import { Field, Label } from '@headlessui/react';
import { FC } from 'react';

import { Valuation } from '../../../types/events.types';
import { VestingTask } from '../../../types/vestingTasks.types';
import { toNumber } from '../../../utils/toNumber';
import { Checkbox } from '../../Checkbox';
import { DatePicker } from '../../DatePicker';
import { TableRow } from '../../Table';
import { ApproveHurdleColumn, approveHurdleColumnMap } from './types';

export type HurdleTableRowProps = {
  isSelected: boolean;
  onSelect: (vestingTask: VestingTask) => void;
  onRemoveSelect: (stakeholderId: string) => void;
  vestingTask: VestingTask;
  valuation: Valuation;
};

export const HurdleTableRow: FC<HurdleTableRowProps> = ({
  isSelected,
  onRemoveSelect,
  onSelect,
  vestingTask,
  valuation,
}) => {
  const {
    id,
    exercisedCount,
    totalSharesCount,
    grantItem: {
      id: grantId,
      numbersOfGrants,
      stakeholder: { fullName },
      plan: {
        hardleValue: hurdleValue,
        pool: { shareClass },
      },
    },
  } = vestingTask;

  if (exercisedCount === totalSharesCount) return;

  return (
    <TableRow
      columns={approveHurdleColumnMap}
      fields={{
        id,
        [ApproveHurdleColumn.SELECT]: (
          <Field className="flex h-full w-full items-center">
            <Checkbox
              checked={isSelected}
              id={id}
              onChange={(checked) => {
                checked ? onSelect(vestingTask) : onRemoveSelect(id);
              }}
            />
            <Label className="absolute left-0 top-0 h-full w-full cursor-pointer" htmlFor={id} />
          </Field>
        ),
        [ApproveHurdleColumn.STAKEHOLDER]: fullName,
        [ApproveHurdleColumn.GRANT]: grantId.split('-')[0].toUpperCase(),
        [ApproveHurdleColumn.APPROVAL_DATE]: (
          <DatePicker className="border-b border-gray-700 bg-gray-50 px-3 py-2" />
        ),
        [ApproveHurdleColumn.SHARE_CLASS]: shareClass.name,
        [ApproveHurdleColumn.HURDLE]: `${hurdleValue?.toLocaleString('en-US')} $`,
        [ApproveHurdleColumn.CURRENT_VALUATION]: `${(
          toNumber(valuation.sharePrice) *
          toNumber(valuation.issuedShares || valuation.issuedSharesOnStart)
        ).toLocaleString('en-US')} $`,
        [ApproveHurdleColumn.ISSUED_SHARES]: numbersOfGrants.toLocaleString('en-US'),
      }}
      tdClassName="pl-4"
      trClassName={isSelected && 'bg-gray-50'}
    />
  );
};
