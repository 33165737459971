import { differenceInSeconds } from 'date-fns';
import React, { FC } from 'react';

import { HelpCircle, InfoCircle } from '../../../../assets/icons';
import { InfoCircleVariant } from '../../../../assets/icons/InfoCircle';
import { AppFormattedMessage } from '../../../../components/AppFormattedMessage';
import Button from '../../../../components/Button';
import { useFormat } from '../../../../hooks';
import { StringKey } from '../../../../lang';
import { SubscriptionStatus } from '../../../../types/companyTypes';
import { Plan } from '../../../../types/planTypes';
import { toPositive } from '../../../../utils/toPositive';
import { SubscriptionVariantTranslation } from '../types';

type ModalProps = {
  handleClose: () => void;
  handleChangePlan: (props: Plan) => void;
  selectedPlan: Plan | undefined;
  newPlan: Plan | undefined;
  renewDate: number;
  currentPeriodStart: number;
  currentPeriodEnd: number;
  isPending: boolean;
  subscriptionStatus: SubscriptionStatus;
};

export const PlanChangeWarningModal: FC<ModalProps> = ({
  handleClose,
  selectedPlan,
  handleChangePlan,
  newPlan,
  renewDate,
  isPending,
  currentPeriodEnd,
  currentPeriodStart,
  subscriptionStatus,
}) => {
  const { format } = useFormat();

  const currentDate = new Date();
  const startDate = currentPeriodStart * 1000;
  const endDate = currentPeriodEnd * 1000;

  const secondsPassed = differenceInSeconds(currentDate, startDate);
  const totalPeriodSeconds = differenceInSeconds(endDate, startDate);
  const percentagePassed = secondsPassed / totalPeriodSeconds;
  const balance = selectedPlan?.priceInCents
    ? Math.round((1 - percentagePassed) * selectedPlan?.priceInCents)
    : 0;

  if (!selectedPlan || !newPlan) return;

  return (
    <div className="flex h-full w-full max-w-[500px] flex-col gap-8 overflow-hidden rounded-[4px] bg-gray-25 p-4 shadow-3xl">
      <div className="flex max-h-[80vh] w-full flex-col gap-4 overflow-y-auto">
        <div className="flex w-full items-center justify-between">
          <span className="text-xl font-semibold text-gray-700">Plan Change</span>
          <Button className="size-6" styleType="NONE">
            <HelpCircle />
          </Button>
        </div>

        <div className="flex flex-col gap-3">
          <div className="flex flex-col rounded-md bg-gray-50">
            <span className="px-4 py-3 text-sm font-[550] text-gray-700">
              Current Plan - {selectedPlan.title}
            </span>
            <div className="flex flex-col divide-y divide-gray-100 rounded-md bg-white shadow-sm">
              <div className="flex w-full items-center justify-between px-4 py-3">
                <span className="text-xs font-[450] text-gray-500">Billing Cycle</span>
                <span className="text-xs font-[450] text-gray-700">
                  {SubscriptionVariantTranslation[selectedPlan.duration]}
                </span>
              </div>
              <div className="flex w-full items-center justify-between px-4 py-3">
                <span className="text-xs font-[450] text-gray-500">Amount due</span>
                <span className="text-xs font-[450] text-gray-700">
                  $ {selectedPlan.priceInCents / 100}
                </span>
              </div>
              <div className="flex w-full items-center justify-between px-4 py-3">
                <span className="text-xs font-[450] text-gray-500">Stakeholder Limit</span>
                <span className="text-xs font-[450] text-gray-700">
                  {selectedPlan.stakeholdersCount}
                </span>
              </div>
            </div>
          </div>

          <div className="flex flex-col rounded-md bg-brand-25">
            <span className="px-4 py-3 text-sm font-[550] text-brand-700">
              New Plan - {newPlan.title}
            </span>
            <div className="flex flex-col divide-y divide-gray-100 rounded-md bg-white shadow-sm">
              <div className="flex w-full items-center justify-between px-4 py-3">
                <span className="text-xs font-[450] text-gray-500">Billing Cycle</span>
                <span className="text-xs font-[450] text-gray-700">
                  {SubscriptionVariantTranslation[newPlan.duration]}
                </span>
              </div>
              <div className="flex w-full items-center justify-between px-4 py-3">
                <span className="text-xs font-[450] text-gray-500">Amount due</span>
                <span className="text-xs font-[450] text-gray-700">
                  $ {newPlan.priceInCents / 100}
                </span>
              </div>
              <div className="flex w-full items-center justify-between px-4 py-3">
                <span className="text-xs font-[450] text-gray-500">Stakeholder Limit</span>
                <span className="text-xs font-[450] text-gray-700">
                  {newPlan.stakeholdersCount}
                </span>
              </div>
            </div>
          </div>

          <div className="flex flex-col rounded-md bg-forest-50">
            <span className="px-4 py-3 text-sm font-[550] text-forest-600">Payment Summary</span>
            <div className="flex flex-col divide-y divide-gray-100 rounded-md bg-white shadow-sm">
              <div className="flex w-full items-center justify-between px-4 py-3">
                <div className="flex flex-col">
                  <span className="text-xs font-[450] text-gray-500">Remaining balance</span>
                  <span className="text-label-sm font-medium text-gray-400">From current plan</span>
                </div>

                <span className="text-xs font-[450] text-gray-700">$ {balance / 100}</span>
              </div>
              <div className="flex w-full items-center justify-between px-4 py-3">
                <span className="text-xs font-[450] text-gray-500">New plan cost</span>
                <span className="text-xs font-[450] text-gray-700">
                  $ {newPlan.priceInCents / 100}
                </span>
              </div>
              <div className="flex w-full items-center justify-between px-4 py-3">
                <span className="text-xs font-[450] text-gray-500">Amount due</span>
                <span className="text-xs font-[450] text-gray-700">
                  ${' '}
                  {subscriptionStatus === SubscriptionStatus.TRIALING
                    ? 0
                    : toPositive(newPlan.priceInCents - balance) / 100}
                </span>
              </div>
            </div>
          </div>

          <div className="flex items-center gap-3 rounded-[4px] bg-gray-50 p-2">
            <InfoCircle variant={InfoCircleVariant.PLAN_CHANGE} />
            <span className="text-xs font-[450] text-gray-800">
              Your new plan starts immediately after the payment and the next billing date will be{' '}
              {renewDate && format(renewDate * 1000, 'dd-MMM-yyyy')}
            </span>
          </div>
        </div>
      </div>
      <div className="mt-4 flex h-[44px] w-full justify-end gap-3">
        <Button
          className="h-full w-fit rounded border border-gray-300 bg-white px-3 py-[6px] text-sm font-[450] text-gray-700 shadow-xs"
          onClick={handleClose}
          styleType="NONE"
          type="button"
        >
          <AppFormattedMessage id={StringKey.CANCEL} />
        </Button>
        <Button
          className="h-full w-fit px-4 py-[6px] text-sm font-[550] text-white"
          isLoading={isPending}
          onClick={() => {
            handleChangePlan(newPlan);
            handleClose();
          }}
          type="button"
        >
          Confirm & Pay
        </Button>
      </div>
    </div>
  );
};
