import React, { FC } from 'react';
import { Control } from 'react-hook-form';
import { twMerge } from 'tailwind-merge';

import { ChevronDownIcon } from '../../../assets/icons';
import { AppFormattedMessage } from '../../../components/AppFormattedMessage';
import {
  ComboboxButton,
  ComboboxOptions,
  FormCombobox,
  FormComboboxInput,
  FormComboboxOption,
} from '../../../components/Combobox';
import { useLocale } from '../../../hooks';
import { StringKey } from '../../../lang';
import { arrayOfStakeholderTypeTranslation } from '../../../translations/stakeholderTranslation';
import { FormSchema } from './Validation';

export type StakeholderTypeItemsListProps = {
  control: Control<FormSchema>;
};

const StakeholderTypeItemsList: FC<StakeholderTypeItemsListProps> = ({ control }) => {
  return (
    <>
      {arrayOfStakeholderTypeTranslation.map(([stakeholderTypeKey, stakeholderType]) => (
        <FormComboboxOption
          control={control}
          key={stakeholderTypeKey}
          name="stepOne.stakeholderType"
          value={stakeholderTypeKey as any}
        >
          <span className="text-sm font-normal text-gray-700">
            <AppFormattedMessage id={stakeholderType} />
          </span>
        </FormComboboxOption>
      ))}
    </>
  );
};

export type StakeholderTypeComboboxProps = {
  control: Control<FormSchema>;
  onSelect?: () => void;
  disabled: boolean;
};

const StakeholderTypeCombobox: FC<StakeholderTypeComboboxProps> = ({
  control,
  onSelect,
  disabled,
}: StakeholderTypeComboboxProps) => {
  const { messagesLocale } = useLocale();
  return (
    <FormCombobox
      className="relative"
      control={control}
      name="stepOne.stakeholderType"
      onSelect={() => onSelect?.()}
    >
      <div className="relative">
        <FormComboboxInput
          className={'absolute z-0 bg-gray-900'}
          control={control}
          customValue={(value) => (value ? messagesLocale[value] : value)}
          icon={<ChevronDownIcon className={twMerge('mt-3', disabled && 'hidden')} />}
          name="stepOne.stakeholderType"
          placeholder={<AppFormattedMessage id={StringKey.STAKEHOLDER_TYPE} />}
          readOnly
          wrapperClassName={disabled && 'border-none'}
        />
        <ComboboxButton className="absolute left-0 top-0 z-1 h-full w-full" />
      </div>
      <ComboboxOptions className="w-full">
        <StakeholderTypeItemsList control={control} />
      </ComboboxOptions>
    </FormCombobox>
  );
};

export default StakeholderTypeCombobox;
