import { MutateOptions } from '@tanstack/react-query';
import React, { FC, useState } from 'react';
import { toast } from 'react-toastify';

import { AlertDialogWrapper } from '../../../../components/AlertDialog';
import { AppFormattedMessage } from '../../../../components/AppFormattedMessage';
import { CloseModalButton } from '../../../../components/CloseModalButton';
import { Sheet, SheetContent } from '../../../../components/Sheet';
import { HttpException } from '../../../../exceptions';
import { AnalyticHooks, Stakeholder, useLocale, useModalState } from '../../../../hooks';
import { StringKey } from '../../../../lang';
import { PossibleAction } from '../../../../types/google-analytics-types';
import { CompanyStakeholderDto, HumanStakeholderDto } from '../../../../types/stakeholderTypes';
import UpdateStakeholderModal from '../../modals/UpdateStakeholderModal';
import { EditStakeholderVariant } from '../type';
import EditStakeholderForm from './EditStakeholderForm';

export type EditStakeholderModalProps = {
  isOpenModal: boolean;
  setClose: () => void;
  companyId: string;
  stakeholderId: string;
  invalidateQuery: () => void;
  isDefaultEditing: boolean;
  isDemo: boolean;
  onEditClick: (props: { id: string; step: EditStakeholderVariant }) => void;
  step: EditStakeholderVariant;
};

const EditStakeholderModal: FC<EditStakeholderModalProps> = ({
  isOpenModal,
  setClose,
  companyId,
  stakeholderId,
  invalidateQuery,
  isDefaultEditing,
  isDemo,
  onEditClick,
  step,
}) => {
  const { stakeholder, isLoading } = Stakeholder.useStakeholder({ companyId, stakeholderId });

  const editStakeholderTracker = AnalyticHooks.useClick(PossibleAction.EDIT_STAKEHOLDER);
  const { update } = Stakeholder.useUpdate();
  const { messagesLocale } = useLocale();

  const [context, setContext] = useState<{
    variables: CompanyStakeholderDto | HumanStakeholderDto;
    options:
      | MutateOptions<unknown, HttpException, CompanyStakeholderDto | HumanStakeholderDto, unknown>
      | undefined;
  }>();

  const { isOpen, handleOpenModal, handleSuccessModal, handleDismissModal, toggler } =
    useModalState({
      onSuccess: () => {
        if (!context) return;
        const { options, variables } = context;

        update(
          { companyId, stakeholderId, data: variables },
          {
            onSuccess: (data, variables, context) => {
              editStakeholderTracker();
              toast.success(messagesLocale[StringKey.STAKEHOLDER_UPDATE_TOAST]);
              options?.onSuccess?.(data, variables.data, context);
              invalidateQuery();
              setClose();
            },
          },
        );
      },
    });
  if (stakeholderId && (isLoading || !stakeholder)) return <></>;

  return (
    <Sheet open={isOpenModal}>
      <SheetContent
        className="w-full max-w-[485px] border-transparent bg-transparent p-2 shadow-none"
        onInteractOutside={setClose}
        side="RIGHT"
      >
        <AlertDialogWrapper control={{ open: isOpen, onOpenChange: toggler }}>
          <UpdateStakeholderModal onDismiss={handleDismissModal} onSuccess={handleSuccessModal} />
        </AlertDialogWrapper>

        <div className="flex h-full w-full flex-col overflow-hidden overflow-y-auto rounded-lg border-[1px] border-gray-300 bg-gray-100 pb-4">
          <div className="flex h-fit w-full items-center justify-between bg-white px-6 py-3">
            <span className="text-xl font-[550] text-gray-700">
              {isDefaultEditing ? (
                <AppFormattedMessage id={StringKey.EDIT_STAKEHOLDER} />
              ) : (
                'View Stakeholder'
              )}
            </span>
            <CloseModalButton onClose={setClose} />
          </div>
          <EditStakeholderForm
            defaultData={stakeholder}
            isDemo={isDemo}
            isEditing={isDefaultEditing}
            mutateStakeholder={(variables, options) => {
              setContext({ variables, options });
              handleOpenModal();
            }}
            onEditClick={onEditClick}
            setCloseModal={setClose}
            stakeholderId={stakeholderId || ''}
            step={step}
          />
        </div>
      </SheetContent>
    </Sheet>
  );
};

export default EditStakeholderModal;
