import { useInfiniteQuery } from '@tanstack/react-query';
import { useMemo } from 'react';

import { BackendRoute } from '../../config';
import { QueryKey } from '../../constants';
import { ApiService } from '../../services';
import { SharePlanType } from '../../types/pool-plans.types';
import { Stakeholder } from '../../types/stakeholderTypes';

export const useStakeholdersForExercise = ({
  companyId,
  numberOfFetchedItems = 50,
  enabled = true,
  search,
  planType,
}: {
  numberOfFetchedItems?: number;
  companyId: string;
  enabled?: boolean;
  search: string;
  planType: SharePlanType;
}) => {
  const {
    data: stakeholdersData,
    fetchNextPage,
    hasNextPage,
    isLoading,
  } = useInfiniteQuery<{
    stakeholders: Stakeholder[];
    totalPages: number;
  }>({
    queryKey: [
      QueryKey.GET_STAKEHOLDERS_FOR_EXERCISE,
      {
        search,
        planType,
      },
    ],
    queryFn: ({ pageParam = 0 }) => {
      return ApiService.get(
        { endpoint: BackendRoute.COMPANIES, routePath: [companyId, 'stakeholders-for-exercise'] },
        {
          queryParams: {
            take: numberOfFetchedItems,
            skip: pageParam,
            search,
            planType,
          },
        },
      );
    },
    enabled: !!companyId && enabled,
    initialPageParam: 0,
    refetchOnReconnect: true,
    getNextPageParam: (lastPage, allPages) =>
      lastPage.totalPages > allPages.length ? allPages.length * numberOfFetchedItems : undefined,
  });
  const stakeholders = useMemo(
    () =>
      stakeholdersData?.pages?.reduce<Stakeholder[]>((acc, page) => {
        return [...acc, ...page.stakeholders];
      }, []),
    [stakeholdersData?.pages],
  );

  return {
    stakeholders: stakeholders || [],
    hasNextPage,
    fetchNextPage,
    stakeholdersData,
    isEmpty: stakeholders?.length === 0,
    numberOfFetchedItems,
    isLoading,
  };
};
