import { FC, useCallback, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { twMerge } from 'tailwind-merge';

import { BuildingIcon, ChevronDownIcon } from '../../assets/icons';
import { Company } from '../../hooks';
import { StringKey } from '../../lang';
import { companyTypeTranslation } from '../../translations/companyTranslation';
import { CompanyFilter } from '../../types/companyTypes';
import { AppFormattedMessage } from '../AppFormattedMessage';
import { Combobox, ComboboxButton, ComboboxOption, ComboboxOptions } from '../Combobox';

type CompanySelectProps = {
  noAdaptive?: boolean;
  className?: string;
};

const CompanySelect: FC<CompanySelectProps> = ({ noAdaptive = false, className }) => {
  const navigate = useNavigate();
  const [selectedCompanyId, setSelectedCompanyId] = useState('');
  const { selectedCompany, invalidateQuery: invalidateSelectedCompanyQuery } =
    Company.useSelected();
  const { companies, invalidateQuery: invalidateCompaniesQuery } = Company.useCompanies({
    filter: CompanyFilter.ACTIVE,
  });

  useEffect(() => {
    if (!selectedCompany) return;

    setSelectedCompanyId(selectedCompany.id);
  }, [selectedCompany]);

  const invalidateQuery = useCallback(() => {
    invalidateSelectedCompanyQuery();
    invalidateCompaniesQuery();
  }, [invalidateCompaniesQuery, invalidateSelectedCompanyQuery]);

  const { setSelected: setSelectedCompany } = Company.useSetSelected();

  const handleSelect = useCallback(
    (value: null | string) => {
      setSelectedCompanyId(value || selectedCompanyId);
      setSelectedCompany(value || '', { onSuccess: invalidateQuery });
      window.location.reload();
    },
    [invalidateQuery, selectedCompanyId, setSelectedCompany],
  );
  return (
    <Combobox onChange={handleSelect} value={selectedCompanyId}>
      {({ open, value }) => (
        <div
          className={twMerge(
            'relative w-full min-w-[105px] max-w-[280px] font-inter lg:w-full',
            className,
          )}
        >
          <ComboboxButton
            className={twMerge(
              'flex h-full w-full items-center justify-between gap-2 rounded-t bg-gray-50 px-2 py-1.5 lg:border-b',
              open ? 'border-brand-700' : 'border-gray-700',
            )}
          >
            <div className="flex w-full items-center gap-1 overflow-hidden">
              {selectedCompany?.logoImgSrc ? (
                <img
                  alt=""
                  className="size-4 shrink-0 overflow-hidden rounded-full object-cover lg:size-5"
                  src={selectedCompany?.logoImgSrc}
                />
              ) : (
                <div className="size-5 shrink-0 rounded-full bg-gray-200"></div>
              )}

              <div className="w-full shrink truncate text-start text-sm font-[450] text-gray-700">
                {selectedCompany?.name}
              </div>
            </div>

            <div
              className={twMerge(
                'flex items-center gap-2',
                !noAdaptive && selectedCompany?.isDemo && 'max-lg:hidden',
              )}
            >
              {selectedCompany?.isDemo ? (
                <div className="max-w-24 truncate rounded-2xl bg-[#F79009] px-2 py-[2px] text-label-sm font-[550] text-white">
                  Demo
                </div>
              ) : (
                <div className="max-w-24 truncate rounded-2xl bg-brand-50 px-2 py-[2px] text-label-sm font-[550] text-brand-700 max-lg:hidden">
                  {selectedCompany?.type && (
                    <AppFormattedMessage id={companyTypeTranslation[selectedCompany.type]} />
                  )}
                </div>
              )}
              <ChevronDownIcon />
            </div>
          </ComboboxButton>
          <ComboboxOptions
            className={
              'absolute right-0 z-5 flex w-full min-w-[230px] translate-y-[5px] flex-col gap-1.5 rounded bg-white p-2 shadow-xl'
            }
          >
            {companies.map((item) => (
              <ComboboxOption
                className={twMerge(
                  'flex w-full shrink-0 cursor-pointer justify-between overflow-hidden rounded px-2 py-[8.5px] hover:bg-brand-25',
                  value === item.id ? 'bg-brand-25' : '',
                )}
                key={`company-${item.id}`}
                value={item.id}
              >
                <div className="flex w-full items-center justify-between gap-1">
                  <div className="flex w-full items-center gap-1.5 overflow-hidden">
                    {item.logoImgSrc ? (
                      <img
                        alt=""
                        className="size-5 shrink-0 rounded-full object-cover"
                        src={item.logoImgSrc}
                      />
                    ) : (
                      <div className="size-5 shrink-0 rounded-full bg-gray-200"></div>
                    )}

                    <div className="truncate text-nowrap text-sm font-normal text-gray-800">
                      {item.name}
                    </div>
                  </div>

                  {item.id === value && (
                    <svg
                      className="h-fit w-fit shrink-0"
                      fill="none"
                      height="17"
                      viewBox="0 0 16 17"
                      width="16"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <circle cx="8" cy="8.5" fill="#2565C8" r="8" />
                      <path
                        d="M11.2725 6.31885L6.77251 10.8188L4.72705 8.77339"
                        stroke="white"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="1.3"
                      />
                    </svg>
                  )}
                </div>
              </ComboboxOption>
            ))}

            <div
              className={twMerge('h-[1px] w-full bg-gray-200', companies.length < 1 && 'hidden')}
            />

            <button
              className="flex items-center gap-1.5 text-nowrap p-2 font-inter text-sm font-[450] text-brand-700"
              onClick={() => {
                navigate('/my-companies');
              }}
            >
              <BuildingIcon />
              <div>
                <AppFormattedMessage id={StringKey.VIEW_MY_COMPANIES} />
              </div>
            </button>
          </ComboboxOptions>
        </div>
      )}
    </Combobox>
  );
};

export default CompanySelect;
