import { useQueryClient } from '@tanstack/react-query';
import { useCallback } from 'react';

import { BackendRoute } from '../../config';
import { QueryKey } from '../../constants';
import { ApiService } from '../../services';
import { Safe } from '../../types/safes.types';
import { useAppQuery } from '../useAppQuery';

export const useSafe = ({ companyId, safeId }: { companyId: string; safeId: string }) => {
  const queryClient = useQueryClient();

  const { data: safe, isLoading } = useAppQuery<Safe>({
    queryKey: [QueryKey.GET_SAFE, companyId, safeId],
    queryFn: () =>
      ApiService.get({ endpoint: BackendRoute.COMPANIES, routePath: [companyId, 'safes', safeId] }),
    enabled: !!companyId && !!safeId,
  });

  const invalidateQuery = useCallback(() => {
    queryClient.invalidateQueries({ queryKey: [QueryKey.GET_SAFE] });
  }, [queryClient]);

  return {
    invalidateQuery,
    safe,
    isLoading,
  };
};
