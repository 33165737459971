import React, { FC, ReactNode } from 'react';
import { useWatch } from 'react-hook-form';

import {
  ChevronLeftIcon,
  EventBuyBack,
  EventClassConversion,
  EventFundraising,
  EventGrant,
  EventIssueShares,
  EventSecondaries,
  EventValuation,
  UploadIcon,
} from '../../../../assets/icons';
import { AppFormattedMessage } from '../../../../components/AppFormattedMessage';
import Button from '../../../../components/Button';
import { StringKey } from '../../../../lang';
import { EventFormType } from '../../../../types/events.types';
import { ChildrenFormProps } from '../type';
import { FormSchema } from '../validation';

type EventTypeButtonProps = {
  title: string | ReactNode;
  description: string | ReactNode;
  children: ReactNode;
  handleNext: () => void;
};

const EventTypeButton: FC<EventTypeButtonProps> = ({
  title,
  description,
  children,
  handleNext,
}) => {
  return (
    <Button
      className="flex items-center justify-between gap-4 py-2"
      onClick={handleNext}
      styleType="NONE"
    >
      <div className="flex items-center gap-4">
        <div className="rounded-lg border-[1px] border-[#F2F2F2] bg-white p-[9px]">{children}</div>
        <div className="flex flex-col items-start gap-1">
          <span className="text-sm font-[550] text-[#172335]">{title}</span>
          <span className="text-label-sm font-normal text-gray-500">{description}</span>
        </div>
      </div>
      <ChevronLeftIcon className="size-4 rotate-180 transform" />
    </Button>
  );
};

export type InitialStepProps = ChildrenFormProps;

export const InitialStep: FC<InitialStepProps> = ({
  handleCloseModal,
  nextFormStep,
  control,
  setFormData,
}) => {
  const { initialStep } = useWatch<FormSchema>({ control });

  const handleNext = (value: EventFormType) => {
    setFormData('initialStep.type', value);
    nextFormStep();
  };

  initialStep;

  return (
    <>
      <div className="flex h-full flex-col gap-4 overflow-y-auto rounded-b-xl border-b border-gray-300 bg-white p-6 pt-0">
        <div className="flex flex-col gap-1 rounded-xl bg-gray-50 p-4">
          <EventTypeButton
            description={<AppFormattedMessage id={StringKey.EMPOWER_STAKEHOLDERS} />}
            handleNext={() => handleNext(EventFormType.GRANT)}
            title={<AppFormattedMessage id={StringKey.GRANT} />}
          >
            <EventGrant />
          </EventTypeButton>
          <EventTypeButton
            description={<AppFormattedMessage id={StringKey.BULK_IMPORT_GRANTS} />}
            handleNext={() => handleNext(EventFormType.BULK_IMPORT_GRANT)}
            title="Bulk Import Grant"
          >
            <UploadIcon className="size-5 rotate-180 transform" iconColor="#344054" />
          </EventTypeButton>

          <div className="h-[1px] w-full bg-gray-100" />
          <EventTypeButton
            description={<AppFormattedMessage id={StringKey.INITIATE_FUNDRAISING} />}
            handleNext={() => handleNext(EventFormType.FUNDRAISING_ROUND)}
            title="Fundraising Round"
          >
            <EventFundraising />
          </EventTypeButton>

          <div className="h-[1px] w-full bg-gray-100" />
          <EventTypeButton
            description={<AppFormattedMessage id={StringKey.DISTRIBUTE_SHARES} />}
            handleNext={() => handleNext(EventFormType.SHARE_ISSUANCE)}
            title={<AppFormattedMessage id={StringKey.ISSUE_SHARES} />}
          >
            <EventIssueShares />
          </EventTypeButton>

          <div className="h-[1px] w-full bg-gray-100" />
          <EventTypeButton
            description={<AppFormattedMessage id={StringKey.TRANSFER_SHARES} />}
            handleNext={() => handleNext(EventFormType.SECONDARIES)}
            title={<AppFormattedMessage id={StringKey.SECONDARIES} />}
          >
            <EventSecondaries />
          </EventTypeButton>

          <div className="h-[1px] w-full bg-gray-100" />
          <EventTypeButton
            description={<AppFormattedMessage id={StringKey.SHARE_BUYBACKS} />}
            handleNext={() => handleNext(EventFormType.BUYBACK)}
            title={<AppFormattedMessage id={StringKey.BUYBACK_DECREASE} />}
          >
            <EventBuyBack />
          </EventTypeButton>
          <EventTypeButton
            description={<AppFormattedMessage id={StringKey.CONVERT_SHARE_CLASSES} />}
            handleNext={() => handleNext(EventFormType.CONVERSION)}
            title={<AppFormattedMessage id={StringKey.CLASS_CONVERSION} />}
          >
            <EventClassConversion />
          </EventTypeButton>
          <EventTypeButton
            description={<AppFormattedMessage id={StringKey.COMPANY_VALUATION} />}
            handleNext={() => handleNext(EventFormType.VALUATION)}
            title={<AppFormattedMessage id={StringKey.VALUATION} />}
          >
            <EventValuation />
          </EventTypeButton>
        </div>
      </div>

      <div className="flex h-9 w-full justify-between gap-3 px-6">
        <Button
          className="w-fit bg-transparent px-4 py-[6px] text-sm font-[450] text-gray-700"
          onClick={handleCloseModal}
          styleType="NONE"
          type="button"
        >
          <AppFormattedMessage id={StringKey.CANCEL} />
        </Button>
      </div>
    </>
  );
};
