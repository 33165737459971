import { BackendRoute } from '../../config';
import { QueryKey } from '../../constants';
import { ApiService } from '../../services';
import { PatchSafeDto } from '../../types/safes.types';
import { useAppMutation } from '../useAppMutation';

export const useUpdate = () => {
  const { mutate: update, isPending } = useAppMutation([QueryKey.PATCH_SAFE], {
    mutationFn: ({
      companyId,
      data,
      safeId,
    }: {
      data: PatchSafeDto;
      companyId: string;
      safeId: string;
    }) =>
      ApiService.patch(
        { endpoint: BackendRoute.COMPANIES, routePath: [companyId, 'safes', safeId] },
        { body: data },
      ),
  });
  return { update, isPending };
};
