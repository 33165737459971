import { FC } from 'react';
import { twMerge } from 'tailwind-merge';

import { PlusIcon } from '../../assets/icons';
import Button from '../../components/Button';
import memoImg from './../../assets/images/memo-img.png';

type EmptySafesProps = {
  title: string;
  text: string;
  showButton?: boolean;
  onClick?: () => void;
  isMyHoldingsPage?: boolean;
};

export const EmptySafes: FC<EmptySafesProps> = ({
  title,
  text,
  showButton,
  onClick,
  isMyHoldingsPage,
}) => {
  return (
    <div
      className={twMerge(
        'radial-gradient-border-yellow centered relative m-auto flex h-fit flex-col gap-6 rounded-2xl px-6 py-8 xlg:my-[176px] xlg:w-[393px] lg:my-[64px]',
        isMyHoldingsPage && 'my-0 mb-4 xlg:my-0 xlg:mb-4 lg:my-0 lg:mb-4',
      )}
    >
      <div
        className="rounded-full bg-white p-3"
        style={{
          boxShadow: '0px 0px 8px 0px #0985F721 inset',
        }}
      >
        <div
          className="centered flex size-28 items-center gap-3 rounded-full"
          style={{
            background:
              'linear-gradient(180deg, rgba(253, 113, 34, 0.13) 0%, rgba(220, 55, 3, 0.13) 100%)',
          }}
        >
          <div className="size-[56px] object-cover">
            <img alt="" src={memoImg} />
          </div>
        </div>
      </div>

      <div className="flex w-full flex-col items-center justify-center gap-2">
        <span
          className="!bg-clip-text text-xl font-semibold text-transparent"
          style={{
            background: 'linear-gradient(132.59deg, #475467 29.58%, #101828 84.27%)',
          }}
        >
          {title}
        </span>
        <span className="max-w-[257px] text-center text-label-md font-[450] text-gray-500">
          {text}
        </span>
      </div>

      {showButton && (
        <Button
          className="flex size-9 gap-1 px-4 py-[6px] max-xlg:hidden xlg:w-fit"
          onClick={onClick}
        >
          <PlusIcon />
          <span className="text-sm font-[450] text-white">Add SAFE</span>
        </Button>
      )}
      <span className="text-center text-label-md font-medium text-gray-700 xlg:hidden">
        You can use CapQuest on Desktop or Tablet to add new SAFE
      </span>
    </div>
  );
};
