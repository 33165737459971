import React, { FC, useState } from 'react';

import { InfoCircle } from '../../../../assets/icons';
import { InfoCircleVariant } from '../../../../assets/icons/InfoCircle';
import { AppFormattedMessage } from '../../../../components/AppFormattedMessage';
import { Radio, RadioGroup } from '../../../../components/RadioGroup';
import { SelectedCompanyCurrency } from '../../../../components/SelectedCompanyCurrency';
import { SnapshotHooks } from '../../../../hooks';
import { StringKey } from '../../../../lang';
import { GraphVariant, graphVariantMap } from '../../variables';
import { EmptyGraph } from './EmptyGraph';
import { EventGraph } from './EventGraph';

type GraphsDeskProps = {
  companyId: string;
};

export const GraphsDesk: FC<GraphsDeskProps> = ({ companyId }) => {
  const [selectedGraphVariant, setGraphVariant] = useState(GraphVariant.VALUATION);
  const { snapshots, isLoading } = SnapshotHooks.useSnapshots({ companyId });
  const { snapshot: currentSnapshot, isLoading: isCurrentLoading } = SnapshotHooks.useCurrent({
    companyId,
    includePhantom: true,
  });

  return (
    <div className="flex h-fit w-full flex-col gap-12 rounded-md p-6 shadow-sm">
      <div className="flex w-full items-center justify-between">
        <div className="flex flex-col">
          <span className="text-sm font-[550] text-gray-700">
            {selectedGraphVariant === GraphVariant.VALUATION ? (
              <AppFormattedMessage id={StringKey.VALUATION_HISTORY} />
            ) : (
              <AppFormattedMessage id={StringKey.OWNERSHIP_HISTORY} />
            )}
          </span>
          <span className="text-label-sm font-medium text-gray-500">
            {selectedGraphVariant === GraphVariant.OWNERSHIP_PERCENT ? (
              <AppFormattedMessage id={StringKey.BY_PERCENTAGE} />
            ) : (
              <AppFormattedMessage id={StringKey.BY_SHARES} />
            )}
          </span>
          <div className="mt-2 flex w-full items-center gap-1 border-t border-dashed border-gray-200 pt-2">
            <span className="text-label-md font-medium text-gray-500">
              {selectedGraphVariant === GraphVariant.OWNERSHIP ? (
                <AppFormattedMessage id={StringKey.NUMBER_OF_SHARES_IN_THOUSANDS} />
              ) : (
                <>
                  <AppFormattedMessage id={StringKey.CURRENCY} />: <SelectedCompanyCurrency />
                  (<AppFormattedMessage id={StringKey.MILLIONS} />)
                </>
              )}
            </span>
            <InfoCircle variant={InfoCircleVariant.EVENTS} />
          </div>
        </div>
        <div className="rounded-3xl border border-gray-200 p-1">
          <RadioGroup
            onChange={setGraphVariant}
            value={selectedGraphVariant}
            wrapperClassName="w-full grow flex-1"
          >
            {graphVariantMap.map(([graphKey, graphTitle]) => (
              <Radio
                className="h-8 rounded-[32px] border-transparent bg-transparent !px-3 !py-2 text-xs font-[450] text-gray-700 data-[checked]:font-[550] data-[checked]:text-brand-25"
                key={graphKey}
                value={graphKey}
              >
                <span className="text-nowrap">
                  <AppFormattedMessage id={graphTitle} />
                </span>
              </Radio>
            ))}
          </RadioGroup>
        </div>
      </div>
      <div className="h-[350px] w-full">
        {snapshots.length > 0 && !isLoading && !isCurrentLoading ? (
          <EventGraph
            currentSnapshot={currentSnapshot}
            snapshots={snapshots}
            variant={selectedGraphVariant}
          />
        ) : (
          <EmptyGraph variant={selectedGraphVariant} />
        )}
      </div>
    </div>
  );
};
