import { FC, useState } from 'react';

import { AppFormattedMessage } from '../../../../components/AppFormattedMessage';
import { PaginationController } from '../../../../components/PaginationController';
import { EventHooks, useFormat } from '../../../../hooks';
import { StringKey } from '../../../../lang';
import {
  eventFormType,
  EventTypeBackend,
  ShareIssuanceEventItem,
} from '../../../../types/events.types';
import { TransactionOverviewItem } from './TransactionOverviewItem';
import { TransactionTitleItem } from './TransactionTitleItem';
import { CardProps } from './type';

export const ShareIssuance: FC<CardProps> = ({ event, companyId }) => {
  const { format } = useFormat();
  const [currentPage, setCurrentPage] = useState(1);
  const { items, totalPages } = EventHooks.useTransactions<ShareIssuanceEventItem>({
    eventId: event.id,
    eventType: EventTypeBackend.SHARE_ISSUANCE,
    companyId: companyId,
    currentPage,
  });
  if (event.type !== EventTypeBackend.SHARE_ISSUANCE) return <></>;
  const { type, date, valuation, sharePrice } = event;
  return (
    <>
      {items.map(({ sharesCount, shareClass, stakeholder }, i) => (
        <div
          className="flex flex-col divide-y divide-gray-100 rounded-md shadow-sm"
          key={i + shareClass.id}
        >
          <TransactionTitleItem
            createdAt={format(date, 'dd/MM/yyyy')}
            name={stakeholder?.fullName}
          />
          <TransactionOverviewItem
            title={<AppFormattedMessage id={StringKey.TRANSACTION_TYPE} />}
            value={<AppFormattedMessage id={eventFormType[type]} />}
          />
          <TransactionOverviewItem
            title={<AppFormattedMessage id={StringKey.VALUATION_EVENT} />}
            value={valuation?.name}
          />
          <TransactionOverviewItem
            title={<AppFormattedMessage id={StringKey.SHARE_CLASS} />}
            value={shareClass?.name}
          />
          <TransactionOverviewItem
            title={<AppFormattedMessage id={StringKey.SHARES_PRICE} />}
            value={sharePrice?.toLocaleString('en-US')}
          />
          <TransactionOverviewItem
            title={<AppFormattedMessage id={StringKey.NO_OF_SHARES} />}
            value={sharesCount?.toLocaleString('en-US')}
          />
        </div>
      ))}
      <PaginationController
        className="mt-6 shrink-0"
        currentPage={currentPage}
        onClick={setCurrentPage}
        totalPages={totalPages}
      />
    </>
  );
};
