import { z } from 'zod';

import { StringKey } from '../../../lang';
import { getTranslation } from '../../../utils/getTranslation';

export const MAX_FILE_SIZE = 1024 * 1024 * 10;

export const ACCEPTED_FILE_MIME_TYPES = [
  'application/pdf',
  'application/msword',
  'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
  'application/vnd.ms-excel',
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  'text/csv',
  'image/png',
  'image/jpeg',
];

export const fileSchemaMessage = getTranslation(StringKey.FILE_SIZE_TOO_LARGE);

const commonStock = z.object({
  stockItems: z
    .array(
      z
        .object({
          stakeholder: z.object({
            fullName: z
              .string()
              .trim()
              .min(1, { message: getTranslation(StringKey.REQUIRED) }),
            id: z
              .string()
              .trim()
              .min(1, { message: getTranslation(StringKey.REQUIRED) }),
          }),
          grant: z.object({
            id: z
              .string()
              .trim()
              .min(1, { message: getTranslation(StringKey.REQUIRED) }),
            exercisedCount: z.coerce.number().min(0),
            vestedSharesCount: z.coerce.number(),
            grantItem: z.object({
              plan: z.object({
                strikePrice: z.coerce.number(),
                conversionRatio: z.coerce.number(),
                pool: z.object({
                  shareClass: z.object({
                    name: z
                      .string()
                      .trim()
                      .min(1, { message: getTranslation(StringKey.REQUIRED) }),
                  }),
                }),
              }),
            }),
          }),
          date: z.coerce.date(),
          exercised: z.coerce.number().min(1, { message: getTranslation(StringKey.REQUIRED) }),
        })
        .refine(
          ({ exercised, grant }) => {
            return (
              exercised <=
              grant.vestedSharesCount / (grant.grantItem.plan.conversionRatio || 1) -
                grant.exercisedCount / (grant.grantItem.plan.conversionRatio || 1)
            );
          },
          {
            message: getTranslation(StringKey.OPTIONS_MUST_BE_LESS_THAN_BALANCE),
            path: ['exercised'],
          },
        ),
    )
    .min(1, { message: getTranslation(StringKey.REQUIRED) }),
});

const sar = z.object({
  sarItems: z
    .array(
      z
        .object({
          stakeholder: z.object({
            fullName: z
              .string()
              .trim()
              .min(1, { message: getTranslation(StringKey.REQUIRED) }),
            id: z
              .string()
              .trim()
              .min(1, { message: getTranslation(StringKey.REQUIRED) }),
          }),
          grant: z.object({
            id: z
              .string()
              .trim()
              .min(1, { message: getTranslation(StringKey.REQUIRED) }),
            exercisedCount: z.coerce.number().min(0),
            vestedSharesCount: z.coerce.number(),
            grantItem: z.object({
              plan: z.object({
                sarBasePrice: z.coerce.number(),
                pool: z.object({
                  shareClass: z.object({
                    name: z
                      .string()
                      .trim()
                      .min(1, { message: getTranslation(StringKey.REQUIRED) }),
                  }),
                }),
              }),
            }),
          }),
          date: z.coerce.date(),
          sarCount: z.coerce.number().min(1, { message: getTranslation(StringKey.REQUIRED) }),
        })
        .refine(
          ({ sarCount, grant }) => {
            return sarCount <= grant.vestedSharesCount - grant.exercisedCount;
          },
          {
            message: getTranslation(StringKey.SARS_MUST_BE_LESS_THAN_BALANCE),
            path: ['sarCount'],
          },
        ),
    )
    .min(1, { message: getTranslation(StringKey.REQUIRED) }),
});

const phantom = z.object({
  phantomItems: z
    .array(
      z
        .object({
          stakeholder: z.object({
            fullName: z
              .string()
              .trim()
              .min(1, { message: getTranslation(StringKey.REQUIRED) }),
            id: z
              .string()
              .trim()
              .min(1, { message: getTranslation(StringKey.REQUIRED) }),
          }),
          grant: z.object({
            id: z
              .string()
              .trim()
              .min(1, { message: getTranslation(StringKey.REQUIRED) }),
            exercisedCount: z.coerce.number().min(0),
            vestedSharesCount: z.coerce.number(),
          }),
          date: z.coerce.date(),
          sharePrice: z.coerce.number().min(1, { message: getTranslation(StringKey.REQUIRED) }),
          phantomShares: z.coerce.number().min(1, { message: getTranslation(StringKey.REQUIRED) }),
        })
        .refine(
          ({ phantomShares, grant }) => {
            return phantomShares <= grant.vestedSharesCount - grant.exercisedCount;
          },
          {
            message: getTranslation(StringKey.SHARES_MUST_BE_LESS_THAN_BALANCE),
            path: ['phantomShares'],
          },
        ),
    )
    .min(1, { message: getTranslation(StringKey.REQUIRED) }),
});

const hurdle = z.object({
  hurdleItems: z
    .array(
      z
        .object({
          stakeholder: z.object({
            fullName: z
              .string()
              .trim()
              .min(1, { message: getTranslation(StringKey.REQUIRED) }),
            id: z
              .string()
              .trim()
              .min(1, { message: getTranslation(StringKey.REQUIRED) }),
          }),
          grant: z.object({
            id: z
              .string()
              .trim()
              .min(1, { message: getTranslation(StringKey.REQUIRED) }),
            exercisedCount: z.coerce.number().min(0),
            vestedSharesCount: z.coerce.number(),
            grantItem: z.object({
              plan: z.object({
                hardleValue: z.coerce.number(),
                pool: z.object({
                  shareClass: z.object({
                    name: z
                      .string()
                      .trim()
                      .min(1, { message: getTranslation(StringKey.REQUIRED) }),
                  }),
                }),
              }),
            }),
          }),
          date: z.coerce.date(),
          issuedShares: z.coerce.number().min(1, { message: getTranslation(StringKey.REQUIRED) }),
        })
        .refine(
          ({ issuedShares, grant }) => {
            return issuedShares <= grant.vestedSharesCount - grant.exercisedCount;
          },
          {
            message: getTranslation(StringKey.SHARES_MUST_BE_LESS_THAN_BALANCE),
            path: ['issuedShares'],
          },
        ),
    )
    .min(1, { message: getTranslation(StringKey.REQUIRED) }),
});

const bspce = z.object({
  bspceItems: z
    .array(
      z
        .object({
          stakeholder: z.object({
            fullName: z
              .string()
              .trim()
              .min(1, { message: getTranslation(StringKey.REQUIRED) }),
            id: z
              .string()
              .trim()
              .min(1, { message: getTranslation(StringKey.REQUIRED) }),
          }),
          grant: z.object({
            id: z
              .string()
              .trim()
              .min(1, { message: getTranslation(StringKey.REQUIRED) }),
            exercisedCount: z.coerce.number().min(0),
            vestedSharesCount: z.coerce.number(),
            grantItem: z.object({
              plan: z.object({
                strikePrice: z.coerce.number(),
                conversionRatio: z.coerce.number(),
                pool: z.object({
                  shareClass: z.object({
                    name: z
                      .string()
                      .trim()
                      .min(1, { message: getTranslation(StringKey.REQUIRED) }),
                  }),
                }),
              }),
            }),
          }),
          date: z.coerce.date(),
          exercised: z.coerce.number().min(1, { message: getTranslation(StringKey.REQUIRED) }),
        })
        .refine(
          ({ exercised, grant }) => {
            return (
              exercised <=
              grant.vestedSharesCount / (grant.grantItem.plan.conversionRatio || 1) -
                grant.exercisedCount / (grant.grantItem.plan.conversionRatio || 1)
            );
          },
          {
            message: getTranslation(StringKey.OPTIONS_MUST_BE_LESS_THAN_BALANCE),
            path: ['exercised'],
          },
        ),
    )
    .min(1, { message: getTranslation(StringKey.REQUIRED) }),
});

const rsa = z.object({
  rsaItems: z
    .array(
      z
        .object({
          stakeholder: z.object({
            fullName: z
              .string()
              .trim()
              .min(1, { message: getTranslation(StringKey.REQUIRED) }),
            id: z
              .string()
              .trim()
              .min(1, { message: getTranslation(StringKey.REQUIRED) }),
          }),
          grant: z.object({
            id: z
              .string()
              .trim()
              .min(1, { message: getTranslation(StringKey.REQUIRED) }),
            exercisedCount: z.coerce.number().min(0),
            vestedSharesCount: z.coerce.number(),
            grantItem: z.object({
              plan: z.object({
                purchasePrice: z.coerce.number(),
                conversionRatio: z.coerce.number(),
                pool: z.object({
                  shareClass: z.object({
                    name: z
                      .string()
                      .trim()
                      .min(1, { message: getTranslation(StringKey.REQUIRED) }),
                  }),
                }),
              }),
            }),
          }),
          date: z.coerce.date(),
          exercised: z.coerce.number().min(1, { message: getTranslation(StringKey.REQUIRED) }),
        })
        .refine(
          ({ exercised, grant }) => {
            return (
              exercised <=
              grant.vestedSharesCount / (grant.grantItem.plan.conversionRatio || 1) -
                grant.exercisedCount / (grant.grantItem.plan.conversionRatio || 1)
            );
          },
          {
            message: getTranslation(StringKey.OPTIONS_MUST_BE_LESS_THAN_BALANCE),
            path: ['exercised'],
          },
        ),
    )
    .min(1, { message: getTranslation(StringKey.REQUIRED) }),
});

const warrantExercise = z.object({
  warrantExerciseItems: z
    .array(
      z
        .object({
          stakeholder: z.object({
            fullName: z
              .string()
              .trim()
              .min(1, { message: getTranslation(StringKey.REQUIRED) }),
            id: z
              .string()
              .trim()
              .min(1, { message: getTranslation(StringKey.REQUIRED) }),
          }),
          grant: z.object({
            id: z
              .string()
              .trim()
              .min(1, { message: getTranslation(StringKey.REQUIRED) }),
            exercisedCount: z.coerce.number().min(0),
            vestedSharesCount: z.coerce.number(),
            grantItem: z.object({
              plan: z.object({
                warrantPrice: z.coerce.number(),
                conversionRatio: z.coerce.number(),
                pool: z.object({
                  shareClass: z.object({
                    name: z
                      .string()
                      .trim()
                      .min(1, { message: getTranslation(StringKey.REQUIRED) }),
                  }),
                }),
              }),
            }),
          }),
          date: z.coerce.date(),
          exercised: z.coerce.number().min(1, { message: getTranslation(StringKey.REQUIRED) }),
        })
        .refine(
          ({ exercised, grant }) => {
            return (
              exercised <=
              grant.vestedSharesCount / (grant.grantItem.plan.conversionRatio || 1) -
                grant.exercisedCount / (grant.grantItem.plan.conversionRatio || 1)
            );
          },
          {
            message: getTranslation(StringKey.OPTIONS_MUST_BE_LESS_THAN_BALANCE),
            path: ['exercised'],
          },
        ),
    )
    .min(1, { message: getTranslation(StringKey.REQUIRED) }),
});

const warrantExpire = z.object({
  warrantExpireItems: z
    .array(
      z
        .object({
          stakeholder: z.object({
            fullName: z
              .string()
              .trim()
              .min(1, { message: getTranslation(StringKey.REQUIRED) }),
            id: z
              .string()
              .trim()
              .min(1, { message: getTranslation(StringKey.REQUIRED) }),
          }),
          grant: z.object({
            id: z
              .string()
              .trim()
              .min(1, { message: getTranslation(StringKey.REQUIRED) }),
            exercisedCount: z.coerce.number().min(0),
            vestedSharesCount: z.coerce.number(),
          }),
          date: z.coerce.date(),
          expired: z.coerce.number().min(1, { message: getTranslation(StringKey.REQUIRED) }),
        })
        .refine(
          ({ expired, grant }) => {
            return expired <= grant.vestedSharesCount;
          },
          {
            message: 'Warrants must be less than or equal to balance',
            path: ['expired'],
          },
        ),
    )
    .min(1, { message: getTranslation(StringKey.REQUIRED) }),
});

export const stepOne = z.object({
  commonStock: commonStock.optional(),
  sar: sar.optional(),
  phantom: phantom.optional(),
  hurdle: hurdle.optional(),
  bspce: bspce.optional(),
  rsa: rsa.optional(),
  warrantExercise: warrantExercise.optional(),
  warrantExpire: warrantExpire.optional(),
});

export const stepTwo = z
  .object({
    additionalNotes: z
      .string()
      .max(3000, getTranslation(StringKey.MAXIMUM_CHARACTERS, { count: 3000 }))
      .optional(),
    files: z
      .object({
        docLink: z.string(),
        loadProgress: z.number().max(100),
        abort: z.function(),
        id: z.string(),
        doc: z
          .custom<File>()
          .or(
            z.object({
              size: z.number(),
              type: z.string(),
              name: z.string(),
            }),
          )
          .refine(
            ({ type }) => ACCEPTED_FILE_MIME_TYPES.includes(type),
            getTranslation(StringKey.FILE_FORMAT_NOT_SUPPORTED),
          )
          .refine(({ size }) => size <= MAX_FILE_SIZE, fileSchemaMessage),
      })
      .array()

      .optional(),
  })
  .optional();

export const formSchema = z.object({
  stepOne,
  stepTwo,
});

export type FormSchema = z.infer<typeof formSchema>;
