import { FC } from 'react';
import { useParams } from 'react-router-dom';

import { ArrowIcon } from '../../assets/icons';
import { AppFormattedMessage } from '../../components/AppFormattedMessage';
import Button from '../../components/Button';
import Loader from '../../components/Loader';
import { Company, SnapshotHooks, useLocale, useNavigate } from '../../hooks';
import { StringKey } from '../../lang';
import { capTableStore } from '../../storage/capTableStore';
import { stakeholderTypeTranslation } from '../../translations/stakeholderTranslation';
import { PageRoute } from '../../types/pageTypes';
import { StakeholderType } from '../../types/stakeholderTypes';
import { DetailsItem } from './DetailsItem';
import { latestSnapshotId } from './variables';

export const CapTableMobileStakeholderDetailsPage: FC = () => {
  const { stakeholderType, id } = useParams<{ stakeholderType: StakeholderType; id: string }>();

  const { messagesLocale } = useLocale();
  const { selectedCompanyId } = Company.useSelected();
  const { isLoading, snapshots } = SnapshotHooks.useSnapshots({ companyId: selectedCompanyId });
  const { snapshot: currentSnapshot } = SnapshotHooks.useCurrent({ companyId: selectedCompanyId });
  const { snapshot: snapshotByDate, isLoading: isLoadingSnapshotByDate } =
    SnapshotHooks.useSnapshotByDate({
      companyId: selectedCompanyId,
      date: capTableStore.get()?.customCapTableDate,
    });

  const snapshot =
    latestSnapshotId === id
      ? currentSnapshot
      : snapshotByDate || snapshots.find(({ valuation }) => valuation.id === id) || snapshots[0];

  const navigate = useNavigate(PageRoute.CAP_TABLE);

  if (isLoading || isLoadingSnapshotByDate) return <Loader />;

  return (
    <div className="flex w-full flex-col gap-4 rounded-md bg-white px-3 py-4 shadow-sm">
      <div className="flex cursor-pointer gap-2 pt-4 text-xs font-[450] text-gray-400">
        <span className="text-brand-700" onClick={() => navigate()}>
          <AppFormattedMessage id={StringKey.CAP_TABLE} />
        </span>
        <span>/</span>
        <span className="text-brand-700" onClick={() => navigate([id || ''])}>
          <AppFormattedMessage id={StringKey.POSITIONS} />
        </span>
        <span>/</span>
        <span>
          {stakeholderType && messagesLocale[stakeholderTypeTranslation[stakeholderType]]}
        </span>
      </div>
      <div className="flex items-center justify-between border-y border-gray-100 py-3">
        <div className="flex items-center gap-2">
          <Button
            className="centered size-9 rounded-full"
            onClick={() => navigate([id || ''])}
            style={{
              boxShadow: '0px 0px 24px 0px #00000014',
              border: '1px solid #F2F2F2',
            }}
            styleType="NONE"
          >
            <ArrowIcon />
          </Button>
          <div className="flex flex-col">
            <span className="text-base font-semibold text-gray-700">
              {stakeholderType && messagesLocale[stakeholderTypeTranslation[stakeholderType]]}
            </span>
            <span className="text-xs font-[450] text-gray-400">
              <AppFormattedMessage id={StringKey.DETAILS} />
            </span>
          </div>
        </div>
      </div>
      {snapshot?.stats?.stakeholders
        ?.filter(
          ({ type, shareClasses }) =>
            type === stakeholderType &&
            shareClasses.reduce((sharesTotal, { issued }) => sharesTotal + issued, 0) > 0,
        )
        ?.map((stakeholder) => (
          <DetailsItem
            avatar
            key={stakeholder.id}
            {...stakeholder}
            description={messagesLocale[stakeholderTypeTranslation[stakeholder.type]]}
          />
        ))}
    </div>
  );
};
