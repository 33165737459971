import { FC } from 'react';

import { AppFormattedMessage } from '../../components/AppFormattedMessage';
import { StringKey } from '../../lang';
import flashlight from './../../assets/images/flashlight.png';

export const EmptyMyHoldings: FC = () => {
  return (
    <div className="radial-gradient-border-orange centered relative m-auto my-20 flex h-fit flex-col gap-6 rounded-2xl px-6 py-8 lg:max-w-[393px]">
      <div
        className="rounded-full bg-white p-3"
        style={{
          boxShadow: '0px 0px 8px 0px #DC680321 inset',
        }}
      >
        <div
          className="centered flex size-28 items-center gap-3 rounded-full"
          style={{
            background:
              ' linear-gradient(180deg, rgba(254, 200, 75, 0.13) 0%, rgba(220, 104, 3, 0.13) 100%)',
          }}
        >
          <div className="size-[56px] object-cover">
            <img alt="" src={flashlight} />
          </div>
        </div>
      </div>

      <span
        className="!bg-clip-text text-xl font-semibold text-transparent"
        style={{
          background: 'linear-gradient(132.59deg, #475467 29.58%, #101828 84.27%)',
        }}
      >
        <AppFormattedMessage id={StringKey.NOTHING_HERE} />
      </span>
      <span className="max-w-[240px] text-center text-xs font-[450] text-gray-500">
        <AppFormattedMessage id={StringKey.HOLDINGS_INFO} />
      </span>
    </div>
  );
};
