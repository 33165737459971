import React, { FC, useCallback, useEffect } from 'react';
import {
  Control,
  FieldErrors,
  useFieldArray,
  UseFormClearErrors,
  UseFormGetValues,
  UseFormSetError,
  UseFormSetValue,
  useWatch,
} from 'react-hook-form';

import { CalendarIcon } from '../../../../assets/icons';
import { AppFormattedMessage } from '../../../../components/AppFormattedMessage';
import Button from '../../../../components/Button';
import { FormInput } from '../../../../components/Input';
import { Stakeholder, useFormat } from '../../../../hooks';
import { StringKey } from '../../../../lang';
import { EventFormType } from '../../../../types/events.types';
import { DatePicker } from '../../../PoolPlans/PoolForm/DatePicker';
import {
  FormDropDown,
  ShareClassCombobox,
  StakeholderCombobox,
  SubEventFileUpload,
} from '../Components';
import { FormSchema } from '../validation';

export type BuyBackStepOneProps = {
  setFormData: UseFormSetValue<FormSchema>;
  control: Control<FormSchema>;
  companyId: string;
  formData: UseFormGetValues<FormSchema>;
  setError: UseFormSetError<FormSchema>;
  clearErrors: UseFormClearErrors<FormSchema>;
  errors: FieldErrors<FormSchema>;
};

export const BuyBackStepOne: FC<BuyBackStepOneProps> = ({
  control,
  setFormData,
  companyId,
  clearErrors,
  errors,
  formData,
  setError,
}) => {
  const { initialStep } = useWatch<FormSchema>({ control });

  const defaultDate = new Date(+0);

  const {
    fields: buyBackItemsFields,
    append: buyBackItemsAppend,
    remove: buyBackItemsRemove,
  } = useFieldArray({
    control,
    name: 'stepOne.eventDetails.buyback.buyBackItems',
  });

  const handleRemoveBuyBackItems = useCallback(
    (index: number) => {
      buyBackItemsRemove(index);
    },
    [buyBackItemsRemove],
  );

  const handleAppendBuyBackItems = useCallback(() => {
    buyBackItemsAppend({
      date: defaultDate,
      balance: 0,
      stakeholder: { id: '', fullName: '' },
      shareClass: { id: '', name: '' },
      shares: 0,
    });
  }, [buyBackItemsAppend]);

  useEffect(() => {
    if (initialStep?.type === EventFormType.BUYBACK && buyBackItemsFields.length === 0) {
      handleAppendBuyBackItems();
    }
  }, [initialStep?.type]);

  return (
    <>
      {buyBackItemsFields.map((field, index) => (
        <BuyBackItem
          clearErrors={clearErrors}
          companyId={companyId}
          control={control}
          errors={errors}
          formData={formData}
          handleRemoveBuyBackItems={handleRemoveBuyBackItems}
          index={index}
          key={field.id}
          setError={setError}
          setFormData={setFormData}
          showRemoveButton={buyBackItemsFields.length > 1}
        />
      ))}
      <Button
        className="w-fit px-4 py-2 text-sm font-[450] text-brand-700 underline disabled:border-transparent disabled:bg-transparent"
        disabled={buyBackItemsFields.length >= 30}
        onClick={handleAppendBuyBackItems}
        styleType="NONE"
      >
        + <AppFormattedMessage id={StringKey.ADD_ANOTHER_BUYBACK} />
      </Button>
    </>
  );
};

const BuyBackItem: FC<
  BuyBackStepOneProps & {
    index: number;
    handleRemoveBuyBackItems: (index: number) => void;
    showRemoveButton: boolean;
  }
> = ({
  control,
  setFormData,
  companyId,
  index,
  handleRemoveBuyBackItems,
  showRemoveButton,
  clearErrors,
  errors: { stepOne: errors },
  formData,
  setError,
}) => {
  const { stepOne } = useWatch<FormSchema>({ control });
  const { format } = useFormat();

  const defaultDate = new Date(+0);

  const stakeholderId =
    stepOne?.eventDetails?.buyback?.buyBackItems?.[index]?.stakeholder?.id || '';
  const shareClassId = stepOne?.eventDetails?.buyback?.buyBackItems?.[index]?.shareClass?.id || '';
  const initialShares =
    stepOne?.eventDetails?.buyback?.buyBackItems?.[index]?.initialShares || undefined;
  const initialStakeholderId =
    stepOne?.eventDetails?.buyback?.buyBackItems?.[index]?.initialStakeholderId;
  const initialShareClassId =
    stepOne?.eventDetails?.buyback?.buyBackItems?.[index]?.initialShareClassId;
  const shouldAddInitialShares =
    initialShares && initialStakeholderId === stakeholderId && initialShareClassId === shareClassId;

  const { balance } = Stakeholder.useBalance({ companyId, stakeholderId, shareClassId });

  useEffect(() => {
    if (balance) {
      setFormData(
        `stepOne.eventDetails.buyback.buyBackItems.${index}.balance`,
        shouldAddInitialShares ? Number(balance) + initialShares : Number(balance),
      );
    }
  }, [balance, index, initialShares, setFormData, shouldAddInitialShares]);

  const getFullTitle = useCallback(
    (index: number) => {
      const truncateName = (name: string) => {
        return name.length > 25 ? `${name.slice(0, 25)}...` : name;
      };

      const stakeholderName =
        stepOne?.eventDetails?.buyback?.buyBackItems?.[index]?.stakeholder?.fullName;

      if (stakeholderName) {
        return `Stakeholder - ${truncateName(stakeholderName)}`;
      }

      return 'Stakeholder';
    },
    [stepOne?.eventDetails?.buyback?.buyBackItems],
  );

  const calculateBalance = useCallback(
    (index: number) => {
      if (balance) {
        const currentItem = stepOne?.eventDetails?.buyback?.buyBackItems?.[index];

        if (!currentItem) {
          return Number(balance);
        }

        const filteredItems =
          stepOne?.eventDetails?.buyback?.buyBackItems &&
          stepOne?.eventDetails?.buyback?.buyBackItems.filter((_, i) => i !== index);

        if (!filteredItems || filteredItems.length === 0) {
          return Number(balance);
        }

        const matchingItems = filteredItems.filter(
          (item) =>
            item.stakeholder?.id === currentItem.stakeholder?.id &&
            item.shareClass?.id === currentItem.shareClass?.id,
        );

        if (matchingItems.length === 0) {
          return Number(balance);
        }

        const totalShares = matchingItems.reduce((sum, item) => sum + (item.shares || 0), 0);

        return Number(balance - totalShares);
      }
      return 0;
    },
    [balance, stepOne?.eventDetails?.buyback?.buyBackItems],
  );

  const calculateAvailableShares = useCallback(
    (index: number) => {
      const shares = stepOne?.eventDetails?.buyback?.buyBackItems?.[index]?.shares || 0;
      const balance = shouldAddInitialShares
        ? calculateBalance(index) + (initialShares || 0)
        : calculateBalance(index);

      return balance - shares;
    },
    [
      calculateBalance,
      initialShares,
      shouldAddInitialShares,
      stepOne?.eventDetails?.buyback?.buyBackItems,
    ],
  );

  return (
    <FormDropDown
      handleRemove={() => handleRemoveBuyBackItems(index)}
      showRemoveButton={showRemoveButton}
      title={getFullTitle(index)}
    >
      <div className="flex w-full flex-col gap-4">
        <div className="flex w-full flex-col gap-3">
          <div className="flex w-full items-center justify-between">
            <span className="text-sm font-[450] text-gray-700">
              <AppFormattedMessage id={StringKey.EVENT_DATE} />
            </span>
            <DatePicker
              defaultMonth={
                stepOne?.eventDetails?.buyback?.buyBackItems?.[index]?.date || undefined
              }
              onSelect={(date) =>
                setFormData(`stepOne.eventDetails.buyback.buyBackItems.${index}.date`, date)
              }
              value={stepOne?.eventDetails?.buyback?.buyBackItems?.[index]?.date}
              wrapperClassName="w-[224px]"
            >
              <FormInput
                autoComplete="off"
                control={control}
                customValue={(value) => {
                  return value && new Date(value).getTime() !== defaultDate.getTime()
                    ? format(value, 'dd/MM/yyyy')
                    : '';
                }}
                disabledInput
                icon={<CalendarIcon className="mr-2 size-6" iconColor="#98A2B3" />}
                name={`stepOne.eventDetails.buyback.buyBackItems.${index}.date`}
                placeholder={<AppFormattedMessage id={StringKey.DATE} />}
                wrapperClassName="w-full"
              />
            </DatePicker>
          </div>
          <div className="flex w-full items-center justify-between">
            <span className="text-sm font-[450] text-gray-700">
              <AppFormattedMessage id={StringKey.STAKEHOLDER} />
            </span>
            <StakeholderCombobox
              companyId={companyId}
              control={control}
              name={`stepOne.eventDetails.buyback.buyBackItems.${index}.stakeholder`}
            />
          </div>
          <div className="flex w-full items-center justify-between">
            <span className="text-sm font-[450] text-gray-700">
              <AppFormattedMessage id={StringKey.SHARE_CLASS} />
            </span>
            <ShareClassCombobox
              companyId={companyId}
              control={control}
              fieldName={`stepOne.eventDetails.buyback.buyBackItems.${index}.shareClass`}
              stakeholderFromId={
                stepOne?.eventDetails?.buyback?.buyBackItems?.[index]?.stakeholder?.id
              }
            />
          </div>
          <div className="flex w-full items-center justify-between">
            <span className="text-sm font-[450] text-gray-700">
              <AppFormattedMessage id={StringKey.SHARES} />
            </span>
            <div className="flex flex-col items-start gap-2">
              <FormInput
                control={control}
                name={`stepOne.eventDetails.buyback.buyBackItems.${index}.shares`}
                numberOnly
                placeholder={<AppFormattedMessage id={StringKey.VALUE} />}
                shouldFormatNumber
                wrapperClassName="w-[224px]"
              />
              {balance && (
                <span className="text-xs text-gray-600">
                  <AppFormattedMessage id={StringKey.AVAILABLE} />{' '}
                  {Math.max(calculateAvailableShares(index), 0).toLocaleString('en-US')}
                </span>
              )}
            </div>
          </div>
        </div>
        <SubEventFileUpload
          clearErrors={clearErrors}
          errors={errors?.eventDetails?.buyback?.buyBackItems?.[index]?.files || undefined}
          field={`stepOne.eventDetails.buyback.buyBackItems.${index}.files`}
          files={stepOne?.eventDetails?.buyback?.buyBackItems?.[index]?.files || []}
          formData={formData}
          setError={setError}
          setFormData={setFormData}
          text="buyback"
        />
      </div>
    </FormDropDown>
  );
};
