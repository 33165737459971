import { FC, ReactNode, useState } from 'react';
import { twMerge } from 'tailwind-merge';

import { ChevronDownIcon } from '../../../../assets/icons';

type SafeDropDownProps = {
  title: string;
  children: ReactNode;
};
export const SafeDropDown: FC<SafeDropDownProps> = ({ title, children }) => {
  const [isOpen, setOpen] = useState(false);
  return (
    <div className="flex w-full flex-col transition-all">
      <div
        className={twMerge(
          'flex items-center justify-between bg-gray-50 px-4 py-3',
          isOpen ? 'rounded-t-md' : 'rounded-md',
        )}
      >
        <span className="text-sm font-[450] text-gray-700">{title}</span>
        <div className="flex items-center gap-4">
          <div
            className="h-fit w-fit cursor-pointer p-1 transition-colors hover:bg-gray-50"
            onClick={() => {
              setOpen((prev) => !prev);
            }}
          >
            <ChevronDownIcon
              className={twMerge('size-4', isOpen ? 'rotate-180' : '', 'transition-transform')}
              iconColor="#344054"
            />
          </div>
        </div>
      </div>
      <div
        className={twMerge(
          'grid grid-flow-row transition-all duration-200',
          isOpen
            ? 'grid-rows-[1fr] rounded-b-xl border-[1px] border-gray-100 p-4'
            : 'grid-rows-[0fr]',
        )}
      >
        <div className={twMerge(isOpen ? '' : 'overflow-hidden')}>{children}</div>
      </div>
    </div>
  );
};
