export enum ApproveHurdleColumn {
  SELECT = 'select',
  STAKEHOLDER = 'stakeholder',
  GRANT = 'grant',
  APPROVAL_DATE = 'approval-date',
  SHARE_CLASS = 'share-class',
  HURDLE = 'hurdle',
  CURRENT_VALUATION = 'current-valuation',
  ISSUED_SHARES = 'issued-shares',
}

export const approveHurdleColumnMap = Object.values(ApproveHurdleColumn);
