import React, { FC } from 'react';
import { twMerge } from 'tailwind-merge';

import { EditIcon } from '../../../../assets/icons';
import { AppFormattedMessage } from '../../../../components/AppFormattedMessage';
import Button from '../../../../components/Button';
import { SummaryItem } from '../../../../components/SummaryItem';
import { useFormat } from '../../../../hooks';
import { StringKey } from '../../../../lang';
import { antiDilutionBaseTitle } from '../../../../types/share-classes.types';
import { ChildrenFormProps, EditShareClassStepVariant } from '../type';

export type EditPreviewProps = {
  onEditClick: (step: EditShareClassStepVariant) => void;
} & ChildrenFormProps;

export const EditPreview: FC<EditPreviewProps> = ({ handleCloseModal, formData, onEditClick }) => {
  const { stepOne, stepFour, stepThree, stepTwo } = formData();
  const { format } = useFormat();
  return (
    <form className="flex h-full flex-col gap-4 overflow-hidden">
      <div className="flex h-full flex-col gap-4 overflow-y-auto rounded-b-xl border-b border-gray-300 bg-white p-6 pt-0">
        <div className="flex h-fit flex-col rounded-lg bg-gray-50">
          <div className="flex h-9 w-full items-center justify-between px-4">
            <span className="text-sm font-[550] text-gray-700">
              <AppFormattedMessage id={StringKey.GENERAL} />
            </span>
            <Button className="w-fit" onClick={() => onEditClick(1)} styleType="NONE">
              <EditIcon iconColor="#2565C8" />
            </Button>
          </div>
          <div className="flex flex-col divide-y divide-gray-100 rounded-b-lg border border-gray-100 bg-white p-4 pb-3">
            <SummaryItem
              className="pt-0"
              title={<AppFormattedMessage id={StringKey.SHARE_CLASS_NAME} />}
              value={stepOne.name}
            />

            <SummaryItem
              title={<AppFormattedMessage id={StringKey.CREATION_DATE} />}
              value={format(stepOne.creationDate, 'dd/MM/yyyy')}
            />

            <div className="flex flex-col gap-3 py-3">
              <SummaryItem
                className="py-0"
                title={<AppFormattedMessage id={StringKey.VOTING_RIGHT} />}
                value={
                  stepOne.votingRight?.enabled ? (
                    <AppFormattedMessage id={StringKey.YES} />
                  ) : (
                    <AppFormattedMessage id={StringKey.NO} />
                  )
                }
              />

              <SummaryItem
                className="py-0"
                title={<AppFormattedMessage id={StringKey.MULTIPLY_VALUE} />}
                value={stepOne.votingRight?.value || '-'}
              />
            </div>
            <div className="flex flex-col gap-3 py-3">
              <SummaryItem
                className="py-0"
                title={<AppFormattedMessage id={StringKey.CONVERSION_RATIO} />}
                value={
                  stepOne.conversionRatio?.enabled ? (
                    <AppFormattedMessage id={StringKey.YES} />
                  ) : (
                    <AppFormattedMessage id={StringKey.NO} />
                  )
                }
              />
              <SummaryItem
                className="py-0"
                title={<AppFormattedMessage id={StringKey.MULTIPLY_VALUE} />}
                value={stepOne.conversionRatio?.value || '-'}
              />
            </div>
            <SummaryItem
              className="pb-0"
              title={<AppFormattedMessage id={StringKey.DIVIDEND_RIGHT} />}
              value={
                stepOne.dividendRightEnabled ? (
                  <AppFormattedMessage id={StringKey.YES} />
                ) : (
                  <AppFormattedMessage id={StringKey.NO} />
                )
              }
            />
          </div>
        </div>
        <div className="flex h-fit flex-col rounded-lg bg-gray-50">
          <div className="flex h-9 w-full items-center justify-between px-4">
            <span
              className={twMerge(
                'text-sm font-[550] text-gray-700',
                !stepTwo?.liquidityPreferences?.enabled && 'text-gray-400',
              )}
            >
              <AppFormattedMessage id={StringKey.LIQUIDITY} />
            </span>
            <Button className="w-fit" onClick={() => onEditClick(2)} styleType="NONE">
              <EditIcon iconColor="#2565C8" />
            </Button>
          </div>
          {stepTwo?.liquidityPreferences?.enabled && (
            <div className="flex flex-col divide-y divide-gray-100 rounded-b-lg border border-gray-100 bg-white p-4 pb-3">
              <div className="flex flex-col gap-3 pb-3">
                <SummaryItem
                  className="py-0"
                  title={<AppFormattedMessage id={StringKey.SENIORITY} />}
                  value={stepTwo?.liquidityPreferences?.seniority || '-'}
                />

                <SummaryItem
                  className="py-0"
                  title={<AppFormattedMessage id={StringKey.MULTIPLY_VALUE} />}
                  value={stepTwo?.liquidityPreferences?.multiple || '-'}
                />
              </div>
              <div className="flex flex-col gap-3 pt-3">
                <SummaryItem
                  className="py-0"
                  title={<AppFormattedMessage id={StringKey.PARTICIPATING} />}
                  value={
                    stepTwo?.liquidityPreferences?.participating?.enabled ? (
                      <AppFormattedMessage id={StringKey.YES} />
                    ) : (
                      <AppFormattedMessage id={StringKey.NO} />
                    )
                  }
                />
                <SummaryItem
                  className="py-0"
                  title={<AppFormattedMessage id={StringKey.CAP_VALUE} />}
                  value={stepTwo?.liquidityPreferences?.participating?.capValue || '-'}
                />
                <SummaryItem
                  className="py-0"
                  title={<AppFormattedMessage id={StringKey.INTEREST} />}
                  value={
                    stepTwo?.liquidityPreferences?.participating?.interest
                      ? stepTwo?.liquidityPreferences?.participating?.interest + '%'
                      : '-'
                  }
                />
                <SummaryItem
                  className="py-0"
                  title={<AppFormattedMessage id={StringKey.DAYS_PER_YEAR} />}
                  value={stepTwo?.liquidityPreferences?.participating?.daysPersYear || '-'}
                />
              </div>
            </div>
          )}
        </div>

        <div className="flex h-fit flex-col rounded-lg bg-gray-50">
          <div className="flex h-9 w-full items-center justify-between px-4">
            <span
              className={twMerge(
                'text-sm font-[550] text-gray-700',
                !stepThree?.antiDilutionRights?.enabled && 'text-gray-400',
              )}
            >
              <AppFormattedMessage id={StringKey.ANTI_DILUTION_RIGHTS} />
            </span>
            <Button className="w-fit" onClick={() => onEditClick(3)} styleType="NONE">
              <EditIcon iconColor="#2565C8" />
            </Button>
          </div>
          {stepThree?.antiDilutionRights?.enabled && (
            <div className="flex flex-col divide-y divide-gray-100 rounded-b-lg border border-gray-100 bg-white p-4 pb-3">
              <SummaryItem
                className="pt-0"
                title={<AppFormattedMessage id={StringKey.BASE} />}
                value={
                  stepThree?.antiDilutionRights?.base
                    ? antiDilutionBaseTitle[stepThree?.antiDilutionRights?.base]
                    : '-'
                }
              />

              <SummaryItem
                className="pb-0"
                title={<AppFormattedMessage id={StringKey.PRE_EMPTIVE_RIGHTS} />}
                value={
                  stepThree?.antiDilutionRights?.enabled ? (
                    <AppFormattedMessage id={StringKey.YES} />
                  ) : (
                    <AppFormattedMessage id={StringKey.NO} />
                  )
                }
              />
            </div>
          )}
        </div>
        <div className="flex h-[38px] w-full items-center justify-between rounded-lg border border-gray-100 bg-white">
          <div className="flex w-full gap-3 overflow-hidden px-4 py-2">
            <span className="w-[120px] flex-shrink-0 text-xs font-[450] text-gray-500">
              <AppFormattedMessage id={StringKey.BOARD_RESOLUTION} />
            </span>
            <span
              className={twMerge(
                'max-w-[250px] truncate text-xs font-[450] text-gray-700',
                !stepFour?.doc?.name && 'text-sunbeam-400',
              )}
            >
              {stepFour?.doc?.name || <AppFormattedMessage id={StringKey.NO_FILE_ATTACHED} />}
            </span>
          </div>

          <Button className="w-fit pr-4" onClick={() => onEditClick(4)} styleType="NONE">
            <EditIcon iconColor="#2565C8" />
          </Button>
        </div>
      </div>

      <div className="flex h-9 w-full justify-start gap-3 px-6">
        <Button
          className="w-fit bg-transparent px-4 py-[6px] text-sm font-[450] text-gray-700"
          onClick={handleCloseModal}
          styleType="NONE"
          type="button"
        >
          <AppFormattedMessage id={StringKey.CANCEL} />
        </Button>
      </div>
    </form>
  );
};
