type Data = {
  title: string;
  text?: (string | string[])[];
};

export const termsSections: Data[] = [
  {
    title: 'Parties',
    text: [
      `CapQuest Software Limited, a private company incorporated in the Dubai International
Financial Centre under company registration number 9388 (“CapQuest,” “we,” “our,” or
“us”), provides the equity management platform and associated services (referred to as
the “Services”) accessible through our website, capquest.io (the “Website”) and the web
application app.capquest.io (the “Application”).`,
      `These Terms of Service (the “Terms”) form a binding agreement between: (1) CapQuest;
and (2) the entity or person on whose behalf you are acting, hereafter called the
“Customer” or “you.”`,
    ],
  },
  {
    title: 'Agreement to the terms',
    text: [
      `You, as an authorized representative of the Customer, hereby represent and warrant that
you have all the powers and authority to represent the Customer in availing any Services.
CapQuest may ask for evidence of the legal authority of the authorised representative of
the Customer, however, CapQuest shall not be responsible or liable to verify any legal
authority of the representative of the Customer.`,
      `Before accessing the Website, the Application or using any of the Services, you, as an
authorized representative of the Customer, must review, acknowledge and agree to all
terms and conditions outlined or referenced in these Terms.`,
      `These Terms govern the Customer's access to and use of the Website and the Application.
By accessing or using the Website and the Application, you affirm that you have reviewed,
understood and agreed to be bound by all provisions set forth in these Terms and the
Privacy Policy available on the Website.`,
      `A contractual agreement between CapQuest and the Customer shall be effective upon
your confirmation of acceptance of these Terms, which may occur through actions such as
registering for an account with CapQuest, selecting ‘accept’in the registration flow, signing
a document that incorporates these Terms, using the Application, paying for a subscription
and/or accessing the Services (as applicable). Acceptance of the Terms via any of these
methods shall mark the “Subscription Start Date.” Electronic signatures are accepted and
valid. By taking any of these actions on behalf of the Customer or authorizing others to do
so, you confirm that you are of legal age and duly authorized to enter into binding
agreements in the name and on the Customer’s behalf.`,
      `CapQuest reserves the right to reject or not accept a Customer in its sole discretion and no
justification need to be provided.`,
      `If you do not agree with all of these Terms, you are prohibited from using the Website, the
Application and the Services, and must discontinue any use immediately.`,
      `This agreement supersedes all prior discussions, negotiations, or communications
between CapQuest and the Customer regarding the Services, the Application and the
Website, unless expressly agreed otherwise in writing.`,
    ],
  },
  {
    title: 'Services',
    text: [
      `The Services we provide are detailed on our Website, and the specific subscription plan for
each Customer is determined by the information available in the Customer's account,
which can be accessed via the Application. The available options include:`,
      [
        `
          Free Services: A limited selection of our Services or the full extent of our Services for
          a limited period of time, accessed without cost (“Free Services”), as specified within the
          Application.`,
        `
          Paid Subscription: Access to features and Services is granted under a paid plan (“Paid
          Subscription”) with varying degrees of access dependent on the type of Paid Subscription
          selected, contingent upon payment as described in the Application. The Application will
          display comprehensive information on available Services and the associated subscription
          fees.`,
      ],
      `We may amend, change, remove or update any of the Services offered at any time via the
Application or the Website at our sole discretion.`,
      `The Customers may upgrade from the Free Services to Paid Subscription anytime by either
making the necessary payment through the Application or contacting us directly.
Conversely, the Customers can choose to downgrade from Paid Subscription to Free
Services by reaching out to us, though any subscription fees already paid shall not be
refunded.`,
      `We guarantee that the Services will be delivered with an appropriate level of skill and care,
adhering to internationally recognized standards applicable to comparable services.`,
      `We may also alter the functionality of the Services, the Application or the Website at any
time, whether to better serve our customers and users or to ensure compliance with legal
or regulatory requirements. If we introduce significant changes, we will give the Customer
at least fifteen (15) calendar days' notice, setting out the material changes, unless
circumstances make it impossible to provide notice. Should you object to these changes
(when notified), your exclusive remedy is the right to terminate the subscription or the
agreement within this notice period.`,
    ],
  },
  {
    title: 'Fees',
    text: [
      `Subscription fees and charges are determined and invoiced based on the details provided
in the Application, and all prices listed are exclusive of value added tax (VAT), unless
explicitly stated otherwise.`,
      `Payments are required in full, without any offsets, deductions, or withholdings and must be
made via specified payment methods as set out in the Application. Payments shall be
processed via payment facility provided by third party payment facility provider. We shall
not be liable for any errors, failures or other faults within the payment process or any
payment related data that is being transmitted or received during such payment process as
we have no access to such data and process, and we are not directly involved in collection
or storing any such payment related data. We do not store payment data in our Application.`,
      `All payments must be made on time. Should an invoice remain unpaid for more than
fourteen (14) calendar days past its due date, we may suspend access to the Application
and/or the Services until the outstanding amount is settled. Whilst the access to the
Application and/or the Services has been suspended, the fees shall remain payable for the

3

duration of the suspension. CapQuest shall not be liable for any inconvenience, loss or
damage that such suspension may cause to the Customer.`,
      `Except as required by applicable law, all payments, charges and subscription fees are non-
refundable.`,
      `Any disputes regarding invoiced charges or fees must be reported to CapQuest in writing
as soon as possible but no later than within thirty (30) calendar days of the invoice date, no
queries shall be entertained after such timeframe.`,
      `We reserve the right to review and adjust the fees and/or charges for the Services at our sole
discretion at any time with or without notice. Any changes to the fees or charges shall be
published on our Website or the Application and shall apply from the next renewal cycle of
the Customer’s Subscription Period. Should it become necessary to implement fee
updates outside of this schedule or it is necessary during the Customers’ Subscription
Period, we will provide the Customer at least thirty (30) calendar days' prior written notice.`,
    ],
  },
  {
    title: 'Data',
    text: [
      `In these Terms, the term “Data” refers to any information that the Customer, their users, or
any third party on behalf of the Customer imports or enters into the Application. Ownership
of this Data remains solely with the Customer. It is the Customer’s sole obligation to ensure
that the Data provided is accurate, comprehensive and current. CapQuest disclaims any
liability for errors, omissions or outdated information in the Data.`,
      `By using our Services, the Customer provides CapQuest with a non-exclusive, worldwide,
royalty-free license to use, copy, cache, store, display, and reproduce the Data. This license
is granted solely for the purpose of fulfilling our obligations under these Terms, including
maintaining and improving our Services.`,
      `CapQuest reserves the right to collect and utilize quantitative insights generated from the
use of our Services. Such Data will be aggregated and anonymized to ensure it does not
reveal the identity of any individual and shall not be considered personal data. These
insights may be leveraged for purposes like industry analysis, benchmarking, analytics,
marketing and other strategic business activities that enhance the quality and delivery of
our Services.`,
      `The Customer retains full access to their Data within the Application and has the right to
export Data in an industry-standard format at any time during the Subscription Period.
Should any technical issues in the Application prevent the Customer from exporting their
Data, CapQuest will, upon receipt of a written request, supply the Data to the Customer in
a standard format within thirty (30) working days, provided that there are no extraordinary
circumstances beyond our control that would prevent this.`,
    ],
  },
  {
    title: 'Assisted onboarding services',
    text: [
      `At the Customer's request, CapQuest may assist the Customer with onboarding the
Customer’s Data to the Application. To avail this service, the Customer shall be bound by
the terms contained within the Clause “Assisted Onboarding Services” of the Terms
(herein after the “Onboarding Terms”). The Onboarding Terms enables CapQuest to assist
with importing the Customer’s Data into the Application. To facilitate this service, the
Customer is required to provide the necessary Data in a format that meets our reasonable
specifications for the purpose of uploading the Data onto the Application. CapQuest shall
not be liable for any delay in transfer of the Data if such Data is not provided in the format
as required by CapQuest.`,
      `Once the Customer has requested the Assisted Onboarding Services, CapQuest commits
to making reasonable efforts to complete the onboarding process within sixty (60) working
days from the Subscription Start Date, defined as the “Onboarding Period”. If the
Customer is non-responsive to our requests or fails to supply the necessary Data or
cooperation during this period, the onboarding shall be deemed finalized and completed
at the end of the Onboarding Period.`,
      `Upon completion of the assisted onboarding, we will inform the Customer of the
completion and endeavour to highlight any discrepancies identified in the imported Data.
The Customer must review and, if necessary, notify us of any additional errors within twenty
(20) calendar days from the completion notification, which period shall be known as the
“Correction Period”. Should we not receive any feedback or notification within the
Correction Period, we deem that the Data contains no discrepancies, is accurate and up to
date.`,
      `Should the Customer identify and report any necessary corrections within the Correction
Period, we may, at our sole discretion, choose to address and resolve these discrepancies.
It is the Customer’s sole obligation to ensure that the Data provided for onboarding is
accurate and up to date.`,
    ],
  },
  {
    title: 'Duration & termination',
    text: [
      `For Paid Subscriptions, the term of the agreement between the Customer and CapQuest
commences from the Subscription Start Date and continues for the duration of the
selected subscription period (“Subscription Period”). Once the initial Subscription Period
ends, the subscription shall renew automatically for the same Subscription Period, unless
either party provides the other a written notice of non-renewal at least thirty (30) calendar
days prior to the end of the current Subscription Period.`,
      `In case of Free Services, the term of the agreement between the Customer and CapQuest
is commencing from the Subscription Start Date and ends when theCustomer deletes their
account from the Application. In the event that the Customer upgrades the Free Services
account to Paid Subscription, the Paid Subscription agreement shall commence from the
Paid Subscription Start Date and shall be valid for the Subscription Period.`,
      `CapQuest and the Customer, each have the right to terminate the agreement immediately
by delivering a written notice to the other party in case the other party commits a material
breach of these Terms and if such breach is not remediable or if the breach is capable of
remedy but the party in breach fails to remedy within thirty (30) calendar days of receiving
a written request to do so. Non-payment of any fees and/or charges and taxes is explicitly
recognized as a material breach. Suspension of the Services or access to the Application
shall not be a pre-requisite to termination under this clause.`,
      `In addition to any other rights of CapQuest as provided in these Terms to suspend or
terminate the subscription, the agreement or access, CapQuest may suspend access to
the Services or the Application or terminate the agreement or subscription if there is a
reasonable cause to believe that such action is necessary to safeguard our operations,
protect other customers, users, or third parties, or mitigate potential security risks.
Examples may include, but are not limited to, activities that endanger our systems, infringe
on intellectual property rights or cause other significant liability.`,
      `Suspension or termination may occur if payments remain overdue for more than fourteen
(14) calendar days or if required to comply with legal mandates or directives from
authorities. Where feasible and permissible, advance notice of any suspension may be
provided.`,
      `If the Services or access to the Application has been suspended but the agreement remains
active, the Customer shall continue to pay all applicable fees and charges during the
suspension period. The Customer is responsible for all subscription charges accrued up
until the date of termination or expiry of the Subscription Period, with no entitlement to a
refund of any prepaid amounts, even if termination is due to a material breach.`,
      `Upon the expiry or termination of a Paid Subscription, the Customer can choose to either
delete their Data from the Application or transition to the Free Services plan, governed by
these Terms. For Free Services, we reserve the right to deactivate and remove inactive
accounts after a set period, at which point the agreement with the Customer shall end
automatically with no further notice provided.`,
      `Any termination of the agreement between CapQuest and the Customer under these Terms
shall be within the meaning of mutual consent of the parties and shall not require any court
order in accordance with the applicable law.`,
    ],
  },
  {
    title: 'Intellectual property rights',
    text: [
      `All elements of the Website and the Application, including but not limited to source code,
databases, features, software, design elements, text, multimedia, and graphics
(“Content”), as well as trademarks, logos, and service marks (“Marks”), are either owned
by us, controlled by us, or provided under license. These assets are protected by copyright,
trademark, and various other intellectual property laws applicable in the jurisdictions
where we operate. The Content and the Marks are made available on the Website and
Application purely for your informational and personal use on an “AS IS” basis. Unless
explicitly allowed within these Terms, you may not copy, reproduce, aggregate, republish,
upload, display publicly, encode, translate, transmit, distribute, sell, license or otherwise
utilize any part of the Content or Marks for commercial purposes without our prior written
consent.`,
      `Upon payment of the necessary fees (if required), the Customers are granted the right to
access and use our Services, Website and the Application and to authorize their users to
do so, but strictly during the active Subscription Period and in full compliance with these
Terms.`,
      `The rights extended under this section are strictly limited to the Customer and do not
include any affiliates or related companies, unless expressly stated otherwise in the
subscription. Other than the ability to use the Services, Website and the Application as
permitted, the Customer and its users are not granted any additional rights. Specifically,
the Customers must not, and must ensure their users do not: alter, translate, duplicate, or
attempt to create derivative works from any part of the Website or the Application; engage
in reverse engineering, decompiling, disassembly, or any effort to transform the
Application’s object code back into source code; or distribute, assign, sell, rent, lease,
transmit, host, disclose, or commercially exploit the Application or any rights to use it,
unless explicitly authorized by us.`,
    ],
  },
  {
    title: 'Customer’s responsibilities',
    text: [
      `By accessing or using the Website and the Application, the Customer represents and
warrants that: (1) all registration details provided are truthful, precise, up-to-date and
comprehensive; (2) any information provided remains accurate and complete and the
Customer promptly makes any changes as needed; (3) the Customer possesses the legal
authority to agree to and abide by these Terms and enter into the binding agreement that
results from any subscription to the Services; (4) the Customer’s representative is of legal

6

age and not considered a minor under the laws of your jurisdiction; (5) the Customer shall
not employ automated or non-human means, such as bots or scripts, to access the
Website or Application; (6) the use of the Website and the Application shall never involve
unlawful or unauthorized activities; and (7) the Customer shall operate the Website and the
Application in compliance with all applicable laws and regulations.`,
      `Should you supply any information that is false, outdated, incomplete or inaccurate, we
reserve the right to suspend or terminate your agreement, subscription or account, as well
as to deny any current or future access to the Website and the Application or any of their
components.`,
      `The Customer further undertakes not to attempt or facilitate access to any part of the
Application for the purpose of developing a competing product or service. Using the
Application to assess, test or benchmark performance, functionality or availability for
competitive motives is strictly forbidden.`,
      `The Customer must ensure that each login to the Application is used by only one authorized
user. It is essential to take all reasonable measures to safeguard login credentials against
unauthorized access or disclosure. If a security breach is suspected or identified, the
Customer or its user must notify us immediately.`,
      `The Customer nor its users may introduce any malicious software or code (referred to as a
“Malware”) into the Application, nor make the Application vulnerable to security risks.
Malware for the purpose of these Terms encompasses anything that could disrupt, impair,
or negatively impact software, hardware, networks, or services, such as viruses, worms,
Trojan horses or other harmful components.`,
      `The Customer bears full responsibility and liability for all actions taken using their users’
login credentials, whether authorized or not. We disclaim any liability for loss or damage
the Customer may experience due to unauthorized access, unless it is proven direct
consequence of our intentional misconduct or breach of contractual obligations.`,
      `Our liability does not extend to delays or failures in performing our duties that result from
the Customer’s non-compliance with these Terms.`,
      `When using e-signature features within the Application, the Customer consents to
documents being signed electronically and acknowledges that these electronic signatures
are equivalent to ink signatures and are valid and binding on the Customer. It is the
Customer's duty to ensure that the Customer’s electronic signatures are legally valid,
enforceable, and admissible under the laws of their jurisdiction.`,
    ],
  },
  {
    title: 'Third-party services, websites and content',
    text: [
      `The Website may feature links to external websites ("Third-Party Websites") and host or
redirect you to a variety of content including articles, images, text, graphics, videos, music,

software and other materials that are provided by or originate from third parties ("Third-
Party Content"). We do not investigate, monitor, or verify the accuracy, relevance, or

completeness of any Third-Party Websites or Third-Party Content, and we disclaim all
responsibility for any Third-Party Websites you visit through links on our Website or for any
Third-Party Content you access, use, or install. This includes any issues related to the
content's accuracy, potential offensiveness, expressed opinions, reliability or the privacy
and other policies of such Third-Party Websites or content providers.`,
      `Our inclusion of, or linking to, Third-Party Websites or Thid Party Content should not be
interpreted as an endorsement or approval of the content, products or services provided
by those third parties. If you choose to leave our Website and visit any Third-Party Websites

7

or use Third-Party Content, you do so entirely at your own risk. It is your sole responsibility
to review any terms, policies, and data collection practices of any Third-Party Websites or
services you engage with.`,
      `We bear no responsibility for transactions you make on Third-Party Websites, as these are
exclusively between you and the external provider. You acknowledge that we do not
endorse any products or services on these websites and you agree to fully indemnify us and

hold us harmless for any damage or loss incurred from your interactions with these third-
party offerings.`,
      `Additionally, we offer optional integrations with third-party services ("Third-Party Service")
via our Application. If you decide to use these integrations, you will need to enter into
separate agreements with third-party service providers and are solely responsible for
determining their suitability for your needs. You acknowledge and accept all risks related
to the use of these Third-Party Services.`,
      `We do not control or assume liability for the functionality, availability, or performance of
Third-Party Services and expressly disclaim responsibility for any damages or liability
arising from their use, integration issues or the cessation of support for such integrations.`,
      `When you enable a Third-Party Service, you are authorizing CapQuest to allow these Third-
Party Services to access your data as needed for the integration, which may include

modifying or adding to your Data. You understand and agree that CapQuest has no control
over how these third-party providers use, disclose or handle your Data, and such Data
management will be governed solely by the agreements and terms between you and the
respective Third-Party Service provider.`,
    ],
  },
  {
    title: 'Security measures',
    text: [
      `We prioritize the security of our Application and the Data, employing robust safeguards
such as encryption to protect information during transmission and conducting regular data
backups. These measures are designed to prevent accidental loss, unauthorized access,
theft, or disclosure.`,
      `If the Customer suspects or identifies any security breach related to the Services or the
Application, it must notify us immediately. Both parties are then expected to make all
reasonable efforts to swiftly address and resolve the security issue.`,
      `We may make changes to the Services or the Application and update the software. Any
such software changes and configuration changes may be implemented automatically or
may require you to download or update the version of the software. It may also be required
to remove certain features or functionality of the Services or discontinue any aspect of the
Services or stop provision of Services completely. We accept no liability to any person for
any such changes.`,
    ],
  },
  {
    title: 'Privacy and data protection',
    text: [
      `Our Privacy Policy governs the handling of customer data, whether we are acting as a data
processor, such as when personal information is uploaded into our Application by the
Customer or an end user, or a data controller, such as when we are collecting user profile
details, tracking usage data on our Website or managing other direct data interactions. Our
Privacy Policy can be accessed on our Website.`,
      `We use commercially reasonable physical, managerial, and technical safeguards to
preserve the integrity and security of your personal information. However, we cannot
guarantee that unauthorized third parties will never be able to defeat our security measures
or use your personal information for improper purposes. You acknowledge that you provide

8

your personal information at your own risk. Once we receive your transmission of
information, we make commercially reasonable efforts to ensure the security of our
systems. However, please note that this is not a guarantee that such information may not
be accessed, disclosed, altered, or destroyed by breach of any of physical, technical, or
managerial safeguards. If we learn of a security systems breach, then we may attempt to
notify you so that you can take appropriate protective steps. We may post a notice on the
Application, if a security breach occurs.`,
      `Please remember that no data transmission over the internet can be guaranteed to be 100%
secure. As a result, whilst we strive to protect your personal information, we cannot
warrant the security of any information or Data you transmit, and you do so at your own risk.`,
      `The Customer acknowledges and agrees that in order to provide the Services, CapQuest
will require and will have access to the Customer’s (and/or its affiliates) personal data
regardless of the jurisdiction of the Customer.`,
      `The Customer represents and warrants:`,
      [
        `that it has acquired all required consents for processing of personal data and for
sharing such personal data with CapQuest for provision of the Services;`,
        `that data subjects have been informed through appropriate privacy notices about the
collection and processing of their personal data, including, but not limited to,
provision of personal data to CapQuest for intended purposes;`,
        `to be responsible for the accuracy, quality, and legality of personal data and the
means by which it was acquired from the data subjects;`,
        `and it shall provide instructions to CapQuest for processing of personal data in
compliance with applicable data protection laws to provide the Services.`,
      ],
      `Any Customers who are established within the European Union or the European Economic
Area who are subject to or whose data processing activities are subject to the General Data
Protection Regulation (GDPR), additional legal terms will apply in the form of the data
processing agreement (“DPA”) regarding processing of their personal data. Such
Customers undertake to accept and agree to the terms of the separatee DPA with
CapQuest which shall be read in conjunction with these Terms.`,
    ],
  },
  {
    title: 'Limitation of liability',
    text: [
      `These Terms, along with any incorporated documents, shall not limit or exclude any liability
of either party (or their agents or representatives) for: (i) death or personal injury caused by
negligence; (ii) fraud or fraudulent misrepresentation; (iii) intentional misconduct or gross
negligence, and (iv) any other liability that cannot legally be excluded or limited.`,
      `Subject to clause 63, the aggregate liability of CapQuest for any claims related to or arising
from these Terms and the provision of Services whether in contract, tort or otherwise shall
be limited to the total fees paid by the Customer in the last twelve (12) months preceding
the claim and if no fees were paid during such period, the liability is capped at USD 100.`,
      `Subject to clauses 63 and 64, neither party will be held liable for any indirect, incidental,
special or consequential damages or losses, including but not limited to loss of profits,
Data, business, opportunities, costs related to sourcing replacement products or services;
business interruptions, even if the possibility of such damages was known.`,
      `To the maximum extent permitted by applicable law, we disclaim all liability for any harm,
loss, cost or damage, whether wholly or partially caused by (i) delays, interruptions, or
failures in unavailability of the Website, the Application (ii) delivering the Services; or (iii)
actions taken by the Customer based on the use or reliance on our Services.`,
      `In no event shall we be liable to you for any direct or indirect damages, losses or liabilities
whatsoever resulting from any: (i) errors, mistakes, or inaccuracies of the Content; (ii)
personal injury or property damage, of any nature whatsoever, resulting from your access
to and use of the Services; (iii) any unauthorized access to or use of our secure servers
and/or any and all personal information and/or financial information stored therein; (iv) any
interruption or cessation of transmission to or from our servers; (iv) any bugs, viruses, trojan
horses, or the like, which may be transmitted to or through the Services by any third party.
The foregoing limitation of liability shall apply to the fullest extent permitted by law.`,
      `The parties acknowledge and agree that the limitations and exclusions specified in this
section of the Terms are fair and reasonable, considering all relevant circumstances.`,
    ],
  },
  {
    title: 'Indemnification',
    text: [
      `The Customer represents and warrants to CapQuest that any information and Data
provided by the Customer into the Application shall not infringe the intellectual property
rights of any third party.`,
      `CapQuest confirms that the use of the Application and the Services by the Customer does
not infringe intellectual property rights of any third party. CapQuest further agrees to
indemnify the Customer against any direct, actual and proven liabilities, damages and
reasonable costs incurred due to a claim alleging that the Application or the Services
infringe third-party intellectual property rights in the jurisdiction where we operate. This
indemnification is contingent upon the Customer fulfilling certain obligations. Specifically,
the Customer must: (i) promptly notify CapQuest in writing of any claim for which it seeks
indemnification ("IPR Claim"); (ii) allow CapQuest to manage all legal proceedings,
negotiations, and settlements related to the IPR Claim; (iii) offer reasonable support and
assistance as needed by CapQuest in handling the claim, with actual reasonable costs
reimbursed by CapQuest; and (iv) avoid making any admissions or settlements concerning
the claim without first consulting with CapQuest.`,
      `Should any IPR Claim arise, CapQuest may seek to secure continued use of the intellectual

property rights or alternatively, modify or replace the affected rights to ensure non-
infringing use while maintaining the original functionality of the Application or the Services.`,
      `The above indemnity does not apply in situations where the IPR Claim arises due to actions
or inactions of the Customer or a user of the Customer breaching these Terms or any other
terms or policy with CapQuest or if the Customer continued to use the Services or the
Application after making the IPR Claim.`,
      `The Customer agrees to defend, indemnify and hold harmless CapQuest from and against
any and all claims, damages, obligations, losses, liabilities, costs or debt, and expenses
(including but not limited to attorney’s fees) arising from the breach of these Terms by the
Customer, its representatives and any of its users.`,
    ],
  },
  {
    title: 'Warranties disclaimer',
    text: [
      `The Application is expected to function substantially as described on the Website.
Nevertheless, we shall not guarantee the accuracy, reliability, timeliness, or completeness
of the Services and we cannot assure that the Services or any integrated Third-Party
Services will always be accessible, free from errors or devoid of harmful elements.`,
      `You agree that your use of the Application and the Services shall be at your sole risk and ‘as
available’. To the fullest extent permitted by law, CapQuest, its officers, directors,
employees, representatives, and agents disclaim all warranties, express or implied, in
connection with the Services and your use thereof. CapQuest makes no warranties or

10

representations about the accuracy or completeness of the Services’ content and assumes
no liability or responsibility for any: (i) errors, mistakes, or inaccuracies of the Content; (ii)
personal injury or property damage, of any nature whatsoever, resulting from your access
to and use of the Services; (iii) unauthorized access to or use of our servers and/or any and
all personal information and/or financial information stored therein; (iv) interruption or
cessation of transmission to or from the Services; (iv) bugs, viruses, trojan horses, or the
like which may be transmitted to or through the Services through the actions of any third
party.`,
      `CapQuest does not warrant, endorse, guarantee, or assume responsibility for any product
or service advertised or offered by a third party through the Website or the Application or
any hyperlinked website or feature in the Services or the Application or other advertising or
news, and CapQuest will not be a party to or in any way be responsible for monitoring any
transaction between you and third-party providers of products or services. As with the
purchase of a product or service through any medium or in any environment, you should
use your best judgment and exercise caution, diligence and common sense, where
appropriate.`,
      `Our Services are provided for informational purposes only and do not take into
consideration any specific entity’s financial status, investment goals or personal
circumstances. We do not promote, recommend, or solicit any particular actions based on
the information available through the Services. None of the material contained in the
Services or the Application should be interpreted as a solicitation or offer to buy or sell any
financial product, security, or instrument. Investments in unlisted companies involve
considerable risk and may not be suitable for every investor. Therefore, the Customers and
their users should engage in such transactions only after fully understanding all associated
risks and independently determining their suitability.`,
      `Any Content or information presented shall not be considered as professional advice
related to finance, investment, business, trading, hedging, legal matters, regulatory
compliance, taxes or accounting. We disclaim any responsibility and liability for decisions
or actions taken by the Customers or users based on the information obtained through the
Services. It is the sole obligation of the Customer to obtain prior legal advice, where
appropriate.`,
      `We offer no express or implied warranties concerning the use of the Services by Customers
or users. This includes, but is not limited to, implied warranties of merchantability, fitness
for a particular purpose, satisfactory quality or quiet enjoyment. The Application and the
Services are made available strictly “as is.” It is solely the Customer’s responsibility to
ensure compliance with relevant laws and regulations within their jurisdiction when using
the Services.`,
      `The Services offered through the Application may be unavailable from time to time, may be
offered for a limited time, or may vary depending on your region or device. We endeavour to
keep the Services up and running at all times; however, all online services suffer occasional
disruptions and outages, and we shall not be liable for any disruption or loss you may suffer
as a result of such interruptions or loss or unavailability of your stored Data.`,
      `Links to external websites may be found within the Website or Application; however, such
sites operate under their own terms of use. We are not responsible for the content or
availability of these third-party websites and disclaim liability for any losses or damages
arising from the Customer’s or users’ access to or interaction with such sites.`,
    ],
  },
  {
    title: 'Confidentiality',
    text: [
      `Each party (referred to as the "Receiving Party") agrees to exert reasonable efforts to
maintain the confidentiality of all aspects of the agreement and Confidential Information,
including but not limited to Terms, pricing and any disclosed information and
documentation shared by the other party (the "Disclosing Party") or that has come to the
knowledge of the Receiving Party. Confidential material pertains to the Disclosing Party's
business, operations, software, processes, trade secrets, products, dealings, and related
elements such as specifications, designs, graphics, customer-provided information
through service use, and audit-related data and such information is collectively termed
"Confidential Information" and is not to be utilized by the Receiving Party for any purpose
beyond fulfilling obligations under these Terms. Disclosure to third parties is strictly
prohibited without the prior written approval of the Disclosing Party. Reproduction or
distribution of any part of the Confidential Information without written consent from the
Disclosing Party is prohibited.`,
      `Confidential Information may be shared by the Receiving Party with its employees,
representatives and agents ("Recipients") solely when necessary for the execution of
obligations under these Terms. The Receiving Party is required to ensure that these
Recipients understand and adhere to the same confidentiality commitments, as though
they were bound by these provisions themselves.`,
      `Certain circumstances exempt the Receiving Party from these confidentiality
requirements, including when the information: (i) is publicly accessible through means
other than a breach by the Receiving Party or its Recipients; (ii) was legally and
unrestrictedly provided by a third party without confidentiality obligations to the Disclosing
Party; (iii) must be disclosed under legal requirements, court order or regulatory directive,
provided that the Receiving Party gives the Disclosing Party reasonable notice, whenever
permissible; (iv) was already in the possession of the Receiving Party before the Disclosing
Party’s disclosure; or (v) has been independently created by the Receiving Party without
reliance on or use of any Confidential Information.`,
      `All tangible or electronic formats of Confidential Information, including copies, excerpts,
or summaries prepared by any party, remain the property of the Disclosing Party. Upon
written request or upon expiry or termination of the relationship between the parties, the
Receiving Party must return or destroy Confidential Information, unless retention is
required for compliance with applicable laws or regulatory requirements.`,
    ],
  },
  {
    title: 'Force majeure',
    text: [
      `Neither party shall be held accountable for failing to meet their obligations, excluding any
payment obligations, when such failure or delay arises from circumstances beyond their
reasonable control. This includes, but is not limited to, events such as war, riots, acts of
terrorism, uprisings, labour strikes, fires, natural disasters, embargo, currency restrictions,
import/export bans, major disruptions in transportation, energy supply interruptions or
failures, outages of utilities, issues with public data systems, breakdowns or delays from
internet or telecommunications service providers and the severe illness or death of critical
personnel. Additional situations include epidemics, pandemics, or other widespread
health crises, as well as any related government measures like quarantines or workforce
restrictions, and instances of force majeure affecting affiliates, partners, or
subcontractors.`,
      `Should such a force majeure event persist for a duration of thirty (30) calendar days or
more, either party may choose to terminate this agreement by providing a written notice to
the other party. This termination will not carry any liability, except for any obligations that
have accrued prior to the force majeure event's onset (including any payment obligations).`,
    ],
  },
  {
    title: 'Notices',
    text: [
      `All communications related to these Terms must be issued in English language and
delivered as follows:`,
      [
        `When the Customer sends a notice to CapQuest, it should be done via email at
support@capquest.io. Notices sent during CapQuest’s standard business hours 09:00
am to 06:00 pm Gulf Standard Time, Monday through Friday, excluding public holidays in
the Emirate of Dubai (“Business Hours”) will be considered received at the time of
successful transmission. If a notice is sent outside Business Hours, it will be deemed
received the next business day during Business Hours.`,
        `CapQuest will issue notices to the Customer via the email address associated with the
Customer’s registered account. Such notices are considered received at the time they
are sent or, if dispatched outside of the Business Hours, the next business day during
Business Hours. It is the responsibility of the Customer to ensure that their registered
email address is kept up to date and accurate.`,
      ],
    ],
  },
  {
    title: 'Marketing',
    text: [
      `The Customer consents to our use of their name as a reference in promotional materials,
including but not limited to our Website, customer lists, and other marketing content,
which may also include displaying the Customer’s logo to highlight our relationship and the
services provided.`,
      `With the Customer’s prior approval, we may also create, publish, and share a case study
or story that details the Customer’s experience with our product or Services, which can be
utilized for marketing and promotional purposes.`,
      `Additionally, the Customer grants CapQuest a non-exclusive license to incorporate and
display their trademarks within the user interface of our Application as a means of referring
to the Customer.`,
    ],
  },
  {
    title: 'Sale, Merger, Acquisition',
    text: [
      `In the event that CapQuest is acquired by or merged with a third-party entity, CapQuest
reserves the right to transfer or assign the Data and information collected from its
Customers and users as part of such merger, acquisition, sale, or other change of control.
In the unlikely event of CapQuest’s bankruptcy, insolvency, reorganization, receivership, or
assignment for the benefit of creditors, or the application of laws or equitable principles
affecting creditors’ rights generally, CapQuest may not be able to control how your personal
information is treated, transferred, or used.`,
    ],
  },
  {
    title: 'Amendments to terms',
    text: [
      `We reserve the right to modify these Terms whenever deemed necessary. We endeavour to
provide the Customer with a written notice prior to any changes taking effect, unless
circumstances make such notice impracticable. It is the Customer’s sole obligation to

13

review the Terms from time to time to familiarise themselves with the latest version of the
Terms as made available on the Website.`,
      `If a modification to the Terms during the Subscription Period is material, the Customer has
the option to terminate the agreement by providing a written notice to us within fourteen
(14) calendar days of receiving notification of the material change to the Terms.`,
      `The Customers on Paid Subscriptions may opt to adhere to the existing Terms until the
conclusion of the current Subscription Period, after which the updated Terms will apply.`,
    ],
  },
  {
    title: 'Law and jurisdiction',
    text: [
      `These Terms and any agreement between CapQuest and the Customer and any dispute,
controversy or claim (including any non-contractual dispute, controversy or claim) arising
out of or in connection with these Terms, including any question regarding the existence,
validity, interpretation, formation, performance, discharge or termination of the Services
and the Terms, shall be governed by the laws of Dubai International Financial Centre.`,
      `All disputes or claims, whether contractual, tort or otherwise, that are related to or stem
from these Terms and the agreement between the parties shall be subject to the exclusive
jurisdiction of the courts of the Dubai International Financial Centre (DIFC), United Arab
Emirates.`,
      `The parties agree that any cause of action arising out of or related to these Terms or the
Services must commence within one (1) year after the cause of action accrues, otherwise
such cause of action is permanently barred.`,
    ],
  },
  {
    title: 'General',
    text: [
      `These Terms and any agreement formed between the parties based on these Terms
represents the entire understanding between the parties and replaces all prior agreements,
promises, assurances, warranties, representations, and understandings, whether oral or
written, relating to the subject matter.`,
      `If any provision or portion of these Terms is deemed invalid or unenforceable, the remaining
portions will continue to be in effect and enforceable. The parties agree to interpret and
apply the rest of the Terms in a manner consistent with the intent and purpose of the
invalidated provision, to the fullest extent permissible by law.`,
      `A party’s failure to exercise, or delay in exercising, any right provided by these Terms does
not constitute a waiver or release of that right. Any waiver or release must be explicitly
documented and signed by the party providing it.`,
      `Nothing contained within these Terms or any related documents shall be interpreted to
establish a partnership, joint venture, or agency relationship between the parties.`,
      `The parties intend for these Terms to be enforceable solely between themselves, with no
intention of extending enforceability to any third party.`,
      `The Customer may not transfer, assign, novate or otherwise deal with the rights and
obligations under these Terms without first obtaining our written consent. We may transfer,
assign, novate or otherwise deal with the rights and obligations under these Terms by
providing the Customer a written notice.`,
      `These Terms may be executed by electronic means or by clicking "I Agree," "Accept," or
similar affirmations. Such actions constitute a valid and enforceable method of execution
under applicable law. The parties acknowledge and agree that this electronically accepted
Terms shall have the same legal force and effect as an agreement physically signed in ink
and delivered in person.`,
      `Should you need to contact us at any time to discuss any part of these Terms, make a
complaint or suggestion, please contact us at support@capquest.io.`,
    ],
  },
];

export const policySections: Data[] = [
  {
    title: 'Data we collect',
    text: [
      `When you access our Website or Application or make any use of our Services, we may collect and
process the following types of personal data:`,
      [
        `Personal Identifiers: Your name, address, email, phone number, date of birth, residency
and visa status, nationality, and passport/ID information.`,
        `Cap Table Data: Your business, management and shareholder data (such as name,
address, email, phone number, date of birth, and other identifying information), share
ledger transaction data, personal data contained in the company legal documents (such
as board resolutions, term sheets, shareholder agreements and other legal documents)
and any other cap table related documents and information.`,
        `Account Information: Details you provide during account registration, such as
username, password, and user preferences.`,
        `Transaction Data: Details about services you purchase, including payment and billing
information.`,
        `Technical Data: IP addresses, browser type, operating system, device information, time
zone settings, and usage data gathered through cookies and similar technologies.`,
        `Communication Data: Communications you send to us directly or indirectly via email,
Website, or the Application, including support requests, emails, feedback, and other
interactions.`,
        `Employee information: Information including employee plan benefits information and
personal data (including the email, phone number, age, nationality and passport
information).`,
        `Legal requests: Information required to comply with laws, the requests and directions of
law enforcement authorities or court orders.`,
      ],
    ],
  },
  {
    title: 'How we collect data',
    text: [
      `We collect personal data in several ways to mainly provide and improve our Services, including:`,
      [
        `Data You Provide Directly: Information you share when you register for an account, use
our Services, fill out forms, communicate with us via email, or participate in surveys,
promotions or attend or organize an event. You may need to provide personal information
if you have a contract or account with us to identify our customers and users. We may
collect your personal information when you become a partner as a supplier, consultant,
or employee.`,
        `Automated Data Collection: Information collected automatically when you interact with
our Website and Application, such as IP addresses, device details, browser type, and
usage data, often gathered through cookies and similar tracking technologies.`,
        `Third-Party Sources: Data obtained from external sources, such as service providers or
publicly available platforms, where permitted by applicable law. We will only collect
information from third parties when we reasonably believe that you have consented to
these sources collecting and disclosing the information to us.`,
      ],
    ],
  },
  {
    title: 'How we use this data',
    text: [
      `Your personal data may be used for the following purposes:`,
      [
        `To Provide Our Services: To register you as a customer, maintain your account and
deliver the Services.`,
        `To Improve and Develop Services: To analyze usage trends, enhance features, and
develop new offerings.`,
        `Operational reasons: To improve efficiency, training, and quality control.`,
        `To Communicate with You: To send transactional emails, updates, newsletters that you
have agreed to receive and respond to inquiries or feedback.`,
        `To Comply with Contracts: To contact you for matters related to our relationship or
contract with you and to comply with our contractual obligations.`,
        `To Process Payments: To process and manage transactions and share data with credit
reference agencies to prevent fraudulent purchases.`,
        `To Comply with Legal Obligations: To meet legal and regulatory requirements and to
prevent and investigate fraud and other misuses. To protect our rights and/or our property.
Conduct checks to identify customers.`,
        `To Provide Support: To give you access to portals with your log in details or resolve
technical issues, troubleshoot, and offer customer support.`,
        `To Hire: For hiring and immigration purposes.`,
        `Marketing: To notify you of our products and services, promotions that you may like.`,
      ],
      `If you agree, we may share your data with our partner companies so that they may offer their
products and services.`,
    ],
  },
  {
    title: 'How we share your information',
    text: [
      `We may share your personal data with:`,
      [
        `Service Providers: Third-party vendors that assist with our operations, such as payment
processors, IT service providers and analytics partners. We may also share your personal
information with our hiring team, employees, government bodies and agencies, for the
purposes of operation of our business, to complete immigration processes and for payroll
and medical insurance purposes.`,
        `Contractual and Legal Obligations: If we are under a duty to disclose or share your data
in order to comply with any legal obligation, or in order to enforce or apply any contract

with you or other agreements; or to protect our rights, property, or safety of our
employees, customers, or others.`,
        `Court and Regulatory Authorities: We will share your personal information if we think
we have to in order to comply with the law (including responding to a court order or
subpoena). We may also share personal information if a government agency or
investigatory body requests it.`,
        `Business Transfers: In connection with mergers, sales of company assets, or corporate
restructuring. In the event that we sell or buy any business or assets, in which case we
may disclose personal data we hold to the prospective seller or buyer of such business or
assets under a confidentiality agreement.`,
      ],
      `Cap Table Data is never shared without explicit instructions from the customer. All Cap Table
information, including equity details, documents, and shareholder data is stored in secure cloud
and the data center provider does not have access to this information.`,
      `We will not sell your personal data to any third parties.`,
    ],
  },
  {
    title: 'Marketing',
    text: [
      `We may notify you of our products and services, promotions that you may like.`,
      `If you do not wish to receive marketing at any time, you can opt out by using the function at the
end of the email or by contacting us directly.`,
    ],
  },
  {
    title: 'International data transfers',
    text: [
      `Your personal data may be transferred to and processed in countries outside your own. We
ensure that any such transfer complies with applicable data protection laws by implementing
appropriate safeguards, such as standard contractual clauses or equivalent mechanisms.`,
    ],
  },
  {
    title: 'Cookies and tracking technology',
    text: [
      `We use cookies and similar technologies to enhance user experience, analyze site usage, and
offer personalized content. You can manage or disable cookies through your browser settings.
For detailed information, please see our Cookie Policy available on our Website.`,
    ],
  },
  {
    title: 'How we store data',
    text: [
      `Your data is securely stored in a high-security data center located in the USA.`,
      `We implement robust security measures to protect your personal data from unauthorized
access, disclosure, alteration, and destruction, including firewalls and access controls to control
access to your personal information. However, no method of transmission over the internet is
entirely secure, and we cannot guarantee absolute security.`,
      `We have further implemented a Data Breach Response Plan and all of our team members are
trained on a regular basis in the best practices in terms of Data Protection and the regulatory
framework which with CapQuest complies.`,
    ],
  },
  {
    title: 'Your rights',
    text: [
      `It is important for you to note that you have legal rights with respect to the Personal Data we hold.
More specifically and depending on your location, you may have some or all of the following rights
regarding your personal data:`,
      [
        `Access: Request a copy of your personal data that we will provide to you in an electronic
format. At our discretion, we may require you to verify your identity before releasing any
requested information.`,
        `Rectification or Erasure: Request corrections to personal data you deem inaccurate or
incomplete or request deletion of data under certain conditions and we will do so within
one (1) month of the request; however, you may need to verify yourself before we can
correctly address your request. We will take reasonable steps to destroy or amend
inaccurate or out-of-date data, however, CapQuest is not responsible for the accuracy of
the data you provide.`,
        `Restriction: Request restrictions on processing your personal data under certain
conditions.`,
        `Data Portability: Request to transfer your personal data to another service provider in a
commonly used format, such as an Excel file, especially when we process it based on
your consent or to deliver our Services.`,
        `Objection: Object to personal data processing on certain conditions. We will honor your
request to cease processing unless we have compelling legitimate grounds that outweigh
your interests or if processing is necessary to establish, exercise, or defend a legal claim.`,
        `Withdrawal of Consent: Withdraw consent at any time when data collection, processing
and transfer is based on your consent. This may include situations where you opt out of
receiving marketing messages for example. To stop receiving such emails, you can click
the “unsubscribe” link in any marketing email or contact us.`,
        `Appealing to a Supervisory Authority: If you believe that our handling of your personal
data breaches data protection laws, you may lodge an appeal with the relevant data
protection supervisory authority.`,
      ],
      `To exercise these rights in the jurisdiction where these apply, please contact us using the details
set out at the end of this Privacy Policy. We will respond to any request promptly but no later than
within thirty (30) days of receipt. We may need to verify you to address your query, and a fee may
apply for processing your request.`,
      `Please be aware that your rights in relation to personal information are not absolute and
regardless of your request, some information cannot be amended or deleted due to legal or
technical reasons.`,
      `If you believe that we have violated any of the rights concerning personal data about you, you may
have a right to lodge a complaint with a supervisory authority, the Commissioner of Data
Protection, or another regulatory agency. Before you do so, we encourage you to first reach out to
us to address your concern directly.`,
      `If you still need to contact the supervisory authority the details are set out below:`,
      `DIFC Commissioner of Data Protection’s Office at:
Dubai International Financial Centre Authority`,
      `Level 14, The Gate Building`,
      `+971 4 362 2222`,
      `commissioner@dp.difc.ae`,
      `If the Customer is subject to the EU General Data Protection Regulation, there may be other
supervisory authorities that may have jurisdiction in case of a complaint by such Customer.`,
    ],
  },
  {
    title: 'Data retention',
    text: [
      `We retain personal data only as long as necessary for the purposes outlined in this Privacy Policy
or as required by law. When data is no longer needed for the purpose for which it was collected,
we will take all reasonable steps to delete or anonymize it in a secure manner. We will, to the
extent required by law, comply with any statutory retention periods.`,
    ],
  },
  {
    title: 'Third-party links',
    text: [
      `Our Website, Application and Services may include links to third-party websites. We are not
responsible for the privacy practices of such websites. We encourage you to read the privacy
policies of any third-party sites you visit and only continue to use any third-party link after you
have considered and are satisfied with their privacy practices.`,
    ],
  },
  {
    title: 'Children’s privacy',
    text: [
      `Our Services are not intended for minors under 18 years of age. Unless there is a legal reason to
collect personal data from a minor as part of our legal obligations (for example employees’
dependents details), we do not knowingly collect personal data from minors under 18. If you
believe that we have collected data from a child for any other reason than required under the law,
please contact us, and we will take appropriate steps to delete such information.`,
    ],
  },
  {
    title: 'Changes to this privacy policy',
    text: [
      `We may update this Privacy Policy from time to time to reflect changes in our practices or legal
obligations. If we make material changes to this Privacy Policy, a revised version with the "Last
Updated" date shall be uploaded to our Website. Your continued use of the Website and/or our
Services following any changes to this Privacy Policy constitutes your acceptance of any such
changes made.`,
      `Any cause of action arising out of or related to this Privacy Policy that you may have against
CapQuest must commence within one (1) year after the cause of action accrues, otherwise such
a cause of action is permanently barred.`,
    ],
  },
  {
    title: 'Contact us',
    text: [
      `If you have any questions or concerns about this Privacy Policy, your data or our data practices,
please contact us at: contact@capquest.io or by writing to us at the following address:`,
      `CapQuest Software Limited`,
      `Innovation Hub One, DIFC, Dubai, UAE`,
    ],
  },
  { title: 'Policy section' },
];
