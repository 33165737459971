import { useQueryClient } from '@tanstack/react-query';
import React, { FC } from 'react';
import { Control } from 'react-hook-form';

import { ChevronDownIcon } from '../../../assets/icons';
import { AppFormattedMessage } from '../../../components/AppFormattedMessage';
import Button from '../../../components/Button';
import {
  ComboboxButton,
  ComboboxOptions,
  FormCombobox,
  FormComboboxInput,
  FormComboboxOption,
} from '../../../components/Combobox';
import { ModalWrapper } from '../../../components/ModalWrapper';
import { BackendRoute } from '../../../config';
import { QueryKey } from '../../../constants';
import { HttpException } from '../../../exceptions';
import { useAppQuery, useModalState } from '../../../hooks';
import { StringKey } from '../../../lang';
import { ApiService } from '../../../services';
import { Pool } from '../../../types/pool-plans.types';
import { AddPoolForm } from '../PoolForm';
import { FormSchema } from './Validation';

export type PoolsItemsListProps = {
  data: Pool[];
  control: Control<FormSchema>;
  isLoading: boolean;
  handleOpenAddPlanModal: () => void;
};

const PoolsItemsList: FC<PoolsItemsListProps> = ({
  data,
  control,
  isLoading,
  handleOpenAddPlanModal,
}) => {
  if (!data || isLoading)
    return (
      <span>
        <AppFormattedMessage id={StringKey.LOADING} />
      </span>
    );

  return (
    <>
      {data.map(({ name, id }, i) => (
        <FormComboboxOption
          control={control}
          key={`${name}_${i}`}
          name="stepOne.pool"
          value={{ id, name }}
        >
          <span className="text-sm text-gray-700">{name}</span>
        </FormComboboxOption>
      ))}
      <Button
        className="h-[37px] w-full justify-start rounded bg-brand-25 px-[6px] text-start text-sm font-[450] text-brand-700"
        onClick={handleOpenAddPlanModal}
        styleType="NONE"
      >
        + <AppFormattedMessage id={StringKey.ADD_NEW_POOL} />
      </Button>
    </>
  );
};

export type PoolComboboxProps = {
  control: Control<FormSchema>;
  companyId: string;
};

export const PoolCombobox: FC<PoolComboboxProps> = ({ control, companyId }) => {
  const queryClient = useQueryClient();
  const { data, isLoading } = useAppQuery<{ pools: Pool[]; totalPages: number }, HttpException>({
    queryKey: [QueryKey.GET_POOLS, companyId],
    queryFn: () =>
      ApiService.get({ endpoint: BackendRoute.COMPANIES, routePath: [companyId, 'pools'] }),
    enabled: !!companyId,
  });
  const invalidateQuery = () => {
    queryClient.invalidateQueries({ queryKey: [QueryKey.GET_POOLS] });
  };

  const { isOpen, toggler, handleOpenModal, handleCloseModal } = useModalState();

  return (
    <>
      <ModalWrapper control={{ open: isOpen, onOpenChange: toggler }} side="RIGHT">
        <div className="mr-6 h-screen w-[485px] border-transparent bg-transparent p-2 shadow-none">
          <AddPoolForm
            companyId={companyId}
            handleClose={handleCloseModal}
            invalidateQuery={invalidateQuery}
          />
        </div>
      </ModalWrapper>

      <FormCombobox className="relative" control={control} name={'stepOne.pool'}>
        <div className="relative">
          <FormComboboxInput
            className={'bg-gray-900'}
            control={control}
            customValue={(value) => value?.name}
            icon={<ChevronDownIcon className="mt-2" />}
            name={'stepOne.pool'}
            placeholder={<AppFormattedMessage id={StringKey.SELECT_POOL} />}
            readOnly
          />
          <ComboboxButton className="absolute left-0 top-0 z-1 h-full w-full" />
        </div>
        <ComboboxOptions className="w-full">
          <PoolsItemsList
            control={control}
            data={data?.pools || []}
            handleOpenAddPlanModal={handleOpenModal}
            isLoading={isLoading}
          />
        </ComboboxOptions>
      </FormCombobox>
    </>
  );
};
