import React, { FC, useCallback, useEffect } from 'react';
import {
  Control,
  FieldErrors,
  useFieldArray,
  UseFormClearErrors,
  UseFormGetValues,
  UseFormSetError,
  UseFormSetValue,
  useWatch,
} from 'react-hook-form';

import { CalendarIcon } from '../../../../assets/icons';
import { AppFormattedMessage } from '../../../../components/AppFormattedMessage';
import Button from '../../../../components/Button';
import { FormInput } from '../../../../components/Input';
import { useFormat, useLocale } from '../../../../hooks';
import { StringKey } from '../../../../lang';
import { EventFormType } from '../../../../types/events.types';
import { DatePicker } from '../../../PoolPlans/PoolForm/DatePicker';
import {
  FormDropDown,
  ShareClassCombobox,
  StakeholderCombobox,
  SubEventFileUpload,
} from '../Components';
import { FormSchema } from '../validation';

export type FundraisingRoundOptionalProps = {
  setFormData: UseFormSetValue<FormSchema>;
  control: Control<FormSchema>;
  companyId: string;
  lockMode?: boolean;
  formData: UseFormGetValues<FormSchema>;
  setError: UseFormSetError<FormSchema>;
  clearErrors: UseFormClearErrors<FormSchema>;
  errors: FieldErrors<FormSchema>;
};

export const FundraisingRoundOptionalStep: FC<FundraisingRoundOptionalProps> = ({
  control,
  setFormData,
  companyId,
  lockMode,
  formData,
  setError,
  clearErrors,
  errors,
}) => {
  const { initialStep, stepOne, optionalStep } = useWatch<FormSchema>({ control });

  const defaultDate = new Date(+0);

  const {
    fields: investmentItemsFields,
    append: investmentItemsAppend,
    remove: investmentItemsRemove,
  } = useFieldArray({
    control,
    name: 'optionalStep.fundraising-round.investmentItems',
  });

  const handleRemoveInvestmentItems = useCallback(
    (index: number) => {
      investmentItemsRemove(index);
    },
    [investmentItemsRemove],
  );

  const handleAppendInvestmentItems = useCallback(() => {
    investmentItemsAppend({
      investmentDate: defaultDate,
      sharePrice: 0,
      shareClass: { id: '', name: '' },
      stakeholder: { id: '', fullName: '' },
      investment: 0,
    });
  }, [investmentItemsAppend]);

  useEffect(() => {
    if (
      initialStep?.type === EventFormType.FUNDRAISING_ROUND &&
      investmentItemsFields.length === 0
    ) {
      handleAppendInvestmentItems();
    }
  }, [initialStep?.type]);

  useEffect(() => {
    if (!lockMode) {
      const closeDate = stepOne?.eventDetails?.['fundraising-round']?.isOpen
        ? undefined
        : stepOne?.eventDetails?.['fundraising-round']?.date;

      if (closeDate?.getTime() !== optionalStep?.['fundraising-round']?.closeDate?.getTime()) {
        setFormData('optionalStep.fundraising-round.closeDate', closeDate);
      }
    }
  }, [
    stepOne?.eventDetails?.['fundraising-round']?.isOpen,
    stepOne?.eventDetails?.['fundraising-round']?.date,
    optionalStep?.['fundraising-round']?.closeDate,
  ]);

  return (
    <>
      <div className="w-fit rounded bg-gray-100 px-2 py-1">
        <span className="text-sm font-[450] text-gray-500">
          {stepOne?.eventDetails?.['fundraising-round']?.event?.name}
        </span>
      </div>
      {investmentItemsFields.map((field, index) => (
        <InvestmentItem
          clearErrors={clearErrors}
          companyId={companyId}
          control={control}
          errors={errors}
          formData={formData}
          handleRemoveInvestmentItems={handleRemoveInvestmentItems}
          index={index}
          key={field.id}
          setError={setError}
          setFormData={setFormData}
          showRemoveButton={investmentItemsFields.length > 1}
        />
      ))}
      <Button
        className="w-fit px-4 py-2 text-sm font-[450] text-brand-700 underline disabled:border-transparent disabled:bg-transparent"
        onClick={handleAppendInvestmentItems}
        styleType="NONE"
      >
        + <AppFormattedMessage id={StringKey.ADD_ANOTHER_INVESTMENT} />
      </Button>
    </>
  );
};

const InvestmentItem: FC<
  FundraisingRoundOptionalProps & {
    index: number;
    handleRemoveInvestmentItems: (index: number) => void;
    showRemoveButton: boolean;
  }
> = ({
  control,
  setFormData,
  companyId,
  index,
  handleRemoveInvestmentItems,
  showRemoveButton,
  clearErrors,
  errors: { optionalStep: errors },
  formData,
  setError,
}) => {
  const { stepOne, optionalStep } = useWatch<FormSchema>({ control });
  const { format } = useFormat();
  const { messagesLocale } = useLocale();
  const defaultDate = new Date(+0);

  const getFullTitle = useCallback(
    (index: number) => {
      const truncateName = (name: string) => {
        return name.length > 25 ? `${name.slice(0, 25)}...` : name;
      };

      const stakeholderName =
        optionalStep?.['fundraising-round']?.investmentItems?.[index]?.stakeholder?.fullName;

      if (stakeholderName) {
        return `${messagesLocale[StringKey.INVESTMENT]} - ${truncateName(stakeholderName)}`;
      }

      return messagesLocale[StringKey.INVESTMENT];
    },
    [messagesLocale, optionalStep],
  );

  const calculateShares = useCallback(
    (index: number) => {
      const investment = optionalStep?.['fundraising-round']?.investmentItems?.[index]?.investment;
      const sharePrice = stepOne?.eventDetails?.['fundraising-round']?.event?.sharePrice;

      if (investment && sharePrice) {
        return Number(investment / sharePrice);
      }

      return 0;
    },
    [optionalStep, stepOne?.eventDetails],
  );

  const isSharesInteger = Number.isInteger(calculateShares(index));

  useEffect(() => {
    if (stepOne?.eventDetails?.['fundraising-round']?.event?.sharePrice)
      setFormData(
        `optionalStep.fundraising-round.investmentItems.${index}.sharePrice`,
        stepOne?.eventDetails?.['fundraising-round']?.event?.sharePrice,
      );
  }, [index]);

  return (
    <FormDropDown
      handleRemove={() => handleRemoveInvestmentItems(index)}
      showRemoveButton={showRemoveButton}
      title={getFullTitle(index)}
    >
      <div className="flex w-full flex-col gap-4">
        <div className="flex w-full flex-col gap-3">
          <div className="flex w-full items-center justify-between">
            <span className="text-sm font-[450] text-gray-700">
              <AppFormattedMessage id={StringKey.STAKEHOLDER} />
            </span>
            <StakeholderCombobox
              companyId={companyId}
              control={control}
              name={`optionalStep.fundraising-round.investmentItems.${index}.stakeholder`}
            />
          </div>
          <div className="flex w-full items-center justify-between">
            <span className="text-sm font-[450] text-gray-700">
              <AppFormattedMessage id={StringKey.INVESTMENT} />
            </span>
            <FormInput
              control={control}
              currencySign
              errorMessage={!isSharesInteger && 'Investment must be a multiple of share price'}
              name={`optionalStep.fundraising-round.investmentItems.${index}.investment`}
              numberOnly
              placeholder={<AppFormattedMessage id={StringKey.VALUE} />}
              shouldFormatNumber
              wrapperClassName="w-[224px]"
            />
          </div>
          <div className="flex w-full items-center justify-between">
            <span className="text-sm font-[450] text-gray-700">
              <AppFormattedMessage id={StringKey.SHARE_CLASS} />
            </span>
            <ShareClassCombobox
              companyId={companyId}
              control={control}
              fieldName={`optionalStep.fundraising-round.investmentItems.${index}.shareClass`}
            />
          </div>
          <div className="flex w-full items-center justify-between">
            <span className="text-sm font-[450] text-gray-700">
              <AppFormattedMessage id={StringKey.INVESTMENT_DATE} />
            </span>
            <DatePicker
              calendar={{ toDate: optionalStep?.['fundraising-round']?.closeDate }}
              defaultMonth={
                optionalStep?.['fundraising-round']?.investmentItems?.[index]?.investmentDate ||
                undefined
              }
              onSelect={(date) =>
                setFormData(
                  `optionalStep.fundraising-round.investmentItems.${index}.investmentDate`,
                  date,
                )
              }
              value={optionalStep?.['fundraising-round']?.investmentItems?.[index]?.investmentDate}
              wrapperClassName="w-[224px]"
            >
              <FormInput
                autoComplete="off"
                control={control}
                customValue={(value) => {
                  return value && new Date(value).getTime() !== defaultDate.getTime()
                    ? format(value, 'dd/MM/yyyy')
                    : '';
                }}
                disabledInput
                icon={<CalendarIcon className="mr-2 size-6" iconColor="#98A2B3" />}
                name={`optionalStep.fundraising-round.investmentItems.${index}.investmentDate`}
                placeholder={<AppFormattedMessage id={StringKey.DATE} />}
                wrapperClassName="w-full"
              />
            </DatePicker>
          </div>
          <div className="h-[1px] w-full bg-gray-100" />
          <div className="flex w-full items-center justify-between pb-1 pt-3">
            <span className="text-sm font-[450] text-gray-700">
              <AppFormattedMessage id={StringKey.NO_OF_SHARES} />
            </span>
            <span className="rounded bg-brand-25 px-3 py-1 text-sm font-[550] text-brand-700">
              {calculateShares(index).toLocaleString('en-US')}
            </span>
          </div>
        </div>
        <SubEventFileUpload
          clearErrors={clearErrors}
          errors={errors?.['fundraising-round']?.investmentItems?.[index]?.files || undefined}
          field={`optionalStep.fundraising-round.investmentItems.${index}.files`}
          files={optionalStep?.['fundraising-round']?.investmentItems?.[index]?.files || []}
          formData={formData}
          setError={setError}
          setFormData={setFormData}
          text="investment"
        />
      </div>
    </FormDropDown>
  );
};
