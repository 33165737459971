import React, { FC, useCallback, useEffect } from 'react';
import { toast } from 'react-toastify';
import { v4 as uuidv4 } from 'uuid';

import { AlertDialogWrapper } from '../../../../components/AlertDialog';
import { AppFormattedMessage } from '../../../../components/AppFormattedMessage';
import { CloseModalButton } from '../../../../components/CloseModalButton';
import { Sheet, SheetContent } from '../../../../components/Sheet';
import { AnalyticHooks, Pool, useModalState, useReactForm } from '../../../../hooks';
import { StringKey } from '../../../../lang';
import { PossibleAction } from '../../../../types/google-analytics-types';
import { DilutionCondition } from '../../../../types/pool-plans.types';
import { getS3FileOriginalName } from '../../../../utils/getS3FileOriginalName';
import { toBackendDateFormat } from '../../../../utils/toBackendDateFormat';
import { UpdateConfirmationModal } from '../../UpdateConfirmationModal';
import { EditPreview, StepOne, StepTwo } from '../Steps';
import { ChildrenFormProps, EditShareClassStepVariant } from '../type';
import { FormSchema, formSchema } from '../Validation';

type ModalProps = {
  isOpenModal: boolean;
  handleClose: () => void;
  companyId: string;
  invalidateQuery: () => void;
  onEditClick: (props: { id: string; step: EditShareClassStepVariant }) => void;
  step: EditShareClassStepVariant;
  handleTerminate: (state: { id: string; date: Date }) => void;
  poolId: string;
  previewMode?: true;
};

const formSteps: ((
  props: {
    onEditClick: (step: EditShareClassStepVariant) => void;
    handleTerminate: (date: Date) => void;
  } & ChildrenFormProps,
) => JSX.Element)[] = [
  (props) => <StepOne {...props} />,
  (props) => <StepTwo {...props} />,
  (props) => <EditPreview {...props} />,
];

export const EditPoolModal: FC<ModalProps> = ({
  companyId,
  isOpenModal,
  handleClose,
  invalidateQuery,
  onEditClick,
  poolId,
  step,
  previewMode,
  handleTerminate,
}) => {
  const { pool } = Pool.usePool({ companyId, poolId });

  const {
    control,
    reset,
    setValue,
    getValues,
    getFieldState,
    handleSubmit,
    setError,
    clearErrors,
    formState: { errors },
  } = useReactForm({
    schema: formSchema,
  });

  useEffect(() => {
    if (!pool) return;

    const files: {
      docLink: string;
      loadProgress: 100;
      abort: () => void;
      id: string;
      doc: {
        size: number;
        type: string;
        name: string;
      };
    }[] = pool?.filesLinks?.map((link) => {
      const originalFileName = getS3FileOriginalName(link);

      return {
        abort: () => '',
        loadProgress: 100,
        doc: {
          name: originalFileName || '',
          size: 10,
          type: 'application/pdf',
        },
        docLink: link,
        id: uuidv4(),
      };
    });

    reset({
      stepOne: {
        date: new Date(pool?.date),
        dillutedSharesCount: pool?.dillutedSharesCount,
        dilutionCondition: pool?.capTableInclusion
          ? DilutionCondition.INCLUDE_CAP_TABLE
          : DilutionCondition.EXCLUDE_CAP_TABLE,
        name: pool?.name,
        shareClass: {
          id: pool?.shareClass?.id,
          name: pool?.shareClass?.name,
        },
      },
      stepTwo: {
        additionalNotes: pool?.additionalNotes || undefined,
        files: files,
      },
    });
  }, [pool, reset]);

  const companyEditPoolTracker = AnalyticHooks.useClick(PossibleAction.EDIT_POOL);
  const { update } = Pool.useUpdate();

  const handleCloseModal = useCallback(() => {
    handleClose();
    invalidateQuery();
    reset();
  }, [handleClose, invalidateQuery, reset]);

  const handleUpdateData = useCallback(
    (data: FormSchema) => {
      const { stepOne, stepTwo } = data;
      update(
        {
          poolId,
          companyId,
          data: {
            capTableInclusion: DilutionCondition.INCLUDE_CAP_TABLE === stepOne.dilutionCondition,
            date: toBackendDateFormat(stepOne.date),
            dillutedSharesCount: stepOne.dillutedSharesCount,
            name: stepOne.name,
            additionalNotes: stepTwo?.additionalNotes,
            filesLinks:
              stepTwo?.files?.reduce<string[]>((prev, curr) => [...prev, curr.docLink], []) || [],
            shareClassId: stepOne.shareClass.id,
          },
        },
        {
          onSuccess: () => {
            companyEditPoolTracker();
            toast.success(<AppFormattedMessage id={StringKey.POOL_SUCCESSFULLY_UPDATED} />);
            handleCloseModal();
            invalidateQuery();
            handleClose();
          },
        },
      );
    },
    [
      companyEditPoolTracker,
      companyId,
      handleClose,
      handleCloseModal,
      invalidateQuery,
      poolId,
      update,
    ],
  );

  const {
    toggler: confirmationModalToggler,
    isOpen: isOpenConfirmationModal,
    handleOpenModal: handleOpenConfirmationModal,
    handleCloseModal: handleCloseConfirmationModal,
    handleSuccessModal: handleSuccessConfirmationModal,
  } = useModalState({
    onSuccess: () => {
      handleSubmit(handleUpdateData)();
      handleCloseModal();
    },
  });

  return (
    <Sheet open={isOpenModal}>
      <SheetContent
        className="w-full max-w-[485px] border-transparent bg-transparent p-2 shadow-none"
        onInteractOutside={handleCloseModal}
        side="RIGHT"
      >
        <AlertDialogWrapper
          control={{ onOpenChange: confirmationModalToggler, open: isOpenConfirmationModal }}
        >
          <UpdateConfirmationModal
            onClose={handleCloseConfirmationModal}
            onSuccess={handleSuccessConfirmationModal}
            text="pool"
          />
        </AlertDialogWrapper>
        <div className="flex h-full w-full flex-col overflow-hidden rounded-lg border-[1px] border-gray-300 bg-gray-100 pb-4">
          <div className="flex h-fit w-full items-center justify-between bg-white px-6 py-3">
            <span className="truncate text-xl font-[550] text-gray-700">
              {previewMode ? (
                pool?.name
              ) : (
                <AppFormattedMessage
                  id={StringKey.EDIT_POOL}
                  values={{
                    text: 'Pool',
                  }}
                />
              )}
            </span>
            <CloseModalButton onClose={handleCloseModal} />
          </div>
          <div className="flex h-full flex-col gap-4 overflow-hidden">
            {formSteps[step - 1]({
              companyId,
              setError,
              formData: getValues,
              nextFormStep: handleOpenConfirmationModal,
              prevFormStep: () => {},
              setFormData: setValue,
              control,
              handleCloseModal,
              filedState: getFieldState,
              errors,
              clearErrors,
              lockMode: true,
              previewMode,
              handleTerminate: (date) => handleTerminate({ date, id: poolId }),
              isTerminated: pool?.isTerminated,
              terminationDate: pool?.terminationDate,
              onEditClick: (step) => onEditClick({ id: poolId, step }),
            })}
          </div>
        </div>
      </SheetContent>
    </Sheet>
  );
};
