import React, { FC, useCallback, useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { twMerge } from 'tailwind-merge';
import { v4 as uuidv4 } from 'uuid';

import { AlertDialogWrapper } from '../../../../components/AlertDialog';
import { AppFormattedMessage } from '../../../../components/AppFormattedMessage';
import Button from '../../../../components/Button';
import { CloseModalButton } from '../../../../components/CloseModalButton';
import { FormDatePicker } from '../../../../components/DatePicker';
import { DropDown } from '../../../../components/Dropdown';
import { Sheet, SheetContent } from '../../../../components/Sheet';
import { SummaryItem } from '../../../../components/SummaryItem';
import { SharePlan, useFormat, useModalState } from '../../../../hooks';
import { StringKey } from '../../../../lang';
import {
  SharePlanType,
  sharePlanTypeTitle,
  SharePlanVestingType,
  terminationIntervalTitle,
  VestingOnTitle,
} from '../../../../types/pool-plans.types';
import { getS3FileOriginalName } from '../../../../utils/getS3FileOriginalName';
import { UpdateConfirmationModal } from '../../UpdateConfirmationModal';
import { formatSingular } from '../Steps';

type ModalProps = {
  isOpenModal: boolean;
  handleClose: () => void;
  companyId: string;
  invalidateQuery: () => void;
  handleTerminate: (state: { id: string; date: Date }) => void;
  planId: string;
};

export const PlanTerminationDateModal: FC<ModalProps> = ({
  companyId,
  isOpenModal,
  handleClose,
  invalidateQuery,
  planId,
  handleTerminate,
}) => {
  const { sharePlan } = SharePlan.useSharePlan({ companyId, planId });
  const { format } = useFormat();
  const [terminatingDate, setTerminatingDate] = useState<Date | null>(null);
  const [filesLinks, setFilesLinks] = useState<
    | {
        docLink: string;
        loadProgress: 100;
        abort: () => void;
        id: string;
        doc: {
          size: number;
          type: string;
          name: string;
        };
      }[]
    | null
  >(null);

  useEffect(() => {
    if (!sharePlan) return;

    const files: {
      docLink: string;
      loadProgress: 100;
      abort: () => void;
      id: string;
      doc: {
        size: number;
        type: string;
        name: string;
      };
    }[] = sharePlan?.filesLinks?.map((link) => {
      const originalFileName = getS3FileOriginalName(link);

      return {
        abort: () => '',
        loadProgress: 100,
        doc: {
          name: originalFileName || '',
          size: 10,
          type: 'application/pdf',
        },
        docLink: link,
        id: uuidv4(),
      };
    });
    setFilesLinks(files);
  }, [sharePlan]);

  const handleCloseModal = useCallback(() => {
    handleClose();
    invalidateQuery();
  }, [handleClose, invalidateQuery]);

  const {
    toggler: confirmationModalToggler,
    isOpen: isOpenConfirmationModal,
    handleOpenModal: handleOpenConfirmationModal,
    handleCloseModal: handleCloseConfirmationModal,
    handleSuccessModal: handleSuccessConfirmationModal,
  } = useModalState({
    onSuccess: () => {
      if (terminatingDate) {
        handleTerminate({ id: planId, date: terminatingDate });
      }
      handleCloseModal();
      toast.success(<AppFormattedMessage id={StringKey.TERMINATION_DATE_UPDATED} />);
    },
  });

  const handleDocumentClick = (link: string) => {
    window.open(link);
  };

  return (
    <Sheet open={isOpenModal}>
      <SheetContent
        className="w-full max-w-[485px] border-transparent bg-transparent p-2 shadow-none"
        onInteractOutside={handleCloseModal}
        side="RIGHT"
      >
        <AlertDialogWrapper
          control={{ onOpenChange: confirmationModalToggler, open: isOpenConfirmationModal }}
        >
          <UpdateConfirmationModal
            onClose={handleCloseConfirmationModal}
            onSuccess={handleSuccessConfirmationModal}
            text="plan"
          />
        </AlertDialogWrapper>

        <div className="flex h-full w-full flex-col overflow-hidden rounded-lg border-[1px] border-gray-300 bg-gray-100 pb-4">
          <div className="flex h-fit w-full items-center justify-between bg-white px-6 py-3">
            <span className="truncate text-xl font-[550] text-gray-700">
              <AppFormattedMessage id={StringKey.EDIT_TERMINATION_DATE} />
            </span>
            <CloseModalButton onClose={handleCloseModal} />
          </div>
          <div className="flex h-full flex-col gap-4 overflow-hidden">
            {sharePlan && (
              <div className="flex h-full flex-col gap-4 overflow-y-auto rounded-b-xl border-b border-gray-300 bg-white p-6 pt-0">
                <div className="flex h-fit flex-col gap-2 rounded-lg bg-gray-50 pt-2">
                  <div className="flex w-full justify-between px-4">
                    <span className="text-sm font-[550] text-gray-700">
                      <AppFormattedMessage id={StringKey.GENERAL} />
                    </span>
                  </div>
                  <div className="flex flex-col divide-y divide-gray-100 rounded-b-lg border border-gray-100 bg-white p-4 pb-0">
                    <SummaryItem
                      className="pt-0"
                      title={<AppFormattedMessage id={StringKey.PLAN_NAME} />}
                      value={sharePlan.name}
                    />
                    <SummaryItem title="Pool" value={sharePlan.pool.name} />
                    <SummaryItem
                      title={<AppFormattedMessage id={StringKey.DATE} />}
                      value={sharePlan.date ? format(sharePlan.date, 'dd/MM/yyyy') : ''}
                    />
                    <SummaryItem
                      title={<AppFormattedMessage id={StringKey.EQUITY_PLAN_TYPE} />}
                      value={<AppFormattedMessage id={sharePlanTypeTitle[sharePlan.type]} />}
                    />
                    {sharePlan.type === SharePlanType.GROWTH_HURDLE && (
                      <SummaryItem
                        title={<AppFormattedMessage id={StringKey.HURDLE_VALUE} />}
                        value={
                          sharePlan?.hardleValue &&
                          Number(sharePlan.hardleValue).toLocaleString('en-US')
                        }
                      />
                    )}
                    {sharePlan.type === SharePlanType.STOCK_OPTIONS && (
                      <>
                        <SummaryItem
                          title={<AppFormattedMessage id={StringKey.STRIKE_PRICE_VALUE} />}
                          value={sharePlan?.strikePrice}
                        />
                        <SummaryItem
                          title={<AppFormattedMessage id={StringKey.CONVERSION_RATIO_X} />}
                          value={sharePlan?.conversionRatio || '-'}
                        />
                      </>
                    )}
                    {sharePlan.type === SharePlanType.BSPCE && (
                      <>
                        <SummaryItem
                          title={<AppFormattedMessage id={StringKey.STRIKE_PRICE_VALUE} />}
                          value={sharePlan?.strikePrice}
                        />
                        <SummaryItem
                          title={<AppFormattedMessage id={StringKey.CONVERSION_RATIO_X} />}
                          value={sharePlan?.conversionRatio || '-'}
                        />
                      </>
                    )}
                    {sharePlan.type === SharePlanType.WARRANTS && (
                      <>
                        <SummaryItem
                          title={<AppFormattedMessage id={StringKey.WARRANT_PRICE_VALUE} />}
                          value={sharePlan?.warrantPrice}
                        />
                        <SummaryItem
                          title={<AppFormattedMessage id={StringKey.CONVERSION_RATIO_X} />}
                          value={sharePlan?.conversionRatio || '-'}
                        />
                      </>
                    )}
                    {sharePlan.type === SharePlanType.SAR && (
                      <SummaryItem
                        title={<AppFormattedMessage id={StringKey.SAR_BASE_PRICE} />}
                        value={sharePlan?.sarBasePrice}
                      />
                    )}
                    {sharePlan.type === SharePlanType.RSU && (
                      <SummaryItem
                        title={<AppFormattedMessage id={StringKey.CONVERSION_RATIO_X} />}
                        value={sharePlan?.conversionRatio || '-'}
                      />
                    )}
                    {sharePlan.type === SharePlanType.PHANTOM && (
                      <SummaryItem
                        title={<AppFormattedMessage id={StringKey.CONVERSION_RATIO_X} />}
                        value={sharePlan?.conversionRatio || '-'}
                      />
                    )}
                    {sharePlan.type === SharePlanType.RSA && (
                      <>
                        <SummaryItem
                          title={<AppFormattedMessage id={StringKey.RSA_PURCHASE_PRICE} />}
                          value={sharePlan?.rsaPurchasePrice}
                        />
                        <SummaryItem
                          title={<AppFormattedMessage id={StringKey.CONVERSION_RATIO_X} />}
                          value={sharePlan?.conversionRatio || '-'}
                        />
                      </>
                    )}
                  </div>
                </div>
                <div className="flex h-fit flex-col gap-2 rounded-lg bg-gray-50 pt-2">
                  <div className="flex w-full justify-between px-4">
                    <span className="text-sm font-[550] text-gray-700">
                      <AppFormattedMessage id={StringKey.VESTING} />
                    </span>
                  </div>
                  <div className="flex flex-col divide-y divide-gray-100 rounded-b-lg border border-gray-100 bg-white p-4 pb-3">
                    {!sharePlan?.vesting && (
                      <span
                        className={twMerge(
                          'text-xs font-[450] text-gray-500',
                          sharePlan.type === SharePlanType.WARRANTS &&
                            !sharePlan?.expiryDate &&
                            'pb-3',
                        )}
                      >
                        <AppFormattedMessage id={StringKey.NO_VESTING_STRUCTURE_SELECTED} />
                      </span>
                    )}
                    {sharePlan?.vesting?.type === SharePlanVestingType.TIME_BASED_STANDARD && (
                      <>
                        <div>
                          <SummaryItem
                            className="pt-0"
                            title={<AppFormattedMessage id={StringKey.UPDATE} />}
                            value={<AppFormattedMessage id={StringKey.TIME_BASED_STANDARD} />}
                          />
                          <SummaryItem
                            className="pt-0"
                            title={<AppFormattedMessage id={StringKey.VESTING_ON} />}
                            value={
                              sharePlan?.vesting?.vestingOn && (
                                <AppFormattedMessage
                                  id={VestingOnTitle[sharePlan?.vesting?.vestingOn]}
                                />
                              )
                            }
                          />
                        </div>
                        <div>
                          <SummaryItem
                            className="pb-0"
                            title={<AppFormattedMessage id={StringKey.DURATION} />}
                            value={formatSingular(sharePlan?.vesting?.durationMonths, 'month')}
                          />
                          <SummaryItem
                            className="pb-0"
                            title={<AppFormattedMessage id={StringKey.VESTING_FREQUENCY_ABBR} />}
                            value={formatSingular(sharePlan?.vesting?.frequencyMonths, 'month')}
                          />
                          <SummaryItem
                            className="pb-0"
                            title={<AppFormattedMessage id={StringKey.CLIFF} />}
                            value={formatSingular(sharePlan?.vesting?.cliffMonths, 'month')}
                          />
                          <SummaryItem
                            className="pb-0"
                            title={<AppFormattedMessage id={StringKey.ACCELERATION_DATE} />}
                            value={
                              sharePlan?.vesting?.accelarationDate
                                ? format(sharePlan?.vesting?.accelarationDate, 'dd/MM/yyyy')
                                : '-'
                            }
                          />
                        </div>
                      </>
                    )}
                    {sharePlan?.vesting?.type === SharePlanVestingType.TIME_BASED_DYNAMIC && (
                      <>
                        <div>
                          <SummaryItem
                            className="pt-0"
                            title={<AppFormattedMessage id={StringKey.TYPE} />}
                            value={<AppFormattedMessage id={StringKey.TIME_BASED_DYNAMIC} />}
                          />
                          <SummaryItem
                            className="pt-0"
                            title={<AppFormattedMessage id={StringKey.VESTING_ON} />}
                            value={
                              sharePlan?.vesting?.vestingOn && (
                                <AppFormattedMessage
                                  id={VestingOnTitle[sharePlan?.vesting?.vestingOn]}
                                />
                              )
                            }
                          />
                        </div>

                        <div className="flex flex-col divide-y divide-gray-100">
                          {sharePlan?.vesting?.periods.map((period, index) => (
                            <div className="pt-3" key={index}>
                              <span className="text-xs font-[550] text-gray-700">
                                <AppFormattedMessage id={StringKey.PERIOD} /> {index + 1}
                              </span>
                              <SummaryItem
                                className="pb-0"
                                title={<AppFormattedMessage id={StringKey.DURATION} />}
                                value={formatSingular(period.durationMonths, 'month')}
                              />
                              <SummaryItem
                                className="pb-0"
                                title={
                                  <AppFormattedMessage id={StringKey.VESTING_FREQUENCY_ABBR} />
                                }
                                value={formatSingular(period.frequencyMonths, 'month')}
                              />
                              <SummaryItem
                                title={<AppFormattedMessage id={StringKey.PLAN_ALLOCATION} />}
                                value={`${period.planAllocation} %`}
                              />
                            </div>
                          ))}
                        </div>
                        <SummaryItem
                          title={<AppFormattedMessage id={StringKey.ACCELERATION_DATE} />}
                          value={
                            sharePlan?.vesting?.accelarationDate
                              ? format(sharePlan?.vesting?.accelarationDate, 'dd/MM/yyyy')
                              : '-'
                          }
                        />
                      </>
                    )}
                    {sharePlan?.vesting?.type === SharePlanVestingType.TARGET_BASED && (
                      <>
                        <SummaryItem
                          className="pt-0"
                          title={<AppFormattedMessage id={StringKey.TYPE} />}
                          value={<AppFormattedMessage id={StringKey.TARGET_BASED} />}
                        />
                        {sharePlan?.vesting?.milestones.map((period, index) => (
                          <div className="pt-3" key={index}>
                            <span className="text-xs font-[550] text-gray-700">
                              <AppFormattedMessage id={StringKey.MILESTONE} /> {index + 1}
                            </span>
                            <SummaryItem
                              className="pb-0"
                              title={<AppFormattedMessage id={StringKey.MILESTONE_NAME} />}
                              value={period.name}
                            />
                            <SummaryItem
                              className="pb-0"
                              title={<AppFormattedMessage id={StringKey.WEIGHT} />}
                              value={`${period.weight} %`}
                            />
                            <SummaryItem
                              title={<AppFormattedMessage id={StringKey.TARGET_DATE} />}
                              value={
                                period.targetDate ? format(period.targetDate, 'dd/MM/yyyy') : '-'
                              }
                            />
                          </div>
                        ))}
                      </>
                    )}
                    {sharePlan?.type === SharePlanType.WARRANTS && (
                      <SummaryItem
                        className="pb-0"
                        title={<AppFormattedMessage id={StringKey.EXPIRY_DATE} />}
                        value={
                          sharePlan?.expiryDate ? format(sharePlan?.expiryDate, 'dd/MM/yyyy') : '-'
                        }
                      />
                    )}
                  </div>
                </div>

                <div className="flex h-fit flex-col gap-2 rounded-lg bg-gray-50 pt-2">
                  <div className="flex w-full justify-between px-4">
                    <span className="text-sm font-[550] text-gray-700">
                      <AppFormattedMessage id={StringKey.TERMINATION_LOGIC} />
                    </span>
                  </div>
                  <div className="flex flex-col divide-y divide-gray-100 rounded-b-lg border border-gray-100 bg-white p-4 pb-3">
                    {!sharePlan?.terminationLogic && (
                      <span className="text-xs font-[450] text-gray-500">
                        <AppFormattedMessage id={StringKey.NO_TERMINATION_LOGIC_SELECTED} />
                      </span>
                    )}
                    {sharePlan?.terminationLogic && (
                      <>
                        {sharePlan?.terminationLogic?.resignation ? (
                          <div>
                            <SummaryItem
                              className="pb-0"
                              title={<AppFormattedMessage id={StringKey.RESIGNATION} />}
                              value={
                                <AppFormattedMessage id={StringKey.EXERCISE_EXPIRATION_PERIOD} />
                              }
                            />
                            <SummaryItem
                              className="pb-0"
                              title={<AppFormattedMessage id={StringKey.VALUE} />}
                              value={sharePlan.terminationLogic.resignation.value}
                            />
                            <SummaryItem
                              title={<AppFormattedMessage id={StringKey.INTERVAL} />}
                              value={
                                <AppFormattedMessage
                                  id={
                                    terminationIntervalTitle[
                                      sharePlan.terminationLogic.resignation.interval
                                    ]
                                  }
                                />
                              }
                            />
                          </div>
                        ) : (
                          <SummaryItem
                            title={<AppFormattedMessage id={StringKey.RESIGNATION} />}
                            value={
                              <AppFormattedMessage id={StringKey.NO_EXERCISE_EXPIRATION_PERIOD} />
                            }
                          />
                        )}
                        {sharePlan?.terminationLogic?.termination ? (
                          <div>
                            <SummaryItem
                              className="pb-0"
                              title={<AppFormattedMessage id={StringKey.TERMINATION} />}
                              value={
                                <AppFormattedMessage id={StringKey.EXERCISE_EXPIRATION_PERIOD} />
                              }
                            />
                            <SummaryItem
                              className="pb-0"
                              title={<AppFormattedMessage id={StringKey.VALUE} />}
                              value={sharePlan?.terminationLogic?.termination.value}
                            />
                            <SummaryItem
                              title={<AppFormattedMessage id={StringKey.INTERVAL} />}
                              value={
                                <AppFormattedMessage
                                  id={
                                    terminationIntervalTitle[
                                      sharePlan.terminationLogic?.termination.interval
                                    ]
                                  }
                                />
                              }
                            />
                          </div>
                        ) : (
                          <SummaryItem
                            title={<AppFormattedMessage id={StringKey.TERMINATION} />}
                            value={
                              <AppFormattedMessage id={StringKey.NO_EXERCISE_EXPIRATION_PERIOD} />
                            }
                          />
                        )}
                        {sharePlan?.terminationLogic?.['termination-with-cause'] ? (
                          <div>
                            <SummaryItem
                              className="pb-0"
                              title={<AppFormattedMessage id={StringKey.TERMINATION_WITH_CAUSE} />}
                              value={
                                <AppFormattedMessage id={StringKey.EXERCISE_EXPIRATION_PERIOD} />
                              }
                            />
                            <SummaryItem
                              className="pb-0"
                              title={<AppFormattedMessage id={StringKey.VALUE} />}
                              value={sharePlan?.terminationLogic?.['termination-with-cause'].value}
                            />
                            <SummaryItem
                              title={<AppFormattedMessage id={StringKey.INTERVAL} />}
                              value={
                                <AppFormattedMessage
                                  id={
                                    terminationIntervalTitle[
                                      sharePlan?.terminationLogic?.['termination-with-cause']
                                        ?.interval
                                    ]
                                  }
                                />
                              }
                            />
                          </div>
                        ) : (
                          <SummaryItem
                            title={<AppFormattedMessage id={StringKey.TERMINATION_WITH_CAUSE} />}
                            value={
                              <AppFormattedMessage id={StringKey.NO_EXERCISE_EXPIRATION_PERIOD} />
                            }
                          />
                        )}
                        {sharePlan.terminationLogic?.retirement ? (
                          <div>
                            <SummaryItem
                              className="pb-0"
                              title={<AppFormattedMessage id={StringKey.RETIREMENT} />}
                              value={
                                <AppFormattedMessage id={StringKey.EXERCISE_EXPIRATION_PERIOD} />
                              }
                            />
                            <SummaryItem
                              className="pb-0"
                              title={<AppFormattedMessage id={StringKey.VALUE} />}
                              value={sharePlan.terminationLogic?.retirement.value}
                            />
                            <SummaryItem
                              className="pb-0"
                              title={<AppFormattedMessage id={StringKey.INTERVAL} />}
                              value={
                                <AppFormattedMessage
                                  id={
                                    terminationIntervalTitle[
                                      sharePlan.terminationLogic?.retirement?.interval
                                    ]
                                  }
                                />
                              }
                            />
                          </div>
                        ) : (
                          <SummaryItem
                            className="pb-0"
                            title={<AppFormattedMessage id={StringKey.RETIREMENT} />}
                            value={
                              <AppFormattedMessage id={StringKey.NO_EXERCISE_EXPIRATION_PERIOD} />
                            }
                          />
                        )}
                      </>
                    )}
                  </div>
                </div>
                <div className="flex w-full items-center justify-between rounded-lg border border-gray-100 bg-white px-4">
                  <div className={'flex gap-3 text-nowrap py-2'}>
                    <span className="w-[120px] flex-shrink-0 text-xs font-[450] text-gray-500">
                      <AppFormattedMessage id={StringKey.DOCUMENTS} />:
                    </span>
                    <div className="flex max-w-[220px] flex-col gap-2">
                      {(filesLinks?.length || 0) > 0 ? (
                        filesLinks?.map(({ doc, docLink }) => (
                          <div
                            className="cursor-pointer truncate text-xs font-[450] text-brand-700 underline"
                            key={docLink}
                            onClick={() => handleDocumentClick(docLink)}
                          >
                            {doc?.name}
                          </div>
                        ))
                      ) : (
                        <span className="truncate text-xs font-[450] text-gray-700">-</span>
                      )}
                    </div>
                  </div>
                </div>
                <div className="flex w-full items-center justify-between rounded-lg border border-gray-100 bg-white px-4 py-2">
                  <div className="flex w-full gap-3">
                    <span className="w-[120px] flex-shrink-0 text-xs font-[450] text-gray-500">
                      {<AppFormattedMessage id={StringKey.ADDITIONAL_NOTES} />}:
                    </span>
                    <div className="max-w-[250px] break-words text-xs font-[450] text-gray-700">
                      {sharePlan?.additionalNotes || '-'}
                    </div>
                  </div>
                </div>
                <div className="flex flex-col gap-3 p-3 pt-5">
                  <div className="flex flex-col gap-1">
                    <span className="text-sm font-[550] text-fireside-600">
                      <AppFormattedMessage id={StringKey.TERMINATE_PLAN} />
                    </span>
                    <div className="flex items-center gap-2">
                      <span className="text-xs font-[450] text-gray-700">
                        <AppFormattedMessage id={StringKey.CURRENT_TERMINATION_DATE} />:
                      </span>
                      <span className="text-xs font-[450] text-fireside-600">
                        {sharePlan?.terminationDate
                          ? format(sharePlan.terminationDate, 'dd/MM/yy')
                          : ''}
                      </span>
                    </div>
                  </div>

                  <div className="flex w-full flex-col gap-3 rounded-md bg-white p-3 shadow-xs">
                    <FormDatePicker
                      calendar={{ fromDate: new Date() }}
                      defaultMonth={terminatingDate || undefined}
                      inputValue={terminatingDate ? format(terminatingDate, 'dd/MM/yy') : ''}
                      inputWrapperClassName="cursor-pointer"
                      onSelect={(date) => setTerminatingDate(date)}
                      placeholder={<AppFormattedMessage id={StringKey.NEW_TERMINATION_DATE} />}
                      value={terminatingDate}
                    />
                    <DropDown
                      answer="Plan termination refers to the process of ending an employee stock option plan, stock purchase plan, or any other equity compensation plan."
                      className="rounded-lg bg-gray-50"
                      question={<AppFormattedMessage id={StringKey.PLAN_TERMINATION_WORKS} />}
                    />
                  </div>
                </div>
              </div>
            )}
            <div className="flex h-9 w-full justify-between gap-3 px-6">
              <Button
                className="w-fit bg-transparent px-4 py-[6px] text-sm font-[450] text-gray-700"
                onClick={handleCloseModal}
                styleType="NONE"
              >
                <AppFormattedMessage id={StringKey.CANCEL} />
              </Button>
              <div className="flex gap-3">
                <Button
                  className="h-full w-fit bg-fireside-600 px-4 py-[6px] text-sm font-[550] text-white"
                  disabled={!terminatingDate}
                  onClick={handleOpenConfirmationModal}
                  type="button"
                >
                  <AppFormattedMessage id={StringKey.UPDATE} />
                </Button>
              </div>
            </div>
          </div>
        </div>
      </SheetContent>
    </Sheet>
  );
};
