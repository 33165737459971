import { ReactNode } from 'react';

import { AppFormattedMessage } from '../../components/AppFormattedMessage';
import { StringKey } from '../../lang';

export enum CapTableActivityVariant {
  LATEST_CAP_TABLE = 'latest-cap-table',
  CUSTOM_DATE = 'custom-date',
}

export enum AmountOfFetchedItems {
  FIVE = '5',
  TEN = '10',
  TWENTY = '20',
  FIFTY = '50',
  HUNDRED = '100',
}

export enum GraphVariant {
  VOTING_RIGHTS = 'voting-rights',
  OWNERSHIP = 'ownership',
}

export const numberOfFetchedItemsSelectVariant = Object.values(AmountOfFetchedItems);

export const capTableActivityVariantTitle: Record<CapTableActivityVariant, ReactNode | string> = {
  [CapTableActivityVariant.LATEST_CAP_TABLE]: (
    <AppFormattedMessage id={StringKey.LATEST_CAP_TABLE} />
  ),
  [CapTableActivityVariant.CUSTOM_DATE]: <AppFormattedMessage id={StringKey.CUSTOM_DATE} />,
};

export const capTableActivityVariantTitleMap = Object.entries(capTableActivityVariantTitle);

export const latestSnapshotId = 'latest-snapshot';
