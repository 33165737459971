import React, { FC } from 'react';

import { DetailsTab, Safe } from '../../../types/safes.types';
import { SafeItem } from './SafeItem';

export type MobileDetailsProps = {
  safes: Safe[];
  selectedTab: DetailsTab;
};

export const MobileDetails: FC<MobileDetailsProps> = ({ safes, selectedTab }) => {
  return (
    <div className="grid w-full grid-cols-1 gap-4 xlg:grid-cols-2 lg:hidden">
      {safes?.map((safe) => <SafeItem key={safe.id} safe={safe} selectedTab={selectedTab} />)}
    </div>
  );
};
