import { FC, useEffect, useMemo } from 'react';

import { ShareClass, useLocale } from '../../../../hooks';
import { StringKey } from '../../../../lang';
import { stakeholderTypeTranslation } from '../../../../translations/stakeholderTranslation';
import { Column, DetailsSortVariant } from '../../../../types/snapshot.types';
import { StakeholderType } from '../../../../types/stakeholderTypes';
import { filterByAllFields } from '../../../../utils/filterByAllFields';
import { toRound } from '../../../../utils/getRoundedNumber';
import { calcAvg, calcTotal } from '../../../../utils/reduceUtils';
import { toNumber } from '../../../../utils/toNumber';
import { ShareClassTableItem } from '../TableVariants/Items/ShareClassTableItem';
import { StakeholderTableItem } from '../TableVariants/Items/StakeholderTableItem';
import { getShareClasses, SnapshotViewByProps } from './type';

export const SnapshotViewByAll: FC<SnapshotViewByProps> = ({
  snapshot: {
    stats: { stakeholdersTypes, stakeholders, pools, shareClasses },
  },
  viewBy,
  visibleColumns,
  type,
  search,
  setEmptySearchResult,
}) => {
  const { shareClass } = ShareClass.useDefaultShareClass();

  const defaultShareClassId = shareClass?.id || '';

  const { messagesLocale } = useLocale();

  const stakeholdersSearchResult = useMemo(
    () => filterByAllFields(stakeholders, search),
    [stakeholders, search],
  );

  const shareClassesSearchResult = useMemo(
    () => filterByAllFields(shareClasses, search),
    [shareClasses, search],
  );
  const stakeholdersTypesSearchResult = useMemo(
    () => filterByAllFields(stakeholdersTypes, search),
    [stakeholdersTypes, search],
  );

  const stakeholderTypesWithDiluted = stakeholdersTypesSearchResult.filter(
    (stakeholder) => stakeholder.diluted > 0,
  );

  const stakeholdersWithDiluted = stakeholdersSearchResult.filter(
    (stakeholder) => stakeholder.diluted > 0,
  );

  const totalEquityPlansGrantable = calcTotal(pools, ({ dilluted }) => toNumber(dilluted));

  useEffect(() => {
    if (
      ((stakeholdersWithDiluted.length === 0 || stakeholderTypesWithDiluted.length === 0) &&
        type === DetailsSortVariant.BY_STAKEHOLDER) ||
      (shareClassesSearchResult.length === 0 && type === DetailsSortVariant.BY_SHARE_CLASS)
    ) {
      setEmptySearchResult(true);
    } else {
      setEmptySearchResult(false);
    }
  }, [
    setEmptySearchResult,
    shareClassesSearchResult.length,
    stakeholderTypesWithDiluted.length,
    stakeholdersWithDiluted.length,
    type,
  ]);

  switch (type) {
    case DetailsSortVariant.BY_STAKEHOLDER: {
      const { otherShareClasses, defaultShareClass } = getShareClasses(
        defaultShareClassId,
        shareClasses,
      );

      const issuedPercentageTotal = calcTotal(
        stakeholdersTypes,
        ({ issuedPercentage }) => issuedPercentage,
      );

      const dilutedPercentageTotal = calcTotal(
        stakeholdersTypes,
        ({ dilutedPercentage }) => dilutedPercentage,
      );

      const votingRightPercentageTotal = calcTotal(
        stakeholdersTypes,
        ({ votingPercentage }) => votingPercentage,
      );

      const issuedTotal = calcTotal(stakeholdersTypes, ({ issued }) => issued);

      const dilutedTotal = calcTotal(stakeholdersTypes, ({ diluted }) => diluted);

      const votingRightTotal = calcTotal(stakeholdersTypes, ({ votingRight }) => votingRight);

      const commonStockTotal = toNumber(defaultShareClass?.issued);

      const shareClassesTotal = otherShareClasses.map(({ issued }) => issued);

      const investmentTotal = calcTotal(stakeholdersTypes, ({ invested }) => invested);

      const averageSharePrice = calcAvg(
        stakeholderTypesWithDiluted,
        ({ avgSharePrice }) => avgSharePrice,
      );

      return (
        <>
          {stakeholderTypesWithDiluted.map(
            (
              {
                diluted,
                dilutedPercentage,
                issued,
                issuedPercentage,
                type,
                votingPercentage,
                shareClasses,
                votingRight,
                invested,
                avgSharePrice,
              },
              i,
            ) => {
              const { defaultShareClass, otherShareClasses } = getShareClasses(
                defaultShareClassId,
                shareClasses,
              );

              const stakeholderWithCurrentType = stakeholdersWithDiluted.filter(
                (stakeholder) => stakeholder.type === type,
              );

              return (
                <StakeholderTableItem
                  fields={{
                    [Column.NAME]: messagesLocale[stakeholderTypeTranslation[type]],
                    [Column.STAKEHOLDER_NAME]: null,
                    [Column.TYPE]: null,
                    [Column.ISSUED]: toRound(issuedPercentage * 100),
                    [Column.DILUTED]: toRound(dilutedPercentage * 100),
                    [Column.VOTING]: toRound(votingPercentage * 100),
                    [Column.NO_ISSUED]: issued,
                    [Column.DILUTED_SHARES]: diluted,
                    [Column.COMMON_STOCK]: toNumber(defaultShareClass?.issued),
                    [Column.OTHER_SHARE_CLASSES]: otherShareClasses.map(({ issued }) => issued),
                    [Column.VOTING_RIGHTS]: votingRight.toLocaleString('en-US'),
                    [Column.INVESTMENT]: invested.toLocaleString('en-US'),
                    [Column.AVERAGE_SHARE_PRICE]: toRound(avgSharePrice),
                  }}
                  key={`${i}_${issued}${type}`}
                  search={search}
                  stakeholders={stakeholderWithCurrentType}
                  viewBy={viewBy}
                  visibleColumns={visibleColumns}
                />
              );
            },
          )}
          <StakeholderTableItem
            fields={{
              [Column.NAME]: messagesLocale[StringKey.EQUITY_POOLS_GRANTABLE],
              [Column.STAKEHOLDER_NAME]: 0,
              [Column.TYPE]: 0,
              [Column.ISSUED]: 0,
              [Column.DILUTED]: 0,
              [Column.VOTING]: 0,
              [Column.NO_ISSUED]: 0,
              [Column.DILUTED_SHARES]: totalEquityPlansGrantable,
              [Column.COMMON_STOCK]: 0,
              [Column.OTHER_SHARE_CLASSES]: otherShareClasses.map(() => ''),
              [Column.INVESTMENT]: 0,
              [Column.AVERAGE_SHARE_PRICE]: 0,
              [Column.VOTING_RIGHTS]: 0,
            }}
            search={search}
            stakeholders={pools?.map(({ dilluted, id, name }) => ({
              id: id,
              name: name,
              diluted: dilluted,
              dilutedPercentage: 0,
              invested: 0,
              issued: 0,
              issuedPercentage: 0,
              shareClasses: otherShareClasses.map((item) => ({ ...item, issued: 0 })),
              type: StakeholderType.VCS,
              votingPercentage: 0,
              votingRight: 0,
              avgSharePrice: 0,
            }))}
            viewBy={viewBy}
            visibleColumns={visibleColumns}
          />
          <StakeholderTableItem
            className="font-[550] text-gray-800"
            fields={{
              [Column.NAME]: messagesLocale[StringKey.TOTAL],
              [Column.STAKEHOLDER_NAME]: null,
              [Column.TYPE]: null,
              [Column.ISSUED]: toRound(issuedPercentageTotal * 100),
              [Column.DILUTED]: toRound(dilutedPercentageTotal * 100),
              [Column.VOTING]: toRound(votingRightPercentageTotal * 100),
              [Column.NO_ISSUED]: issuedTotal.toLocaleString('en-US'),
              [Column.DILUTED_SHARES]: (dilutedTotal + totalEquityPlansGrantable).toLocaleString(
                'en-US',
              ),
              [Column.COMMON_STOCK]: commonStockTotal.toLocaleString('en-US'),
              [Column.OTHER_SHARE_CLASSES]: shareClassesTotal,
              [Column.VOTING_RIGHTS]: votingRightTotal.toLocaleString('en-US'),
              [Column.INVESTMENT]: investmentTotal.toLocaleString('en-US'),
              [Column.AVERAGE_SHARE_PRICE]: toRound(averageSharePrice),
            }}
            viewBy={viewBy}
            visibleColumns={visibleColumns}
          />
        </>
      );
    }
    case DetailsSortVariant.BY_SHARE_CLASS:
      const issuedPercentageTotal = calcTotal(
        shareClasses,
        ({ issuedPercentage }) => issuedPercentage,
      );

      const dilutedPercentageTotal = calcTotal(
        shareClasses,
        ({ dilutedPercentage }) => dilutedPercentage,
      );

      const votingRightPercentageTotal = calcTotal(
        shareClasses,
        ({ votingPercentage }) => votingPercentage,
      );

      const issuedTotal = calcTotal(shareClasses, ({ issued }) => issued);

      const dilutedTotal = calcTotal(shareClasses, ({ diluted }) => diluted);

      const votingRightTotal = calcTotal(shareClasses, ({ votingRight }) => votingRight);

      const investmentTotal = calcTotal(shareClasses, ({ investment }) => investment);

      const totalNoStakeholders = calcTotal(shareClasses, ({ noStakeholders }) => noStakeholders);

      const averageSharePrice = calcAvg(shareClasses, ({ avgSharePrice }) => avgSharePrice);

      return (
        <>
          {shareClasses.map(
            ({
              diluted,
              dilutedPercentage,
              issued,
              issuedPercentage,
              investment,
              name,
              votingPercentage,
              votingRight,
              id,
              avgSharePrice,
              noStakeholders,
            }) => {
              const shareClassStakeholders = stakeholdersWithDiluted.filter((stakeholder) =>
                stakeholder.shareClasses.find(
                  (shareClass) => shareClass.id === id && shareClass.issued > 0,
                ),
              );

              return (
                <ShareClassTableItem
                  fields={{
                    [Column.SHARE_CLASS_NAME]: null,
                    [Column.STAKEHOLDER_NAME]: null,
                    [Column.TYPE]: null,
                    [Column.NAME]: name,
                    [Column.ISSUED]: toRound(issuedPercentage * 100),
                    [Column.DILUTED]: toRound(dilutedPercentage * 100),
                    [Column.VOTING]: toRound(votingPercentage * 100),
                    [Column.NO_ISSUED]: issued.toLocaleString('en-US'),
                    [Column.DILUTED_SHARES]: diluted.toLocaleString('en-US'),
                    [Column.VOTING_RIGHTS]: votingRight.toLocaleString('en-US'),
                    [Column.NO_OF_STAKEHOLDERS]: noStakeholders,
                    [Column.INVESTMENT]: investment.toLocaleString('en-US'),
                    [Column.AVERAGE_SHARE_PRICE]: toRound(avgSharePrice),
                  }}
                  id={id}
                  key={id}
                  search={search}
                  stakeholders={shareClassStakeholders}
                  viewBy={viewBy}
                  visibleColumns={visibleColumns}
                />
              );
            },
          )}

          <ShareClassTableItem
            fields={{
              [Column.STAKEHOLDER_NAME]: null,
              [Column.TYPE]: null,
              [Column.SHARE_CLASS_NAME]: null,
              [Column.NO_OF_STAKEHOLDERS]: '-',
              [Column.NAME]: messagesLocale[StringKey.EQUITY_POOLS_GRANTABLE],
              [Column.ISSUED]: 0,
              [Column.DILUTED]: 0,
              [Column.VOTING]: 0,
              [Column.NO_ISSUED]: 0,
              [Column.DILUTED_SHARES]: totalEquityPlansGrantable,
              [Column.INVESTMENT]: 0,
              [Column.AVERAGE_SHARE_PRICE]: 0,
              [Column.VOTING_RIGHTS]: 0,
            }}
            stakeholders={pools?.map(({ dilluted, id, name }) => ({
              id,
              name,
              diluted: dilluted,
              dilutedPercentage: 0,
              invested: 0,
              issued: 0,
              issuedPercentage: 0,
              shareClasses: [],
              type: StakeholderType.VCS,
              votingPercentage: 0,
              votingRight: 0,
              avgSharePrice: 0,
            }))}
            viewBy={viewBy}
            visibleColumns={visibleColumns}
          />
          <ShareClassTableItem
            className="font-[550] text-gray-800"
            fields={{
              [Column.NAME]: messagesLocale[StringKey.TOTAL],
              [Column.STAKEHOLDER_NAME]: null,
              [Column.TYPE]: null,
              [Column.SHARE_CLASS_NAME]: null,
              [Column.NO_OF_STAKEHOLDERS]: totalNoStakeholders,
              [Column.ISSUED]: toRound(issuedPercentageTotal * 100),
              [Column.DILUTED]: toRound(dilutedPercentageTotal * 100),
              [Column.VOTING]: toRound(votingRightPercentageTotal * 100),
              [Column.NO_ISSUED]: issuedTotal.toLocaleString('en-US'),
              [Column.DILUTED_SHARES]: (dilutedTotal + totalEquityPlansGrantable).toLocaleString(
                'en-US',
              ),
              [Column.VOTING_RIGHTS]: votingRightTotal.toLocaleString('en-US'),
              [Column.INVESTMENT]: investmentTotal.toLocaleString('en-US'),
              [Column.AVERAGE_SHARE_PRICE]: toRound(averageSharePrice),
            }}
            viewBy={viewBy}
            visibleColumns={visibleColumns}
          />
        </>
      );

    default:
      return <></>;
  }
};
