import { z } from 'zod';

import { StringKey } from '../../../lang';
import {
  CompanyAnnualRevenue,
  CompanyIndustry,
  CompanyNumberOfEmployees,
  CompanyType,
  IncorporatedIn,
} from '../../../types/companyTypes';
import { SubscriptionDuration, SubscriptionType } from '../../../types/planTypes';
import { getTranslation } from '../../../utils/getTranslation';

export const MAX_FILE_SIZE = 1024 * 1024 * 2;

export const INPUT_IMAGE_ACCEPT = {
  'image/jpeg': ['.jpeg', '.jpg'],
  'image/svg+xml': ['.svg'],
  'image/svg': ['.svg'],
  'image/png': ['.png'],
};

export const ACCEPTED_IMAGE_MIME_TYPE = ['image/jpeg', 'image/svg+xml', 'image/svg', 'image/png'];

export const ACCEPTED_IMAGE_FORMATS = ['.jpeg', '.svg', '.png', '.jpg'];

export const imageSchemaMessage = 'Max size: 2MB-PNG, JPEG & SVG';
const imageSchema = z
  .custom<{ link?: never } & File>()
  .refine((files) => {
    return files?.size <= MAX_FILE_SIZE;
  }, imageSchemaMessage)
  .refine(
    (files) => ACCEPTED_IMAGE_MIME_TYPE.includes(files?.type),
    'Only .jpeg, .svg or .png formats are supported.',
  )
  .or(
    z.object({
      size: z.number(),
      type: z.string(),
      name: z.string(),
      link: z.string(),
    }),
  )
  .optional();

export const companySetupFormStepOneSchema = z.object({
  name: z
    .string({ message: getTranslation(StringKey.REQUIRED) })
    .trim()
    .min(1),
  annualRevenue: z.nativeEnum(CompanyAnnualRevenue),
  type: z.nativeEnum(CompanyType),
  industry: z.nativeEnum(CompanyIndustry),
  numberOfEmployees: z.nativeEnum(CompanyNumberOfEmployees),
  url: z
    .union([
      z
        .string()
        .startsWith('https://', {
          message: getTranslation(StringKey.INVALID_URL),
        })
        .url(),
      z.literal(''),
      z
        .string()
        .startsWith('http://', {
          message: getTranslation(StringKey.INVALID_URL),
        })
        .url(),
      z.string().regex(new RegExp(/^[a-zA-Z0-9][a-zA-Z0-9-]{1,61}[a-zA-Z0-9]\.[a-zA-Z]{2,}$/)),
    ])
    .default(''),
});

export type CompanySetupFormStepOneSchema = z.infer<typeof companySetupFormStepOneSchema>;
export const companySetupFormStepTwoSchema = z.object({
  city: z
    .string({ message: getTranslation(StringKey.REQUIRED) })
    .min(1, { message: getTranslation(StringKey.REQUIRED) }),
  address: z
    .string({ message: getTranslation(StringKey.REQUIRED) })
    .trim()
    .min(1),
  logoImgSrc: imageSchema,
  country: z.object(
    {
      name: z.string().min(1, { message: getTranslation(StringKey.REQUIRED) }),
      iso2Code: z.string(),
    },
    { message: getTranslation(StringKey.REQUIRED) },
  ),
  currency: z.object(
    {
      name: z.string().min(1, { message: getTranslation(StringKey.REQUIRED) }),
      iso3Code: z.string(),
      countryIso2Code: z.string().optional(),
    },
    { message: getTranslation(StringKey.REQUIRED) },
  ),
  state: z.object(
    {
      name: z.string().min(1, { message: getTranslation(StringKey.REQUIRED) }),
      iso2Code: z.string(),
    },
    { message: getTranslation(StringKey.REQUIRED) },
  ),
  zipCode: z
    .string()
    .regex(/^(?!.*--)(?!.* -)(?!.*- )(?!.* {2})[a-zA-Z0-9 -]+$/, {
      message: getTranslation(StringKey.ENTER_VALID_ZIP_CODE),
    })
    .min(1, { message: getTranslation(StringKey.ENTER_VALID_ZIP_CODE) })
    .trim(),
});

export type CompanySetupFormStepTwoSchema = z.infer<typeof companySetupFormStepTwoSchema>;

export const companySetupFormStepThreeSchema = z.object({
  incorporatedIn: z.nativeEnum(IncorporatedIn).nullable().default(null),
  incorporationZoneName: z.string().optional(),
  companyId: z.string().or(z.literal('')).default(''),
});

export type CompanySetupFormStepThreeSchema = z.infer<typeof companySetupFormStepThreeSchema>;

export const companySetupFormStepFourSchema = z.object({
  title: z.string(),
  duration: z.nativeEnum(SubscriptionDuration),
  type: z.nativeEnum(SubscriptionType),
  stakeholdersCount: z.number(),
  priceInCents: z.number(),
  id: z.string(),
  stripePriceId: z.string(),
  localCurrency: z.string(),
  localPrice: z.number(),
});

export type CompanySetupFormStepFourSchema = z.infer<typeof companySetupFormStepFourSchema>;

export const companySetupFormSchema = z.object({
  stepOne: companySetupFormStepOneSchema,
  stepTwo: companySetupFormStepTwoSchema,
  stepThree: companySetupFormStepThreeSchema,
  stepFour: companySetupFormStepFourSchema,
});

export type CompanySetupFormSchema = z.infer<typeof companySetupFormSchema>;
