import { FC } from 'react';

import { AppFormattedMessage } from '../../../../components/AppFormattedMessage';
import { useFormat, ValuationHooks } from '../../../../hooks';
import { StringKey } from '../../../../lang';
import { eventFormType, EventTypeBackend } from '../../../../types/events.types';
import { toNumber } from '../../../../utils/toNumber';
import { TransactionOverviewItem } from './TransactionOverviewItem';
import { TransactionTitleItem } from './TransactionTitleItem';
import { CardProps } from './type';

export const Payout: FC<CardProps> = ({ companyId, event }) => {
  const { format } = useFormat();
  const { valuation } = ValuationHooks.useCurrent({ companyId });
  if (event.type !== EventTypeBackend.PAYOUT) return <></>;

  const {
    type,
    exercise: {
      exercised,
      vestingTask: {
        grantItem: { stakeholder },
      },
    },
    createdAt,
  } = event;

  return (
    <>
      <div className="flex flex-col divide-y divide-gray-100 rounded-md shadow-sm">
        <TransactionTitleItem
          createdAt={format(createdAt, 'dd/MM/yyyy')}
          name={<AppFormattedMessage id={eventFormType[type]} />}
        />
        <TransactionOverviewItem
          title={<AppFormattedMessage id={StringKey.TRANSACTION_TYPE} />}
          value={<AppFormattedMessage id={eventFormType[type]} />}
        />
        <TransactionOverviewItem
          title={<AppFormattedMessage id={StringKey.STAKEHOLDER} />}
          value={stakeholder.fullName}
        />
        <TransactionOverviewItem
          title={<AppFormattedMessage id={StringKey.SHARES_PAID_OUT} />}
          value={exercised}
        />
        <TransactionOverviewItem
          title={<AppFormattedMessage id={StringKey.TOTAL_PAYOUT} />}
          value={`${(exercised * toNumber(valuation?.sharePrice)).toLocaleString('en-US')}`}
        />
      </div>
    </>
  );
};
