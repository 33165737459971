import { Locale, localeMessagesMap, StringKey } from '../lang';
import { localeStorage } from '../storage/localeStorage';

const locale = localeStorage.get() || Locale.EN;

export const getTranslation = (key: StringKey, params?: Record<string, any>) => {
  const translation = localeMessagesMap[locale][key];
  if (!params) return translation;

  return translation.replace(/{(\w+)}/g, (_, placeholder) => {
    return params[placeholder] !== undefined ? params[placeholder] : `{${placeholder}}`;
  });
};
