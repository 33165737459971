import { FC, useMemo } from 'react';
import { Control, UseFormSetValue, useWatch } from 'react-hook-form';

import { CalendarIcon, CloseIcon } from '../../../../../../assets/icons';
import { AppFormattedMessage } from '../../../../../../components/AppFormattedMessage';
import Button from '../../../../../../components/Button';
import { DatePicker } from '../../../../../../components/DatePicker';
import { FormInput } from '../../../../../../components/Input';
import { useFormat } from '../../../../../../hooks';
import { StringKey } from '../../../../../../lang';
import { SharePlanType } from '../../../../../../types/pool-plans.types';
import { toNumber } from '../../../../../../utils/toNumber';
import { GrantCombobox, StakeholderCombobox } from '../../FormComponents';
import { FormSchema } from '../../validation';

export type SarTableRowProps = {
  id: string;
  rowIndex: number;
  control: Control<FormSchema>;
  companyId: string;
  setFormData: UseFormSetValue<FormSchema>;
  handleStakeholderChange: () => void;
  handleRemoveRow: () => void;
  totalItems: number;
  availableNoOfSar: number;
  availableSarPayout: number;
  availableIssuedShares: number;
};

export const SarTableRow: FC<SarTableRowProps> = ({
  companyId,
  control,
  setFormData,
  rowIndex,
  handleStakeholderChange,
  handleRemoveRow,
  totalItems,
  availableNoOfSar,
  availableIssuedShares,
  availableSarPayout,
}) => {
  const sarItem = useWatch({ control, name: `stepOne.sar.sarItems.${rowIndex}` });
  const { format } = useFormat();

  const checkSharesCount = useMemo(() => {
    const sarCount = Number(sarItem.sarCount);
    const sarPayout = Number(sarItem.sarPayout);
    const issuedShares = Number(sarItem.issuedShares);
    const sharePrice = Number(sarItem.sharePrice);
    const sarBasePrice = Number(sarItem.grant?.grantItem?.plan?.sarBasePrice);
    const priceDifference = sharePrice && sarBasePrice ? Number(sharePrice - sarBasePrice) : 1;
    const showError =
      sarCount &&
      issuedShares &&
      sarPayout &&
      priceDifference &&
      sarCount !== sarPayout / priceDifference + issuedShares;

    return showError || false;
  }, [sarItem]);

  return (
    <div className="grid w-full grid-cols-[repeat(9,143.3px)_repeat(1,40px)] rounded-t-lg">
      <div className="px-4 pb-10 pt-7">
        <StakeholderCombobox
          companyId={companyId}
          control={control}
          handleStakeholderChange={handleStakeholderChange}
          name={`stepOne.sar.sarItems.${rowIndex}.stakeholder`}
          planType={SharePlanType.SAR}
        />
      </div>
      <div className="px-4 pb-10 pt-7">
        <GrantCombobox
          companyId={companyId}
          control={control}
          name={`stepOne.sar.sarItems.${rowIndex}.grant`}
          sharePlanType={SharePlanType.SAR}
          stakeholderId={sarItem?.stakeholder?.id || ''}
        />
      </div>
      <div className="px-4 pb-10 pt-[22px]">
        <DatePicker
          className="px-0"
          onSelect={(date) => setFormData(`stepOne.sar.sarItems.${rowIndex}.date`, date)}
        >
          <FormInput
            autoComplete="off"
            control={control}
            customValue={(value) => {
              return value && format(value, 'dd/MM/yyyy');
            }}
            disabledInput
            icon={<CalendarIcon className="size-5" iconColor="#344054" />}
            inputClassName="h-10 w-[140px]"
            name={`stepOne.sar.sarItems.${rowIndex}.date`}
            wrapperClassName="w-full"
          />
        </DatePicker>
      </div>

      <div className="flex items-center px-8 pb-10 pt-7">
        <span className="text-sm font-[450] text-gray-700">
          {sarItem?.grant?.grantItem?.plan?.pool?.shareClass?.name}
        </span>
      </div>

      <div className="flex items-center px-4 pb-10 pt-7">
        <span className="text-sm font-[450] text-gray-700">
          ${toNumber(sarItem?.grant?.grantItem?.plan?.sarBasePrice)}
        </span>
      </div>

      <div className="px-4 pb-10 pt-6">
        <FormInput
          control={control}
          iconBeforeInput={<span>$</span>}
          iconBeforeInputClassName="mt-1 -mr-1"
          name={`stepOne.sar.sarItems.${rowIndex}.sharePrice`}
          shouldFormatNumber
          wrapperClassName="h-10 "
        />
      </div>

      <div className="mt-6 flex flex-col gap-2 px-4">
        <FormInput
          control={control}
          isShownErrorMessage={false}
          name={`stepOne.sar.sarItems.${rowIndex}.sarCount`}
          shouldFormatNumber
          wrapperClassName="h-10"
        />
        <span className="text-xs font-[450] text-gray-600">
          <AppFormattedMessage id={StringKey.AVAILABLE} /> {availableNoOfSar}
        </span>
      </div>

      <div className="mt-6 flex flex-col gap-2 px-4">
        <FormInput
          control={control}
          isShownErrorMessage={false}
          name={`stepOne.sar.sarItems.${rowIndex}.sarPayout`}
          shouldFormatNumber
          wrapperClassName="h-10"
        />
        <span className="text-xs font-[450] text-gray-600">
          <AppFormattedMessage id={StringKey.AVAILABLE} /> {availableSarPayout}
        </span>
      </div>

      <div className="mt-6 flex flex-col gap-2 px-4">
        <FormInput
          control={control}
          isShownErrorMessage={false}
          name={`stepOne.sar.sarItems.${rowIndex}.issuedShares`}
          shouldFormatNumber
          wrapperClassName="h-10"
        />
        <span className="text-xs font-[450] text-gray-600">
          <AppFormattedMessage id={StringKey.AVAILABLE} />
          {Math.floor(availableIssuedShares)}
        </span>
      </div>

      {totalItems > 1 && (
        <>
          <Button
            className="flex h-full w-full items-center justify-center pb-5"
            onClick={handleRemoveRow}
            styleType="NONE"
          >
            <CloseIcon />
          </Button>
          {checkSharesCount && (
            <span className="w-[400px] px-4 pb-4 text-xs font-[450] text-fireside-600">
              <AppFormattedMessage id={StringKey.ALL_SARS_MUST_BE_DISTRIBUTED} />
            </span>
          )}
        </>
      )}
    </div>
  );
};
