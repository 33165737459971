import { useQueryClient } from '@tanstack/react-query';
import { useCallback } from 'react';

import { BackendRoute } from '../../config';
import { QueryKey } from '../../constants';
import { ApiService } from '../../services';
import { useAppQuery } from '../useAppQuery';

export type SafeRecap = {
  maxCapValue: number;
  maxDiscount: number;
  maxFloorValue: number;
  minCapValue: number;
  minDiscount: number;
  minFloorValue: number;
};

export const useSafeRecap = ({ companyId }: { companyId: string }) => {
  const queryClient = useQueryClient();

  const { data, isLoading } = useAppQuery<SafeRecap>({
    queryKey: [QueryKey.GET_SAFE_RECAP, companyId],
    queryFn: () =>
      ApiService.get({
        endpoint: BackendRoute.COMPANIES,
        routePath: [companyId, 'safes', 'company-recap'],
      }),
    enabled: !!companyId,
  });

  const invalidateQuery = useCallback(() => {
    queryClient.invalidateQueries({ queryKey: [QueryKey.GET_SAFE_RECAP] });
  }, [queryClient]);

  return {
    invalidateQuery,
    data,
    isLoading,
  };
};
