import React, { FC } from 'react';
import { useWatch } from 'react-hook-form';

import { AppFormattedMessage } from '../../../../components/AppFormattedMessage';
import Button from '../../../../components/Button';
import { StringKey } from '../../../../lang';
import { FundraisingRoundOptionalStep, ShareIssuanceOptionalStep } from '../StepComponents';
import { ChildrenFormProps } from '../type';
import { FormSchema, optionalStep as optionalStepSchema } from '../validation';

export type OptionalStepProps = ChildrenFormProps;

export const OptionalStep: FC<OptionalStepProps> = ({
  handleCloseModal,
  nextFormStep,
  prevFormStep,
  control,
  setFormData,
  companyId,
  lockMode,
  filedState,
  clearErrors,
  errors,
  formData,
  setError,
}) => {
  const { optionalStep, stepOne } = useWatch<FormSchema>({ control });
  const { success } = optionalStepSchema.safeParse(optionalStep);

  const isValid = success && !filedState('stepOne').invalid;

  optionalStep;

  return (
    <>
      <div className="flex h-full flex-col gap-6 overflow-y-auto rounded-b-xl border-b border-gray-300 bg-white p-6 pt-0">
        {stepOne?.eventDetails?.['fundraising-round']?.enabled && (
          <FundraisingRoundOptionalStep
            clearErrors={clearErrors}
            companyId={companyId}
            control={control}
            errors={errors}
            formData={formData}
            lockMode={lockMode}
            setError={setError}
            setFormData={setFormData}
          />
        )}
        {stepOne?.eventDetails?.['share-issuance']?.enabled && (
          <ShareIssuanceOptionalStep companyId={companyId} control={control} />
        )}
      </div>

      <div className="flex h-9 w-full justify-between gap-3 px-6">
        <Button
          className="w-fit bg-transparent px-4 py-[6px] text-sm font-[450] text-gray-700"
          onClick={handleCloseModal}
          styleType="NONE"
          type="button"
        >
          <AppFormattedMessage id={StringKey.CANCEL} />
        </Button>
        <div className="flex gap-3">
          {!lockMode && (
            <Button
              className="h-full w-fit rounded border border-gray-100 bg-white px-3 py-[6px] text-sm font-[450] text-gray-700 shadow-xs"
              onClick={prevFormStep}
              styleType="NONE"
              type="button"
            >
              <AppFormattedMessage id={StringKey.BACK} />
            </Button>
          )}
          <Button
            className="h-full w-fit px-4 py-[6px] text-sm font-[550] text-white"
            disabled={!isValid}
            onClick={nextFormStep}
            type="button"
          >
            {lockMode ? (
              <AppFormattedMessage id={StringKey.UPDATE} />
            ) : (
              <AppFormattedMessage id={StringKey.NEXT} />
            )}
          </Button>
        </div>
      </div>
    </>
  );
};
