import { ReactNode } from 'react';

import { StringKey } from '../../../lang';
import { AppFormattedMessage } from '../../AppFormattedMessage';

export enum ApproveHurdleColumn {
  SELECT = 'select',
  STAKEHOLDER = 'stakeholder',
  GRANT = 'grant',
  APPROVAL_DATE = 'approval-date',
  SHARE_CLASS = 'share-class',
  HURDLE = 'hurdle',
  CURRENT_VALUATION = 'current-valuation',
  ISSUED_SHARES = 'issued-shares',
}
export const approveHurdleColumnMap = Object.values(ApproveHurdleColumn);

export const approveHurdleColumnTitle: Record<ApproveHurdleColumn, StringKey | ReactNode> = {
  [ApproveHurdleColumn.SELECT]: <AppFormattedMessage id={StringKey.EMPTY} />,
  [ApproveHurdleColumn.STAKEHOLDER]: <AppFormattedMessage id={StringKey.STAKEHOLDER} />,
  [ApproveHurdleColumn.GRANT]: <AppFormattedMessage id={StringKey.GRANT} />,
  [ApproveHurdleColumn.APPROVAL_DATE]: <AppFormattedMessage id={StringKey.APPROVAL_DATE} />,
  [ApproveHurdleColumn.SHARE_CLASS]: <AppFormattedMessage id={StringKey.SHARE_CLASS} />,
  [ApproveHurdleColumn.HURDLE]: <AppFormattedMessage id={StringKey.HURDLE} />,
  [ApproveHurdleColumn.CURRENT_VALUATION]: <AppFormattedMessage id={StringKey.CURRENT_VALUATION} />,
  [ApproveHurdleColumn.ISSUED_SHARES]: <AppFormattedMessage id={StringKey.ISSUED_SHARES} />,
};

export enum ApproveTargetBasedColumn {
  SELECT = 'select',
  STAKEHOLDER = 'stakeholder',
  GRANT = 'grant',
  APPROVAL_DATE = 'approval-date',
  SHARE_CLASS = 'share-class',
  PLAN_TYPE = 'plan-type',
  TARGET_NAME = 'target-name',
  TARGET_DATE = 'target-date',
  ISSUED_SHARES = 'issued-shares',
}
export const approveTargetBasedColumnMap = Object.values(ApproveTargetBasedColumn);

export const approveTargetBasedColumnTitle: Record<ApproveTargetBasedColumn, string | ReactNode> = {
  [ApproveTargetBasedColumn.SELECT]: '',
  [ApproveTargetBasedColumn.STAKEHOLDER]: 'Stakeholder',
  [ApproveTargetBasedColumn.GRANT]: 'Grant',
  [ApproveTargetBasedColumn.APPROVAL_DATE]: 'Approval Date',
  [ApproveTargetBasedColumn.SHARE_CLASS]: 'Share Class',
  [ApproveTargetBasedColumn.PLAN_TYPE]: 'Plan Type',
  [ApproveTargetBasedColumn.TARGET_NAME]: 'Target Name',
  [ApproveTargetBasedColumn.TARGET_DATE]: 'Target Date',
  [ApproveTargetBasedColumn.ISSUED_SHARES]: 'Issued Shares',
};
