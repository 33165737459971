import React, { FC } from 'react';

import { AppFormattedMessage } from '../../../../components/AppFormattedMessage';
import Button from '../../../../components/Button';
import { SummaryItem } from '../../../../components/SummaryItem';
import { useFormat } from '../../../../hooks';
import { StringKey } from '../../../../lang';
import { ChildrenFormProps } from '../type';

export type FinalStepProps = ChildrenFormProps;

export const FinalStep: FC<FinalStepProps> = ({
  handleCloseModal,
  nextFormStep,
  formData,
  prevFormStep,
}) => {
  const { stepOne, stepTwo, stepThree } = formData();
  const { format } = useFormat();
  return (
    <>
      <div className="flex h-full flex-col gap-4 overflow-y-auto rounded-b-xl border-b border-gray-300 bg-white p-6 pt-0">
        <div className="flex h-fit flex-col gap-2 rounded-lg bg-gray-50 pt-2">
          <span className="px-4 text-sm font-[550] text-gray-700">
            <AppFormattedMessage id={StringKey.GENERAL} />
          </span>
          <div className="flex flex-col divide-y divide-gray-100 rounded-b-lg border border-gray-100 bg-white p-4 pb-3">
            <SummaryItem
              className="pt-0"
              title={<AppFormattedMessage id={StringKey.SHAREHOLDER} />}
              value={stepOne?.stakeholder?.fullName}
            />

            <SummaryItem
              title={<AppFormattedMessage id={StringKey.INVESTMENT} />}
              value={stepOne?.investment && Number(stepOne?.investment).toLocaleString('en-US')}
            />
            <SummaryItem
              className="pb-0"
              title="Issue Date"
              value={format(stepOne?.issueDate, 'dd/MM/yyyy')}
            />
          </div>
        </div>

        <div className="flex h-fit flex-col gap-2 rounded-lg bg-gray-50 pt-2">
          <span className="px-4 text-sm font-[550] text-gray-700">Cap or Discount Details</span>
          <div className="flex flex-col divide-y divide-gray-100 rounded-b-lg border border-gray-100 bg-white p-4 pb-3">
            <SummaryItem
              className="pt-0"
              title="Cap"
              value={
                stepTwo?.cap?.capValue
                  ? Number(stepTwo?.cap?.capValue).toLocaleString('en-US')
                  : '-'
              }
            />

            <SummaryItem
              className="pb-0"
              title="Discount"
              value={
                stepTwo?.discount?.discountValue ? `${stepTwo?.discount?.discountValue}%` : '-'
              }
            />
          </div>
        </div>

        {stepTwo?.floor?.enabled && (
          <div className="flex h-fit flex-col gap-2 rounded-lg bg-gray-50 pt-2">
            <span className="px-4 text-sm font-[550] text-gray-700">Floor & Maturity Date</span>
            <div className="flex flex-col divide-y divide-gray-100 rounded-b-lg border border-gray-100 bg-white p-4 pb-3">
              <SummaryItem
                className="pt-0"
                title="Floor"
                value={
                  stepTwo?.floor?.floorValue
                    ? Number(stepTwo?.floor?.floorValue).toLocaleString('en-US')
                    : '-'
                }
              />
              <SummaryItem
                className="pb-0"
                title="Maturity Date"
                value={
                  stepTwo?.floor?.maturityDate
                    ? format(stepTwo?.floor?.maturityDate, 'dd/MM/yyyy')
                    : '-'
                }
              />
            </div>
          </div>
        )}

        <div className="flex w-full flex-col rounded-lg border border-gray-100 bg-white">
          <div className={'flex gap-3 text-nowrap px-4 py-2'}>
            <span className="w-[120px] flex-shrink-0 text-xs font-[450] text-gray-500">
              <AppFormattedMessage id={StringKey.DOCUMENTS} />:
            </span>
            <div className="flex max-w-[220px] flex-col gap-2">
              {stepThree?.files?.some(({ docLink }) => docLink) ? (
                stepThree.files.map(
                  ({ doc, docLink }) =>
                    docLink && (
                      <span
                        className="truncate text-xs font-[450] text-gray-700"
                        key={docLink + doc.name}
                      >
                        {doc.name}
                      </span>
                    ),
                )
              ) : (
                <span className="truncate text-xs font-[450] text-gray-700">-</span>
              )}
            </div>
          </div>
        </div>
        <div className="flex w-full flex-col rounded-lg border border-gray-100 bg-white">
          <div className="flex w-full gap-3 px-4 py-2">
            <span className="w-[120px] flex-shrink-0 text-xs font-[450] text-gray-500">
              {<AppFormattedMessage id={StringKey.ADDITIONAL_NOTES} />}:
            </span>
            <div className="max-w-[250px] break-words text-xs font-[450] text-gray-700">
              {stepThree?.additionalNotes || '-'}
            </div>
          </div>
        </div>
      </div>

      <div className="flex h-9 w-full justify-between gap-3 px-6">
        <Button
          className="w-fit bg-transparent px-4 py-[6px] text-sm font-[450] text-gray-700"
          onClick={handleCloseModal}
          styleType="NONE"
          type="button"
        >
          <AppFormattedMessage id={StringKey.CANCEL} />
        </Button>
        <div className="flex gap-3">
          <Button
            className="h-full w-fit rounded border border-gray-100 bg-white px-3 py-[6px] text-sm font-[450] text-gray-700 shadow-xs"
            onClick={prevFormStep}
            styleType="NONE"
            type="button"
          >
            <AppFormattedMessage id={StringKey.BACK} />
          </Button>

          <Button
            className="h-full w-fit px-4 py-[6px] text-sm font-[550] text-white"
            onClick={nextFormStep}
            type="button"
          >
            Add SAFE
          </Button>
        </div>
      </div>
    </>
  );
};
