import { Field, Label } from '@headlessui/react';
import { FC, useState } from 'react';
import { toast } from 'react-toastify';

import { CloseIcon } from '../../../assets/icons';
import MinusIcon from '../../../assets/icons/MinusIcon';
import { Company, useModalState, VestingTaskHooks } from '../../../hooks';
import { StringKey } from '../../../lang';
import { VestingTask } from '../../../types/vestingTasks.types';
import { AlertDialogWrapper } from '../../AlertDialog';
import { AppFormattedMessage } from '../../AppFormattedMessage';
import Button from '../../Button';
import { Checkbox } from '../../Checkbox';
import { Table } from '../../Table';
import { HurdleTableRow } from './HurdleTableRow';
import { ApproveHurdleColumn, approveHurdleColumnMap, approveHurdleColumnTitle } from './types';

export const ApproveHurdlePlan: FC = () => {
  const {
    isOpen,
    toggler,
    handleCloseModal,
    paramsValue: requestId,
  } = useModalState('hurdleApproveId');
  const { create: exercise } = VestingTaskHooks.useExercise();

  const { selectedCompanyId: companyId } = Company.useSelected();

  const { approvalRequest, invalidateQuery } = VestingTaskHooks.useRequestApproveTasks({
    requestId,
    enabled: !!requestId,
  });

  const [selectedVestingTasks, setSelectedVestingTasks] = useState<VestingTask[]>([]);

  const onDeselectAll = () => setSelectedVestingTasks([]);

  const onSelectAll = () => {
    setSelectedVestingTasks(approvalRequest?.vestingTasks || []);
  };

  if (!approvalRequest) return;

  const { valuation, vestingTasks } = approvalRequest;

  return (
    <AlertDialogWrapper control={{ onOpenChange: toggler, open: isOpen }}>
      <div className="flex w-[80%] flex-col gap-8 rounded-2xl bg-white p-8">
        <div className="flex w-full justify-between">
          <span className="text-xl font-semibold text-gray-700">Approve Hurdle Plan</span>
          <Button
            className="centered flex size-7 rounded-full border border-gray-100 shadow-sm"
            onClick={handleCloseModal}
            styleType="NONE"
          >
            <CloseIcon />
          </Button>
        </div>
        <div className="max-h-[400px] w-full overflow-y-auto bg-white">
          <Table
            columns={approveHurdleColumnMap}
            columnsTitle={{
              ...approveHurdleColumnTitle,
              [ApproveHurdleColumn.SELECT]: (
                <Field className="flex cursor-pointer items-center">
                  <Checkbox
                    checked={selectedVestingTasks.length > 0}
                    id="select-all-hurdle-plans"
                    onChange={(checked) => (checked ? onSelectAll() : onDeselectAll())}
                    selectIcon={<MinusIcon />}
                  />
                  <Label
                    className="absolute left-0 top-0 h-full w-full cursor-pointer"
                    htmlFor="select-all-hurdle-plans"
                  />
                </Field>
              ),
            }}
            tHeadClassNames={{ thClassName: 'pl-4' }}
          >
            {vestingTasks.map((vestingTask) => (
              <HurdleTableRow
                isSelected={selectedVestingTasks.some((vt) => vt.id === vestingTask.id)}
                key={vestingTask.id}
                onRemoveSelect={(selectedId) =>
                  setSelectedVestingTasks((prev) => prev.filter((vt) => vt.id !== selectedId))
                }
                onSelect={(selectedId) => setSelectedVestingTasks((prev) => [...prev, selectedId])}
                valuation={valuation}
                vestingTask={vestingTask}
              />
            ))}
          </Table>
        </div>
        <div className="flex h-11 w-full justify-between overflow-x-auto text-sm">
          <Button className="w-fit font-[450] text-gray-700" styleType="NONE">
            <AppFormattedMessage id={StringKey.CANCEL} />
          </Button>
          <Button
            className="w-[260px] font-[550] text-white"
            disabled={selectedVestingTasks.length === 0}
            onClick={() =>
              exercise(
                {
                  companyId,
                  data: selectedVestingTasks.map(({ grantItem: { numbersOfGrants }, id }) => ({
                    value: numbersOfGrants,
                    vestingTaskId: id,
                  })),
                },
                {
                  onSuccess: () => {
                    toast.success(<AppFormattedMessage id={StringKey.EXERCISED_SUCCESSFULLY} />);
                    invalidateQuery();
                    onDeselectAll();
                    toggler(false);
                  },
                },
              )
            }
          >
            Approve Hurdles
          </Button>
        </div>
      </div>
    </AlertDialogWrapper>
  );
};
