import { useQueryClient } from '@tanstack/react-query';
import { useCallback } from 'react';

import { BackendRoute } from '../../config';
import { QueryKey } from '../../constants';
import { ApiService } from '../../services';
import { Snapshot } from '../../types/snapshot.types';
import { useAppQuery } from '../useAppQuery';

export const useCurrent = ({
  companyId,
  includePhantom,
  capTableMode,
}: {
  companyId: string;
  includePhantom?: boolean;
  capTableMode?: boolean;
}) => {
  const queryClient = useQueryClient();

  const { data: snapshot, isLoading } = useAppQuery<Snapshot>({
    queryKey: [QueryKey.GET_SNAPSHOT, companyId, includePhantom],
    queryFn: () =>
      ApiService.get(
        {
          endpoint: BackendRoute.COMPANIES,
          routePath: [companyId, capTableMode ? 'cap-table-current-snapshot' : 'current-snapshot'],
        },
        {
          queryParams: {
            includePhantom,
          },
        },
      ),
    enabled: !!companyId,
  });

  const invalidateQuery = useCallback(() => {
    queryClient.invalidateQueries({ queryKey: [QueryKey.GET_SNAPSHOT] });
  }, [queryClient]);

  return {
    invalidateQuery,
    snapshot,
    isLoading,
  };
};
