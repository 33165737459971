export enum StringKey {
  NO_GO_BACK = 'no-go-back',
  NO_KEEP_1 = 'no-keep-1',
  NO_KEEP_2 = 'no-keep-2',
  NO_KEEP_3 = 'no-keep-3',
  NO_KEEP_4 = 'no-keep-4',
  NO_KEEP_5 = 'no-keep-5',
  CAN_REACTIVATE = 'can-reactivate',
  SAVE_APPROX = 'save-approx',
  BETWEEN_SYMBOL = 'between-symbol',
  COUNT_STAKEHOLDERS = 'count-stakeholders',
  ACTIVE = 'active',
  ACTIVE_UNTIL = 'active-until',
  ACTIVITY = 'activity',
  ADD_NEW_COMPANY = 'add-new-company',
  ADD_STAKEHOLDER = 'add-stakeholder',
  ADDRESS = 'address',
  ADDRESS_OPTIONAL = 'address-optional',
  INACTIVE = 'inactive',
  ROWS_SELECTED = 'rows-selected',
  ACCESS_AND_PERMISSIONS = 'access-and-permissions',
  ACCESS_REVOKED = 'access-revoked',
  ACCOUNT = 'account',
  ADMIN = 'admin',
  ADVISOR = 'advisor',
  ALL = 'all',
  ALL_ASSOCIATED_STAKEHOLDER_DATA_WILL_BE_LOST = 'all-associated-stakeholder-data-will-be-lost',
  ALL_STAKEHOLDERS = 'all-stakeholders',
  ALREADY_HAVE_ACCOUNT = 'already-have-account',
  AMOUNT_TO_PAY_TODAY = 'amount-to-pay-today',
  ANGEL = 'angel',
  ANGELS = 'angels',
  ANNUAL = 'annual',
  ANNUAL_REVENUE = 'annual-revenue',
  ANOTHER_BENEFIT = 'another-benefit',
  ANY_ADDITIONAL_FEEDBACK = 'any-additional-feedback',
  ARE_YOU_SURE_YOU_WANT_CANCEL = 'are-you-sure-you-want-cancel',
  ARE_YOU_SURE_YOU_WANT_DEACTIVATE = 'are-you-sure-you-want-deactivate',
  LOSE_ACCESS_CAUTION = 'lose-access-caution',
  ARE_YOU_SURE_YOU_WANT_DELETE_ACCOUNT = 'are-you-sure-you-want-delete-account',
  ARE_YOU_SURE_YOU_WANT_GRANT_ACCESS = 'are-you-sure-you-want-grant-access',
  ARE_YOU_SURE_YOU_WANT_MAKE_CONTRIBUTOR = 'are-you-sure-you-want-make-contributor',
  AT_LEAST_ONE_LOWER_CASE_LETTER = 'at-least-one-lower-case-letter',
  AT_LEAST_ONE_SPECIAL_CHAR = 'at-least-one-special-char',
  AT_LEAST_ONE_UPPER_CASE_LETTER = 'at-least-one-upper-case-letter',
  AT_LEAST_EIGHT_CHARS = 'at-least-eight-chars',
  AUTO_RENEW_ON = 'auto-renew-on',
  BACK = 'back',
  BACK_TO_SIGN_IN = 'back-to-sign-in',
  BEFORE_YOU_START_YOUR_JOURNEY_CONFIRMATION = 'before-you-start-your-journey-confirmation',
  BILLED_ANNUALY = 'billed-annualy',
  ANNUALY = 'annualy',
  MAXIMUM_FILE_SIZE = 'maximum-file-size',
  CARD_ENDING_NUMBERS = 'card-ending-numbers',
  LETS_TALK = 'lets-talk',
  LAST_UPDATE = 'last-update',
  INDUSTRY = 'industry',
  BILLED_MONTHLY = 'billed-monthly',
  BILLED_MONTHLY_PLAN = 'billed-monthly-plan',
  PAY_MONTHLY = 'pay-monthly',
  BIRTHDAY = 'birthday',
  MAXIMUM_PHOTO_SIZE = 'maximum-photo-size',
  BIRTHDAY_OPTIONAL = 'birthday-optional',
  BULK_MODIFY = 'bulk-modify',
  BULK_MODIFY_STAKEHOLDERS = 'bulk-modify-stakeholders',
  BY_PROCEEDING_YOU_AGREE_TO = 'by-proceeding-you-agree-to',
  TERMS_OF_SERVICE = 'terms-of-service',
  AND = 'and',
  PRIVACY_POLICY = 'privacy-policy',
  CANCEL = 'cancel',
  CANCEL_INVITATION = 'cancel-invitation',
  CANCEL_SUBSCRIPTION = 'cancel-subscription',
  CANNOT_DOWNGRADE = 'cannot-downgrade',
  CAP_TABLE_MANAGEMENT = 'cap-table-management',
  MISSING_FEATURES = 'missing-features',
  TOO_EXPENSIVE = 'too-expensive',
  CARD_NUMBER = 'card-number',
  CARDHOLDER_NAME = 'cardholder-name',
  CHANGE_PASSWORD = 'change-password',
  CHANGE_PLAN = 'change-plan',
  CHANGE_YOUR_PASSWORD = 'change-your-password',
  CHECK_THE_URL = 'check-the-url',
  CHECK_YOUR_EMAIL = 'check-your-email',
  CHECK_YOUR_INBOX = 'check-your-inbox',
  CITY = 'city',
  CLICK_TO_UPLOAD = 'click-to-upload',
  OR_DRAG_AND_DROP = 'or-drag-and-drop',
  CLOSE = 'close',
  COLLAPSE = 'collapse',
  COMPANY_ADDRESS = 'company-address',
  COMPANY_ID_NUMBER_OPTIONAL = 'company-id-number-optional',
  COMPANY_IDENTIFIER = 'company-identifier',
  COMPANY_IDENTIFIER_OPTIONAL = 'company-identifier-optional',
  COMPANY_LOGO_OPTIONAL = 'company-logo-optional',
  COMPANY_NAME = 'company-name',
  COMPANY_REGISTRATION_DETAILS = 'company-registration-details',
  COMPANY_SETTINGS = 'company-settings',
  COMPANY_SETUP = 'company-setup',
  COMPANY_TYPE = 'company-type',
  COMPANY_URL = 'company-url',
  COMPANY_URL_OPTIONAL = 'company-url-optional',
  CONFIRM = 'confirm',
  CONFIRM_PASSWORD = 'confirm-password',
  FILTER_ALL = 'filter-all',
  FILTER_ACTIVE = 'filter-active',
  FILTER_INACTIVE = 'filter-inactive',
  FILTER_IN_USE = 'filter-in-use',
  FILTER_NOT_USED = 'filter-not-used',
  KILOBYTE = 'kilobyte',
  MINUTES_AGO = 'minutes-ago',
  SINGULAR_MIN = 'singular-min',
  PLURAL_MIN = 'plural-min',
  HOURS_AGO = 'hours-ago',
  SINGULAR_HOUR = 'singular-hour',
  PLURAL_HOUR = 'plural-hour',
  DAYS_AGO = 'days-ago',
  SINGULAR_DAY = 'singular-day',
  PLURAL_DAY = 'plural-day',
  MONTHS_AGO = 'months-ago',
  SINGULAR_MONTH = 'singular-month',
  PLURAL_MONTH = 'plural-month',
  LESS_THAN_MIN_AGO = 'less-than-min-ago',
  ZIP_CODE = 'zip-code',
  SWITCH_TO_ANNUAL_PLAN_MESSAGE = 'switch-to-annual-plan-message',
  SWITCH_TO_ANNUAL_PLAN_CHARGE_MESSAGE = 'switch-to-annual-plan-charge-message',
  SWITCH_TO_ANNUAL_PLAN_ACTIVE_MESSAGE = 'switch-to-annual-plan-active-message',
  COMPANY_SETUP_EXIT = 'company-setup-exit',
  STAKEHOLDER_HUMAN_GROUP = 'stakeholder-human-group',
  MONTH = 'month',
  MONTHS = 'months',
  MONTHLY = 'monthly',
  PLAN_SOON_EXPIRE = 'plan-soon-expire',
  STAKEHOLDERS_LIMIT = 'stakeholders-limit',
  CURRENT_SHARE_PRICE = 'current-share-price',
  VALUE_AT_LATEST_VALUATION_EVENT = 'value-at-latest-valuation-event',
  ADD_SHARE_CLASS = 'add-share-class',
  ADD_NEW_SHARE_CLASS = 'add-new-share-class',
  DIVIDENDS = 'dividends',
  DIVIDEND_RIGHT = 'dividend-right',
  ANTI_DILUTION = 'anti-dilution',
  VOTING_RIGHTS = 'voting-rights',
  VOTING_RIGHT = 'voting-right',
  CONVERSION_RATIO = 'conversion-ratio',
  CONVERSION_RATIO_X = 'conversion-ratio-x',
  CONVERSION_RATIO_X_OPTIONAL = 'conversion-ratio-x-optional',
  SENIORITY = 'seniority',
  LIQUIDITY = 'liquidity',
  LIQUIDITY_PREF = 'liquidity-pref',
  LIQUIDITY_PREFERENCES = 'liquidity-preferences',
  PARTICIPATING_CAP = 'participating-cap',
  PARTICIPATING = 'participating',
  MULTIPLY = 'multiple',
  MULTIPLY_VALUE = 'multiple-value',
  INTEREST = 'interest',
  DOCUMENT = 'document',
  DOC = 'doc',
  CREATION_DATE = 'creation-date',
  RIGHTS = 'rights',
  EDIT = 'edit',
  EDIT_SHARE_CLASS = 'edit-share-class',
  SHARE_CLASS_NAME = 'share-class-name',
  GENERAL = 'general',
  HOW_TO_NAME_A_SHARE_CLASS = 'how-to-name-a-share-class',
  WHAT_ARE_VOTING_RIGHTS = 'what-are-voting-rights',
  WHAT_ARE_DIVIDEND_RIGHTS = 'what-are-dividend-rights',
  WHAT_IS_CONVERSION_RATIO = 'what-is-conversion-ratio',
  CAP_VALUE = 'cap-value',
  YEARLY_INTEREST = 'yearly-interest',
  NON_COMPOUNDING = 'non-compounding',
  DAYS_PER_YEAR = 'days-per-year',
  WHAT_ARE_LIQUIDITY_PREFERENCES = 'what-are-liquidity-preferences',
  WHAT_IS_SENIORITY = 'what-is-seniority',
  WHAT_IS_MULTIPLY = 'what-is-multiply',
  WHAT_IS_PARTICIPATING = 'what-is-participating',
  WHAT_IS_CAP_VALUE = 'what-is-cap-value',
  ANTI_DILUTION_RIGHTS = 'anti-dilution-rights',
  WHAT_IS_ANTI_DILUTION = 'what-is-anti-dilution',
  BASE = 'base',
  BROAD_BASED_WEIGHTED_AVERAGE = 'broad-based-weighted-avarage',
  FULL_RATCHET = 'full-ratchet',
  NARROW_BASED_WEIGHTED_AVERAGE = 'narrow-based-weighted-avarage',
  BOARD_RESOLUTION = 'board-resolution',
  NO_FILE_ATTACHED = 'no-file-attached',
  SHARE_CLASS_CREATED = 'share-class-created',
  SHARE_CLASS_UPDATED = 'share-class-updated',
  OWNERSHIP_SUMMARY = 'ownership=summary',
  YOUR_CONSOLIDATED_POSITION = 'your-consolidated-position',
  NUMBER_OF_ISSUED_SHARES = 'number-of-issued-shares',
  COMPANY_OWNED = 'company-owned',
  SHARES_PRICE = 'shares-price',
  VALUE_OF_SHARES = 'value-of-shares',
  INVESTMENT = 'investment',
  ROI = 'roi',
  YOUR_POSITION_ISSUED = 'your-position-issued',
  YOU = 'you',
  EQUITY_AND_CAPITAL_CHANGE = 'equity-and-capital-change',
  YOUR_ESTIMATED_VALUE_MILLION = 'your-estimated-value-million',
  YOUR_EQUITY = 'your-equity',
  AUTOMATIC_VESTING = 'automatic-vesting',
  VESTING_SCHEDULE_AND_PLAN = 'vesting-schedule-and-plan',
  ABOUT_PLAN = 'about-plan',
  YOUR_VESTING_STRUCTURE = 'your-vesting-structure',
  PLAN = 'plan',
  GRANT_ID = 'grant-id',
  PLAN_TYPE = 'plan-type',
  CURRENT_PRICE = 'current-price',
  STRIKE_PRICE = 'strike-price',
  GRANTED = 'granted',
  TYPE = 'type',
  REMAINING = 'remaining',
  VESTED = 'vested',
  NEXT_VESTING = 'next-vesting',
  FULL_VESTING = 'full-vesting',
  EXERCISE = 'exercise',
  INVESTMENT_SUMMARY = 'investment-summary',
  EMPLOYEE = 'employee',
  FOUNDER = 'founder',
  BOARD_MEMBERS = 'board-members',
  VCS = 'vcs',
  INVESTMENT_BANKS = 'investment-banks',
  PES = 'pes',
  FAMILY_OFFICE = 'family-office',
  VENTURE_CAPITAL = 'venture-capital',
  COMPANY = 'company',
  OTHER_INST = 'other-inst',
  CONTRIBUTOR = 'contributor',
  STAKEHOLDER = 'stakeholder',
  INVITE_USER = 'invite-user',
  INVITED = 'invited',
  ACTIVE_STAKEHOLDERS = 'active-stakeholders',
  INACTIVE_STAKEHOLDERS = 'inactive-stakeholders',
  ALL_TERMINATED = 'all-terminated',
  ACTIVE_TERMINATED = 'active-terminated',
  INACTIVE_TERMINATED = 'inactive-terminated',
  STAKEHOLDERS_TAB = 'stakeholders-tab',
  TERMINATED_TAB = 'terminated-tab',
  CONGRATS_ON_YOUR_GROWTH = 'congrats-on-your-growth',
  CONTINUE = 'continue',
  CONTINUE_TO_PLANS = 'continue-to-plans',
  CONTINUE_TO_USE_CAPQUEST_UNTIL = 'continue-to-use-capquest-until',
  CONTINUE_WITH_GOOGLE = 'continue-with-google',
  COUNTRY_OF_COMPANY = 'country-of-company',
  CREATE_MY_ACCOUNT = 'create-my-account',
  CREATING_YOUR_ACCOUNT = 'creating-your-account',
  CURRENT_PLAN = 'current-plan',
  CURRENTLY_SELECTED = 'currently-selected',
  DASHBOARD = 'dashboard',
  DATE_CREATED = 'date-created',
  DATE_OF_INCORPORATION = 'date-of-incorporation',
  DATE_OF_INCORPORATION_OPTIONAL = 'date-of-incorporation-optional',
  DAY = 'day',
  DEACTIVATE = 'deactivate',
  DEACTIVATE_COMPANY = 'deactivate-company',
  DELETE = 'delete',
  DELETE_ACCOUNT = 'delete-account',
  DELETE_STAKEHOLDER = 'delete-stakeholder',
  DELETE_YOUR_CAPQUEST_ACCOUNT = 'delete-your-capquest-account',
  DEMO = 'demo',
  DETAILS = 'details',
  DONT_HAVE_AN_ACCOUNT_YET = 'dont-have-an-account-yet',
  DONT_WORRY_YOUR_DATA_IS_SAVED = 'dont-worry-your-data-is-saved',
  DOWNGRADE = 'downgrade',
  YOURE_SAVE_APPROX_WITH_ANNUAL = 'save-approx-with-annual',
  UPGRADE = 'upgrade',
  SWITCH_ANNUAL = 'switch-annual',
  SELECT = 'default-plan-text',
  SPACIAL_PLAN_TEXT = 'spacial-plan-text',
  CANNOT_DOWNGRADE_STAKEHOLDERS = 'cannot-downgrade-stakeholders',
  CANCEL_SUBSCRIPTION_PROBLEM_1 = 'cancel-subscription-problem-1',
  EXIT = 'exit',
  CONFIRM_DELETE = 'confirm-delete',
  CONFIRM_CANCEL = 'confirm-cancel',
  CONFIRM_DEACTIVATE = 'confirm-deactivate',
  YEAR = 'year',
  YEARS = 'years',
  WHERERE_LOCATED = 'wherere-located',
  WELCOME = 'welcome',
  WELCOME_TO_CAPQUEST = 'welcome-to-capquest',
  WELCOME_BACK = 'welcome-back',
  WELCOME_ABOARD = 'welcome-aboard',
  FEW_DETAILS = 'few-details',
  SENDED_RECEIPT_TO_EMAIL = 'sended-receipt-to-email',
  SENDED_LOGIN_VERIFY_LINK = 'sended-login-verify-link',
  WANT_EXIT = 'want-exit',
  VIEW_MODIFICATION_GUID = 'view-modification-guid',
  VIEW_IMPORT_GUID = 'view-import-guid',
  VIEW_DETAILS = 'view-details',
  DOWNLOAD_STAKEHOLDER_INFO_PART1 = 'download-stakeholder-info-part1',
  DOWNLOAD_STAKEHOLDER_INFO_PART2 = 'download-stakeholder-info-part2',
  DOWNLOAD_STAKEHOLDER_INFO_PART3 = 'download-stakeholder-info-part3',
  DOWNLOAD_TEMPLATE = 'download-template',
  EDIT_DETAILS = 'edit-details',
  EDIT_STAKEHOLDER = 'edit-stakeholder',
  EFFICIENTLY_HANDLE_MANAGEMENT = 'efficiently-handle-management',
  EMAIL = 'email',
  EMAIL_ADDRESS = 'email-address',
  ENTER_A_NEW_PASSWORD_BELOW = 'enter-a-new-password-below',
  ENTER_YOUR_EMAIL_ADDRESS_AND_WE_WILL = 'enter-your-email-address-and-we-will',
  ENTERPRISE_AND_FI = 'enterprise-and-fi',
  EVENTS = 'events',
  EVERYTHING_FROM_STARTER_PLAN = 'everything-from-starter-plan',
  EXPIRY = 'expiry',
  EXPORT = 'export',
  FILE_FORMAT_NOT_SUPPORTED = 'file-format-not-supported',
  FILE_PROCESSED_SUCCESSFULLY = 'file-processed-successfully',
  FOR_CUSTOM_PRICING = 'for-custom-pricing',
  FORGOT_PASSWORD = 'forgot-password',
  FULL_NAME = 'full-name',
  GENERATING_INVITATION_AGAIN_WILL_VOID = 'generating-invitation-again-will-void',
  GRANT_ACCESS = 'grant-access',
  HELP_ME_SETUP = 'help-me-setup',
  HELP_US_MAKE_CAPQUEST_BETTTER = 'help-us-make-capquest-bettter',
  HERE_ARE_A_FEW_THING_TO_TRY = 'here-are-a-few-thing-to-try',
  I_FOUND_BETTER_ALTERNATIVE = 'i-found-better-alternative',
  I_HAVE_SEEN_MY_INFO = 'i-have-seen-my-info',
  I_NO_LONGER_NEED_IT = 'i-no-longer-need-it',
  IF_YOU_CANCEL = 'if-you-cancel',
  IF_YOU_RECEIVED_LINK_IN_EMAIL = 'if-you-received-link-in-email',
  IMPORT = 'import',
  IMPORT_STAKEHOLDERS = 'import-stakeholders',
  IN_THE_MEANTIME_FEEL_FREE_TO = 'in-the-meantime-feel-free-to',
  INACTIVE_COMPANY = 'inactive-company',
  INCOMPLETE_SETUP = 'incomplete-setup',
  LAST_ACTIVE = 'last-active',
  LET_US_HELP_TO_SETUP = 'let-us-help-to-setup',
  LET_US_KNOW_ABOUT_YOUR_COMPANY_DETAILS = 'let-us-know-about-your-company-details',
  LOCATION = 'location',
  LOG_OUT = 'log-out',
  LOG_IN = 'log-in',
  LOSE_SPECIAL_OFFER_PRICE = 'lose-special-offer-price',
  MAKE_CONTRIBUTOR = 'make-contributor',
  MY_BUSINESS_IS_NO_LONGER_ACTIVE = 'my-business-is-no-longer-active',
  VIEW_MY_COMPANIES = 'view-my-companies',
  MY_COMPANIES = 'my-companies-title',
  MY_COMPANY = 'my-company',
  MY_HOLDING_EQUITY = 'my-holding-equity',
  MY_HOLDINGS = 'my-holdings',
  MY_PROFILE = 'my-profile',
  NAME = 'name',
  NATIONALITY = 'nationality',
  NATIONALITY_OPTIONAL = 'nationality-optional',
  NEW_PASSWORD = 'new-password',
  NEXT = 'next',
  VAT_GST = 'vat-gst',
  USER_ROLE = 'user-role',
  MISC_ADVISE_3 = 'misc-advise-3',
  UPLOAD_AGAIN = 'upload-again',
  UPGRADE_PLAN_NOW = 'upgrade-plan-now',
  UPDATE = 'update',
  UNABLE_DELETE = 'unable-delete',
  TERMINATION = 'termination',
  TERMINATED = 'terminated',
  TERMINATE = 'terminate',
  SWITCH_TO_ANNUAL_BILLING = 'switch-to-annual-billing',
  SWITCH_COMPANY = 'switch-company',
  SUPPORTED_FORMATS = 'supported-formats',
  SUBSCRIPTION_CYCLE = 'subscription-cycle',
  SUBSCRIPTION_CANCELED = 'subscription-canceled',
  SUBMIT = 'submit',
  STATUS = 'status',
  STATE = 'state',
  STAKEHOLDERS = 'stakeholders',
  STAKEHOLDER_UPDATE_TOAST = 'stakeholder-update',
  STAKEHOLDER_TYPE = 'stakeholder-type',
  STAKEHOLDER_DELETED_TOAST = 'stakeholder-deleted-toast',
  STAKEHOLDER_CREATED_TOAST = 'stakeholder-created-toast',
  SOMETHING_ELSE = 'something-else',
  SKYROCKETING = 'skyrocketing',
  SKIP = 'skip',
  SIGN_UP = 'sign-up',
  SIGN_UP_TO_CAPQUEST = 'sign-up-to-capquest',
  SIGN_IN = 'sign-in',
  SIGN_IN_TO_CAPQEUST = 'sign-in-to-capqeust',
  SHARE_CLASSES = 'share-classes',
  POOL_PLANS_POOL = 'pool-plans-pool',
  POOL_PLANS_PLAN = 'pool-plans-plan',
  POOL_PLANS = 'pool-plans',
  CAP_TABLE = 'cap-table',
  SETTINGS = 'settings',
  ACCEPT_INVITATION = 'accept-invitation',
  LOGIN = 'login',
  REGISTER = 'register',
  REGISTER_VERIFY = 'register-verify',
  PASSWORD_RECOVERY = 'password-recovery',
  BILLING = 'billing',
  ERROR = 'error',
  SEND_EMAIL = 'send-email',
  SEARCH = 'search',
  SAVED_APPROX_V2 = 'saved-approx-v2',
  SAVE_APPROX_W_ANNUAL_BILLING = 'save-approx-w-annual-billing',
  SAVE = 'save',
  REVOKE_CONTRIBUTOR_ROLE = 'revoke-contributor-role',
  REVOKE_ACCESS = 'revoke-access',
  RESUME_COMPANY_SETUP = 'resume-company-setup',
  RESUME = 'resume',
  RESIGNATION = 'resignation',
  RESEND_VERIFICATION_EMAIL = 'resend-verification-email',
  RESEND_INVITATION = 'resend-invitation',
  RESEND_EMAIL = 'resend-email',
  NO = 'no',
  NO_OF_EMPLOYEES = 'no-of-employees',
  NO_RESULTS_MATCHS = 'no-results-matchs',
  NO_REV = 'no-rev',
  NO_THANKS = 'no-thanks',
  NOTE_THAT_YOUR_INFORMATION_STILL_SAVED = 'note-that-your-information-still-saved',
  OOPS = 'oops',
  OR = 'or',
  PASSPORT_EXPIRY_DATE = 'passport-expiry-date',
  PASSPORT_EXPIRY_DATE_OPTIONAL = 'passport-expiry-date-optional',
  PASSPORT_NO = 'passport-no',
  PASSPORT_NO_OPTIONAL = 'passport-no-optional',
  PASSWORD = 'password',
  PASSWORD_CHANGED = 'password-changed',
  PAY_NOW = 'pay-now',
  PAY_YEARLY = 'pay-yearly',
  PAYMENT_METHOD = 'payment-method',
  PER_MONTH = 'per-month',
  PERSONAL_INFO = 'personal-info',
  PHONE_NUMBER = 'phone-number',
  PHONE_NUMBER_OPTIONAL = 'phone-number-optional',
  PLAN_BILLING = 'plan-billing',
  BILLING_HISTORY = 'billing-history',
  PLAN_FOR_ALL_SIZES = 'plan-for-all-sizes',
  PLAN_SELECTED = 'plan-selected',
  PLEASE_CHECK_THE_EMAIL = 'please-check-the-email',
  PLEASE_TRY_AGAIN = 'please-try-again',
  POOLS_PLANS = 'pools-plans',
  PREVIOUS = 'previos',
  REACTIVATE = 'reactivate',
  REACTIVATE_SUBSCRIPTION = 'reactivate-subscription',
  REENTER_NEW_PASSWORD = 'reenter-new-password',
  REGISTRATION_NUMBER_OPTIONAL = 'registration-number-optional',
  REPORTING_CURRENCY = 'reporting-currency',
  YES = 'yes',
  YES_CANCEL = 'yes-cancel',
  YES_DEACTIVATE = 'yes-deactivate',
  YES_DELETE = 'yes-delete',
  YES_EXIT = 'yes-exit',
  YES_GRANT = 'yes-grant',
  YES_RESEND = 'yes-resend',
  YES_REVOKE = 'yes-revoke',
  YES_UPDATE = 'yes-update',
  YES_TERMINATE = 'yes-terminate',
  UPDATE_STAKEHOLDER = 'update-stakeholder',
  UPDATE_TERMINATION = 'update-termination',
  SIGN_UP_MESSAGE = 'sign-up-message',
  COMPANY_ID_EXPLANATION = 'company-id-explanation',
  UPDATE_INFORMATION_MESSAGE = 'update-information-message',
  INCORRECT_LINK_PT_1 = 'incorrect-link-pt-1',
  INCORRECT_LINK_PT_2 = 'incorrect-link-pt-2',
  NOT_ABLE_TO_USE_STAKEHOLDER = 'not-able-to-use-stakeholder',
  STAKEHOLDER_CANCEL_INVITE_DESCRIPTION = 'stakeholder-cancel-invite-description',
  GREETING_COMPANY_SETUP = 'greeting-company-setup',
  PASSWORD_RECOMMENDATION = 'password-recommendation',
  PASSWORD_SUCCESSFULLY_CHANGED = 'password-successfully-changed',
  TERMINATE_STAKEHOLDER = 'terminate-stakeholder',
  STAKEHOLDER_SUCCESSFULLY_CREATED = 'stakeholder-successfully-created',
  SAVE_APPROX_V2 = 'save-approx-v2',
  FRENCH = 'french',
  ENGLISH = 'english',
  NO_KEEP = 'no-keep',
  CONTACT_SUPPORT = 'contact-support',
  WEBSITE = 'website',
  OVERVIEW = 'overview',
  LATEST_CAP_TABLE = 'latest-cap-table',
  OVERALL_STATUS = 'overall-status',
  OWNERSHIP_PERCENTAGE = 'ownership-percentage',
  VOTING_RIGHTS_PERCENTAGE = 'voting-rights-percentage',
  SWITCH_TO_VOTING_RIGHTS = 'switch-to-voting-rights',
  SWITCH_TO_OWNERSHIP = 'switch-to-ownership',
  POST_MONEY_VALUATION = 'post-money-valuation',
  VALUATION_DATE = 'valuation-date',
  ISSUED_SHARES = 'issued-shares',
  LIFETIME_EQUITY_INVESTMENT = 'lifetime-equity-investment',
  INVESTORS = 'investors',
  DILUTED_SHARES = 'diluted-shares',
  BY_STAKEHOLDER = 'by-stakeholder',
  BY_SHARE_CLASS = 'by-share-class',
  CUSTOMIZE_VIEW = 'customize-view',
  EQUITY_POOLS_GRANTABLE = 'equity-pools-grantable',
  ISSUED_PERCENTAGE = 'issued-percentage',
  DILUTED_PERCENTAGE = 'diluted-percentage',
  VOTING_PERCENTAGE = 'voting-percentage',
  VOTING = 'voting',
  NO_ISSUED = 'no-issued',
  AVERAGE_SHARE_PRICE = 'average-share-price',
  SWITCH = 'switch',
  VIEW_POSITIONS = 'view-positions',
  POSITIONS = 'positions',
  VIEW_ASSOCIATED_STAKEHOLDERS = 'view-associated-stakeholders',
  CUSTOM_DATE = 'custom-date',
  RESET = 'reset',
  BY = 'by',
  SHARE_CLASS = 'share-class',
  VIEW_BY = 'view-by',
  COLUMNS = 'columns',
  OTHER_SHARE_CLASSES = 'other-share-classes',
  SET_AS_DEFAULT_VIEW = 'set-as-default-view',
  STAKEHOLDER_NAME = 'stakeholder-name',
  COMMON_STOCK = 'common-stock',
  EMPTY = 'empty',
  NO_OF_STAKEHOLDERS = 'no-of-stakeholders',
  SELECT_AT_LEAST_ONE_VIEW_BY = 'select-at-least-one-view-by',
  TOTAL_ROUNDS = 'total-rounds',
  CURRENT_VALUATION = 'current-valuation',
  TOTAL_RAISED_AMOUNT = 'total-raised-amount',
  AVG_VALUATION_CHANGE = 'avg-valuation-change',
  PER_ROUND = 'per-round',
  LATEST_SHARE_PRICE = 'latest-share-price',
  YOUR_PARTICIPATION = 'your-participation',
  TOTAL_INVESTMENT = 'total-investment',
  ESTIMATED_VALUE = 'estimated-value',
  OVERALL_EQUITY_CHANGE = 'overall-equity-change',
  AVERAGE_INVESTMENT = 'average-investment',
  TOTAL_RAISED = 'total-raised',
  YOUR_EQUITY_CHANGE = 'your-equity-change',
  HURDLE = 'hurdle',
  PRIVATE_EQUITY = 'private-equity',
  SPV = 'spv',
  OTHER = 'other',
  STAKEHOLDER_COMPANY_GROUP = 'stakeholder-company-group',
  ALL_DONE = 'all-done',
  HANG_TIGHT = 'hang-tight',
  ONBOARDING = 'onboarding',
  TOTAL = 'total',
  BASE_PRICE = 'base-price',
  VESTING_PLAN = 'vesting-plan',
  EXERCISABLE = 'exercisable',
  EXERCISED = 'exercised',
  TO_BE_VESTED = 'to-be-vested',
  WARRANT_PRICE = 'warrant-price',
  RSA_PRICE = 'rsa-price',
  NOTHING_HERE = 'nothing-here',
  HOLDINGS_INFO = 'holdings-info',
  REQUEST_EXERCISE = 'request-exercise',
  STOCK_OPTIONS = 'stock-options',
  WARRANT = 'warrant',
  RSA = 'rsa',
  GRANT = 'grant',
  REQUEST_DATE = 'request-date',
  TOTAL_COST = 'total-cost',
  AVAILABLE = 'available',
  GROWTH_HURDLE = 'growth-hurdle',
  SAR = 'sar',
  RSU = 'rsu',
  PHANTOM = 'phantom',
  BSPCE = 'bspce',
  TOP_5_STAKEHOLDERS = 'top-5_stakeholders',
  POOL_ALLOCATION = 'pool-allocation',
  VALUATION_HISTORY = 'valuation-history',
  CURRENCY_USD = 'currency-usd',
  FULLY_DILUTED = 'fully-diluted',
  SHAREHOLDER = 'shareholder',
  SHARES = 'shares',
  ACTIVITY_CENTER = 'activity-center',
  UNREAD_ONLY = 'unread-only',
  MARK_ALL_AS_READ = 'mark-all-as-read',
  GETTING_STARTED = 'getting-started',
  REQUESTS = 'requests',
  RESOLVED = 'resolved',
  STAKEHOLDER_ADDED = 'stakeholder-added',
  INVITATION_ACCEPTED = 'invitation-accepted',
  STAKEHOLDER_TERMINATED = 'stakeholder-terminated',
  ACCEPTED_INVITATION = 'accepted-invitation',
  TODAY = 'today',
  READ_NOTIFICATIONS_DELETED = 'read-notifications-deleted',
  MARK_AS_READ = 'mark-as-read',
  HURDLE_APPROVAL = 'hurdle-approval',
  MARK_AS_RESOLVED = 'mark-as-resolved',
  GRANT_EXERCISED = 'grant-exercised',
  MARKED_AS_RESOLVED = 'marked-as-resolved',
  HURDLE_APPROVED = 'hurdle-approved',
  NO_NOTIFICATIONS = 'no-notifications',
  GET_STARTED = 'get-started',
  ADD_PLAN = 'add-plan',
  ADD_EVENT = 'add-event',
  ALL_CAUGHT_UP = 'all-caught-up',
  SHOW_ALL = 'show-all',
  EXERCISE_STOCK_OPTIONS = 'exercise-stock-options',
  EXERCISE_EXPIRE_WARRANTS = 'exercise-expire-warrants',
  EXERCISE_RSA = 'exercise-rsa',
  EXERCISE_SAR = 'exercise-sar',
  APPROVE = 'approve',
  SUCCESSFUL_COMPANY_SETUP = 'successful-company-setup',
  ADD_STAKEHOLDER_MESSAGE = 'add-stakeholder-message',
  ADD_SHARE_CLASS_MESSAGE = 'add-share-class-message',
  ADD_POOL_MESSAGE = 'add-pool-message',
  ADD_PLAN_MESSAGE = 'add-plan-message',
  ADD_EVENT_MESSAGE = 'add-event-message',
  APPROVE_HURDLE_PLAN = 'approve-hurdle-plan',
  PAYOUT_PHANTOM_SHARES = 'payout-phantom-shares',
  EXERCISE_BSPCE = 'exercise-bspce',
  CAP_TABLE_DETAILS = 'cap-table-details',
  DATE = 'date',
  LOADING = 'loading',
  NO_GRANTS_FOUND = 'no-grants-found',
  NO_SHAREHOLDER_FOUND = 'no-shareholder-found',
  CONVERSION = 'conversion',
  RSA_PURCHASE_PRICE = 'rsa-purchase-price',
  EXERCISED_OPTIONS = 'exercised-options',
  EXERCISE_DATE = 'exercise-date',
  EXERCISED_WARRANTS = 'exercised-warrants',
  EXERCISE_REQUESTED = 'exercise-requested',
  OTHERS = 'others',
  INTEREST_PERCENTAGE_PROMPT = 'interest-percentage-prompt',
  UPLOADED_PERCENTAGE = 'uploaded-percentage',
  INVALID_FILE_TYPE = 'invalid-file-type',
  VOTING_MULTIPLIER = 'voting-multiplier',
  PRE_EMPTIVE_RIGHTS = 'pre-emptive-rights',
  DELETE_SHARE_CLASS = 'delete-share-class',
  SHARE_CLASS_DATA_LOSS_WARNING = 'share-class-data-loss-warning',
  UPDATE_SHARE_CLASS = 'update-share-class',
  UPDATE_SHARE_CLASS_CONFIRM = 'update-share-class-confirm',
  REVIEW_CHANGES_WARNING = 'review-changes-warning',
  BY_POOLS = 'by-pools',
  VEST_BY = 'vest-by',
  ADD = 'add',
  POOL_GRANTED = 'pool-granted',
  TOTAL_GRANT_VESTED = 'total-grant-vested',
  SHARES_VESTED = 'shares-vested',
  CASH_SETTLED = 'cash-settled',
  RETURNED_TO_POOL = 'returned-to-pool',
  EXPIRED = 'expired',
  TERMINATION_SET = 'termination-set',
  EDIT_TERMINATION_DATE = 'edit-termination-date',
  POOL_NAME = 'pool-name',
  FULLY_DILUTED_SHARES = 'fully-diluted-shares',
  DILUTION_CONDITION = 'dilution-condition',
  DOCUMENTS = 'documents',
  ADDITIONAL_NOTES = 'additional-notes',
  ADDITIONAL_NOTES_OPTIONAL = 'additional-notes-optional',
  TERMINATE_POOL = 'terminate-pool',
  CURRENT_TERMINATION_DATE = 'current-termination-date',
  NEW_TERMINATION_DATE = 'new-termination-date',
  POOL_TERMINATION_WORKS = 'pool-termination-works',
  TERMINATE_POOL_CONFIRMATION = 'terminate-pool-confirmation',
  IF_YOU_TERMINATE = 'if-you-terminate',
  TERMINATION_WARNING = 'termination-warning',
  POOL_NO_LINK_AFTER_TERMINATION = 'pool-no-link-after-termination',
  PLAN_NO_GRANTS_AFTER_TERMINATION = 'plan-no-grants-after-termination',
  AVAILABLE_POOL = 'available-pool',
  NEW_POOL = 'new-pool',
  NEW_PLAN = 'new-plan',
  VIEW_POOL = 'view-pool',
  EDIT_POOL = 'edit-pool',
  DELETE_POOL = 'delete-pool',
  DELETE_POOL_WARNING = 'delete-pool-warning',
  DELETE_PLAN_WARNING = 'delete-plan-warning',
  ADD_NEW_POOL = 'add-new-pool',
  ADD_POOL = 'add-pool',
  SELECT_POOL = 'select-pool',
  SELECT_SHARE_CLASS = 'select-share-class',
  INCLUDE_CAP_TABLE = 'include-cap-table',
  EXCLUDE_CAP_TABLE = 'exclude-cap-table',
  AMOUNT_FULLY_DILUTED_SHARES_HELP = 'amount-fully-diluted-shares-help',
  DILUTION_CONDITIONS_HELP = 'dilution-conditions-help',
  DOCUMENTS_UPLOAD = 'documents-upload',
  POOL_CREATED_SUCCESS = 'pool-created-success',
  ADD_NEW_PLAN = 'add-new-plan',
  PLAN_NAME = 'plan-name',
  EQUITY_PLAN_TYPE = 'equity-plan-type',
  VALUATION_REQUIRED = 'valuation-required',
  VALUE = 'value',
  WHAT_IS_HURDLE = 'what-is-hurdle',
  VESTING = 'vesting',
  TIME_BASED_STANDARD = 'time-based-standard',
  TIME_BASED_DYNAMIC = 'time-based-dynamic',
  TIME_BASED = 'time-based',
  STANDARD = 'standard',
  DYNAMIC = 'dynamic',
  TARGET_BASED = 'target-based',
  VESTING_ON = 'vesting-on',
  FIRST_DAY_OF_MONTH = 'first-day-of-month',
  LAST_DAY_OF_MONTH = 'last-day-of-month',
  GRANT_DATE = 'grant-date',
  DURATION = 'duration',
  VESTING_FREQUENCY = 'vesting-frequency',
  CLIFF = 'cliff',
  ACCELERATION_DATE = 'acceleration-date',
  VESTING_FREQUENCY_ABBR = 'vesting-freq',
  PLAN_ALLOCATION = 'plan-allocation',
  ADD_PERIOD = 'add-period',
  MILESTONE_NAME = 'milestone-name',
  WEIGHT = 'weight',
  TARGET_DATE = 'target-date',
  ADD_MILESTONE = 'add-milestone',
  TERMINATION_LOGIC = 'termination-logic',
  NO_POOLS_FOUND = 'no-pools-found',
  TERMINATE_PLAN = 'terminate-plan',
  PLAN_TERMINATION_WORKS = 'plan-termination-works',
  DATE_OF_TERMINATION = 'date-of-termination',
  ADD_EXERCISING = 'add-exercising',
  PLAN_EXERCISE_COMPLETED = 'plan-exercise-completed',
  EXPIRED_WARRANTS = 'expired-warrants',
  PHANTOM_SHARES = 'phantom-shares',
  TOTAL_PAYOUT = 'total-payout',
  PURCHASE_PRICE = 'purchase-price',
  SELECT_HURDLE_PLAN = 'select-hurdle-plan',
  APPROVAL_DATE = 'approval-date',
  EXERCISED_SUCCESSFULLY = 'exercised-successfully',
  VESTING_ON_VALUE_HELP = 'vesting-on-value-help',
  SET_DURATION_HELP = 'set-duration-help',
  VESTING_FREQUENCY_HELP = 'vesting-frequency-help',
  VESTING_CLIFF_VALUE_HELP = 'vesting-cliff-value-help',
  MILESTONE_WEIGHT_HELP = 'milestone-weight-help',
  ACTIVITY_OVERVIEW = 'activity-overview',
  OWNERSHIP_HISTORY = 'ownership-history',
  OWNERSHIP_HISTORY_PERCENT = 'ownership-history-percent',
  BY_SHARES = 'by-shares',
  CURRENCY = 'currency',
  MILLIONS = 'millions',
  SEED = 'seed',
  EVENT_HISTORY = 'event-history',
  ALL_TYPES = 'all-types',
  OPEN_ROUND = 'open-round',
  PRE_MONEY_VALUATION = 'pre-money-valuation',
  TRANSACTIONS = 'transactions',
  TRANSACTION_DATE = 'transaction-date',
  TRANSACTION_TYPE = 'transaction-type',
  NO_OF_SHARES = 'no-of-shares',
  FILE = 'file',
  ADD_INVESTMENT = 'add-investment',
  PIN_EVENT = 'pin-event',
  DELETE_EVENT = 'delete-event',
  UNPIN = 'unpin',
  UPDATE_EVENT = 'update-event',
  CONFIRM_UPDATE_EVENT = 'confirm-update-event',
  REVIEW_CHANGES = 'review-changes',
  VIEW_EVENT = 'view-event',
  EDIT_EVENT = 'edit-event',
  NO_EVENTS = 'no-events',
  ADD_FIRST_EVENT = 'add-first-event',
  TRANSACTION = 'transaction',

  SAR_EXERCISED = 'sar-exercised',
  SAR_PAYOUT = 'sar-payout',
  HURDLE_VALUE = 'hurdle-value',
  SHARES_PAID_OUT = 'shares-paid-out',
  FROM_SHARE_CLASS = 'from-share-class',
  TO_SHARE_CLASS = 'to-share-class',
  EQUITY_PLAN = 'equity-plan',
  INVESTMENTS = 'investments',
  EQUITY_POOL = 'equity-pool',
  VALUATION_EVENT = 'valuation-event',
  SHARE_PRICE = 'share-price',
  FROM_STAKEHOLDER = 'from-stakeholder',
  TO_STAKEHOLDER = 'to-stakeholder',
  SHARE_VALUE = 'share-value',
  TRANSFER_AMOUNT = 'transfer-amount',
  ESTABLISH_CUSTOM_TERMINATION = 'establish-custom-termination',
  NO_EXERCISE_EXPIRATION_PERIOD = 'no-exercise-expiration-period',
  EXERCISE_EXPIRATION_PERIOD = 'exercise-expiration-period',
  TERMINATION_WITH_CAUSE = 'termination-with-cause',
  RETIREMENT = 'retirement',
  INTERVAL = 'interval',
  PLAN_ALLOCATION_DOES_NOT_REACH_100 = 'plan-allocation-does-not-reach-100',
  WEIGHT_ALLOCATION_DOES_NOT_REACH_100 = 'weight-allocation-does-not-reach-100',
  DURATION_SHOULD_BE_MULTIPLY = 'duration-should-be-multiply',
  UPDATE_POOL = 'update-pool',
  WHAT_IS_STRIKE_PRICE = 'what-is-strike-price',
  SAR_BASE_PRICE = 'sar-base-price',
  WHAT_IS_SAR_BASE_PRICE = 'what-is-sar-base-price',
  WHAT_IS_WARRANT_PRICE = 'what-is-warrant-price',
  EXPIRY_DATE = 'expiry-date',
  EXERCISE_STOCK_WITH_EASE = 'exercise-stock-with-ease',
  MANAGE_WARRANTS = 'manage-warrants',
  EXERCISE_RSA_WITH_EASE = 'exercise-rsa-with-ease',
  EXECUTE_SAR = 'execute-sar',
  PAYOUT_PHANTOM = 'payout-phantom',
  PAYOUT_PHANTOM_WITH_EASE = 'payout-phantom-with-ease',
  APPROVE_HURDLE = 'approve-hurdle',
  APPROVE_HURDLE_WITH_EASE = 'approve-hurdle-with-ease',
  EXPIRE = 'expire',
  SAR_FOR_EXERCISING = 'sar-for-exercising',
  NO_OF_SARS = 'no-of-sars',
  SELECT_HURDLE_TO_APPROVE = 'select-hurdle-to-approve',
  SAFES = 'safes',
  EVENT = 'event',
  NO_PLANS_TO_APPROVE = 'no-plans-to-approve',
  NAME_SHOULD_BE_UNIQUE = 'name-should-be-unique',
  ASSOCIATED_TO_THIS_PLAN = 'associated-to-this-plan',
  ALL_SARS_MUST_BE_DISTRIBUTED = 'all-sars-must-be-distributed',
  EXERCISE_DATE_CANT_BE_LATER_THAN_EXPIRE = 'exercise-date-cant-be-later-than-expire',
  EXPIRED_SUCCESSFULLY = 'expired-successfully',
  ONE_OF_COUNT_FILES_UPLOADED = 'one-of-count-files-uploaded',
  PLAN_SUCCESSFULLY_UPDATED = 'plan-successfully-updated',
  STRIKE_PRICE_VALUE = 'strike-price-value',
  WARRANT_PRICE_VALUE = 'warrant-price-value',
  NO_VESTING_STRUCTURE_SELECTED = 'no-vesting-structure-selected',
  PERIOD = 'period',
  MILESTONE = 'milestone',
  NO_TERMINATION_LOGIC_SELECTED = 'no-termination-logic-selected',
  NEW_PLAN_CREATED_SUCCESSFULLY = 'new-plan-created-successfully',
  POOL_SUCCESSFULLY_UPDATED = 'pool-successfully-updated',
  TERMINATION_DATE_UPDATED = 'termination-date-updated',
  VIEW_ASSOCIATED_PLANS = 'view-associated-plans',
  PLAN_SUCCESSFULLY_DELETED = 'plan-successfully-deleted',
  POOL_SUCCESSFULLY_DELETED = 'pool-successfully-deleted',
  CHANGES_SAVED = 'changes-saved',
  REQUIRED = 'required',
  CAP_VALUE_LESS_THAN_MULTIPLE = 'cap-value-less-than-multiple',
  MAXIMUM_CHARACTERS = 'maximum-characters',
  FILE_SIZE_TOO_LARGE = 'file-size-too-large',
  CANNOT_ADD_MORE_THAN_COUNT_ITEMS = 'cannot-add-more-than-count-items',
  OPTIONS_MUST_BE_LESS_THAN_BALANCE = 'options-must-be-less-than-balance',
  SARS_MUST_BE_LESS_THAN_BALANCE = 'sars-must-be-less-than-balance',
  SHARES_MUST_BE_LESS_THAN_BALANCE = 'shares-must-be-less-than-balance',
  ENTER_VALID_ZIP_CODE = 'enter-valid-zip-code',
  INVALID_URL = 'invalid-url',
  INVALID_NUMBER = 'invalid-number',
  ITEMS_MAXIMUM = 'items-maximum',
  CHARACTER_LIMIT_IS_COUNT = 'character-limit-is-count',
  INVALID_DATE = 'invalid-date',
  INVALID_INVESTMENT_DATE = 'invalid-investment-date',
  INVESTMENT_DATE_MUST_BE_BEFORE_CLOSE_DATE = 'investment-date-must-be-before-close-date',
  STAKEHOLDERS_MUST_BE_DIFFERENT = 'stakeholders-must-be-different',
  SHARE_CLASSES_MUST_BE_DIFFERENT = 'share-classes-must-be-different',
  MUST_SELECT_VESTING_OPTION = 'must-select-vesting-option',
  DURATION_SHOULD_BE_MULTIPLE_OF_FREQUENCY = 'duration-should-be-multiple-of-frequency',
  INVALID_TARGET_DATE = 'invalid-target-date',
  PASSWORDS_DONT_MATCH = 'passwords-dont-match',
  MODIFY_GUIDE = 'modify-guide',
  BY_PERCENTAGE = 'by-percentage',
  NUMBER_OF_SHARES_IN_THOUSANDS = 'number-of-shares-in-thousands',
  FUNDRAISING = 'fundraising',
  SHARES_ISSUANCE = 'shares-issuance',
  APPROVAL_HURDLE = 'approval-hurdle',
  EXPIRE_WARRANTS = 'expire-warrants',
  LAST_COUNT_DAYS = 'last-count-days',
  LAST_QUARTER = 'last-quarter',
  LAST_YEAR = 'last-year',
  ALL_TIME = 'all-time',
  ADD_GRANT = 'add-grant',
  BULK_IMPORT_GRANT = 'bulk-import-grant',
  ADD_FUNDRAISING_ROUND = 'add-fundraising-round',
  ADD_SHARE_ISSUANCE = 'add-share-issuance',
  ADD_SECONDARIES = 'add-secondaries',
  ADD_BUYBACK = 'add-buyback',
  ADD_CLASS_CONVERSION = 'add-class-conversion',
  ADD_VALUATION = 'add-valuation',
  DELETE_TRANSACTION = 'delete-transaction',
  CLOSED_ROUND = 'closed-round',
  START_DATE = 'start-date',
  END_DATE = 'end-date',
  TRANSACTION_SUCCESSFULLY_DELETED = 'transaction-successfully-deleted',
  EVENT_SUCCESSFULLY_DELETED = 'event-successfully-deleted',
  UNABLE_TO_DELETE_EVENT = 'unable-to-delete-event',
  GRANTS_SUCCESSFULLY_IMPORTED = 'grants-successfully-imported',
  UNEXPECTED_ERROR_OCCURRED = 'unexpected-error-occurred',
  ERROR_RESPONSE_NOT_PARSED = 'error-response-not-parsed',
  NEW_EVENT_CREATED_SUCCESSFULLY = 'new-event-created-successfully',
  NO_RESULTS_FOUND = 'no-results-found',
  CHOOSE_VALUATION_NAME = 'choose-valuation-name',
  ROUND_NAME = 'round-name',
  EVENT_NAME = 'event-name',
  REMOVE = 'remove',
  NO_PLAN_FOUND = 'no-plan-found',
  ADD_NEW_SHAREHOLDER = 'add-new-shareholder',
  ADD_ANOTHER_BUYBACK = 'add-another-buyback',
  EVENT_SUCCESSFULLY_UPDATED = 'event-successfully-updated',
  ADD_ANOTHER_CLASS_CONVERSION = 'add-another-class-conversion',
  ADD_ANOTHER_GRANT = 'add-another-grant',
  ADD_ANOTHER_SHARE_TRANSFER = 'add-another-share-transfer',
  ADD_ANOTHER_ISSUANCE = 'add-another-issuance',
  EVENT_DATE = 'event-date',
  PLEASE_CHOOSE_DIFFERENT_SHARE_CLASSES = 'please-choose-different-share-classes',
  CONVERTED_SHARES = 'converted-shares',
  ADD_ANOTHER_INVESTMENT = 'add-another-investment',
  BULK_IMPORT_GRANT_FILES = 'bulk-import-grant-files',
  INITIATE_FUNDRAISING_ROUND = 'initiate-fundraising-round',
  TRANSFER_SHARES_SEAMLESSLY = 'transfer-shares-seamlessly',
  EXECUTE_SHARE_BUYBACKS = 'execute-share-buybacks',
  BUYBACK_DECREASE_SHARES = 'buyback-decrease-shares',
  ENTER_COMPANY_VALUATION = 'enter-company-valuation',
  VIEW_COUNT_TRANSACTIONS = 'view-count-transactions',
  FILES_ATTACHED_TO_EVENT = 'files-attached-to-event',
  WARRANTS_MUST_BE_BALANCE = 'warrants-must-be-balance',
  POOLS = 'pools',
  PLANS = 'plans',
  INVESTMENT_DATE = 'investment_date',
  FUNDRAISING_ROUND_STATUS = 'fundraising_round_status',
  FUNDRAISING_ROUND_STATE = 'fundraising_round_state',
  FUNDRAISING_ROUND_CONDITIONS = 'fundraising_round_conditions',
  CALCULATED_BY = 'calculated_by',
  VALUE_CALCULATION_INFO = 'value_calculation_info',
  GRANT_ASSOCIATION_INFO = 'grant_association_info',
  DIFFERENT_STAKEHOLDERS = 'different_stakeholders',
  ISSUANCE = 'issuance',
  INVEST_DATE = 'invest_date',
  ROUND_STATUS = 'round_status',
  OPEN_DATE = 'open_date',
  CLOSING_DATE = 'closing_date',
  EMPOWER_STAKEHOLDERS = 'empower_stakeholders',
  BULK_IMPORT_GRANTS = 'bulk_import_grants',
  INITIATE_FUNDRAISING = 'initiate_fundraising',
  DISTRIBUTE_SHARES = 'distribute_shares',
  ISSUE_SHARES = 'issue_shares',
  SECONDARIES = 'secondaries',
  TRANSFER_SHARES = 'transfer_shares',
  SHARE_BUYBACKS = 'share_buybacks',
  BUYBACK_DECREASE = 'buyback-decrease',
  CONVERT_SHARE_CLASSES = 'convert_share_classes',
  COMPANY_VALUATION = 'company_valuation',
  VIEW_TRANSACTIONS = 'view_transactions',
  NO_EVENTS_FOUND = 'no_events_found',
  FILTERS = 'filters',
  TYPES = 'types',
  APPLY_FILTER = 'apply_filter',
  FILES = 'files',
  ATTACHED_FILES = 'attached_files',
  DOWNLOAD_ALL = 'download_all',
  WARRANTS_BALANCE = 'warrants_balance',
  OPEN = 'open',
  CLOSED = 'closed',
  CLASS_CONVERSION = 'class-conversion',
  VALUATION = 'valuation',
  FUNDRAISING_ROUND = 'fundraising-round',
  SHARE_ISSUANCE = 'share-issuance',
  APPROVAL = 'approval',
  PAYOUT = 'payout',
  BUYBACK = 'buyback',
}
