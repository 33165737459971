import { useInfiniteQuery, useQueryClient } from '@tanstack/react-query';
import { useMemo } from 'react';

import { BackendRoute } from '../../config';
import { QueryKey } from '../../constants';
import { ApiService } from '../../services';
import { Safe, SafeOrder, SafeSortBy } from '../../types/safes.types';
import { GetSafesResponse } from './useSafes';

export const useInfiniteSafes = ({
  companyId,
  sortBy,
  sortOrder,
  convertedOnly = false,
  take = 100,
  currentPage = 0,
  search,
}: {
  companyId: string;
  take?: number;
  currentPage?: number;
  search?: string;
  sortOrder?: SafeOrder | null;
  sortBy?: SafeSortBy | null;
  convertedOnly?: boolean;
}) => {
  const queryClient = useQueryClient();

  const skip = (currentPage - 1 <= 0 ? 0 : currentPage - 1) * take;
  const { data, fetchNextPage, hasNextPage } = useInfiniteQuery<GetSafesResponse>({
    queryKey: [
      QueryKey.GET_SAFES,
      companyId,
      {
        page: currentPage,
        sortBy,
        sortOrder,
        skip,
        take,
        search,
        convertedOnly,
      },
    ],
    queryFn: ({ pageParam = 0 }) =>
      ApiService.get(
        { endpoint: BackendRoute.COMPANIES, routePath: [companyId, 'safes'] },
        {
          queryParams: {
            take,
            skip: currentPage ? skip : pageParam,
            search,
            convertedOnly: convertedOnly,
            ...(sortOrder && sortBy ? { sortBy, sortOrder } : {}),
          },
        },
      ),
    initialPageParam: 0,
    getNextPageParam: (lastPage, allPages) =>
      lastPage.totalPages > allPages.length ? allPages.length * take : undefined,
  });

  const safesList = useMemo(
    () =>
      data?.pages?.reduce<Safe[]>((acc, page) => {
        return [...acc, ...page.data];
      }, []),
    [data?.pages],
  );

  const invalidateQuery = () => {
    queryClient.invalidateQueries({ queryKey: [QueryKey.GET_SAFES] });
  };

  return {
    fetchNextPage,
    hasNextPage,
    invalidateQuery,
    safes: safesList || [],
    totalPages: data?.pages[0]?.totalPages || 0,
  };
};
