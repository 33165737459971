import React, { FC, ReactNode } from 'react';
import { twMerge } from 'tailwind-merge';

import { IconProps } from './type';

export enum InfoCircleVariant {
  DEFAULT,
  EVENTS,
  PLAN_CHANGE,
}

type InfoCircleProps = { thinIconVariant?: boolean; variant?: InfoCircleVariant } & IconProps;

const infoCircleIconVariant: Record<
  InfoCircleVariant,
  (props: Except<InfoCircleProps, 'variant'>) => ReactNode
> = {
  [InfoCircleVariant.DEFAULT]: ({ iconColor = '#D92D20', className, thinIconVariant, ...props }) =>
    thinIconVariant ? (
      <svg
        className={twMerge('shrink-0', className)}
        fill="none"
        height="50"
        viewBox="0 0 50 50"
        width="50"
        {...props}
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M25.0006 15.6668V25.0002M25.0006 34.3335H24.9773M1.66731 25.0002C1.66731 12.1135 12.114 1.66683 25.0006 1.66683C37.8873 1.66683 48.334 12.1135 48.334 25.0002C48.334 37.8868 37.8873 48.3335 25.0006 48.3335C12.114 48.3335 1.66731 37.8868 1.66731 25.0002Z"
          stroke={iconColor}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="3"
        />
      </svg>
    ) : (
      <svg
        className={twMerge('shrink-0', className)}
        fill="none"
        height="12"
        viewBox="0 0 12 12"
        width="12"
        {...props}
        xmlns="http://www.w3.org/2000/svg"
      >
        <g clipPath="url(#clip0_1062_20363)">
          <path
            d="M6 8V6M6 4H6.005M11 6C11 8.76142 8.76142 11 6 11C3.23858 11 1 8.76142 1 6C1 3.23858 3.23858 1 6 1C8.76142 1 11 3.23858 11 6Z"
            stroke={iconColor}
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </g>
        <defs>
          <clipPath id="clip0_1062_20363">
            <rect fill="white" height="12" width="12" />
          </clipPath>
        </defs>
      </svg>
    ),
  [InfoCircleVariant.EVENTS]: ({ iconColor = '#667085', className, ...props }) => (
    <svg
      className={twMerge('shrink-0', className)}
      fill="none"
      height="16"
      viewBox="0 0 16 16"
      width="16"
      {...props}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M7.99998 10.6666V7.99998M7.99998 5.33331H8.00665M14.6666 7.99998C14.6666 11.6819 11.6819 14.6666 7.99998 14.6666C4.31808 14.6666 1.33331 11.6819 1.33331 7.99998C1.33331 4.31808 4.31808 1.33331 7.99998 1.33331C11.6819 1.33331 14.6666 4.31808 14.6666 7.99998Z"
        stroke={iconColor}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.2"
      />
    </svg>
  ),
  [InfoCircleVariant.PLAN_CHANGE]: ({ iconColor = '#DC6803', className, ...props }) => (
    <svg
      className={twMerge('shrink-0', className)}
      fill="none"
      height="24"
      viewBox="0 0 24 24"
      width="24"
      {...props}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12 16V12M12 8H12.01M22 12C22 17.5228 17.5228 22 12 22C6.47715 22 2 17.5228 2 12C2 6.47715 6.47715 2 12 2C17.5228 2 22 6.47715 22 12Z"
        stroke={iconColor}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.5"
      />
    </svg>
  ),
};

const InfoCircle: FC<InfoCircleProps> = ({ variant = InfoCircleVariant.DEFAULT, ...props }) =>
  infoCircleIconVariant[variant](props);

export default InfoCircle;
