import { StringKey } from '../string-key.enum';

export const enMessages: Record<StringKey, string> = {
  /// For this keys Translation is missing
  [StringKey.EMPLOYEE]: 'Employee',
  [StringKey.FOUNDER]: 'Founder',
  [StringKey.BOARD_MEMBERS]: 'Board Member',
  [StringKey.VCS]: 'Venture Capital',
  [StringKey.INVESTMENT_BANKS]: 'Investment Bank',
  [StringKey.PES]: 'Private Equity',
  [StringKey.OTHER_INST]: 'Other Institutional Investor',
  [StringKey.FAMILY_OFFICE]: 'Family Office',
  [StringKey.STAKEHOLDER]: 'Stakeholder',
  [StringKey.PLAN_SOON_EXPIRE]:
    'We are sorry to see you go. You may continue to use CapQuest until your current plan expires on {date}.',
  [StringKey.NO_GO_BACK]: 'No, go back',
  [StringKey.NO_KEEP_1]: 'No, Keep',
  [StringKey.NO_KEEP_2]: 'No, Keep',
  [StringKey.NO_KEEP_3]: 'No, Keep',
  [StringKey.NO_KEEP_4]: 'No, Keep',
  [StringKey.NO_KEEP_5]: 'No, Keep',
  [StringKey.CAN_REACTIVATE]: 'You can reactivate the subscription within next 6 months',
  [StringKey.SAVE_APPROX]: '(save approx. 17%)',
  [StringKey.BETWEEN_SYMBOL]: '-',
  [StringKey.COUNT_STAKEHOLDERS]: '{count} stakeholders',
  [StringKey.ACTIVE]: 'Active',
  [StringKey.ACTIVE_UNTIL]: 'Active until {date}',
  [StringKey.ACTIVITY]: 'Activity',
  [StringKey.ADD_NEW_COMPANY]: 'Add a new Company',
  [StringKey.ADD_STAKEHOLDER]: 'Add stakeholder',
  [StringKey.ADDRESS]: 'Address',
  [StringKey.ADDRESS_OPTIONAL]: 'Address (optional)',
  [StringKey.INACTIVE]: 'Inactive',
  [StringKey.ROWS_SELECTED]: '{count, plural, =1 {# row} other {# rows}} selected',
  [StringKey.ACCESS_AND_PERMISSIONS]: 'Access & Permissions',
  [StringKey.ACCESS_REVOKED]: 'Access revoked',
  [StringKey.ACCOUNT]: 'Account',
  [StringKey.ADMIN]: 'Admin',
  [StringKey.ADVISOR]: 'Advisor',
  [StringKey.ALL]: 'All',
  [StringKey.ALL_ASSOCIATED_STAKEHOLDER_DATA_WILL_BE_LOST]:
    'All of the data associated to the stakeholder will be lost.',
  [StringKey.ALL_STAKEHOLDERS]: 'All Stakeholders',
  [StringKey.ALREADY_HAVE_ACCOUNT]: 'Already have an account?',
  [StringKey.AMOUNT_TO_PAY_TODAY]: 'Amount to pay today',
  [StringKey.ANGEL]: 'Angel',
  [StringKey.ANGELS]: 'Angels',
  [StringKey.ANNUAL]: 'Annual',
  [StringKey.ANNUAL_REVENUE]: 'Annual revenue ($)',
  [StringKey.ANOTHER_BENEFIT]: 'Another benefit No {num}',
  [StringKey.ANY_ADDITIONAL_FEEDBACK]: 'Any additional feedback?',
  [StringKey.ARE_YOU_SURE_YOU_WANT_CANCEL]: 'Are you sure you want to cancel the subscription?',
  [StringKey.ARE_YOU_SURE_YOU_WANT_DEACTIVATE]: 'Are you sure you want to deactivate the company?',
  [StringKey.LOSE_ACCESS_CAUTION]:
    'You and all stakeholders won’t have access to it. You have {time} to reactivate the company after which it will be permanently deleted',
  [StringKey.ARE_YOU_SURE_YOU_WANT_DELETE_ACCOUNT]: 'Are you sure you want to delete your account?',
  [StringKey.ARE_YOU_SURE_YOU_WANT_GRANT_ACCESS]: 'Are you sure you want to grant access?',
  [StringKey.ARE_YOU_SURE_YOU_WANT_MAKE_CONTRIBUTOR]:
    'Are you sure you want to make this user contributor?',
  [StringKey.AT_LEAST_ONE_LOWER_CASE_LETTER]: 'At least 1 lower case letter (a-z)',
  [StringKey.AT_LEAST_ONE_SPECIAL_CHAR]: 'At least 1 special characters (e.g !@#$%^&*)',
  [StringKey.AT_LEAST_ONE_UPPER_CASE_LETTER]: 'At least 1 upper case letter (A-Z)',
  [StringKey.AT_LEAST_EIGHT_CHARS]: 'At least 8 characters',
  [StringKey.AUTO_RENEW_ON]: 'Auto renew on',
  [StringKey.BACK]: 'Back',
  [StringKey.BACK_TO_SIGN_IN]: 'Back to Sign in',
  [StringKey.BEFORE_YOU_START_YOUR_JOURNEY_CONFIRMATION]:
    'Before you start your journey, we wanted to show you how your information has been saved. If you have any comments, please contact a company administrator',
  [StringKey.BILLED_ANNUALY]: 'Billed annually',
  [StringKey.BILLED_MONTHLY]: 'Billed monthly',
  [StringKey.BIRTHDAY]: 'Birthday',
  [StringKey.BIRTHDAY_OPTIONAL]: 'Birthday (optional)',
  [StringKey.BULK_MODIFY]: 'Bulk modify',
  [StringKey.BULK_MODIFY_STAKEHOLDERS]: 'Bulk Modify Stakeholders',
  [StringKey.BY_PROCEEDING_YOU_AGREE_TO]: 'By proceeding, you agree to',
  [StringKey.TERMS_OF_SERVICE]: 'the Terms of Service',
  [StringKey.AND]: 'and',
  [StringKey.PRIVACY_POLICY]: 'Privacy Policy',
  [StringKey.CANCEL]: 'Cancel',
  [StringKey.CANCEL_INVITATION]: 'Cancel Invitation',
  [StringKey.CANCEL_SUBSCRIPTION]: 'Cancel Subscription',
  [StringKey.CANNOT_DOWNGRADE]: 'Cannot downgrade',
  [StringKey.CAP_TABLE_MANAGEMENT]: 'Cap table management',
  [StringKey.MISSING_FEATURES]: 'CapQuest has missing features',
  [StringKey.TOO_EXPENSIVE]: 'CapQuest is too expensive',
  [StringKey.CARD_NUMBER]: 'Card number ',
  [StringKey.CARDHOLDER_NAME]: 'Cardholder name',
  [StringKey.CHANGE_PASSWORD]: 'Change Password',
  [StringKey.CHANGE_PLAN]: 'Change Plan',
  [StringKey.CHANGE_YOUR_PASSWORD]: 'Change your password',
  [StringKey.CHECK_THE_URL]: 'Check the URL for any typos and try again.',
  [StringKey.CHECK_YOUR_EMAIL]: 'Check your email',
  [StringKey.CHECK_YOUR_INBOX]: 'Check your inbox',
  [StringKey.CITY]: 'City',
  [StringKey.CLICK_TO_UPLOAD]: 'Click to upload',
  [StringKey.OR_DRAG_AND_DROP]: 'or drag and drop',
  [StringKey.CLOSE]: 'Close',
  [StringKey.COLLAPSE]: 'Collapse',
  [StringKey.COMPANY_ADDRESS]: 'Company Address',
  [StringKey.COMPANY_ID_NUMBER_OPTIONAL]: 'Company ID number (Optional)',
  [StringKey.COMPANY_IDENTIFIER]: 'Company Identifier',
  [StringKey.COMPANY_IDENTIFIER_OPTIONAL]: 'Company Identifier (Optional)',
  [StringKey.COMPANY_LOGO_OPTIONAL]: 'Company logo (optional)',
  [StringKey.COMPANY_NAME]: 'Company name',
  [StringKey.COMPANY_REGISTRATION_DETAILS]: 'Company registration details',
  [StringKey.COMPANY_SETTINGS]: 'Company settings',
  [StringKey.COMPANY_SETUP]: 'Company setup',
  [StringKey.COMPANY_TYPE]: 'Company Type',
  [StringKey.COMPANY_URL]: 'Company URL',
  [StringKey.COMPANY_URL_OPTIONAL]: 'Company URL (optional)',
  [StringKey.CONFIRM]: 'Confirm',
  [StringKey.CONFIRM_PASSWORD]: 'Confirm Password',
  [StringKey.CONGRATS_ON_YOUR_GROWTH]:
    "Congratulations on your growth! You've reached your stakeholder limit. Please upgrade your plan to continue",
  [StringKey.CONTINUE]: 'Continue',
  [StringKey.CONTINUE_TO_PLANS]: 'Continue to plans',
  [StringKey.CONTINUE_TO_USE_CAPQUEST_UNTIL]:
    'Continue to use CapQuest until your current plan expires on {date}',
  [StringKey.CONTINUE_WITH_GOOGLE]: 'Continue with Google',
  [StringKey.CONTRIBUTOR]: 'Contributor',
  [StringKey.INVITE_USER]: 'Invite user',
  [StringKey.INVITED]: 'Invited',
  [StringKey.ACTIVE_STAKEHOLDERS]: 'Active Stakeholders',
  [StringKey.INACTIVE_STAKEHOLDERS]: 'Inactive Stakeholders',
  [StringKey.ALL_TERMINATED]: 'All Terminated',
  [StringKey.ACTIVE_TERMINATED]: 'Active Terminated',
  [StringKey.INACTIVE_TERMINATED]: 'Inactive Terminated',
  [StringKey.STAKEHOLDERS_TAB]: 'Stakeholders',
  [StringKey.TERMINATED_TAB]: 'Terminated',
  [StringKey.FILTER_ALL]: 'All',
  [StringKey.FILTER_ACTIVE]: 'Active',
  [StringKey.FILTER_INACTIVE]: 'Inactive',
  [StringKey.FILTER_IN_USE]: 'In Use',
  [StringKey.FILTER_NOT_USED]: 'Not Used',
  [StringKey.KILOBYTE]: 'kb',
  [StringKey.MINUTES_AGO]: '{date} ago',
  [StringKey.SINGULAR_MIN]: 'min',
  [StringKey.PLURAL_MIN]: 'minutes',
  [StringKey.HOURS_AGO]: '{date} ago',
  [StringKey.SINGULAR_HOUR]: 'hour',
  [StringKey.PLURAL_HOUR]: 'hours',
  [StringKey.DAYS_AGO]: '{date} ago',
  [StringKey.SINGULAR_DAY]: 'day',
  [StringKey.PLURAL_DAY]: 'days',
  [StringKey.MONTHS_AGO]: '{date} ago',
  [StringKey.SINGULAR_MONTH]: 'month',
  [StringKey.PLURAL_MONTH]: 'months',
  [StringKey.LESS_THAN_MIN_AGO]: 'less than a min ago',
  [StringKey.ZIP_CODE]: 'Zip Code',
  [StringKey.SWITCH_TO_ANNUAL_PLAN_MESSAGE]:
    'Your subscription will be renewed starting from {date}',
  [StringKey.COUNTRY_OF_COMPANY]: 'Country of company',
  [StringKey.CREATE_MY_ACCOUNT]: 'Create my account',
  [StringKey.CREATING_YOUR_ACCOUNT]: 'Creating your account ...',
  [StringKey.CURRENT_PLAN]: 'Current Plan',
  [StringKey.CURRENTLY_SELECTED]: 'Currently selected',
  [StringKey.DASHBOARD]: 'Dashboard',
  [StringKey.DATE_CREATED]: 'Date created',
  [StringKey.DATE_OF_INCORPORATION]: 'Date of Incorporation',
  [StringKey.DATE_OF_INCORPORATION_OPTIONAL]: 'Date of Incorporation (optional)',
  [StringKey.DAY]: 'Day',
  [StringKey.DEACTIVATE]: 'Deactivate',
  [StringKey.DEACTIVATE_COMPANY]: 'Deactivate Company',
  [StringKey.DELETE]: 'Delete',
  [StringKey.DELETE_ACCOUNT]: 'Delete Account',
  [StringKey.DELETE_STAKEHOLDER]: 'Delete {count, plural, =1 {stakeholder} other {stakeholders}}',
  [StringKey.DELETE_YOUR_CAPQUEST_ACCOUNT]: 'Delete your CapQuest account',
  [StringKey.DEMO]: 'Demo',
  [StringKey.DETAILS]: 'Details',
  [StringKey.DONT_HAVE_AN_ACCOUNT_YET]: 'Don’t have an account yet',
  [StringKey.DONT_WORRY_YOUR_DATA_IS_SAVED]:
    'Don’t worry, your data is saved with us. You can reactivate the subscription during next {timeDistance}',
  [StringKey.DOWNGRADE]: 'Downgrade',
  [StringKey.SWITCH_TO_ANNUAL_PLAN_CHARGE_MESSAGE]:
    'Your primary billing method will be charged {currency} {price}',
  [StringKey.SWITCH_TO_ANNUAL_PLAN_ACTIVE_MESSAGE]: 'Your plan will be activate immediately',
  [StringKey.COMPANY_SETUP_EXIT]: 'Your progress will be lost if you exit',
  [StringKey.PRIVATE_EQUITY]: 'Private Equity',
  [StringKey.VENTURE_CAPITAL]: 'Venture Capital',
  [StringKey.SPV]: 'SPV',
  [StringKey.COMPANY]: 'Company',
  [StringKey.OTHER]: 'Other financial institutions',
  [StringKey.STAKEHOLDER_COMPANY_GROUP]: 'Legal entities',
  [StringKey.STAKEHOLDER_HUMAN_GROUP]: 'Natural persons',
  [StringKey.MONTH]: 'Month',
  [StringKey.MONTHS]: 'Months',
  [StringKey.ANNUALY]: 'Annualy',
  [StringKey.MONTHLY]: 'Monthly',
  [StringKey.BILLED_MONTHLY_PLAN]: 'Billed monthly',
  [StringKey.PAY_MONTHLY]: 'Pay monthly',
  [StringKey.MAXIMUM_FILE_SIZE]: 'Maximum file size {size} MB',
  [StringKey.MAXIMUM_PHOTO_SIZE]: 'Maximum size: {rules}',
  [StringKey.CARD_ENDING_NUMBERS]: '{brand} ending in {number}',
  [StringKey.LETS_TALK]: 'Let`s talk',
  [StringKey.LAST_UPDATE]: 'Last update: {date}',
  [StringKey.INDUSTRY]: 'Industry',
  [StringKey.STAKEHOLDERS_LIMIT]:
    'You cannot downgrade your plan because you currently have more than 25 stakeholders. Contact support',
  [StringKey.YOURE_SAVE_APPROX_WITH_ANNUAL]: 'You are saving approx. 17%',
  [StringKey.UPGRADE]: 'Upgrade',
  [StringKey.SWITCH_ANNUAL]: 'Switch to annual',
  [StringKey.SELECT]: 'Select',
  [StringKey.SPACIAL_PLAN_TEXT]: 'Speak to sales',
  [StringKey.CANNOT_DOWNGRADE_STAKEHOLDERS]:
    'You cannot downgrade your plan because you currently have yearly plan',
  [StringKey.CANCEL_SUBSCRIPTION_PROBLEM_1]:
    'You and all stakeholders won’t have access to this company afterward.',
  [StringKey.EXIT]: 'Yes, Exit',
  [StringKey.CONFIRM_DELETE]: 'Yes, Delete',
  [StringKey.CONFIRM_DEACTIVATE]: 'Yes, Deactivate',
  [StringKey.CONFIRM_CANCEL]: 'Yes, Cancel',
  [StringKey.YEAR]: 'Year',
  [StringKey.YEARS]: 'Years',
  [StringKey.WHERERE_LOCATED]: 'Where are you located?',
  [StringKey.WELCOME]: 'Welcome',
  [StringKey.WELCOME_TO_CAPQUEST]: 'Welcome to CapQuest',
  [StringKey.WELCOME_BACK]: 'Welcome back',
  [StringKey.WELCOME_ABOARD]: 'Welcome Aboard',
  [StringKey.FEW_DETAILS]: 'We just need a few details to get you started',
  [StringKey.SENDED_RECEIPT_TO_EMAIL]:
    'We have sent you the receipt on your affiliated email address',
  [StringKey.SENDED_LOGIN_VERIFY_LINK]:
    'We have sent you a secure login link. Please click the link to authenticate your account',
  [StringKey.WANT_EXIT]: 'Want to exit?',
  [StringKey.VOTING_RIGHTS]: 'Voting Rights',
  [StringKey.VOTING_RIGHT]: 'Voting right',
  [StringKey.VIEW_MY_COMPANIES]: 'View my companies',
  [StringKey.VIEW_MODIFICATION_GUID]: 'View modification guide',
  [StringKey.VIEW_IMPORT_GUID]: 'View import guide',
  [StringKey.VIEW_DETAILS]: 'View Details',
  [StringKey.DOWNLOAD_STAKEHOLDER_INFO_PART1]: 'Download',
  [StringKey.DOWNLOAD_STAKEHOLDER_INFO_PART2]: 'stakeholder',
  [StringKey.DOWNLOAD_STAKEHOLDER_INFO_PART3]: 'info',
  [StringKey.DOWNLOAD_TEMPLATE]: 'Download template',
  [StringKey.EDIT_DETAILS]: 'Edit Details',
  [StringKey.EDIT_STAKEHOLDER]: 'Edit Stakeholder',
  [StringKey.EFFICIENTLY_HANDLE_MANAGEMENT]:
    'Efficiently handle stock option management with our cost-saving annual plans',
  [StringKey.EMAIL]: 'Email',
  [StringKey.EMAIL_ADDRESS]: 'Email address',
  [StringKey.ENTER_A_NEW_PASSWORD_BELOW]:
    'Enter a new password below to change your current password and continue your access to CapQuest',
  [StringKey.ENTER_YOUR_EMAIL_ADDRESS_AND_WE_WILL]:
    'Enter your email address, and we will send you an email with password reset instructions',
  [StringKey.ENTERPRISE_AND_FI]: 'Enterprise',
  [StringKey.EQUITY_AND_CAPITAL_CHANGE]: 'Equity & Capital Change',
  [StringKey.EVENTS]: 'Events',
  [StringKey.EVERYTHING_FROM_STARTER_PLAN]: 'Everything from Starter Plan +',
  [StringKey.EXPIRY]: 'Expiry',
  [StringKey.EXPORT]: 'Export',
  [StringKey.FILE_FORMAT_NOT_SUPPORTED]: 'File format is not supported',
  [StringKey.FILE_PROCESSED_SUCCESSFULLY]: 'File processed successfully',
  [StringKey.FOR_CUSTOM_PRICING]: 'for custom pricing',
  [StringKey.FORGOT_PASSWORD]: 'Forgot password',
  [StringKey.FULL_NAME]: 'Full name',
  [StringKey.GENERATING_INVITATION_AGAIN_WILL_VOID]:
    'Generating invitation again will void the previous invite.',
  [StringKey.GRANT_ACCESS]: 'Grant Access',
  [StringKey.HELP_ME_SETUP]: 'Help me setup',
  [StringKey.HELP_US_MAKE_CAPQUEST_BETTTER]:
    'Help us make CapQuest better - tell us why you cancelled this plan',
  [StringKey.HERE_ARE_A_FEW_THING_TO_TRY]: 'Here are a few things you can try',
  [StringKey.I_FOUND_BETTER_ALTERNATIVE]: 'I found a better alternative',
  [StringKey.I_HAVE_SEEN_MY_INFO]: 'I have seen my information',
  [StringKey.I_NO_LONGER_NEED_IT]: 'I no longer need it',
  [StringKey.IF_YOU_CANCEL]: 'If you cancel',
  [StringKey.IF_YOU_RECEIVED_LINK_IN_EMAIL]:
    'If you received the link in an email, try contacting the sender for a new one.',
  [StringKey.IMPORT]: 'Import',
  [StringKey.IMPORT_STAKEHOLDERS]: 'Import Stakeholders',
  [StringKey.IN_THE_MEANTIME_FEEL_FREE_TO]: 'In the meantime, feel free to browse our',
  [StringKey.INACTIVE_COMPANY]: 'Inactive company',
  [StringKey.INCOMPLETE_SETUP]: 'Incomplete setup',
  [StringKey.LAST_ACTIVE]: 'Last active',
  [StringKey.LET_US_HELP_TO_SETUP]: 'Let us help to setup your CapQuest',
  [StringKey.LET_US_KNOW_ABOUT_YOUR_COMPANY_DETAILS]: 'Let us know about your company details',
  [StringKey.LOCATION]: 'Location',
  [StringKey.LOG_IN]: 'Log in',
  [StringKey.LOG_OUT]: 'Log out',
  [StringKey.LOSE_SPECIAL_OFFER_PRICE]: 'Lose your special offer price, if any.',
  [StringKey.MAKE_CONTRIBUTOR]: 'Make Contributor',
  [StringKey.MY_BUSINESS_IS_NO_LONGER_ACTIVE]: 'My business is no longer active',
  [StringKey.MY_COMPANIES]: 'My Companies',
  [StringKey.MY_COMPANY]: 'My Company',
  [StringKey.MY_HOLDING_EQUITY]: 'My Holdings / Equity',
  [StringKey.MY_HOLDINGS]: 'My Holdings',
  [StringKey.MY_PROFILE]: 'My Profile',
  [StringKey.NAME]: 'Name',
  [StringKey.NATIONALITY]: 'Nationality',
  [StringKey.NATIONALITY_OPTIONAL]: 'Nationality (optional)',
  [StringKey.NEW_PASSWORD]: 'New password',
  [StringKey.NEXT]: 'Next',
  [StringKey.VAT_GST]: 'VAT / GST number',
  [StringKey.USER_ROLE]: 'User role',
  [StringKey.MISC_ADVISE_3]:
    "Use the website's search bar to find the information you were looking for.",
  [StringKey.UPLOAD_AGAIN]: 'Upload again',
  [StringKey.UPGRADE_PLAN_NOW]: 'Upgrade your plan now',
  [StringKey.UPDATE]: 'Update',
  [StringKey.UNABLE_DELETE]: 'Unable to delete',
  [StringKey.TYPE]: 'Type',
  [StringKey.TERMINATION]: 'Termination',
  [StringKey.TERMINATED]: 'Terminated',
  [StringKey.TERMINATE]: 'Terminate',
  [StringKey.SWITCH_TO_ANNUAL_BILLING]: 'Switch to annual billing',
  [StringKey.SWITCH_COMPANY]: 'Switch company',
  [StringKey.SUPPORTED_FORMATS]: 'Supported formate: {formats}',
  [StringKey.SUBSCRIPTION_CYCLE]: 'Subscription cycle',
  [StringKey.SUBSCRIPTION_CANCELED]: 'Subscription Cancelled',
  [StringKey.SUBMIT]: 'Submit',
  [StringKey.STATUS]: 'Status',
  [StringKey.STATE]: 'State',
  [StringKey.STAKEHOLDERS]: 'Stakeholders',
  [StringKey.STAKEHOLDER_UPDATE_TOAST]: 'Stakeholder updated successfully',
  [StringKey.STAKEHOLDER_TYPE]: 'Stakeholder Type',
  [StringKey.STAKEHOLDER_DELETED_TOAST]: 'Deleted successfully',
  [StringKey.STAKEHOLDER_CREATED_TOAST]: 'Stakeholder created successfully',
  [StringKey.SOMETHING_ELSE]: 'Something else',
  [StringKey.SKYROCKETING]: 'Skyrocketing growth ahead',
  [StringKey.SKIP]: 'Skip',
  [StringKey.SIGN_UP]: 'Sign up',
  [StringKey.SIGN_UP_TO_CAPQUEST]: 'Sign in to your CapQuest account',
  [StringKey.SIGN_IN_TO_CAPQEUST]: 'Sign in to your CapQuest account',
  [StringKey.SIGN_IN]: 'Sign in',
  [StringKey.SHARE_CLASSES]: 'Share Classes',
  [StringKey.POOL_PLANS_POOL]: 'Pool Details',
  [StringKey.POOL_PLANS_PLAN]: 'Plan Details',
  [StringKey.POOL_PLANS]: 'Pool & Plans',
  [StringKey.CAP_TABLE]: 'Cap Table',
  [StringKey.SETTINGS]: 'Settings',
  [StringKey.ACCEPT_INVITATION]: 'Accept Invitation',
  [StringKey.LOGIN]: 'Login',
  [StringKey.REGISTER]: 'Register',
  [StringKey.REGISTER_VERIFY]: 'Register Verify',
  [StringKey.PASSWORD_RECOVERY]: 'Password Recovery',
  [StringKey.BILLING]: 'Billing',
  [StringKey.ERROR]: 'Error',
  [StringKey.SEND_EMAIL]: 'Send email',
  [StringKey.SEARCH]: 'Search',
  [StringKey.SAVED_APPROX_V2]: 'Saved approx. 17%',
  [StringKey.SAVE_APPROX_V2]: 'Save approx. 17%',
  [StringKey.SAVE_APPROX_W_ANNUAL_BILLING]: 'Save approx. 17% with annual billing',
  [StringKey.SAVE]: 'Save',
  [StringKey.REVOKE_CONTRIBUTOR_ROLE]: 'Revoke Contributor Role',
  [StringKey.REVOKE_ACCESS]: 'Revoke Access',
  [StringKey.RESUME_COMPANY_SETUP]: 'Resume setting up your company',
  [StringKey.RESUME]: 'Resume',
  [StringKey.RESIGNATION]: 'Resignation',
  [StringKey.RESEND_VERIFICATION_EMAIL]: 'Resend verification email',
  [StringKey.RESEND_INVITATION]: 'Resend Invitation',
  [StringKey.RESEND_EMAIL]: 'Resend email',
  [StringKey.NO]: 'No',
  [StringKey.NO_OF_EMPLOYEES]: 'No of employees',
  [StringKey.NO_RESULTS_MATCHS]: 'No results match this search term',
  [StringKey.NO_REV]: 'No Revenue',
  [StringKey.NO_THANKS]: 'No, thanks',
  [StringKey.NOTE_THAT_YOUR_INFORMATION_STILL_SAVED]:
    'Note that your information will still be saved as a stakeholder and visible to Admins and Contributors.',
  [StringKey.OOPS]: 'Oops!',
  [StringKey.OR]: 'OR',
  [StringKey.PASSPORT_EXPIRY_DATE]: 'Passport Expiry date',
  [StringKey.PASSPORT_EXPIRY_DATE_OPTIONAL]: 'Passport Expiry date (optional)',
  [StringKey.PASSPORT_NO]: 'Passport No',
  [StringKey.PASSPORT_NO_OPTIONAL]: 'Passport No (optional)',
  [StringKey.PASSWORD]: 'Password',
  [StringKey.PASSWORD_CHANGED]: 'Password changed',
  [StringKey.PAY_NOW]: 'Pay now',
  [StringKey.PAY_YEARLY]: 'Pay yearly',
  [StringKey.PAYMENT_METHOD]: 'Payment method',
  [StringKey.PER_MONTH]: 'Per month',
  [StringKey.PERSONAL_INFO]: 'Personal info',
  [StringKey.PHONE_NUMBER]: 'Phone number',
  [StringKey.PHONE_NUMBER_OPTIONAL]: 'Phone number (optional)',
  [StringKey.PLAN_BILLING]: 'Plan & Billing',
  [StringKey.BILLING_HISTORY]: 'Billing History',
  [StringKey.PLAN_FOR_ALL_SIZES]: 'Plan for all sizes',
  [StringKey.PLAN_SELECTED]: 'Plan selected',
  [StringKey.PLEASE_CHECK_THE_EMAIL]:
    'Please check the email address {email} for instructions to reset your password.',
  [StringKey.PLEASE_TRY_AGAIN]: 'Please try again',
  [StringKey.POOLS_PLANS]: 'Pool & Plans',
  [StringKey.PREVIOUS]: 'Previous',
  [StringKey.REACTIVATE]: 'Reactivate',
  [StringKey.REACTIVATE_SUBSCRIPTION]: 'Reactivate subscription',
  [StringKey.REENTER_NEW_PASSWORD]: 'Re-enter new password',
  [StringKey.REGISTRATION_NUMBER_OPTIONAL]: 'Registration number (optional)',
  [StringKey.REPORTING_CURRENCY]: 'Reporting Currency',
  [StringKey.YES]: 'Yes',
  [StringKey.YES_CANCEL]: 'Yes, Cancel',
  [StringKey.YES_DEACTIVATE]: 'Yes, Deactivate',
  [StringKey.YES_DELETE]: 'Yes, Delete',
  [StringKey.YES_EXIT]: 'Yes, Exit',
  [StringKey.YES_GRANT]: 'Yes, Grant',
  [StringKey.YES_RESEND]: 'Yes, Resend',
  [StringKey.YES_REVOKE]: 'Yes, Revoke',
  [StringKey.YES_UPDATE]: 'Yes, Update',
  [StringKey.YES_TERMINATE]: 'Yes, Terminate',
  [StringKey.UPDATE_STAKEHOLDER]: 'Update Stakeholder',
  [StringKey.UPDATE_TERMINATION]: 'Update Termination',
  [StringKey.SIGN_UP_MESSAGE]:
    'Thank you for being an early adopter. To start enjoying the benefits, let’s set up your account.',
  [StringKey.COMPANY_ID_EXPLANATION]:
    'The company ID number can be any unique identifier typically used for a company in contracts, grants, and invoicing, e.g., for US companies, this could be an Employer Identification Number (EIN), and for UAE companies, it may be a license number.',
  [StringKey.UPDATE_INFORMATION_MESSAGE]: 'The information you changed will be updated and saved.',
  [StringKey.INCORRECT_LINK_PT_1]: 'The link you clicked might be out of date or incorrect.',
  [StringKey.INCORRECT_LINK_PT_2]: "Don't worry, these things happen!",
  [StringKey.NOT_ABLE_TO_USE_STAKEHOLDER]:
    'The selected stakeholder will not be able to use the CapQuest.',
  [StringKey.STAKEHOLDER_CANCEL_INVITE_DESCRIPTION]:
    'The stakeholder may not able to join your team on CapQuest.',
  [StringKey.GREETING_COMPANY_SETUP]:
    'To get started, add your stakeholders and invite people from your company to join your team',
  [StringKey.PASSWORD_RECOMMENDATION]: 'Your password must contain:',
  [StringKey.PASSWORD_SUCCESSFULLY_CHANGED]: 'Your password has been changed successfully',
  [StringKey.TERMINATE_STAKEHOLDER]: 'Terminate stakeholder',
  [StringKey.STAKEHOLDER_SUCCESSFULLY_CREATED]: 'Stakeholder successfully created',
  [StringKey.FRENCH]: 'Français',
  [StringKey.ENGLISH]: 'English',
  [StringKey.NO_KEEP]: 'No, keep',
  [StringKey.CONTACT_SUPPORT]: 'Contact support',
  [StringKey.WEBSITE]: 'Website',
  [StringKey.ALL_DONE]: 'All done',
  [StringKey.HANG_TIGHT]: 'Hang tight',
  [StringKey.ONBOARDING]: 'Onboarding',
  [StringKey.OVERVIEW]: 'Overview',
  [StringKey.LATEST_CAP_TABLE]: 'Latest Cap Table',
  [StringKey.OVERALL_STATUS]: 'Overall Status',
  [StringKey.OWNERSHIP_PERCENTAGE]: 'Ownership Percentage',
  [StringKey.VOTING_RIGHTS_PERCENTAGE]: 'Voting Rights Percentage',
  [StringKey.SWITCH_TO_VOTING_RIGHTS]: 'Switch to Voting Rights',
  [StringKey.SWITCH_TO_OWNERSHIP]: 'Switch to Ownership',
  [StringKey.POST_MONEY_VALUATION]: 'Post-Money Valuation',
  [StringKey.VALUATION_DATE]: 'Valuation Date',
  [StringKey.ISSUED_SHARES]: 'Issued Shares',
  [StringKey.LIFETIME_EQUITY_INVESTMENT]: 'Lifetime Equity Investment',
  [StringKey.INVESTORS]: 'Investors',
  [StringKey.DILUTED_SHARES]: 'Diluted Shares',
  [StringKey.BY_STAKEHOLDER]: 'By Stakeholder',
  [StringKey.BY_SHARE_CLASS]: 'By Share Class',
  [StringKey.CUSTOMIZE_VIEW]: 'Customize View',
  [StringKey.EQUITY_POOLS_GRANTABLE]: 'Equity Pools Grantable',
  [StringKey.POOLS]: 'Pools',
  [StringKey.PLANS]: 'Plans',
  [StringKey.TOTAL]: 'Total',
  [StringKey.ISSUED_PERCENTAGE]: 'Issued %',
  [StringKey.DILUTED_PERCENTAGE]: 'Diluted Shares %',
  [StringKey.VOTING_PERCENTAGE]: 'Voting %',
  [StringKey.NO_ISSUED]: 'Issued Shares',
  [StringKey.INVESTMENT]: 'Investment',
  [StringKey.AVERAGE_SHARE_PRICE]: 'Avg Share Price',
  [StringKey.SWITCH]: 'Switch',
  [StringKey.VIEW_POSITIONS]: 'View Positions',
  [StringKey.POSITIONS]: 'Positions',
  [StringKey.VIEW_ASSOCIATED_STAKEHOLDERS]: 'View {stakeholdersCount} Associated Stakeholders(s)',
  [StringKey.CUSTOM_DATE]: 'Custom Date',
  [StringKey.RESET]: 'Reset',
  [StringKey.BY]: 'By',
  [StringKey.SHARE_CLASS]: 'Share Class',
  [StringKey.VIEW_BY]: 'View By',
  [StringKey.COLUMNS]: 'Columns',
  [StringKey.OTHER_SHARE_CLASSES]: 'Other Share Classes',
  [StringKey.SET_AS_DEFAULT_VIEW]: 'Set as a Default View',
  [StringKey.STAKEHOLDER_NAME]: 'Stakeholder Name',
  [StringKey.COMMON_STOCK]: 'Common Stock',
  [StringKey.SHARE_CLASS_NAME]: 'Share class name',
  [StringKey.NO_OF_STAKEHOLDERS]: 'No. of Stakeholders',
  [StringKey.SELECT_AT_LEAST_ONE_VIEW_BY]: 'Selected at least one View By',
  [StringKey.EMPTY]: ' ',
  [StringKey.CURRENT_SHARE_PRICE]: 'Current share price',
  [StringKey.VALUE_AT_LATEST_VALUATION_EVENT]: 'Value at Latest Valuation Event',
  [StringKey.ADD_SHARE_CLASS]: 'Add share class',
  [StringKey.ADD_NEW_SHARE_CLASS]: 'Add a new Share class',
  [StringKey.DIVIDENDS]: 'Dividends',
  [StringKey.DIVIDEND_RIGHT]: 'Dividend right',
  [StringKey.ANTI_DILUTION]: 'Anti - Dilution',
  [StringKey.CONVERSION_RATIO]: 'Conversion ratio',
  [StringKey.CONVERSION_RATIO_X]: 'Conversion ratio (x)',
  [StringKey.CONVERSION_RATIO_X_OPTIONAL]: 'Conversion ratio (x) (optional)',
  [StringKey.SENIORITY]: 'Seniority',
  [StringKey.LIQUIDITY_PREF]: 'Liquidity Pref.',
  [StringKey.LIQUIDITY]: 'Liquidity',
  [StringKey.LIQUIDITY_PREFERENCES]: 'Liquidity Preferences',
  [StringKey.PARTICIPATING_CAP]: 'Participating Cap',
  [StringKey.PARTICIPATING]: 'Participating',
  [StringKey.MULTIPLY]: 'Multiple',
  [StringKey.MULTIPLY_VALUE]: 'Multiple value',
  [StringKey.INTEREST]: 'Interest',
  [StringKey.DOC]: 'Doc',
  [StringKey.DOCUMENT]: 'Document',
  [StringKey.CREATION_DATE]: 'Creation date',
  [StringKey.RIGHTS]: 'Rights',
  [StringKey.EDIT]: 'Edit',
  [StringKey.EDIT_SHARE_CLASS]: 'Edit share class',
  [StringKey.GENERAL]: 'General',
  [StringKey.HOW_TO_NAME_A_SHARE_CLASS]: 'How to name a share class?',
  [StringKey.WHAT_ARE_DIVIDEND_RIGHTS]: 'What are dividend rights?',
  [StringKey.WHAT_ARE_VOTING_RIGHTS]: 'What are voting rights?',
  [StringKey.WHAT_IS_CONVERSION_RATIO]: 'What is conversion ratio?',
  [StringKey.CAP_VALUE]: 'Cap value',
  [StringKey.YEARLY_INTEREST]: 'Yearly interest',
  [StringKey.NON_COMPOUNDING]: 'Non-compounding',
  [StringKey.DAYS_PER_YEAR]: 'Days per year',
  [StringKey.WHAT_ARE_LIQUIDITY_PREFERENCES]: 'What are liquidity preferences?',
  [StringKey.WHAT_IS_SENIORITY]: 'What is Seniority?',
  [StringKey.WHAT_IS_MULTIPLY]: 'What is Multiple?',
  [StringKey.WHAT_IS_PARTICIPATING]: 'What is Participating?',
  [StringKey.WHAT_IS_CAP_VALUE]: 'What is Cap Value?',
  [StringKey.ANTI_DILUTION_RIGHTS]: 'Anti - Dilution rights',
  [StringKey.WHAT_IS_ANTI_DILUTION]: 'What is anti - dilution?',
  [StringKey.BASE]: 'Base',
  [StringKey.BROAD_BASED_WEIGHTED_AVERAGE]: 'Broad-Based Weighted Average',
  [StringKey.FULL_RATCHET]: 'Full Ratchet',
  [StringKey.NARROW_BASED_WEIGHTED_AVERAGE]: 'Narrow Based Weighted Average',
  [StringKey.BOARD_RESOLUTION]: 'Board Resolution',
  [StringKey.NO_FILE_ATTACHED]: 'No file attached',
  [StringKey.SHARE_CLASS_CREATED]: 'Share class successfully created',
  [StringKey.SHARE_CLASS_UPDATED]: 'Share class successfully updated',
  [StringKey.OWNERSHIP_SUMMARY]: 'Ownership Summary',
  [StringKey.YOUR_CONSOLIDATED_POSITION]: 'Your Consolidated Position',
  [StringKey.NUMBER_OF_ISSUED_SHARES]: 'Number of Issued Shares',
  [StringKey.COMPANY_OWNED]: '% of Company Owned',
  [StringKey.VOTING]: 'Voting (%)',
  [StringKey.SHARES_PRICE]: 'Shares Price',
  [StringKey.VALUE_OF_SHARES]: 'Value of Shares',
  [StringKey.ROI]: 'ROI',
  [StringKey.YOUR_POSITION_ISSUED]: 'Your position (Issued) vs Cap Table',
  [StringKey.YOU]: 'You',
  [StringKey.YOUR_ESTIMATED_VALUE_MILLION]: 'Your Estimated Value (Million)',
  [StringKey.YOUR_EQUITY]: 'Your Equity',
  [StringKey.AUTOMATIC_VESTING]: 'Automatic vesting',
  [StringKey.VESTING_SCHEDULE_AND_PLAN]: 'Vesting schedule and plan',
  [StringKey.ABOUT_PLAN]: 'About Plan',
  [StringKey.PLAN]: 'Plan',
  [StringKey.PLAN_TYPE]: 'Plan Type',
  [StringKey.YOUR_VESTING_STRUCTURE]: 'Your Vesting Structure',
  [StringKey.GRANT_ID]: 'Grant ID',
  [StringKey.CURRENT_PRICE]: 'Current Price',
  [StringKey.STRIKE_PRICE]: 'Strike Price',
  [StringKey.GRANTED]: 'Granted',
  [StringKey.REMAINING]: 'Remaining',
  [StringKey.VESTED]: 'Vested',
  [StringKey.NEXT_VESTING]: 'Next Vesting',
  [StringKey.FULL_VESTING]: 'Full Vesting',
  [StringKey.EXERCISE]: 'Exercise',
  [StringKey.INVESTMENT_SUMMARY]: 'Investment Summary',
  [StringKey.TOTAL_ROUNDS]: 'Total Rounds',
  [StringKey.CURRENT_VALUATION]: 'Current Valuation',
  [StringKey.TOTAL_RAISED_AMOUNT]: 'Total Raised Amount',
  [StringKey.AVG_VALUATION_CHANGE]: 'Avg. Valuation Change',
  [StringKey.PER_ROUND]: 'Per round',
  [StringKey.LATEST_SHARE_PRICE]: 'Latest Share Price',
  [StringKey.YOUR_PARTICIPATION]: 'Your Participation',
  [StringKey.TOTAL_INVESTMENT]: 'Total Investment',
  [StringKey.ESTIMATED_VALUE]: 'Estimated Value',
  [StringKey.OVERALL_EQUITY_CHANGE]: 'Overall Equity Change',
  [StringKey.AVERAGE_INVESTMENT]: 'Average Investment',
  [StringKey.TOTAL_RAISED]: 'Total Raised',
  [StringKey.YOUR_EQUITY_CHANGE]: 'Your Equity Change',
  [StringKey.HURDLE]: 'Hurdle',
  [StringKey.BASE_PRICE]: 'Base Price',
  [StringKey.VESTING_PLAN]: 'Exercisable Vesting - Vesting schedule and plan',
  [StringKey.EXERCISABLE]: 'Exercisable',
  [StringKey.EXERCISED]: 'Exercised',
  [StringKey.TO_BE_VESTED]: 'To be vested',
  [StringKey.WARRANT_PRICE]: 'Warrant Price',
  [StringKey.RSA_PRICE]: 'RSA Price',
  [StringKey.NOTHING_HERE]: 'Nothing here!',
  [StringKey.HOLDINGS_INFO]: 'When available, your holdings information will be displayed here.',
  [StringKey.REQUEST_EXERCISE]: 'Request Exercise',
  [StringKey.STOCK_OPTIONS]: 'Stock Options',
  [StringKey.WARRANT]: 'Warrant',
  [StringKey.RSA]: 'RSA',
  [StringKey.GRANT]: 'Grant',
  [StringKey.GROWTH_HURDLE]: 'Growth / Hurdle',
  [StringKey.SAR]: 'SAR',
  [StringKey.RSU]: 'RSU',
  [StringKey.PHANTOM]: 'Phantom',
  [StringKey.BSPCE]: 'BSPCE',
  [StringKey.REQUEST_DATE]: 'Request date',
  [StringKey.TOTAL_COST]: 'Total cost:',
  [StringKey.AVAILABLE]: 'Available:',
  [StringKey.TOP_5_STAKEHOLDERS]: 'top 5 stakeholders',
  [StringKey.POOL_ALLOCATION]: 'Pool allocation',
  [StringKey.VALUATION_HISTORY]: 'Valuation History',
  [StringKey.CURRENCY_USD]: 'Currency: USD (millions)',
  [StringKey.FULLY_DILUTED]: 'Fully Diluted',
  [StringKey.SHAREHOLDER]: 'Shareholder',
  [StringKey.SHARES]: 'Shares',
  [StringKey.VALUE]: 'Value',
  [StringKey.ACTIVITY_CENTER]: 'Activity Center',
  [StringKey.UNREAD_ONLY]: 'Unread only',
  [StringKey.MARK_ALL_AS_READ]: 'Mark all as read',
  [StringKey.GETTING_STARTED]: 'Getting Started',
  [StringKey.REQUESTS]: 'Requests',
  [StringKey.RESOLVED]: 'Resolved',
  [StringKey.STAKEHOLDER_ADDED]: 'Stakeholder Added',
  [StringKey.INVITATION_ACCEPTED]: 'Invitation Accepted',
  [StringKey.STAKEHOLDER_TERMINATED]: 'Stakeholder Terminated',
  [StringKey.ACCEPTED_INVITATION]: 'Accepted Invitation',
  [StringKey.TODAY]: 'Today',
  [StringKey.READ_NOTIFICATIONS_DELETED]:
    'Read notifications in Activity are deleted after 90 days',
  [StringKey.MARK_AS_READ]: 'Mark as read',
  [StringKey.EXERCISE_REQUESTED]: 'Exercise Requested',
  [StringKey.HURDLE_APPROVAL]: 'Hurdle Approval',
  [StringKey.MARK_AS_RESOLVED]: 'Mark as Resolved',
  [StringKey.GRANT_EXERCISED]: 'Grant Exercised',
  [StringKey.MARKED_AS_RESOLVED]: 'Marked as Resolved',
  [StringKey.HURDLE_APPROVED]: 'Hurdle Approved',
  [StringKey.NO_NOTIFICATIONS]: 'No Notifications!',
  [StringKey.GET_STARTED]: 'Get Started',
  [StringKey.ADD_POOL]: 'Add Pool',
  [StringKey.ADD_PLAN]: 'Add Plan',
  [StringKey.ADD_EVENT]: 'Add Event',
  [StringKey.ALL_CAUGHT_UP]: 'All Caught Up!',
  [StringKey.SHOW_ALL]: 'Show All',
  [StringKey.EXERCISE_STOCK_OPTIONS]: 'Exercise Stock Options',
  [StringKey.EXERCISE_EXPIRE_WARRANTS]: 'Exercise / Expire Warrants',
  [StringKey.EXERCISED_WARRANTS]: 'Exercised Warrants',
  [StringKey.EXERCISE_RSA]: 'Exercise RSA',
  [StringKey.EXERCISE_SAR]: "Exercise SAR's",
  [StringKey.RSA_PURCHASE_PRICE]: 'RSA Purchase Price',
  [StringKey.ADDITIONAL_NOTES]: 'Additional notes',
  [StringKey.DOCUMENTS_UPLOAD]: 'Documents upload',
  [StringKey.APPROVE]: 'Approve',
  [StringKey.SUCCESSFUL_COMPANY_SETUP]: 'You have successfully set up your company',
  [StringKey.ADD_STAKEHOLDER_MESSAGE]: 'Add details about your company stakeholders',
  [StringKey.ADD_SHARE_CLASS_MESSAGE]: 'Add details about your company share classes',
  [StringKey.ADD_POOL_MESSAGE]: 'Setup your first pool',
  [StringKey.ADD_PLAN_MESSAGE]: 'Setup your first company plan',
  [StringKey.ADD_EVENT_MESSAGE]: 'Add new or existing events',
  [StringKey.APPROVE_HURDLE_PLAN]: 'Approve Hurdle Plan',
  [StringKey.PAYOUT_PHANTOM_SHARES]: 'Payout Phantom Shares',
  [StringKey.EXERCISE_BSPCE]: 'Exercise BSPCE',
  [StringKey.CAP_TABLE_DETAILS]:
    'When available, your Cap Table details information will be displayed here.',
  [StringKey.DATE]: 'Date',
  [StringKey.LOADING]: 'Loading...',
  [StringKey.NO_GRANTS_FOUND]: 'No Grants found',
  [StringKey.NO_SHAREHOLDER_FOUND]: 'No Shareholder found',
  [StringKey.CONVERSION]: 'Conversion',
  [StringKey.EXERCISED_OPTIONS]: 'Exercised Options',
  [StringKey.EXERCISE_DATE]: 'Exercise date',
  [StringKey.OTHERS]: 'Others',
  [StringKey.INTEREST_PERCENTAGE_PROMPT]: 'Please enter interest percentage',
  [StringKey.UPLOADED_PERCENTAGE]: '% uploaded...',
  [StringKey.INVALID_FILE_TYPE]:
    '{fileType} type is invalid. Please select a supported file format',
  [StringKey.VOTING_MULTIPLIER]: 'Voting Multiplier',
  [StringKey.PRE_EMPTIVE_RIGHTS]: 'Pre-emptive rights',
  [StringKey.DELETE_SHARE_CLASS]: 'Delete Share Class?',
  [StringKey.SHARE_CLASS_DATA_LOSS_WARNING]:
    'All of the data associated to the share class will be lost.',
  [StringKey.UPDATE_SHARE_CLASS]: 'Update Share Class?',
  [StringKey.UPDATE_SHARE_CLASS_CONFIRM]: 'Are you sure you want to update this share class?',
  [StringKey.REVIEW_CHANGES_WARNING]:
    'Please review your changes carefully. Modifying the {text} can have significant implications for all associated shareholders and future allocations.',
  [StringKey.BY_POOLS]: 'By Pools',
  [StringKey.VEST_BY]: 'Vest by:',
  [StringKey.ADD]: 'Add',
  [StringKey.POOL_GRANTED]: 'Pool Granted',
  [StringKey.TOTAL_GRANT_VESTED]: 'Total Grant Vested',
  [StringKey.SHARES_VESTED]: 'Shares Vested',
  [StringKey.CASH_SETTLED]: 'Cash Settled',
  [StringKey.RETURNED_TO_POOL]: 'Returned to Pool',
  [StringKey.EXPIRED]: 'Expired',
  [StringKey.TERMINATION_SET]: 'Termination set:',
  [StringKey.EDIT_TERMINATION_DATE]: 'Edit Termination Date',
  [StringKey.POOL_NAME]: 'Pool name',
  [StringKey.FULLY_DILUTED_SHARES]: 'Amount of fully diluted shares',
  [StringKey.DILUTION_CONDITION]: 'Dilution Condition',
  [StringKey.DOCUMENTS]: 'Documents',
  [StringKey.ADDITIONAL_NOTES_OPTIONAL]: 'Additional notes (optional)',
  [StringKey.TERMINATE_POOL]: 'Terminate Pool',
  [StringKey.CURRENT_TERMINATION_DATE]: 'Current Termination Date',
  [StringKey.NEW_TERMINATION_DATE]: 'New termination date',
  [StringKey.POOL_TERMINATION_WORKS]: 'How does pool termination works?',
  [StringKey.TERMINATE_POOL_CONFIRMATION]: 'Are you sure you want to terminate the ',
  [StringKey.IF_YOU_TERMINATE]: 'If you terminate',
  [StringKey.TERMINATION_WARNING]:
    'You and all stakeholders won’t have access to this {text} afterwards.',
  [StringKey.POOL_NO_LINK_AFTER_TERMINATION]:
    'You will not be able to link plans to this pool after the Termination Date',
  [StringKey.PLAN_NO_GRANTS_AFTER_TERMINATION]:
    'You will not be able to issue grants from this plan after the Termination Date',
  [StringKey.AVAILABLE_POOL]: 'Available Pool',
  [StringKey.NEW_POOL]: 'New Pool',
  [StringKey.NEW_PLAN]: 'New Plan',
  [StringKey.VIEW_POOL]: 'View {text}',
  [StringKey.EDIT_POOL]: 'Edit {text}',
  [StringKey.DELETE_POOL]: 'Delete {text}',
  [StringKey.DELETE_POOL_WARNING]: 'The pool cannot be deleted as they are associated to plans.',
  [StringKey.DELETE_PLAN_WARNING]: 'All of the data associated to the {text} will be lost.',
  [StringKey.ADD_NEW_POOL]: 'Add new Pool',
  [StringKey.SELECT_POOL]: 'Select Pool',
  [StringKey.SELECT_SHARE_CLASS]: 'Select share class',
  [StringKey.INCLUDE_CAP_TABLE]: `Include in Cap Table`,
  [StringKey.EXCLUDE_CAP_TABLE]: `Exclude from Cap Table`,
  [StringKey.AMOUNT_FULLY_DILUTED_SHARES_HELP]: 'What is “Amount of fully diluted shares”?',
  [StringKey.DILUTION_CONDITIONS_HELP]: 'What are the dilution conditions?',
  [StringKey.POOL_CREATED_SUCCESS]: 'New pool created successfully',
  [StringKey.ADD_NEW_PLAN]: 'Add a Plan',
  [StringKey.PLAN_NAME]: 'Plan name',
  [StringKey.EQUITY_PLAN_TYPE]: 'Equity plan type',
  [StringKey.VALUATION_REQUIRED]: 'Valuation required',
  [StringKey.WHAT_IS_HURDLE]: `What is a Hurdle plan?`,
  [StringKey.VESTING]: 'Vesting',
  [StringKey.TIME_BASED_STANDARD]: 'Time Based (Standard)',
  [StringKey.TIME_BASED_DYNAMIC]: 'Time Based (Dynamic)',
  [StringKey.TARGET_BASED]: 'Target Based',
  [StringKey.TIME_BASED]: 'Time Based',
  [StringKey.STANDARD]: 'Standard',
  [StringKey.DYNAMIC]: 'Dynamic',
  [StringKey.VESTING_ON]: 'Vesting on',
  [StringKey.FIRST_DAY_OF_MONTH]: 'First day of the month',
  [StringKey.LAST_DAY_OF_MONTH]: 'Last day of the month',
  [StringKey.GRANT_DATE]: 'Grant date',
  [StringKey.DURATION]: 'Duration',
  [StringKey.VESTING_FREQUENCY]: 'Vesting Frequency',
  [StringKey.CLIFF]: 'Cliff',
  [StringKey.ACCELERATION_DATE]: 'Acceleration Date',
  [StringKey.VESTING_FREQUENCY_ABBR]: 'Vesting Freq.',
  [StringKey.PLAN_ALLOCATION]: 'Plan Allocation',
  [StringKey.ADD_PERIOD]: 'Add Period',
  [StringKey.MILESTONE_NAME]: 'Milestone Name',
  [StringKey.WEIGHT]: 'Weight',
  [StringKey.TARGET_DATE]: 'Target Date',
  [StringKey.ADD_MILESTONE]: 'Add Milestone',
  [StringKey.TERMINATION_LOGIC]: 'Termination logic',
  [StringKey.NO_POOLS_FOUND]: 'No Pools found',
  [StringKey.TERMINATE_PLAN]: 'Terminate Plan',
  [StringKey.PLAN_TERMINATION_WORKS]: 'How does plan termination works?',
  [StringKey.ADD_EXERCISING]: 'Add Exercising',
  [StringKey.PLAN_EXERCISE_COMPLETED]: 'Plan exercise completed',
  [StringKey.EXPIRED_WARRANTS]: 'Expired warrants',
  [StringKey.PHANTOM_SHARES]: 'Phantom Shares',
  [StringKey.TOTAL_PAYOUT]: 'Total Payout',
  [StringKey.PURCHASE_PRICE]: 'Purchase price',
  [StringKey.SELECT_HURDLE_PLAN]: 'Select a hurdle plan',
  [StringKey.APPROVAL_DATE]: 'Approval date',
  [StringKey.EXERCISED_SUCCESSFULLY]: 'Exercised successfully',
  [StringKey.VESTING_ON_VALUE_HELP]: 'What is “Vesting on”?',
  [StringKey.SET_DURATION_HELP]: 'How do I set the duration?',
  [StringKey.VESTING_FREQUENCY_HELP]: 'What is frequency in vesting?',
  [StringKey.VESTING_CLIFF_VALUE_HELP]: 'What is cliff value in vesting?',
  [StringKey.MILESTONE_WEIGHT_HELP]: 'What is milestone weight?',
  [StringKey.ACTIVITY_OVERVIEW]: 'Activity Overview',
  [StringKey.OWNERSHIP_HISTORY]: 'Ownership history',
  [StringKey.OWNERSHIP_HISTORY_PERCENT]: 'Ownership history %',
  [StringKey.BY_SHARES]: 'By Shares',
  [StringKey.CURRENCY]: 'Currency',
  [StringKey.MILLIONS]: 'millions',
  [StringKey.SEED]: 'Seed',
  [StringKey.EVENT_HISTORY]: 'Event History',
  [StringKey.ALL_TYPES]: 'All Types',
  [StringKey.OPEN_ROUND]: 'Open Round',
  [StringKey.PRE_MONEY_VALUATION]: 'Pre-Money Valuation',
  [StringKey.TRANSACTIONS]: 'Transactions',
  [StringKey.TRANSACTION]: 'Transaction',
  [StringKey.TRANSACTION_DATE]: 'Transaction Date',
  [StringKey.TRANSACTION_TYPE]: 'Transaction type',
  [StringKey.NO_OF_SHARES]: 'No of Shares',
  [StringKey.FILE]: 'File',
  [StringKey.ADD_INVESTMENT]: 'Add Investment',
  [StringKey.PIN_EVENT]: 'Pin Event',
  [StringKey.DELETE_EVENT]: 'Delete Event',
  [StringKey.UNPIN]: 'Unpin',
  [StringKey.UPDATE_EVENT]: 'Update [Event Name] Event?',
  [StringKey.CONFIRM_UPDATE_EVENT]: 'Are you sure you want to update {text} Event?',
  [StringKey.REVIEW_CHANGES]:
    'Please review your changes carefully. Editing the event can affect the overall equity structure.',
  [StringKey.VIEW_EVENT]: 'View Event',
  [StringKey.EDIT_EVENT]: 'Edit Event',
  [StringKey.NO_EVENTS]: 'No Events yet!',
  [StringKey.ADD_FIRST_EVENT]: 'You have no events created yet. Add your first event!',
  [StringKey.SAR_EXERCISED]: 'SAR Exercised',
  [StringKey.SAR_PAYOUT]: 'SAR Payout',
  [StringKey.HURDLE_VALUE]: 'Hurdle Value',
  [StringKey.SHARES_PAID_OUT]: 'Shares Paid Out',
  [StringKey.FROM_SHARE_CLASS]: 'From Share Class',
  [StringKey.TO_SHARE_CLASS]: 'To Share Class',
  [StringKey.EQUITY_PLAN]: 'Equity Plan',
  [StringKey.INVESTMENTS]: 'Investments',
  [StringKey.EQUITY_POOL]: 'Equity Pool',
  [StringKey.VALUATION_EVENT]: 'Valuation Event',
  [StringKey.SHARE_PRICE]: 'Share Price',
  [StringKey.FROM_STAKEHOLDER]: 'From Stakeholder',
  [StringKey.TO_STAKEHOLDER]: 'To Stakeholder',
  [StringKey.SHARE_VALUE]: 'Share Value',
  [StringKey.TRANSFER_AMOUNT]: 'Transfer Amount',
  [StringKey.ESTABLISH_CUSTOM_TERMINATION]:
    'Establish custom termination period for different termination types',
  [StringKey.NO_EXERCISE_EXPIRATION_PERIOD]: 'No Exercise Expiration Period',
  [StringKey.EXERCISE_EXPIRATION_PERIOD]: 'Exercise Expiration Period',
  [StringKey.TERMINATION_WITH_CAUSE]: 'Termination with Cause',
  [StringKey.RETIREMENT]: 'Retirement',
  [StringKey.INTERVAL]: 'Interval',
  [StringKey.PLAN_ALLOCATION_DOES_NOT_REACH_100]: 'Plan allocation percentage does not reach 100%',
  [StringKey.WEIGHT_ALLOCATION_DOES_NOT_REACH_100]: 'Weight allocation does not reach 100%',
  [StringKey.DURATION_SHOULD_BE_MULTIPLY]: 'The duration should be a multiple of your Frequency',
  [StringKey.DATE_OF_TERMINATION]: 'Date of termination',
  [StringKey.UPDATE_POOL]: 'Are you sure you want to update this {text}?',
  [StringKey.WHAT_IS_STRIKE_PRICE]: 'What is Strike price?',
  [StringKey.SAR_BASE_PRICE]: 'SAR Base Price',
  [StringKey.WHAT_IS_SAR_BASE_PRICE]: 'What is SAR base price?',
  [StringKey.WHAT_IS_WARRANT_PRICE]: 'What is Warrant price?',
  [StringKey.EXPIRY_DATE]: 'Expiry Date',
  [StringKey.EXERCISE_STOCK_WITH_EASE]: 'Execute your stock options with ease',
  [StringKey.MANAGE_WARRANTS]: 'Manage or execute your warrants with ease',
  [StringKey.EXERCISE_RSA_WITH_EASE]: 'Execute your RSA with ease',
  [StringKey.EXECUTE_SAR]: 'Execute your SAR’s with ease',
  [StringKey.PAYOUT_PHANTOM]: 'Payout Phantom Shares',
  [StringKey.PAYOUT_PHANTOM_WITH_EASE]: 'Payout your phantom shares with ease',
  [StringKey.APPROVE_HURDLE]: 'Approve Hurdle Shares',
  [StringKey.APPROVE_HURDLE_WITH_EASE]: 'Approve your hurdle shares with ease',
  [StringKey.EXPIRE]: 'Expire',
  [StringKey.SAR_FOR_EXERCISING]: 'SAR for exercising',
  [StringKey.NO_OF_SARS]: 'No of SARs',
  [StringKey.SELECT_HURDLE_TO_APPROVE]: 'Select Hurdle Plan to Approve',
  [StringKey.SAFES]: 'SAFEs',
  [StringKey.EVENT]: 'Event',
  [StringKey.NO_PLANS_TO_APPROVE]: 'You have no plans to approve',
  [StringKey.NAME_SHOULD_BE_UNIQUE]: 'Name should be unique',
  [StringKey.ASSOCIATED_TO_THIS_PLAN]: 'associated to this plan',
  [StringKey.ALL_SARS_MUST_BE_DISTRIBUTED]: 'All No of SARs must be distributed',
  [StringKey.EXERCISE_DATE_CANT_BE_LATER_THAN_EXPIRE]:
    "Exercise date can't be later than expire date",
  [StringKey.EXPIRED_SUCCESSFULLY]: 'Expired successfully',
  [StringKey.ONE_OF_COUNT_FILES_UPLOADED]: '{count} of {totalCount} files uploaded',
  [StringKey.PLAN_SUCCESSFULLY_UPDATED]: 'Plan successfully updated',
  [StringKey.STRIKE_PRICE_VALUE]: 'Strike Price value',
  [StringKey.WARRANT_PRICE_VALUE]: 'Warrant Price value',
  [StringKey.NO_VESTING_STRUCTURE_SELECTED]: 'No Vesting Structure Selected',
  [StringKey.PERIOD]: 'Period',
  [StringKey.MILESTONE]: 'Milestone',
  [StringKey.NO_TERMINATION_LOGIC_SELECTED]: 'No Termination logic Structure Selected',
  [StringKey.NEW_PLAN_CREATED_SUCCESSFULLY]: 'New plan created successfully',
  [StringKey.POOL_SUCCESSFULLY_UPDATED]: 'Pool successfully updated',
  [StringKey.TERMINATION_DATE_UPDATED]: 'Termination date successfully updated',
  [StringKey.VIEW_ASSOCIATED_PLANS]: 'View {sharePlansCount} associated plan(s)',
  [StringKey.PLAN_SUCCESSFULLY_DELETED]: 'Plan successfully deleted',
  [StringKey.POOL_SUCCESSFULLY_DELETED]: 'Pool successfully deleted',
  [StringKey.CHANGES_SAVED]: 'Changes saved',
  [StringKey.REQUIRED]: 'Required',
  [StringKey.CAP_VALUE_LESS_THAN_MULTIPLE]: 'Cap value is less than the multiple',
  [StringKey.MAXIMUM_CHARACTERS]: 'Maximum {count} characters',
  [StringKey.FILE_SIZE_TOO_LARGE]: 'File size is too large',
  [StringKey.CANNOT_ADD_MORE_THAN_COUNT_ITEMS]: 'You cannot add more than {count} items',
  [StringKey.OPTIONS_MUST_BE_LESS_THAN_BALANCE]: 'Options must be less than or equal to balance',
  [StringKey.SARS_MUST_BE_LESS_THAN_BALANCE]: 'No of SARs must be less than or equal to balance',
  [StringKey.SHARES_MUST_BE_LESS_THAN_BALANCE]: 'Shares must be less than or equal to balance',
  [StringKey.ENTER_VALID_ZIP_CODE]: 'Enter valid zip code',
  [StringKey.INVALID_URL]: 'Invalid url',
  [StringKey.INVALID_NUMBER]: 'Invalid Number',
  [StringKey.ITEMS_MAXIMUM]: '{count} items maximum',
  [StringKey.CHARACTER_LIMIT_IS_COUNT]: 'Character limit is {count}',
  [StringKey.INVALID_DATE]: 'Invalid date',
  [StringKey.INVALID_INVESTMENT_DATE]: 'Invalid investment date',
  [StringKey.INVESTMENT_DATE_MUST_BE_BEFORE_CLOSE_DATE]:
    'Investment date must be on or before the close date',
  [StringKey.STAKEHOLDERS_MUST_BE_DIFFERENT]: 'stakeholderFrom and stakeholderTo must be different',
  [StringKey.SHARE_CLASSES_MUST_BE_DIFFERENT]: 'shareClassFrom and shareClassTo must be different',
  [StringKey.MUST_SELECT_VESTING_OPTION]: 'You must select a vesting option',
  [StringKey.DURATION_SHOULD_BE_MULTIPLE_OF_FREQUENCY]:
    'The duration should be a multiple of your frequency',
  [StringKey.INVALID_TARGET_DATE]: 'Invalid target date',
  [StringKey.PASSWORDS_DONT_MATCH]: "Passwords don't match",
  [StringKey.MODIFY_GUIDE]: 'Modify Guide',
  [StringKey.BY_PERCENTAGE]: 'By Percentage',
  [StringKey.NUMBER_OF_SHARES_IN_THOUSANDS]: 'Number of shares in thousands',
  [StringKey.FUNDRAISING]: 'Fundraising',
  [StringKey.SHARES_ISSUANCE]: 'Shares issuance',
  [StringKey.SECONDARIES]: 'Secondaries',
  [StringKey.BUYBACK_DECREASE]: 'Buyback',
  [StringKey.VALUATION]: 'Valuation',
  [StringKey.APPROVAL_HURDLE]: 'Approval (Hurdle plan)',
  [StringKey.EXPIRE_WARRANTS]: 'Expire (Warrants)',
  [StringKey.LAST_COUNT_DAYS]: 'Last {count} days',
  [StringKey.LAST_QUARTER]: 'Last quarter',
  [StringKey.LAST_YEAR]: 'Last year',
  [StringKey.ALL_TIME]: 'All time',
  [StringKey.CLASS_CONVERSION]: 'Class conversion',
  [StringKey.FUNDRAISING_ROUND]: 'Fundraising round',
  [StringKey.APPROVAL]: 'Approval',
  [StringKey.PAYOUT]: 'Payout',
  [StringKey.ADD_GRANT]: 'Add grant',
  [StringKey.BULK_IMPORT_GRANT]: 'Bulk import grants',
  [StringKey.ADD_FUNDRAISING_ROUND]: 'Add fundraising round',
  [StringKey.ADD_SHARE_ISSUANCE]: 'Add share issuance',
  [StringKey.ADD_SECONDARIES]: 'Add secondaries',
  [StringKey.ADD_BUYBACK]: 'Add buyback',
  [StringKey.ADD_CLASS_CONVERSION]: 'Add class conversion',
  [StringKey.ADD_VALUATION]: 'Add valuation',
  [StringKey.DELETE_TRANSACTION]: 'Delete transaction',
  [StringKey.CLOSED_ROUND]: 'Close round',
  [StringKey.START_DATE]: 'Start date',
  [StringKey.END_DATE]: 'End date',
  [StringKey.TRANSACTION_SUCCESSFULLY_DELETED]: 'Transaction successfully deleted',
  [StringKey.EVENT_SUCCESSFULLY_DELETED]: 'Event successfully deleted',
  [StringKey.UNABLE_TO_DELETE_EVENT]: 'Unable to delete event linked to another event',
  [StringKey.GRANTS_SUCCESSFULLY_IMPORTED]: 'Grants successfully imported',
  [StringKey.UNEXPECTED_ERROR_OCCURRED]: 'An unexpected error occurred',
  [StringKey.ERROR_RESPONSE_NOT_PARSED]: 'An error occurred, and the response could not be parsed',
  [StringKey.NEW_EVENT_CREATED_SUCCESSFULLY]: 'New event created successfully',
  [StringKey.NO_RESULTS_FOUND]: 'No results found',
  [StringKey.CHOOSE_VALUATION_NAME]: 'Choose {valuationName}',
  [StringKey.ROUND_NAME]: 'Round name',
  [StringKey.EVENT_NAME]: 'Event name',
  [StringKey.REMOVE]: 'Remove',
  [StringKey.NO_PLAN_FOUND]: 'No plan found',
  [StringKey.ADD_NEW_SHAREHOLDER]: 'Add new shareholder',
  [StringKey.ADD_ANOTHER_BUYBACK]: 'Add another buyback',
  [StringKey.EVENT_SUCCESSFULLY_UPDATED]: 'Event successfully updated',
  [StringKey.ADD_ANOTHER_CLASS_CONVERSION]: 'Add another class conversion',
  [StringKey.ADD_ANOTHER_GRANT]: 'Add another grant',
  [StringKey.ADD_ANOTHER_SHARE_TRANSFER]: 'Add another share transfer',
  [StringKey.ADD_ANOTHER_ISSUANCE]: 'Add another issuance',
  [StringKey.EVENT_DATE]: 'Event date',
  [StringKey.PLEASE_CHOOSE_DIFFERENT_SHARE_CLASSES]: 'Please choose different share classes',
  [StringKey.CONVERTED_SHARES]: 'Converted shares',
  [StringKey.ADD_ANOTHER_INVESTMENT]: 'Add another investment',
  [StringKey.INVESTMENT_DATE]: 'Investment date',
  [StringKey.FUNDRAISING_ROUND_STATUS]: 'Fundraising round status',
  [StringKey.ROUND_STATUS]: 'Round status',
  [StringKey.OPEN_DATE]: 'Open date',
  [StringKey.CLOSING_DATE]: 'Closing date',
  [StringKey.EMPOWER_STAKEHOLDERS]: 'Empower your stakeholders',
  [StringKey.BULK_IMPORT_GRANT_FILES]: 'Bulk import grant files',
  [StringKey.INITIATE_FUNDRAISING_ROUND]: 'Initiate a fundraising round',
  [StringKey.DISTRIBUTE_SHARES]: 'Distribute shares',
  [StringKey.ISSUE_SHARES]: 'Issue shares',
  [StringKey.TRANSFER_SHARES_SEAMLESSLY]: 'Transfer shares seamlessly',
  [StringKey.EXECUTE_SHARE_BUYBACKS]: 'Execute share buybacks',
  [StringKey.BUYBACK_DECREASE_SHARES]: 'Buyback - Decrease shares',
  [StringKey.CONVERT_SHARE_CLASSES]: 'Convert share classes',
  [StringKey.ENTER_COMPANY_VALUATION]: 'Enter company valuation',
  [StringKey.VIEW_COUNT_TRANSACTIONS]: 'View {count} transactions',
  [StringKey.NO_EVENTS_FOUND]: 'No events found',
  [StringKey.FILTERS]: 'Filters',
  [StringKey.TYPES]: 'Types',
  [StringKey.APPLY_FILTER]: 'Apply filter',
  [StringKey.FILES]: 'Files',
  [StringKey.FILES_ATTACHED_TO_EVENT]: 'Files attached to the event',
  [StringKey.DOWNLOAD_ALL]: 'Download all',
  [StringKey.WARRANTS_MUST_BE_BALANCE]: 'Warrants must balance diluted shares',
  [StringKey.FUNDRAISING_ROUND_STATE]: 'Fundraising round is {state} for investments',
  [StringKey.FUNDRAISING_ROUND_CONDITIONS]:
    'You already have a Fundraising Round matching these conditions. To edit that event, or add more investments please click here',
  [StringKey.CALCULATED_BY]: 'Calculated by CapQuest',
  [StringKey.VALUE_CALCULATION_INFO]: 'How this value is calculated?',
  [StringKey.GRANT_ASSOCIATION_INFO]:
    'You already have a Grant associated with this Stakeholder and Plan. To edit that Grant, please click here',
  [StringKey.DIFFERENT_STAKEHOLDERS]: 'Please choose different stakeholders',
  [StringKey.ISSUANCE]: 'Issuance',
  [StringKey.INVEST_DATE]: 'Invest date',
  [StringKey.BULK_IMPORT_GRANTS]: 'Easily bulk import grant files',
  [StringKey.INITIATE_FUNDRAISING]: 'Initiate your fundraising round now',
  [StringKey.TRANSFER_SHARES]: 'Seamlessly transfer shares',
  [StringKey.SHARE_BUYBACKS]: 'Efficiently execute share buybacks',
  [StringKey.COMPANY_VALUATION]: 'Enter your company’s valuation',
  [StringKey.VIEW_TRANSACTIONS]: 'View {count} transactions(s)',
  [StringKey.ATTACHED_FILES]: '{count} files attached to event',
  [StringKey.WARRANTS_BALANCE]: 'Warrants must be less than or equal to balance',
  [StringKey.OPEN]: 'Open',
  [StringKey.CLOSED]: 'Closed',
  [StringKey.SHARE_ISSUANCE]: 'Shares Issuance',
  [StringKey.BUYBACK]: 'Buyback',
};
// <AppFormattedMessage id={StringKey.} />
