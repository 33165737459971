import { FC } from 'react';

import { AppFormattedMessage } from '../../../../components/AppFormattedMessage';
import { useFormat } from '../../../../hooks';
import { StringKey } from '../../../../lang';
import { eventFormType, EventTypeBackend } from '../../../../types/events.types';
import { TransactionOverviewItem } from './TransactionOverviewItem';
import { TransactionTitleItem } from './TransactionTitleItem';
import { CardProps } from './type';

export const Expire: FC<CardProps> = ({ event }) => {
  const { format } = useFormat();

  if (event.type !== EventTypeBackend.EXPIRE) return <></>;

  const {
    type,
    exercise: {
      exercised,
      vestingTask: {
        grantItem: {
          stakeholder,
          plan: {
            pool: { shareClass },
          },
        },
      },
    },
    createdAt,
  } = event;

  return (
    <div className="flex flex-col divide-y divide-gray-100 rounded-md shadow-sm">
      <TransactionTitleItem
        createdAt={format(createdAt, 'dd/MM/yyyy')}
        name={eventFormType[type]}
      />
      <TransactionOverviewItem
        title={<AppFormattedMessage id={StringKey.TRANSACTION_TYPE} />}
        value={eventFormType[type]}
      />
      <TransactionOverviewItem
        title={<AppFormattedMessage id={StringKey.STAKEHOLDER} />}
        value={stakeholder.fullName}
      />
      <TransactionOverviewItem
        title={<AppFormattedMessage id={StringKey.EXPIRED_WARRANTS} />}
        value={exercised}
      />
      <TransactionOverviewItem
        title={<AppFormattedMessage id={StringKey.SHARE_CLASS} />}
        value={shareClass.name}
      />
    </div>
  );
};
