import { z } from 'zod';

import { StringKey } from '../../../lang';
import { StakeholderType } from '../../../types/stakeholderTypes';
import { getTranslation } from '../../../utils/getTranslation';

export const MAX_FILE_SIZE = 1024 * 1024 * 10;

export const ACCEPTED_FILE_MIME_TYPES = ['application/pdf', 'image/png', 'image/jpeg'];
export const fileSchemaMessage = getTranslation(StringKey.FILE_SIZE_TOO_LARGE);

const phoneRegex = new RegExp(/^([+]?[\s0-9]+)?(\d{3}|[(]?[0-9]+[)])?([-]?[\s]?[0-9])+$/);

const humanSchema = z
  .object({
    nationality: z
      .object({
        name: z.string().min(1, { message: getTranslation(StringKey.REQUIRED) }),
        iso2Code: z.string(),
      })
      .optional(),
    passportNo: z.string().or(z.literal('')).default(''),
    passportExpiryDate: z
      .object({
        day: z.coerce.number(),
        month: z.coerce.number(),
        year: z.coerce.number(),
      })
      .optional(),
    birthdayDate: z
      .object({
        day: z.coerce.number(),
        month: z.coerce.number(),
        year: z.coerce.number(),
      })
      .optional(),
  })
  .optional();

const companySchema = z
  .object({
    incorporationCountry: z
      .object({
        name: z.string().min(1, { message: getTranslation(StringKey.REQUIRED) }),
        iso2Code: z.string(),
      })
      .optional(),
    identifier: z.string().or(z.literal('')).default(''),
    incorporationDate: z
      .object({
        day: z.coerce.number(),
        month: z.coerce.number(),
        year: z.coerce.number(),
      })
      .optional(),
  })
  .optional();

export const stepOne = z
  .object({
    fullName: z.string().trim().min(1),
    email: z.string().email(),
    stakeholderType: z.nativeEnum(StakeholderType),

    human: humanSchema.optional(),

    company: companySchema.optional(),

    access: z
      .object({
        capTable: z.boolean().optional().default(true),
        position: z.boolean().optional().default(true),
        equity: z.boolean().optional().default(true),
        companyTable: z.boolean().optional().default(true),
      })
      .optional(),

    phoneCountry: z
      .object({
        iso2Code: z.string(),
        name: z.string(),
        prefix: z.string(),
      })
      .optional(),
    phoneNumber: z
      .string()
      .regex(phoneRegex, getTranslation(StringKey.INVALID_NUMBER))
      .or(z.literal(''))
      .default(''),
    address: z.string().trim().min(1).or(z.literal('')).default(''),
  })
  .refine(() => {
    return true;
  });

export const stepTwo = z
  .object({
    additionalNotes: z
      .string()
      .max(3000, getTranslation(StringKey.MAXIMUM_CHARACTERS, { count: 3000 }))
      .optional(),
    files: z
      .object({
        docLink: z.string(),
        loadProgress: z.number().max(100),
        abort: z.function(),
        id: z.string(),
        doc: z
          .custom<File>()
          .or(
            z.object({
              size: z.number(),
              type: z.string(),
              name: z.string(),
            }),
          )
          .refine(
            ({ type }) => ACCEPTED_FILE_MIME_TYPES.includes(type),
            getTranslation(StringKey.FILE_FORMAT_NOT_SUPPORTED),
          )
          .refine(({ size }) => size <= MAX_FILE_SIZE, fileSchemaMessage),
      })
      .array()

      .optional(),
  })
  .optional();

export const formSchema = z.object({
  stepOne,
  stepTwo,
});

export type FormSchema = z.infer<typeof formSchema>;
