import { BackendRoute } from '../../config';
import { QueryKey } from '../../constants';
import { ApiService } from '../../services';
import { useSelected } from '../company';
import { useAppMutation } from '../useAppMutation';

export const useExport = () => {
  const { selectedCompanyId: companyId } = useSelected();
  const { mutate: mutateExport } = useAppMutation([QueryKey.EXPORT_EVENTS, companyId], {
    mutationFn: async () => {
      await ApiService.downloadFile({
        endpoint: BackendRoute.COMPANIES,
        routePath: [companyId, 'safes', 'export'],
      });
    },
  });

  return { mutateExport };
};
