import React, { FC, useCallback } from 'react';
import { FileRejection, useDropzone } from 'react-dropzone';
import { useWatch } from 'react-hook-form';
import { twMerge } from 'tailwind-merge';
import { v4 as uuidv4 } from 'uuid';

import {
  CloseIcon,
  CloudUploadIcon,
  InfoCircle,
  UploadedFileIcon,
  UploadIcon,
} from '../../../../assets/icons';
import LoaderIcon from '../../../../assets/icons/LoaderIcon';
import { AppFormattedMessage } from '../../../../components/AppFormattedMessage';
import Button from '../../../../components/Button';
import { FormTextarea } from '../../../../components/Textarea';
import { BackendRoute } from '../../../../config';
import { useLocale } from '../../../../hooks';
import { StringKey } from '../../../../lang';
import { ApiService } from '../../../../services';
import { getTranslation } from '../../../../utils/getTranslation';
import { ChildrenFormProps } from '../type';
import { FormSchema, stepTwo as stepTwoFormSchema } from '../validation';
export type StepTwoProps = ChildrenFormProps;

export const StepTwo: FC<StepTwoProps> = ({
  handleCloseModal,
  control,
  setFormData,
  nextFormStep,
  prevFormStep,
  formData,
  lockMode,
  clearErrors,
  setError,
  errors: { stepTwo: errors },
}) => {
  const { stepTwo } = useWatch<FormSchema>({ control });
  const { success } = stepTwoFormSchema.safeParse(stepTwo);
  const { messagesLocale } = useLocale();

  const files = stepTwo?.files || [];
  const isValid = success && (files.some(({ docLink }) => docLink) || files.length === 0);
  const uploadedFiles = files.reduce((prev, curr) => (curr.docLink ? prev + 1 : prev), 0);
  const isReachedUploadMaximum = uploadedFiles >= 2;
  const getStepTwoFilesData = useCallback(() => formData('stepTwo.files') || [], [formData]);

  const onDrop = useCallback(
    async (acceptedFiles: File[], fileRejections: FileRejection[]) => {
      if (fileRejections.length > 0) {
        fileRejections.forEach(({ file, errors }) => {
          errors.forEach((e) => {
            if (e.code === 'file-invalid-type') {
              setError('stepTwo.files', {
                type: 'manual',
                message: `${messagesLocale[StringKey.INVALID_FILE_TYPE].replace('{fileType}', file.name)}`,
              });
            }
          });
        });
        return;
      }

      if (isReachedUploadMaximum) return;
      const existingFiles = getStepTwoFilesData();
      const filesToUpload = acceptedFiles.slice(0, 2 - existingFiles.length);
      filesToUpload.forEach(async (file) => {
        const { callback, abort } = ApiService.uploadFile<string>();
        const id = uuidv4();
        const { error, success } = stepTwoFormSchema.safeParse({
          files: [{ doc: file, docLink: '', loadProgress: 1, abort, id }],
        });

        if (!success) return setError('stepTwo.files', error.errors[0]);

        if (success) clearErrors('stepTwo.files');

        setFormData(
          'stepTwo.files',
          [...existingFiles, { doc: file, docLink: '', loadProgress: 1, abort, id }],
          { shouldValidate: true },
        );

        const link = await callback(
          { endpoint: BackendRoute.SHARE_CLASSES, routePath: ['file'] },
          ['file', file],
          {
            onUploadProgress: (n) => {
              const currentFiles = getStepTwoFilesData().map((file) =>
                file.id === id ? { ...file, loadProgress: n } : file,
              );
              setFormData('stepTwo.files', currentFiles);
            },
            onAboard: () => {
              const currentData = getStepTwoFilesData().filter((file) => file.id !== id);
              setFormData('stepTwo.files', currentData);
            },
          },
        );
        if (!link) return;
        const currentFiles = getStepTwoFilesData().map((file) =>
          file.id === id ? { ...file, loadProgress: 100, docLink: link } : file,
        );
        setFormData('stepTwo.files', currentFiles);
      });
    },
    [clearErrors, getStepTwoFilesData, isReachedUploadMaximum, setError, setFormData],
  );

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    maxFiles: 1,
    multiple: false,
    disabled: isReachedUploadMaximum,
    onDropRejected: () =>
      setError('stepTwo.files', {
        message: getTranslation(StringKey.FILE_FORMAT_NOT_SUPPORTED),
        type: 'validate',
      }),
    accept: {
      'application/pdf': ['.pdf'],
      'application/msword': ['.doc'],
      'application/vnd.openxmlformats-officedocument.wordprocessingml.document': ['.docx'],
      'application/vnd.ms-excel': ['.xlsx'],
      'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': ['.xls'],
      'text/csv': ['.csv'],
      'image/png': ['.png'],
      'image/jpeg': ['.jpeg'],
    },
  });

  const handleDeleteFile = useCallback(
    (id: string) => {
      const current = (formData('stepTwo.files') || []).find((file) => file.id === id);

      if (current && !current.docLink) current.abort();

      const currentData = (formData('stepTwo.files') || []).filter((file) => file.id !== id);
      setFormData('stepTwo.files', currentData);
      if (currentData.length === 0) return clearErrors('stepTwo.files');
    },
    [clearErrors, formData, setFormData],
  );

  return (
    <>
      <div className="flex h-full flex-col gap-4 overflow-y-auto rounded-b-xl border-b border-gray-300 bg-white p-6 pt-0">
        <span className="text-xs font-[450] text-gray-500">
          <AppFormattedMessage id={StringKey.DOCUMENTS_UPLOAD} />
        </span>
        <input {...getInputProps()} accept=".xlsx" className="hidden" type="file" />
        <label
          {...getRootProps()}
          className={twMerge(
            'relative flex w-full items-center gap-6 rounded-lg border border-dashed border-gray-200 p-6',
            isReachedUploadMaximum ? 'cursor-auto' : 'cursor-pointer',
          )}
        >
          {errors?.files ? <InfoCircle className="size-14" thinIconVariant /> : <CloudUploadIcon />}
          <div className="flex flex-col gap-2">
            <div className="flex">
              {errors?.files ? (
                <span className="text-sm font-[550] text-fireside-600">
                  {errors?.files.message?.toString()}
                </span>
              ) : (
                <p className="text-sm font-medium text-gray-700">
                  <span className="underline">
                    <AppFormattedMessage id={StringKey.CLICK_TO_UPLOAD} />{' '}
                  </span>
                  <AppFormattedMessage id={StringKey.OR_DRAG_AND_DROP} />
                </p>
              )}
            </div>

            <div className="flex flex-col gap-1 text-label-sm font-[450] text-gray-500">
              <span>
                <AppFormattedMessage
                  id={StringKey.SUPPORTED_FORMATS}
                  values={{
                    formats: 'pdf, doc/docs, xls/xlsx, csv, png/jpeg',
                  }}
                />
              </span>
              <span>
                <AppFormattedMessage id={StringKey.MAXIMUM_FILE_SIZE} values={{ size: 10 }} />
              </span>
            </div>
            {errors?.files && (
              <span className="flex h-9 w-fit items-center gap-1 rounded border border-gray-100 bg-gray-25 px-3 py-[6px] text-sm font-[450] text-gray-700">
                <UploadIcon />
                <AppFormattedMessage id={StringKey.UPLOAD_AGAIN} />
              </span>
            )}
          </div>
        </label>
        {(files?.length || 0) > 0 && (
          <div className="flex flex-col gap-2">
            <span className="pb-2 text-xs font-[450] text-gray-500">
              <AppFormattedMessage
                id={StringKey.ONE_OF_COUNT_FILES_UPLOADED}
                values={{ count: uploadedFiles, totalCount: 2 }}
              />
            </span>
            {files?.map(({ doc, docLink, loadProgress, id }, i) => (
              <div
                className="flex flex-col overflow-hidden rounded border border-brand-50 bg-brand-25"
                key={`${id}_${docLink}`}
              >
                <div className="flex justify-between p-3" key={`${doc?.name}_${i}`}>
                  <div className="flex items-center gap-2 overflow-hidden">
                    {docLink ? <UploadedFileIcon /> : <LoaderIcon className="size-4" />}
                    <span className="truncate text-xs font-[450] text-gray-700">{doc?.name}</span>
                  </div>
                  <Button
                    className="w-fit p-1 hover:bg-gray-50"
                    onClick={() => handleDeleteFile(id || '')}
                    styleType="NONE"
                    type="button"
                  >
                    <CloseIcon className="size-2" />
                  </Button>
                </div>
                <div
                  className={twMerge(
                    'h-[2px] shrink-0 bg-brand-700 transition-all duration-700',
                    docLink && 'bg-transparent',
                  )}
                  style={{ width: (loadProgress || (docLink && 100)) + '%' }}
                />
              </div>
            ))}
          </div>
        )}
        <FormTextarea
          control={control}
          name="stepTwo.additionalNotes"
          placeholder={<AppFormattedMessage id={StringKey.ADDITIONAL_NOTES_OPTIONAL} />}
          styleVariant="DEFAULT_W_PLACEHOLDER"
          wrapperClassName="bg-gray-50"
        />
      </div>

      <div className="flex h-9 w-full justify-between gap-3 px-6">
        <Button
          className="w-fit bg-transparent px-4 py-[6px] text-sm font-[450] text-gray-700"
          onClick={handleCloseModal}
          styleType="NONE"
        >
          <AppFormattedMessage id={StringKey.CANCEL} />
        </Button>
        <div className="flex gap-3">
          {!lockMode && (
            <Button
              className="h-full w-fit rounded border border-gray-100 bg-white px-3 py-[6px] text-sm font-[450] text-gray-700 shadow-xs"
              onClick={prevFormStep}
              styleType="NONE"
              type="button"
            >
              <AppFormattedMessage id={StringKey.BACK} />
            </Button>
          )}
          <Button
            className="h-full w-fit px-4 py-[6px] text-sm font-[550] text-white"
            disabled={!isValid}
            onClick={nextFormStep}
            type="button"
          >
            {lockMode ? (
              <AppFormattedMessage id={StringKey.UPDATE} />
            ) : !lockMode && (stepTwo?.additionalNotes || stepTwo?.files?.length || 0 > 0) ? (
              'Review'
            ) : (
              <AppFormattedMessage id={StringKey.SKIP} />
            )}
          </Button>
        </div>
      </div>
    </>
  );
};
