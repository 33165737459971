import React, { FC } from 'react';

import { AppFormattedMessage } from '../../../../components/AppFormattedMessage';
import { StringKey } from '../../../../lang';
import { PlanStakeholder } from '../../../../types/stakeholderTypes';

type StakeholderCardProps = {
  stakeholder: PlanStakeholder;
};

export const StakeholderCard: FC<StakeholderCardProps> = ({ stakeholder }) => {
  const stakeholderDilutedSharesCount = 0;
  const stakeholderAvailablePool = 0;

  const { granted, totalGrantVested, sharesVested, exercisable, exercised } = stakeholder;

  return (
    <div className="mb-4 flex h-fit w-full min-w-[300px] max-w-[440px] flex-col divide-y-[1px] divide-gray-100 rounded-md shadow-sm">
      <div className="flex flex-col p-3">
        <div className="flex items-center gap-3">
          <div className="flex size-10 shrink-0 items-center justify-center rounded-full bg-gray-200 text-sm font-[450] text-gray-700">
            {stakeholder.fullName[0]}
          </div>
          <div className="flex flex-col">
            <span className="truncate text-sm font-[550] text-gray-700">
              {stakeholder.fullName}
            </span>
            <span className="truncate text-xs font-[450] text-gray-400">{stakeholder.email}</span>
          </div>
        </div>
      </div>
      <div className="flex flex-wrap divide-x-[1px] divide-y-[1px] divide-[#F2F4F7]">
        <div className="flex w-1/2 flex-col items-center gap-3 p-3">
          <span className="whitespace-nowrap text-label-md font-[450] text-[#172335]">
            <AppFormattedMessage id={StringKey.DILUTED_SHARES} />
          </span>
          <span className="whitespace-nowrap text-sm text-[#172335]">
            {stakeholderDilutedSharesCount}
          </span>
        </div>
        <div className="flex w-1/2 flex-col items-center gap-3 p-3">
          <span className="whitespace-nowrap text-label-md font-[450] text-[#172335]">
            <AppFormattedMessage id={StringKey.AVAILABLE_POOL} />
          </span>
          <span className="whitespace-nowrap text-sm text-[#172335]">
            {stakeholderAvailablePool}
          </span>
        </div>
        <div className="flex w-1/2 flex-col items-center gap-3 p-3">
          <span className="whitespace-nowrap text-label-md font-[450] text-[#172335]">
            <AppFormattedMessage id={StringKey.POOL_GRANTED} />
          </span>
          <span className="whitespace-nowrap text-sm text-[#172335]">{granted}</span>
        </div>
        <div className="flex w-1/2 flex-col items-center gap-3 p-3">
          <span className="whitespace-nowrap text-label-md font-[450] text-[#172335]">
            <AppFormattedMessage id={StringKey.TOTAL_GRANT_VESTED} />
          </span>
          <span className="text-sm text-[#172335]">{totalGrantVested}</span>
        </div>
        <div className="flex w-1/2 flex-col items-center gap-3 p-3">
          <span className="whitespace-nowrap text-label-md font-[450] text-[#172335]">
            <AppFormattedMessage id={StringKey.SHARES_VESTED} />
          </span>
          <span className="text-sm text-[#172335]">{sharesVested}</span>
        </div>
        <div className="flex w-1/2 flex-col items-center gap-3 p-3">
          <span className="whitespace-nowrap text-label-md font-[450] text-[#172335]">
            <AppFormattedMessage id={StringKey.EXERCISABLE} />
          </span>
          <span className="text-sm text-[#172335]">{exercisable}</span>
        </div>
        <div className="flex w-1/2 flex-col items-center gap-3 p-3">
          <span className="whitespace-nowrap text-label-md font-[450] text-[#172335]">
            <AppFormattedMessage id={StringKey.EXERCISED} />
          </span>
          <span className="text-sm text-[#172335]">{exercised}</span>
        </div>
        <div className="flex w-1/2 flex-col items-center gap-3 p-3">
          <span className="whitespace-nowrap text-label-md font-[450] text-[#172335]">
            <AppFormattedMessage id={StringKey.CASH_SETTLED} />
          </span>
          <span className="text-sm text-gray-300">-</span>
        </div>
        <div className="flex w-1/2 flex-col items-center gap-3 p-3">
          <span className="whitespace-nowrap text-label-md font-[450] text-[#172335]">
            <AppFormattedMessage id={StringKey.RETURNED_TO_POOL} />
          </span>
          <span className="text-sm text-gray-300">-</span>
        </div>
        <div className="flex w-1/2 flex-col items-center gap-3 p-3">
          <span className="whitespace-nowrap text-label-md font-[450] text-[#172335]">
            <AppFormattedMessage id={StringKey.EXPIRED} />
          </span>
          <span className="text-sm text-gray-300">-</span>
        </div>
      </div>
    </div>
  );
};
