import { BackendRoute } from '../../config';
import { QueryKey } from '../../constants';
import {
  CapTableManagementTypes,
  EnterpriseFeatures,
} from '../../pages/CompanySetup/CompanySetupForm/EnterpriseModal/types';
import { ApiService } from '../../services';
import { useAppMutation } from '../useAppMutation';

export const useEnterpriseRequest = () => {
  const { mutate: enterpriseRequest, isPending } = useAppMutation([QueryKey.CREATE_COMPANY], {
    mutationFn: ({
      companyId,
      ...body
    }: {
      companyId: string;
      capTableManagement: CapTableManagementTypes;
      email?: string;
      phonoNumber?: string;
      additionalNotes?: string;
      features?: Record<EnterpriseFeatures, boolean>;
      anotherSoftware?: string;
    }) =>
      ApiService.post(
        {
          endpoint: BackendRoute.COMPANIES,
          routePath: [companyId, 'request-enterprise'],
        },
        { body },
      ),
  });
  return { enterpriseRequest, isPending };
};
