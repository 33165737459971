import React, { FC, useState } from 'react';

import { ChevronDownIcon } from '../../../assets/icons';
import { AppFormattedMessage } from '../../../components/AppFormattedMessage';
import {
  Combobox,
  ComboboxButton,
  ComboboxInput,
  ComboboxOption,
  ComboboxOptions,
} from '../../../components/Combobox';
import { useLocale } from '../../../hooks';
import { StringKey } from '../../../lang';
import { VestingOnOption, VestingOnTitle } from '../../../types/pool-plans.types';

const VestingOnItemsList = () => {
  return (
    <>
      {Object.entries(VestingOnTitle).map(([key, value]) => (
        <ComboboxOption key={key} value={key}>
          <span className="text-sm text-gray-700">{<AppFormattedMessage id={value} />}</span>
        </ComboboxOption>
      ))}
    </>
  );
};

export type VestingOnComboboxProps = {
  onSelect?: (state: VestingOnOption) => void;
  defaultValue?: VestingOnOption;
};

export const VestingOnCombobox: FC<VestingOnComboboxProps> = ({ onSelect, defaultValue }) => {
  const [value, setValue] = useState<VestingOnOption | null>(defaultValue ?? null);
  const { messagesLocale } = useLocale();
  return (
    <Combobox
      as={'div'}
      className="relative w-[200px]"
      onChange={(state: VestingOnOption) => {
        onSelect?.(state);
        setValue(state);
      }}
    >
      <div className="relative">
        <ComboboxInput
          className={'bg-gray-900'}
          icon={<ChevronDownIcon className="mt-2" />}
          onChange={() => ''}
          placeholder={<AppFormattedMessage id={StringKey.VESTING} />}
          readOnly
          value={value ? messagesLocale[VestingOnTitle[value]] : value || ''}
        />
        <ComboboxButton className="absolute left-0 top-0 z-1 h-full w-full" />
      </div>
      <ComboboxOptions className="w-full">
        <VestingOnItemsList />
      </ComboboxOptions>
    </Combobox>
  );
};
