import { FC, useState } from 'react';
import { twMerge } from 'tailwind-merge';

import { EditIcon } from '../../../../assets/icons';
import { AlertDialogWrapper } from '../../../../components/AlertDialog';
import { AppFormattedMessage } from '../../../../components/AppFormattedMessage';
import Button from '../../../../components/Button';
import { FormDatePicker } from '../../../../components/DatePicker';
import { DropDown } from '../../../../components/Dropdown';
import { SummaryItem } from '../../../../components/SummaryItem';
import { Switch } from '../../../../components/Switch';
import { useFormat, useModalState } from '../../../../hooks';
import { StringKey } from '../../../../lang';
import {
  SharePlanType,
  sharePlanTypeTitle,
  SharePlanVestingType,
  terminationIntervalTitle,
  VestingOnTitle,
} from '../../../../types/pool-plans.types';
import { TerminationModal } from '../../TerminationModal';
import { ChildrenFormProps, EditPlanStepVariant } from '../type';
import { formatSingular } from './FinalStep';

export type EditPlanPreviewProps = {
  onEditClick: (step: EditPlanStepVariant) => void;
  previewMode?: true;
  handleTerminate: (date: Date) => void;
} & ChildrenFormProps;

export const EditPlanPreview: FC<EditPlanPreviewProps> = ({
  handleCloseModal,
  formData,
  onEditClick,
  handleTerminate,
  previewMode,
  isTerminated,
  terminationDate,
}) => {
  const { format } = useFormat();
  const [terminatingData, setTerminatingData] = useState<{
    isTerminating: boolean;
    date: Date | null;
  } | null>(null);
  const { stepOne, stepTwo, stepThree, stepFour } = formData();

  const handleDocumentClick = (link: string) => {
    window.open(link);
  };

  const {
    toggler: terminationModalToggler,
    isOpen: isOpenTerminationModal,
    handleOpenModal: handleOpenTerminationModal,
    handleCloseModal: handleCloseTerminationModal,
    handleSuccessModal: handleSuccessTerminationModalModal,
  } = useModalState({
    onSuccess: () => {
      if (!terminatingData?.date) return;
      handleTerminate(terminatingData.date);
      handleCloseModal();
    },
  });

  return (
    <>
      <AlertDialogWrapper
        control={{ onOpenChange: terminationModalToggler, open: isOpenTerminationModal }}
      >
        <TerminationModal
          onClose={handleCloseTerminationModal}
          onSuccess={handleSuccessTerminationModalModal}
          text="plan"
        />
      </AlertDialogWrapper>
      <div className="flex h-full flex-col gap-4 overflow-y-auto rounded-b-xl border-b border-gray-300 bg-white p-6 pt-0">
        <div className="flex h-fit flex-col gap-2 rounded-lg bg-gray-50 pt-2">
          <div className="flex w-full justify-between px-4">
            <span className="text-sm font-[550] text-gray-700">
              <AppFormattedMessage id={StringKey.GENERAL} />
            </span>
            {!previewMode && (
              <Button className="w-fit" onClick={() => onEditClick(1)} styleType="NONE">
                <EditIcon iconColor="#2565C8" />
              </Button>
            )}
          </div>
          <div className="flex flex-col divide-y divide-gray-100 rounded-b-lg border border-gray-100 bg-white p-4 pb-0">
            <SummaryItem
              className="pt-0"
              title={<AppFormattedMessage id={StringKey.PLAN_NAME} />}
              value={stepOne?.name}
            />
            <SummaryItem title="Pool" value={stepOne?.pool?.name} />
            <SummaryItem
              title={<AppFormattedMessage id={StringKey.DATE} />}
              value={stepOne?.date ? format(stepOne?.date, 'dd/MM/yyyy') : ''}
            />
            <SummaryItem
              title={<AppFormattedMessage id={StringKey.EQUITY_PLAN_TYPE} />}
              value={
                <AppFormattedMessage
                  id={stepOne?.type ? sharePlanTypeTitle[stepOne?.type] : StringKey.EMPTY}
                />
              }
            />
            {stepOne?.type === SharePlanType.GROWTH_HURDLE && (
              <SummaryItem
                title={<AppFormattedMessage id={StringKey.HURDLE_VALUE} />}
                value={
                  stepOne?.financialDetails['growth-hurdle']?.value &&
                  Number(stepOne?.financialDetails['growth-hurdle']?.value).toLocaleString('en-US')
                }
              />
            )}
            {stepOne?.type === SharePlanType.STOCK_OPTIONS && (
              <>
                <SummaryItem
                  title={<AppFormattedMessage id={StringKey.STRIKE_PRICE_VALUE} />}
                  value={stepOne?.financialDetails['stock-options']?.value}
                />
                <SummaryItem
                  title={<AppFormattedMessage id={StringKey.CONVERSION_RATIO_X} />}
                  value={stepOne?.financialDetails['stock-options']?.conversionRatio || '-'}
                />
              </>
            )}
            {stepOne?.type === SharePlanType.BSPCE && (
              <>
                <SummaryItem
                  title={<AppFormattedMessage id={StringKey.STRIKE_PRICE_VALUE} />}
                  value={stepOne?.financialDetails['bspce']?.value}
                />
                <SummaryItem
                  title={<AppFormattedMessage id={StringKey.CONVERSION_RATIO_X} />}
                  value={stepOne?.financialDetails['bspce']?.conversionRatio || '-'}
                />
              </>
            )}
            {stepOne?.type === SharePlanType.WARRANTS && (
              <>
                <SummaryItem
                  title={<AppFormattedMessage id={StringKey.WARRANT_PRICE_VALUE} />}
                  value={stepOne?.financialDetails['warrants']?.value}
                />
                <SummaryItem
                  title={<AppFormattedMessage id={StringKey.CONVERSION_RATIO_X} />}
                  value={stepOne?.financialDetails['warrants']?.conversionRatio || '-'}
                />
              </>
            )}
            {stepOne?.type === SharePlanType.SAR && (
              <SummaryItem
                title={<AppFormattedMessage id={StringKey.SAR_BASE_PRICE} />}
                value={stepOne?.financialDetails['sar']?.value}
              />
            )}
            {stepOne?.type === SharePlanType.RSU && (
              <SummaryItem
                title={<AppFormattedMessage id={StringKey.CONVERSION_RATIO_X} />}
                value={stepOne?.financialDetails['rsu']?.conversionRatio || '-'}
              />
            )}
            {stepOne?.type === SharePlanType.PHANTOM && (
              <SummaryItem
                title={<AppFormattedMessage id={StringKey.CONVERSION_RATIO_X} />}
                value={stepOne?.financialDetails['phantom']?.conversionRatio || '-'}
              />
            )}
            {stepOne?.type === SharePlanType.RSA && (
              <>
                <SummaryItem
                  title={<AppFormattedMessage id={StringKey.RSA_PURCHASE_PRICE} />}
                  value={stepOne?.financialDetails['rsa']?.value || '-'}
                />
                <SummaryItem
                  title={<AppFormattedMessage id={StringKey.CONVERSION_RATIO_X} />}
                  value={stepOne?.financialDetails['rsa']?.conversionRatio || '-'}
                />
              </>
            )}
          </div>
        </div>
        <div className="flex h-fit flex-col gap-2 rounded-lg bg-gray-50 pt-2">
          <div className="flex w-full justify-between px-4">
            <span className="text-sm font-[550] text-gray-700">
              <AppFormattedMessage id={StringKey.VESTING} />
            </span>
            {!previewMode && (
              <Button className="w-fit" onClick={() => onEditClick(2)} styleType="NONE">
                <EditIcon iconColor="#2565C8" />
              </Button>
            )}
          </div>
          <div className="flex flex-col divide-y divide-gray-100 rounded-b-lg border border-gray-100 bg-white p-4 pb-3">
            {!stepTwo?.vesting && (
              <span
                className={twMerge(
                  'text-xs font-[450] text-gray-500',
                  stepOne?.type === SharePlanType.WARRANTS && !stepTwo?.expiryDate?.date && 'pb-3',
                )}
              >
                <AppFormattedMessage id={StringKey.NO_VESTING_STRUCTURE_SELECTED} />
              </span>
            )}
            {stepTwo?.vesting?.vestingMechanism === SharePlanVestingType.TIME_BASED_STANDARD && (
              <>
                <div>
                  <SummaryItem
                    className="pt-0"
                    title={<AppFormattedMessage id={StringKey.UPDATE} />}
                    value={<AppFormattedMessage id={StringKey.TIME_BASED_STANDARD} />}
                  />
                  <SummaryItem
                    className="pt-0"
                    title={<AppFormattedMessage id={StringKey.VESTING_ON} />}
                    value={
                      stepTwo?.vesting?.timeBasedStandard?.vestingOn && (
                        <AppFormattedMessage
                          id={VestingOnTitle[stepTwo?.vesting?.timeBasedStandard?.vestingOn]}
                        />
                      )
                    }
                  />
                </div>
                <div>
                  <SummaryItem
                    className="pb-0"
                    title={<AppFormattedMessage id={StringKey.DURATION} />}
                    value={formatSingular(
                      stepTwo?.vesting?.timeBasedStandard?.vestingDuration,
                      'month',
                    )}
                  />
                  <SummaryItem
                    className="pb-0"
                    title={<AppFormattedMessage id={StringKey.VESTING_FREQUENCY_ABBR} />}
                    value={formatSingular(
                      stepTwo?.vesting?.timeBasedStandard?.vestingFrequency,
                      'month',
                    )}
                  />
                  <SummaryItem
                    className="pb-0"
                    title={<AppFormattedMessage id={StringKey.CLIFF} />}
                    value={formatSingular(
                      stepTwo?.vesting?.timeBasedStandard?.cliffPeriod,
                      'month',
                    )}
                  />
                  <SummaryItem
                    className={stepTwo?.expiryDate?.enabled ? 'pb-3' : 'pb-0'}
                    title={<AppFormattedMessage id={StringKey.ACCELERATION_DATE} />}
                    value={
                      stepTwo?.vesting?.timeBasedStandard?.accelerationDate
                        ? format(
                            stepTwo?.vesting?.timeBasedStandard?.accelerationDate,
                            'dd/MM/yyyy',
                          )
                        : '-'
                    }
                  />
                </div>
              </>
            )}
            {stepTwo?.vesting?.vestingMechanism === SharePlanVestingType.TIME_BASED_DYNAMIC && (
              <>
                <div>
                  <SummaryItem
                    className="pt-0"
                    title={<AppFormattedMessage id={StringKey.TYPE} />}
                    value={<AppFormattedMessage id={StringKey.TIME_BASED_DYNAMIC} />}
                  />
                  <SummaryItem
                    className="pt-0"
                    title={<AppFormattedMessage id={StringKey.VESTING_ON} />}
                    value={
                      stepTwo?.vesting?.timeBasedDynamic?.vestingOn && (
                        <AppFormattedMessage
                          id={VestingOnTitle[stepTwo?.vesting?.timeBasedDynamic?.vestingOn]}
                        />
                      )
                    }
                  />
                </div>

                <div className="flex flex-col divide-y divide-gray-100">
                  {stepTwo?.vesting?.timeBasedDynamic?.dynamicVestings.map((period, index) => (
                    <div className="pt-3" key={index}>
                      <span className="text-xs font-[550] text-gray-700">
                        <AppFormattedMessage id={StringKey.PERIOD} /> {index + 1}
                      </span>
                      <SummaryItem
                        className="pb-0"
                        title={<AppFormattedMessage id={StringKey.DURATION} />}
                        value={formatSingular(period.vestingDuration, 'month')}
                      />
                      <SummaryItem
                        className="pb-0"
                        title={<AppFormattedMessage id={StringKey.VESTING_FREQUENCY_ABBR} />}
                        value={formatSingular(period.vestingFrequency, 'month')}
                      />
                      <SummaryItem
                        title={<AppFormattedMessage id={StringKey.PLAN_ALLOCATION} />}
                        value={`${period.planAllocation} %`}
                      />
                    </div>
                  ))}
                </div>
                <SummaryItem
                  className={stepTwo?.expiryDate?.enabled && 'pb-3'}
                  title={<AppFormattedMessage id={StringKey.ACCELERATION_DATE} />}
                  value={
                    stepTwo?.vesting?.timeBasedDynamic?.accelerationDate
                      ? format(stepTwo?.vesting?.timeBasedDynamic?.accelerationDate, 'dd/MM/yyyy')
                      : '-'
                  }
                />
              </>
            )}
            {stepTwo?.vesting?.vestingMechanism === SharePlanVestingType.TARGET_BASED && (
              <>
                <SummaryItem
                  className="pt-0"
                  title={<AppFormattedMessage id={StringKey.TYPE} />}
                  value={<AppFormattedMessage id={StringKey.TARGET_BASED} />}
                />
                {stepTwo?.vesting?.targetBasedStandard?.targetVestings.map((period, index) => (
                  <div className="pt-3" key={index}>
                    <span className="text-xs font-[550] text-gray-700">
                      <AppFormattedMessage id={StringKey.MILESTONE} /> {index + 1}
                    </span>
                    <SummaryItem
                      className="pb-0"
                      title={<AppFormattedMessage id={StringKey.MILESTONE_NAME} />}
                      value={period.name}
                    />
                    <SummaryItem
                      className="pb-0"
                      title={<AppFormattedMessage id={StringKey.WEIGHT} />}
                      value={`${period.weight} %`}
                    />
                    <SummaryItem
                      title={<AppFormattedMessage id={StringKey.TARGET_DATE} />}
                      value={period.targetDate ? format(period.targetDate, 'dd/MM/yyyy') : '-'}
                    />
                  </div>
                ))}
              </>
            )}
            {stepOne?.type === SharePlanType.WARRANTS && (
              <SummaryItem
                className="pb-0"
                title={<AppFormattedMessage id={StringKey.EXPIRY_DATE} />}
                value={
                  stepTwo?.expiryDate?.date ? format(stepTwo?.expiryDate?.date, 'dd/MM/yyyy') : '-'
                }
              />
            )}
          </div>
        </div>

        <div className="flex h-fit flex-col gap-2 rounded-lg bg-gray-50 pt-2">
          <div className="flex w-full justify-between px-4">
            <span className="text-sm font-[550] text-gray-700">
              <AppFormattedMessage id={StringKey.TERMINATION_LOGIC} />
            </span>

            {!previewMode && (
              <Button className="w-fit" onClick={() => onEditClick(3)} styleType="NONE">
                <EditIcon iconColor="#2565C8" />
              </Button>
            )}
          </div>
          <div className="flex flex-col divide-y divide-gray-100 rounded-b-lg border border-gray-100 bg-white p-4 pb-3">
            {!stepThree?.terminationLogic && (
              <span className="text-xs font-[450] text-gray-500">
                <AppFormattedMessage id={StringKey.NO_TERMINATION_LOGIC_SELECTED} />
              </span>
            )}
            {stepThree?.terminationLogic?.enabled && (
              <>
                {stepThree?.terminationLogic?.resignation?.enabled ? (
                  <div>
                    <SummaryItem
                      className="pb-0"
                      title={<AppFormattedMessage id={StringKey.RESIGNATION} />}
                      value={<AppFormattedMessage id={StringKey.EXERCISE_EXPIRATION_PERIOD} />}
                    />
                    <SummaryItem
                      className="pb-0"
                      title={<AppFormattedMessage id={StringKey.VALUE} />}
                      value={stepThree.terminationLogic.resignation.value}
                    />
                    <SummaryItem
                      title={<AppFormattedMessage id={StringKey.INTERVAL} />}
                      value={
                        <AppFormattedMessage
                          id={
                            terminationIntervalTitle[
                              stepThree.terminationLogic.resignation.interval
                            ]
                          }
                        />
                      }
                    />
                  </div>
                ) : (
                  <SummaryItem
                    title={<AppFormattedMessage id={StringKey.RESIGNATION} />}
                    value={<AppFormattedMessage id={StringKey.NO_EXERCISE_EXPIRATION_PERIOD} />}
                  />
                )}
                {stepThree?.terminationLogic?.termination?.enabled ? (
                  <div>
                    <SummaryItem
                      className="pb-0"
                      title={<AppFormattedMessage id={StringKey.TERMINATION} />}
                      value={<AppFormattedMessage id={StringKey.EXERCISE_EXPIRATION_PERIOD} />}
                    />
                    <SummaryItem
                      className="pb-0"
                      title={<AppFormattedMessage id={StringKey.VALUE} />}
                      value={stepThree.terminationLogic.termination.value}
                    />
                    <SummaryItem
                      title={<AppFormattedMessage id={StringKey.INTERVAL} />}
                      value={
                        <AppFormattedMessage
                          id={
                            terminationIntervalTitle[
                              stepThree.terminationLogic.termination.interval
                            ]
                          }
                        />
                      }
                    />
                  </div>
                ) : (
                  <SummaryItem
                    title={<AppFormattedMessage id={StringKey.TERMINATION} />}
                    value={<AppFormattedMessage id={StringKey.NO_EXERCISE_EXPIRATION_PERIOD} />}
                  />
                )}
                {stepThree?.terminationLogic?.terminationWithCause?.enabled ? (
                  <div>
                    <SummaryItem
                      className="pb-0"
                      title={<AppFormattedMessage id={StringKey.TERMINATION_WITH_CAUSE} />}
                      value={<AppFormattedMessage id={StringKey.EXERCISE_EXPIRATION_PERIOD} />}
                    />
                    <SummaryItem
                      className="pb-0"
                      title={<AppFormattedMessage id={StringKey.VALUE} />}
                      value={stepThree.terminationLogic.terminationWithCause.value}
                    />
                    <SummaryItem
                      title={<AppFormattedMessage id={StringKey.INTERVAL} />}
                      value={
                        <AppFormattedMessage
                          id={
                            terminationIntervalTitle[
                              stepThree.terminationLogic.terminationWithCause.interval
                            ]
                          }
                        />
                      }
                    />
                  </div>
                ) : (
                  <SummaryItem
                    title={<AppFormattedMessage id={StringKey.TERMINATION_WITH_CAUSE} />}
                    value={<AppFormattedMessage id={StringKey.NO_EXERCISE_EXPIRATION_PERIOD} />}
                  />
                )}
                {stepThree?.terminationLogic?.retirement?.enabled ? (
                  <div>
                    <SummaryItem
                      className="pb-0"
                      title={<AppFormattedMessage id={StringKey.RETIREMENT} />}
                      value={<AppFormattedMessage id={StringKey.EXERCISE_EXPIRATION_PERIOD} />}
                    />
                    <SummaryItem
                      className="pb-0"
                      title={<AppFormattedMessage id={StringKey.VALUE} />}
                      value={stepThree.terminationLogic.retirement.value}
                    />
                    <SummaryItem
                      className="pb-0"
                      title={<AppFormattedMessage id={StringKey.INTERVAL} />}
                      value={
                        <AppFormattedMessage
                          id={
                            terminationIntervalTitle[stepThree.terminationLogic.retirement.interval]
                          }
                        />
                      }
                    />
                  </div>
                ) : (
                  <SummaryItem
                    className="pb-0"
                    title={<AppFormattedMessage id={StringKey.RETIREMENT} />}
                    value={<AppFormattedMessage id={StringKey.NO_EXERCISE_EXPIRATION_PERIOD} />}
                  />
                )}
              </>
            )}
          </div>
        </div>
        <div className="flex w-full items-center justify-between rounded-lg border border-gray-100 bg-white px-4">
          <div className={'flex gap-3 text-nowrap py-2'}>
            <span className="w-[120px] flex-shrink-0 text-xs font-[450] text-gray-500">
              <AppFormattedMessage id={StringKey.DOCUMENTS} />:
            </span>
            <div className="flex max-w-[220px] flex-col gap-2">
              {(stepFour?.files?.length || 0) > 0 ? (
                stepFour?.files?.map(({ id, doc, docLink }) => (
                  <div
                    className={twMerge(
                      'truncate text-xs font-[450] text-gray-700',
                      previewMode && 'cursor-pointer text-brand-700 underline',
                    )}
                    key={id}
                    onClick={() => previewMode && handleDocumentClick(docLink)}
                  >
                    {doc?.name}
                  </div>
                ))
              ) : (
                <span className="truncate text-xs font-[450] text-gray-700">-</span>
              )}
            </div>
          </div>
          {!previewMode && (
            <Button className="w-fit" onClick={() => onEditClick(4)} styleType="NONE">
              <EditIcon iconColor="#2565C8" />
            </Button>
          )}
        </div>
        <div className="flex w-full items-start justify-between rounded-lg border border-gray-100 bg-white px-4 py-2">
          <div className="flex w-full gap-3">
            <span className="w-[120px] flex-shrink-0 text-xs font-[450] text-gray-500">
              {<AppFormattedMessage id={StringKey.ADDITIONAL_NOTES} />}:
            </span>
            <div
              className={twMerge(
                'max-w-[250px] break-words text-xs font-[450] text-gray-700',
                !previewMode ? 'max-w-[220px]' : 'max-w-[250px]',
              )}
            >
              {stepFour?.additionalNotes || '-'}
            </div>
          </div>
          {!previewMode && (
            <Button className="w-fit" onClick={() => onEditClick(4)} styleType="NONE">
              <EditIcon iconColor="#2565C8" />
            </Button>
          )}
        </div>

        {!previewMode && !isTerminated && (
          <div className="flex w-full flex-col justify-center rounded-lg bg-fireside-25 px-1 py-1">
            <div className="flex gap-2 px-3 py-3">
              <Switch
                onChange={(state) =>
                  setTerminatingData(state ? { isTerminating: state, date: null } : null)
                }
              />
              <span className="text-sm font-[550] text-fireside-600">
                <AppFormattedMessage id={StringKey.TERMINATE_PLAN} />
              </span>
            </div>
            {terminatingData && (
              <div className="flex w-full flex-col gap-3 rounded-md bg-white p-3 shadow-xs">
                <FormDatePicker
                  calendar={{ fromDate: new Date() }}
                  defaultMonth={terminatingData?.date || undefined}
                  inputValue={
                    terminatingData?.date ? format(terminatingData?.date, 'dd/MM/yy') : ''
                  }
                  inputWrapperClassName="cursor-pointer"
                  onSelect={(date) => setTerminatingData({ isTerminating: true, date })}
                  placeholder={<AppFormattedMessage id={StringKey.DATE_OF_TERMINATION} />}
                  value={terminatingData?.date}
                />
                <DropDown
                  answer="Plan termination refers to the process of ending an employee stock option plan, stock purchase plan, or any other equity compensation plan."
                  className="rounded-lg bg-gray-50"
                  question={<AppFormattedMessage id={StringKey.PLAN_TERMINATION_WORKS} />}
                />
              </div>
            )}
          </div>
        )}

        {!previewMode && isTerminated && terminationDate && (
          <div className="flex flex-col gap-3 p-3 pt-5">
            <div className="flex flex-col gap-1">
              <span className="text-sm font-[550] text-fireside-600">
                <AppFormattedMessage id={StringKey.TERMINATE_PLAN} />
              </span>
              <div className="flex items-center gap-2">
                <span className="text-xs font-[450] text-gray-700">
                  <AppFormattedMessage id={StringKey.CURRENT_TERMINATION_DATE} />:
                </span>
                <span className="text-xs font-[450] text-fireside-600">
                  {terminationDate ? format(terminationDate, 'dd/MM/yy') : ''}
                </span>
              </div>
            </div>

            <div className="flex w-full flex-col gap-3 rounded-md bg-white p-3 shadow-xs">
              <FormDatePicker
                calendar={{ fromDate: new Date() }}
                defaultMonth={terminatingData?.date || undefined}
                inputValue={terminatingData?.date ? format(terminatingData.date, 'dd/MM/yy') : ''}
                inputWrapperClassName="cursor-pointer"
                onSelect={(date) => setTerminatingData({ isTerminating: true, date })}
                placeholder={<AppFormattedMessage id={StringKey.NEW_TERMINATION_DATE} />}
                value={terminatingData?.date}
              />
              <DropDown
                answer="Plan termination refers to the process of ending an employee stock option plan, stock purchase plan, or any other equity compensation plan."
                className="rounded-lg bg-gray-50"
                question={<AppFormattedMessage id={StringKey.PLAN_TERMINATION_WORKS} />}
              />
            </div>
          </div>
        )}
      </div>

      <div
        className={twMerge(
          'flex h-9 w-full justify-start gap-3 px-6',
          (previewMode || terminatingData) && 'justify-end',
        )}
      >
        <Button
          className={twMerge(
            'w-fit bg-transparent px-4 py-[6px] text-sm font-[450] text-gray-700',
            terminatingData && 'bg-fireside-600 text-white',
            previewMode && 'bg-brand-700 text-white',
          )}
          disabled={(terminatingData && !terminatingData.date) || false}
          onClick={() => {
            if (!terminatingData) return handleCloseModal();

            terminatingData.date && handleOpenTerminationModal();
          }}
          styleType="NONE"
          type="button"
        >
          {terminatingData ? (
            <AppFormattedMessage id={StringKey.TERMINATE_PLAN} />
          ) : previewMode ? (
            <AppFormattedMessage id={StringKey.CLOSE} />
          ) : (
            <AppFormattedMessage id={StringKey.CANCEL} />
          )}
        </Button>
      </div>
    </>
  );
};
