import { FC } from 'react';
import { twMerge } from 'tailwind-merge';

import { ArrowIcon } from '../../../assets/icons';
import { AppFormattedMessage } from '../../../components/AppFormattedMessage';
import Button from '../../../components/Button';
import { SelectedCompanyCurrency } from '../../../components/SelectedCompanyCurrency';
import { useNavigate } from '../../../hooks';
import { StringKey } from '../../../lang';
import { PageRoute } from '../../../types/pageTypes';
import { Snapshot } from '../../../types/snapshot.types';
import { toRound } from '../../../utils/getRoundedNumber';
import { toNumber } from '../../../utils/toNumber';
import { latestSnapshotId } from '../variables';

export type MobileDetailsProps = {
  className?: string;
  valuationId?: string;
  snapshot?: Snapshot;
};

export const MobileDetails: FC<MobileDetailsProps> = ({
  snapshot,
  valuationId = latestSnapshotId,
  className,
}) => {
  const navigate = useNavigate(PageRoute.CAP_TABLE);

  const issuedTotal = snapshot?.stats?.stakeholdersTypes?.reduce(
    (acc, curr) => (acc += curr.issuedPercentage || 0),
    0,
  );
  const dilutedTotal = snapshot?.stats?.stakeholdersTypes?.reduce(
    (acc, curr) => (acc += curr.dilutedPercentage || 0),
    0,
  );
  const votingTotal = snapshot?.stats?.stakeholdersTypes?.reduce(
    (acc, curr) => (acc += curr.votingPercentage || 0),
    0,
  );
  const noIssuedTotal = snapshot?.stats?.stakeholdersTypes?.reduce(
    (acc, curr) => (acc += curr.issued || 0),
    0,
  );
  const dilutedSharesTotal = snapshot?.stats?.stakeholdersTypes?.reduce(
    (acc, curr) => (acc += curr.diluted || 0),
    0,
  );
  const investmentTotal = 0;

  return (
    <div
      className={twMerge('flex w-full flex-col gap-4 rounded-md px-3 py-4 shadow-sm', className)}
    >
      <span className="text-base font-semibold text-gray-700">
        <AppFormattedMessage id={StringKey.DETAILS} />
      </span>
      <div className="flex flex-col overflow-hidden rounded-md shadow-sm">
        <div className="grid grid-flow-row grid-cols-2 text-sm font-[450] text-gray-700">
          <div className="centered flex w-full flex-col gap-3 border border-gray-100 py-3">
            <span className="text-label-md font-medium">
              <AppFormattedMessage id={StringKey.ISSUED_PERCENTAGE} />
            </span>
            <span>{toRound(toNumber(issuedTotal) * 100)}</span>
          </div>
          <div className="centered flex w-full flex-col gap-3 border border-gray-100 py-3">
            <span className="text-label-md font-medium">
              <AppFormattedMessage id={StringKey.DILUTED_PERCENTAGE} />
            </span>
            <span>{toRound(toNumber(dilutedTotal) * 100)}</span>
          </div>
          <div className="centered flex w-full flex-col gap-3 border border-gray-100 py-3">
            <span className="text-label-md font-medium">
              <AppFormattedMessage id={StringKey.VOTING_PERCENTAGE} />
            </span>
            <span>{toRound(toNumber(votingTotal) * 100)}</span>
          </div>
          <div className="centered flex w-full flex-col gap-3 border border-gray-100 py-3">
            <span className="text-label-md font-medium">
              <AppFormattedMessage id={StringKey.NO_ISSUED} />
            </span>
            <span>{(noIssuedTotal || 0).toLocaleString('en-US')}</span>
          </div>
          <div className="centered flex w-full flex-col gap-3 border border-b-0 border-gray-100 py-3">
            <span className="text-label-md font-medium">
              <AppFormattedMessage id={StringKey.DILUTED_SHARES} />
            </span>
            <span>{(dilutedSharesTotal || 0).toLocaleString('en-US')}</span>
          </div>
          <div className="centered flex w-full flex-col gap-3 border border-b-0 border-gray-100 py-3">
            <span className="text-label-md font-medium">
              <AppFormattedMessage id={StringKey.INVESTMENT} /> (<SelectedCompanyCurrency />)
            </span>
            <span>{toRound(investmentTotal)}</span>
          </div>
        </div>
        {snapshot && (
          <Button
            className="flex w-full items-center justify-between rounded-none bg-brand-25 px-4 py-3"
            onClick={() => navigate([valuationId])}
            styleType="NONE"
          >
            <span className="text-sm font-[450] text-brand-700">
              <AppFormattedMessage id={StringKey.VIEW_POSITIONS} />
            </span>
            <ArrowIcon className="rotate-180" iconColor="#2565C8" />
          </Button>
        )}
      </div>
    </div>
  );
};
