import React, { FC, useCallback, useEffect } from 'react';
import {
  Control,
  useFieldArray,
  UseFormResetField,
  UseFormSetValue,
  useWatch,
} from 'react-hook-form';
import { twMerge } from 'tailwind-merge';

import { CalendarIcon, CloseIcon } from '../../../../../assets/icons';
import { AppFormattedMessage } from '../../../../../components/AppFormattedMessage';
import Button from '../../../../../components/Button';
import { FormInput } from '../../../../../components/Input';
import { useFormat } from '../../../../../hooks';
import { StringKey } from '../../../../../lang';
import { SharePlanType } from '../../../../../types/pool-plans.types';
import { DatePicker } from '../../../PoolForm/DatePicker';
import { GrantCombobox, StakeholderCombobox, TableHeaderItem } from '../FormComponents';
import { FormSchema } from '../validation';

export type WarrantExpireProps = {
  setFormData: UseFormSetValue<FormSchema>;
  control: Control<FormSchema>;
  companyId: string;
  resetField: UseFormResetField<FormSchema>;
};

export const WarrantExpire: FC<WarrantExpireProps> = ({
  control,
  setFormData,
  companyId,
  resetField,
}) => {
  const { stepOne } = useWatch<FormSchema>({ control });
  const { format } = useFormat();

  const {
    fields: warrantItemsFields,
    append: warrantItemsAppend,
    remove: warrantItemsRemove,
  } = useFieldArray({
    control,
    name: 'stepOne.warrantExpire.warrantExpireItems',
  });

  const handleRemoveWarrantItems = useCallback(
    (index: number) => {
      warrantItemsRemove(index);
    },
    [warrantItemsRemove],
  );

  const handleAppendWarrantItems = useCallback(() => {
    const defaultDate = new Date();
    warrantItemsAppend({
      stakeholder: { id: '', fullName: '' },
      grant: {
        id: '',
        exercisedCount: 0,
        vestedSharesCount: 0,
        grantItem: { plan: { expiryDate: defaultDate } },
      },
      expired: 0,
    });
  }, [warrantItemsAppend]);

  const handleStakeholderChange = (index: number) => {
    resetField(`stepOne.warrantExpire.warrantExpireItems.${index}.grant`);
    setFormData(
      `stepOne.warrantExpire.warrantExpireItems.${index}.grant.grantItem.plan.expiryDate`,
      new Date(),
    );
  };

  const calculateBalance = useCallback(
    (index: number) => {
      const vestedShares =
        stepOne?.warrantExpire?.warrantExpireItems?.[index]?.grant?.vestedSharesCount || 0;
      const exercisedShares =
        stepOne?.warrantExpire?.warrantExpireItems?.[index]?.grant?.exercisedCount || 0;
      const value = stepOne?.warrantExpire?.warrantExpireItems?.[index]?.expired || 0;
      const balance = Math.max(vestedShares - exercisedShares - value, 0);

      return balance.toLocaleString('en-US');
    },
    [stepOne?.warrantExpire?.warrantExpireItems],
  );

  useEffect(() => {
    if (warrantItemsFields.length === 0) {
      handleAppendWarrantItems();
    }
  }, [handleAppendWarrantItems, warrantItemsFields.length]);

  const showRemoveButton = warrantItemsFields.length > 1;

  return (
    <>
      <div className="flex flex-col rounded-lg border-[1px] border-gray-100 bg-white">
        <div
          className={twMerge(
            'grid w-full',
            showRemoveButton
              ? 'grid-cols-[repeat(4,_minmax(195px,_1fr))_repeat(1,48px)]'
              : 'grid-cols-[repeat(4,_minmax(195px,_1fr))]',
          )}
        >
          <TableHeaderItem showTooltip text={<AppFormattedMessage id={StringKey.STAKEHOLDER} />} />
          <TableHeaderItem showTooltip text={<AppFormattedMessage id={StringKey.GRANT} />} />
          <TableHeaderItem showTooltip text={<AppFormattedMessage id={StringKey.EXPIRY_DATE} />} />
          <TableHeaderItem
            text={<AppFormattedMessage id={StringKey.EXPIRED_WARRANTS} />}
            variant="dark"
          />
          {showRemoveButton && <TableHeaderItem text="" />}
        </div>
        {warrantItemsFields.map((field, index) => (
          <div
            className={twMerge(
              'grid border-b-[1px] border-b-gray-100',
              showRemoveButton
                ? 'grid-cols-[repeat(4,_minmax(195px,_1fr))_repeat(1,48px)]'
                : 'grid-cols-[repeat(4,_minmax(195px,_1fr))]',
            )}
            key={field.id}
          >
            <div className="px-4 pb-10 pt-7">
              <StakeholderCombobox
                companyId={companyId}
                control={control}
                handleStakeholderChange={() => handleStakeholderChange(index)}
                name={`stepOne.warrantExpire.warrantExpireItems.${index}.stakeholder`}
                planType={SharePlanType.WARRANTS}
              />
            </div>
            <div className="px-4 pb-10 pt-7">
              <GrantCombobox
                companyId={companyId}
                control={control}
                name={`stepOne.warrantExpire.warrantExpireItems.${index}.grant`}
                sharePlanType={SharePlanType.WARRANTS}
                stakeholderId={
                  stepOne?.warrantExpire?.warrantExpireItems?.[index]?.stakeholder?.id || ''
                }
              />
            </div>
            <div className="px-4 pb-10 pt-7">
              <DatePicker
                defaultMonth={
                  stepOne?.warrantExpire?.warrantExpireItems?.[index]?.grant?.grantItem?.plan
                    ?.expiryDate || undefined
                }
                onSelect={(date) =>
                  setFormData(
                    `stepOne.warrantExpire.warrantExpireItems.${index}.grant.grantItem.plan.expiryDate`,
                    date,
                  )
                }
                value={
                  stepOne?.warrantExpire?.warrantExpireItems?.[index]?.grant?.grantItem?.plan
                    ?.expiryDate
                }
                wrapperClassName="w-full"
              >
                <FormInput
                  autoComplete="off"
                  control={control}
                  customValue={(value) => {
                    return value && format(value, 'dd/MM/yyyy');
                  }}
                  disabledInput
                  icon={<CalendarIcon className="size-5" iconColor="#344054" />}
                  inputClassName="h-10"
                  name={`stepOne.warrantExpire.warrantExpireItems.${index}.grant.grantItem.plan.expiryDate`}
                  wrapperClassName="w-full"
                />
              </DatePicker>
            </div>
            <div className="flex flex-col gap-2 px-4 pb-4 pt-7">
              <FormInput
                control={control}
                inputClassName="h-10"
                name={`stepOne.warrantExpire.warrantExpireItems.${index}.expired`}
                numberOnly
                shouldFormatNumber
                wrapperClassName="w-full"
              />
              <span className="text-xs font-[450] text-gray-600">
                <AppFormattedMessage id={StringKey.AVAILABLE} /> {calculateBalance(index)}
              </span>
            </div>
            {showRemoveButton && (
              <div>
                <Button
                  className="flex h-full w-full items-center justify-center"
                  onClick={() => handleRemoveWarrantItems(index)}
                  styleType="NONE"
                >
                  <CloseIcon />
                </Button>
              </div>
            )}
          </div>
        ))}
      </div>

      {warrantItemsFields.length < 10 && (
        <div className="sticky bottom-[76px] z-10 w-full bg-white">
          <Button
            className="w-fit px-4 py-2 text-sm font-[450] text-brand-700 underline disabled:border-transparent disabled:bg-transparent"
            onClick={handleAppendWarrantItems}
            styleType="NONE"
          >
            + <AppFormattedMessage id={StringKey.ADD_EXERCISING} />
          </Button>
        </div>
      )}
    </>
  );
};
