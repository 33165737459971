import { ReactNode } from 'react';

export const TransactionTitleItem = ({
  createdAt,
  name,
}: {
  name: string | ReactNode;
  createdAt: string;
}) => (
  <div className="flex flex-col p-3">
    <span className="truncate text-base font-semibold text-gray-700">{name}</span>
    <span className="text-xs font-[450] text-gray-700">{createdAt}</span>
  </div>
);
