export enum BackendRoute {
  INVITATIONS = '/invitations',
  USERS = '/users',
  STAKEHOLDERS = '/stakeholders',
  COMPANIES = '/companies',
  AUTH_REFRESH = '/auth/refresh',
  PLANS = '/plans',
  AUTH_VALIDATE = '/auth/validate',
  AUTH_REGISTER = '/auth/register',
  AUTH_LOGIN = '/auth/login',
  AUTH_TEMPORARY_ACCESS = '/auth/grant-temporary-access',
  CHECK_AUTH_TEMPORARY_ACCESS = '/auth/check-temporary-access',
  AUTH_LOGOUT = '/auth/logout',
  AUTH_RECOVER_PASSWORD = '/auth/recover-password',
  VERIFICATION = '/verification',
  VERIFICATION_REFRESH = '/verification/refresh',
  RECOVERY = '/recovery',
  RECOVERY_RESEND = '/recovery/resend',
  RECOVERY_CHECK = '/check-recovery',
  COUNTRIES = '/countries',
  CURRENCIES = '/currencies',
  POOLS = '/pools',
  SHARE_CLASSES = '/share-classes',
  IP_INFO = '/ipinfo',
  SHARE_PLANS = '/share-plans',
  EVENTS = '/events',
}
