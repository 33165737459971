import { z } from 'zod';

import { Locale, StringKey } from '../../lang';
import { getTranslation } from '../../utils/getTranslation';

export enum PasswordErrorMessage {
  UPPER_CASE_LETTER_CHECK = 'upper-case-letter-check',
  LOWER_CASE_LETTER_CHECK = 'lower-case-letter-check',
  MIN_LENGTH_CHECK = 'min-length-check',
  SPECIAL_LETTER_CHECK = 'special-letter-check',
}

export const passwordSchema = z
  .string()
  .regex(/.*[a-z].*/, PasswordErrorMessage.LOWER_CASE_LETTER_CHECK)
  .regex(/.*[A-Z].*/, PasswordErrorMessage.UPPER_CASE_LETTER_CHECK)
  .regex(/.*[!@#$%^&*].*/i, PasswordErrorMessage.SPECIAL_LETTER_CHECK)
  .min(8, PasswordErrorMessage.MIN_LENGTH_CHECK);

export const formSchema = z
  .object({
    fullName: z.string().regex(new RegExp('^[\\p{L}]+(?: [\\p{L}]+)*$', 'u'), 'Invalid full name'),
    password: passwordSchema.or(z.literal('')).default(''),
    confirmPassword: z.string().or(z.literal('')).default(''),
    language: z.nativeEnum(Locale),
  })
  .refine((data) => data?.password === data?.confirmPassword, {
    message: getTranslation(StringKey.PASSWORDS_DONT_MATCH),
    path: ['confirmPassword'],
  });

export type FormSchema = z.infer<typeof formSchema>;
