import { FC } from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';

import { NotificationHooks, useFormat, useLocale, useSearchParams } from '../../../hooks';
import {
  notificationRequestTitle,
  NotificationType,
  requestNotificationBuilder,
} from '../../../types/notification.types';
import { SharePlanType } from '../../../types/pool-plans.types';
import { StakeholderType } from '../../../types/stakeholderTypes';
import { toNumber } from '../../../utils/toNumber';
import Loader from '../../Loader';
import { RequestNotification } from '../Notification';
import { ApproveNotification } from '../Notification/ApproveNotification';

export type RequestedTabProps = {
  companyId: string;
  onExerciseRequest: (id: string, type: SharePlanType | undefined) => void;
  unreadOnly: boolean;
  isAllRead: boolean;
  setMilestoneIndex: (index: number | null) => void;
};

export const RequestedTab: FC<RequestedTabProps> = ({
  companyId,
  onExerciseRequest,
  unreadOnly,
  isAllRead,
  setMilestoneIndex,
}) => {
  const { messagesLocale } = useLocale();
  const { format } = useFormat();
  const { notifications, fetchNextPage, hasNextPage } = NotificationHooks.useNotifications({
    companyId,
    type: NotificationType.REQUEST,
    unreadOnly,
  });

  const { setValue } = useSearchParams('hurdleApproveId');
  const { markAsRead } = NotificationHooks.useMarkRead({ companyId });
  const { markAsResolved } = NotificationHooks.useMarkResolved({
    companyId,
  });

  return (
    <InfiniteScroll
      className="flex w-full flex-col gap-2"
      dataLength={notifications.length || 0}
      hasMore={hasNextPage}
      loader={<Loader />}
      next={fetchNextPage}
      scrollableTarget="scroll-notifications-target"
    >
      {notifications.map(
        ({
          createdAt,
          is_read: isRead,
          id,
          company,
          stakeholderName = '',
          adminId,
          stakeholderType = StakeholderType.OTHER_INST,
          adminName = '',
          type,
          targetBasedMilestoneRequest,
          requestId = '',
          requestSharesCount = 0,
          requestPlan = '',
          numberOfGrants = 0,
          requestTotalSharesCount = 0,
          requestNextTriggerTime = '',
          requestHurdleValue,
          requestPlanType,
          resolved,
          approveRequestId,
          milestones,
        }) =>
          targetBasedMilestoneRequest ? (
            <ApproveNotification
              createdAt={new Date(createdAt)}
              isRead={isAllRead || isRead}
              key={id}
              milestones={milestones || []}
              onMarkRead={() => markAsRead({ id, type })}
              setMilestoneIndex={setMilestoneIndex}
              targetBasedMilestoneRequest={targetBasedMilestoneRequest}
              type="Target Based Vesting Approval"
            />
          ) : (
            <RequestNotification
              createdAt={new Date(createdAt)}
              isRead={requestPlanType === SharePlanType.GROWTH_HURDLE ? true : isAllRead || isRead}
              key={id}
              message={requestNotificationBuilder({
                hurdleValue: toNumber(requestHurdleValue),
                milestoneDate: format(requestNextTriggerTime, 'dd/MM/yyyy'),
                milestoneName: '',
                planAvailableCount:
                  toNumber(requestTotalSharesCount) -
                  toNumber(numberOfGrants) +
                  toNumber(requestSharesCount),
                planGrunts: toNumber(numberOfGrants),
                planName: requestPlan,
                planType: requestPlanType || SharePlanType.BSPCE,
                requestedSharesCount: toNumber(requestSharesCount),
                adminName,
                stakeholderName,
                isAdminYou: adminId === company?.stakeholder?.id,
                stakeholderType,
              })}
              onExercise={() => {
                requestPlanType === SharePlanType.GROWTH_HURDLE
                  ? setValue(approveRequestId)
                  : onExerciseRequest(requestId, requestPlanType);
              }}
              onMarkRead={() => markAsRead({ id, type })}
              onMarkResolved={() => markAsResolved(id)}
              planType={requestPlanType || SharePlanType.BSPCE}
              resolved={resolved}
              type={
                (requestPlanType && messagesLocale[notificationRequestTitle[requestPlanType]]) || ''
              }
            />
          ),
      )}
    </InfiniteScroll>
  );
};
