import { Transition } from '@headlessui/react';
import { useQueryClient } from '@tanstack/react-query';
import React, { FC, Fragment, useCallback, useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { twMerge } from 'tailwind-merge';

import { ChevronDownIcon, CloseIcon, PlusIcon, SearchIcon } from '../../assets/icons';
import { AppFormattedMessage } from '../../components/AppFormattedMessage';
import Button from '../../components/Button';
import { EmptySearchList } from '../../components/EmptySearchList';
import { Input } from '../../components/Input';
import Loader from '../../components/Loader';
import { PaginationController } from '../../components/PaginationController';
import { Radio, RadioGroup } from '../../components/RadioGroup';
import { Select, SelectButton, SelectOption, SelectOptions } from '../../components/Select';
import { Sheet, SheetContent, SheetTrigger } from '../../components/Sheet';
import { QueryKey } from '../../constants';
import {
  AnalyticHooks,
  ShareClass,
  useDebounce,
  useEventOutsideElement,
  useModalState,
  useQueryParams,
  useScreenSize,
  useSelectedCompany,
  ValuationHooks,
} from '../../hooks';
import { StringKey } from '../../lang';
import { PossibleAction } from '../../types/google-analytics-types';
import {
  FilterVariant,
  filterVariant,
  filterVariantMap,
  ShareClassOrder,
  ShareClassSortBy,
} from '../../types/share-classes.types';
import { toNumber } from '../../utils/toNumber';
import { CurrentSharePrice } from './CurrentSharePrice';
import { AddShareClassModal, EditShareClassModal, EditShareClassStepVariant } from './Modals';
import { ShareClassItem } from './ShareClassItem';
import { ShareClassTable } from './Table';
import { AmountOfFetchedItems, itemsToFetchMap } from './types';

export type SelectedShareClass = {
  id: string;
  step: EditShareClassStepVariant;
};

const ShareClasses: FC = () => {
  const [currentPage, setPage] = useState(1);
  const [itemsToFetch, setItemsToFetch] = useState(AmountOfFetchedItems.FIVE);
  const [selectedFilter, setFilter] = useState<FilterVariant>('all');
  const [sortState, setSortState] = useState<{
    field: ShareClassSortBy | null;
    sortMode: ShareClassOrder | null;
  }>({ field: null, sortMode: null });

  const [selectedShareClass, setSelectedShareClass] = useState<SelectedShareClass>({
    id: '',
    step: 1,
  });
  const { get } = useQueryParams();

  const { selectedCompany } = useSelectedCompany();
  const companyId = selectedCompany?.id || '';

  const [search, setSearch] = useState<null | string>(null);
  const [isOpenSearch, setOpenSearch] = useState(false);

  const [isFocusedInput, setInputFocus] = useState(false);
  const inputRef = useRef<HTMLDivElement>(null);

  const { valuation } = ValuationHooks.useCurrent({ companyId });
  const currentPrice = valuation?.sharePrice || 0;

  const { isMobile } = useScreenSize();
  useEventOutsideElement(inputRef, 'click', () => !search && setOpenSearch(false));

  const queryClient = useQueryClient();
  const invalidateQuery = useCallback(() => {
    queryClient.invalidateQueries({
      queryKey: [QueryKey.GET_SHARE_CLASSES],
    });
  }, [queryClient]);

  const { shareClasses, isLoading, refetch, totalPages } = ShareClass.useShareClasses({
    companyId,
    filter: selectedFilter,
    search: search || '',
    orderBy: sortState.sortMode,
    sortBy: sortState.field,
    currentPage,
    take: toNumber(itemsToFetch),
  });

  const companyDeleteShareClassTracker = AnalyticHooks.useClick(PossibleAction.ADD_SHARE_CLASS);
  const { deleteShareClass } = ShareClass.useDelete();

  const handleSortField = useCallback(
    (newField: ShareClassSortBy) => {
      setSortState((prevState) => {
        if (prevState.field === newField) {
          const nextSortMode = !prevState.sortMode
            ? ShareClassOrder.ASC
            : prevState.sortMode === ShareClassOrder.ASC
              ? ShareClassOrder.DESC
              : null;

          return { ...prevState, sortMode: nextSortMode };
        }
        return { field: newField, sortMode: ShareClassOrder.ASC };
      });
      invalidateQuery();
    },
    [invalidateQuery],
  );

  const { debounceCallback } = useDebounce({
    callback: () => {
      invalidateQuery();
    },
  });

  const handleFilterSelect = (filter: FilterVariant) => {
    setFilter(filter);
  };
  const handleSearch = useCallback(
    (value: string) => {
      setSearch(value);
      debounceCallback(value);
    },
    [debounceCallback],
  );
  const navigate = useNavigate();
  const {
    isOpen: isOpenAddShareClassModal,
    handleOpenModal: handleOpenShareClassModal,
    handleCloseModal: handleCloseShareClassModal,
  } = useModalState({
    initialValue: !!get('openAddModal'),
    onClose: () => {
      navigate('/share-classes');
    },
  });

  const {
    isOpen: isOpenEditShareClassModal,
    handleOpenModal: handleOpenEditShareClassModal,
    handleCloseModal: handleCloseEditShareClassModal,
  } = useModalState({});

  useEffect(() => {
    if (!search && companyId) {
      refetch();
    }
  }, [refetch, search, companyId]);

  if (isLoading || !shareClasses) return <Loader />;

  return (
    <>
      <AddShareClassModal
        companyId={companyId}
        handleClose={handleCloseShareClassModal}
        invalidateQuery={invalidateQuery}
        isOpenModal={isOpenAddShareClassModal}
      />

      <EditShareClassModal
        companyId={companyId}
        handleClose={handleCloseEditShareClassModal}
        invalidateQuery={invalidateQuery}
        isOpenModal={isOpenEditShareClassModal}
        onEditClick={(data) => setSelectedShareClass(data)}
        shareClassId={selectedShareClass.id}
        step={selectedShareClass.step}
      />
      <div className="flex h-full w-full flex-col overflow-hidden rounded-md bg-gray-50 p-1 shadow-sm">
        <CurrentSharePrice
          currency={selectedCompany?.currency?.iso3}
          price={currentPrice}
          valuationEventDate={valuation?.date}
        />
        <div className="flex h-full w-full flex-col rounded-md border-[#F2F2F2] max-lg:gap-3 lg:overflow-y-auto lg:border-[1px] lg:bg-white">
          <div className="flex max-h-[68px] w-full items-center justify-between rounded-md border-[#F2F2F2] bg-white p-4 max-lg:border-[1px]">
            <RadioGroup
              className="h-fit divide-x divide-gray-200 overflow-hidden rounded border border-gray-200"
              onChange={handleFilterSelect}
              value={selectedFilter}
              wrapperClassName={'max-lg:hidden h-9'}
            >
              {filterVariantMap.map(([filterKey, filter]) => (
                <Radio
                  className="flex h-9 items-center justify-center text-nowrap px-4 text-sm font-[450] text-[#858593] data-[checked]:bg-brand-25 data-[checked]:font-[550] data-[checked]:text-[#172335]"
                  key={filterKey}
                  styleType="NONE"
                  value={filterKey}
                >
                  <span>
                    <AppFormattedMessage id={filter} />
                  </span>
                </Radio>
              ))}
            </RadioGroup>

            <Select onChange={handleFilterSelect} value={selectedFilter}>
              {({ open }) => (
                <>
                  <div className="relative rounded border-gray-100 px-[6px] py-2 lg:hidden">
                    <SelectButton className="flex justify-between border-gray-200 bg-brand-25 p-3 px-[6px]">
                      <span className="text-sm font-[550] text-[#172335]">
                        <AppFormattedMessage id={filterVariant[selectedFilter]} />
                      </span>
                      <ChevronDownIcon className="h-4 w-4 text-gray-400" />
                    </SelectButton>

                    <Transition
                      as={Fragment}
                      leave="transition ease-in duration-100"
                      leaveFrom="opacity-100"
                      leaveTo="opacity-0"
                      show={open}
                    >
                      <SelectOptions>
                        {filterVariantMap.map(([filterKey, filter]) => (
                          <SelectOption key={filterKey} value={filterKey}>
                            {({ selected }) => (
                              <span
                                className={twMerge(
                                  selected ? 'font-[450]' : 'font-normal',
                                  'block text-nowrap text-sm text-gray-700',
                                )}
                              >
                                <AppFormattedMessage id={filter} />
                              </span>
                            )}
                          </SelectOption>
                        ))}
                      </SelectOptions>
                    </Transition>
                  </div>
                </>
              )}
            </Select>

            <div className="flex items-center gap-3">
              <Sheet modal={false} open={isMobile && isOpenSearch}>
                <div className="flex items-center justify-end" ref={inputRef}>
                  <Input
                    onBlur={() => setInputFocus(false)}
                    onChange={handleSearch}
                    onFocus={() => {
                      setInputFocus(true);
                    }}
                    placeholder={search ? '' : <AppFormattedMessage id={StringKey.SEARCH} />}
                    value={search || ''}
                    wrapperClassName={twMerge(
                      'transition-all duration-500 w-[200px] overflow-hidden max-h-9',
                      isOpenSearch ? 'delay-500' : 'w-0',
                      isMobile && 'w-0',
                    )}
                  />
                  {!isMobile && isOpenSearch && typeof search === 'string' && (
                    <div
                      className="absolute mr-8 cursor-pointer p-2"
                      onClick={() => {
                        setSearch('');
                        invalidateQuery();
                      }}
                    >
                      <CloseIcon />
                    </div>
                  )}
                  <SheetTrigger
                    className={twMerge(
                      'flex size-9 items-center justify-center rounded border-[1px] border-gray-100 bg-gray-50 transition-all duration-300',
                      !isMobile && isOpenSearch
                        ? 'h-9 rounded-l-none rounded-br-none border-x-0 border-t-0 border-b-black'
                        : 'delay-1000',
                      !isMobile &&
                        isFocusedInput &&
                        'border-b-[2px] border-b-brand-700 transition-none',
                    )}
                    onClick={() => {
                      setOpenSearch((prev) => !prev);
                      setSearch(null);
                      invalidateQuery();
                    }}
                  >
                    <SearchIcon iconColor={isFocusedInput ? '#2565C8' : '#344054'} />
                  </SheetTrigger>
                </div>
                <SheetContent
                  className="w-full"
                  onClick={(e) => e.stopPropagation()}
                  onInteractOutside={() => {
                    if (!search) {
                      setOpenSearch(false);
                      setSearch(null);
                      invalidateQuery();
                    }
                  }}
                  side="TOP"
                >
                  <div className={twMerge('flex')}>
                    <Input
                      icon={search && <CloseIcon />}
                      onBlur={() => setInputFocus(false)}
                      onChange={handleSearch}
                      onFocus={(e) => {
                        setInputFocus(true);
                        e.stopPropagation();
                      }}
                      onIconClick={() => {
                        setTimeout(() => {
                          setSearch('');
                          invalidateQuery();
                        }, 0);
                      }}
                      placeholder={<AppFormattedMessage id={StringKey.SEARCH} />}
                      value={search || ''}
                      wrapperClassName={twMerge('transition-all duration-1000 w-full')}
                    />
                  </div>
                </SheetContent>
              </Sheet>
              {!selectedCompany?.isDemo && (
                <Button
                  className="flex size-9 items-center gap-1 px-4 py-[6px] text-sm font-[550] text-gray-25 max-lg:hidden lg:w-fit"
                  onClick={() => handleOpenShareClassModal()}
                >
                  <PlusIcon className="size-4" />
                  <AppFormattedMessage id={StringKey.ADD_SHARE_CLASS} />
                </Button>
              )}
            </div>
          </div>

          {isMobile ? (
            <div
              className={twMerge(
                'flex h-full min-h-[130px] w-full flex-wrap justify-center gap-3 lg:hidden',
              )}
            >
              {search && shareClasses.length === 0 && (
                <div className="flex w-full items-center justify-center">
                  <EmptySearchList className="my-20" />
                </div>
              )}
              {shareClasses.map((shareClass, i) => (
                <ShareClassItem key={i} {...shareClass} />
              ))}
            </div>
          ) : (
            <ShareClassTable
              className="max-lg:hidden"
              data={shareClasses}
              handleDelete={(shareClassId) =>
                deleteShareClass(
                  { companyId, shareClassId },
                  {
                    onSuccess: () => {
                      companyDeleteShareClassTracker();
                      toast.success('Share class successfully deleted');
                      invalidateQuery();
                      setSelectedShareClass({ id: '', step: 1 });
                    },
                  },
                )
              }
              handleEdit={(id) => {
                setSelectedShareClass({ id, step: 5 });
                handleOpenEditShareClassModal();
              }}
              isDemo={selectedCompany?.isDemo || false}
              onSortClick={handleSortField}
              searchValue={search || ''}
              sortField={sortState.field}
              sortMode={sortState.sortMode}
            />
          )}
          <PaginationController
            currentPage={currentPage}
            itemsToFetch={itemsToFetch}
            itemsToFetchMap={itemsToFetchMap}
            onClick={setPage}
            onFetchAmountChange={setItemsToFetch}
            totalPages={totalPages}
            wrapperClassName="mt-auto"
          />
        </div>
      </div>
    </>
  );
};

export default ShareClasses;
