import React, { FC, useMemo } from 'react';
import {
  Bar,
  CartesianGrid,
  ComposedChart,
  Line,
  ResponsiveContainer,
  Tooltip,
  TooltipProps,
  XAxis,
  YAxis,
} from 'recharts';
import { twMerge } from 'tailwind-merge';

import { AppFormattedMessage } from '../../../components/AppFormattedMessage';
import { SnapshotHooks, useFormat, useScreenSize } from '../../../hooks';
import { StringKey } from '../../../lang';

interface CustomBarProps {
  x: number;
  y: number;
  width: number;
  height: number;
  fill: string;
}

const CustomBar = (props: CustomBarProps) => {
  const { x, y, width, height, fill } = props;
  const radius = 4;

  const barPath = `
    M${x},${y + height}
    H${x + width}
    V${y + radius}
    Q${x + width},${y} ${x + width - radius},${y}
    H${x + radius}
    Q${x},${y} ${x},${y + radius}
    Z
  `;

  return <path d={barPath} fill={fill} />;
};

interface CustomTickProps {
  x: number;
  y: number;
  payload: { value: string };
}

const CustomXAxisTick: FC<any> = ({ x, y, payload }: CustomTickProps) => {
  const [date, name] = payload.value.split('|');
  return (
    <g transform={`translate(${x},${y})`}>
      <text
        dy={16}
        fill="#475467"
        fontSize={10}
        fontWeight={500}
        style={{ lineHeight: '16px', dominantBaseline: 'middle' }}
        textAnchor="middle"
        x={0}
        y={0}
      >
        {name}
      </text>
      <text
        dy={32}
        fill="#475467"
        fontSize={10}
        fontWeight={500}
        style={{ lineHeight: '16px', dominantBaseline: 'middle' }}
        textAnchor="middle"
        x={0}
        y={0}
      >
        {date}
      </text>
    </g>
  );
};

interface GraphData {
  date: string;
  postMoneyValuation: number;
  totalRaised: number;
  name: string;
  percentage: number;
  value: number;
  percentageChange: number;
  participation: number;
  label: string;
}

const CustomToolTip: FC<TooltipProps<number | string, keyof GraphData>> = ({ active, payload }) => {
  if (active && payload && payload.length) {
    const { date, postMoneyValuation, name, totalRaised, percentageChange, participation } =
      payload[0].payload;
    return (
      <div className="flex w-[297px] flex-col gap-2 rounded-lg bg-gray-25 p-1 pt-2 shadow-2xl">
        <div className="flex justify-between gap-1 border-b-[1px] border-gray-200 px-4 pb-2">
          <span className="text-label-sm font-semibold text-gray-700">{name}</span>
          <span className="text-label-sm font-semibold text-gray-700">{date}</span>
        </div>
        <div className="flex justify-between gap-1 border-b-[1px] border-gray-200 px-4 pb-2">
          <span className="text-label-sm font-semibold text-gray-700">
            <AppFormattedMessage id={StringKey.POST_MONEY_VALUATION} />
          </span>
          <span className="text-label-sm font-semibold text-forest-600">
            ${postMoneyValuation.toLocaleString('en-US')}
          </span>
        </div>
        <div className="flex justify-between gap-1 border-b-[1px] border-gray-200 px-4 pb-2">
          <span className="text-label-sm font-semibold text-gray-700">
            {<AppFormattedMessage id={StringKey.TOTAL_RAISED} />}
          </span>
          <span className="text-label-sm font-semibold text-gray-700">
            ${(totalRaised || 0).toLocaleString('en-US')}
          </span>
        </div>
        <div className="flex justify-between gap-1 border-b-[1px] border-gray-200 px-4 pb-2">
          <span className="text-label-sm font-semibold text-gray-700">
            <AppFormattedMessage id={StringKey.YOUR_PARTICIPATION} />
          </span>
          <span className="text-label-sm font-semibold text-gray-700">
            ${(participation || 0).toLocaleString('en-US')}
          </span>
        </div>
        <div className="flex justify-between gap-1 px-4">
          <span className="text-label-sm font-semibold text-gray-700">
            <AppFormattedMessage id={StringKey.YOUR_EQUITY_CHANGE} />
          </span>
          <span className="text-label-sm font-semibold text-gray-700">
            {percentageChange >= 0 ? `+${percentageChange}%` : `${percentageChange}%`}
          </span>
        </div>
      </div>
    );
  }
};

export type EquityGraphProps = {
  companyId: string;
  stakeholderId: string;
};

export const EquityGraph: FC<EquityGraphProps> = ({ companyId, stakeholderId }) => {
  const { format } = useFormat();
  const { width } = useScreenSize();
  const { snapshots } = SnapshotHooks.useSnapshots({ companyId });

  const sortedSnapshots = useMemo(() => {
    return snapshots.sort((a, b) => {
      const dateA = new Date(a.valuation.date);
      const dateB = new Date(b.valuation.date);
      return dateA.getTime() - dateB.getTime();
    });
  }, [snapshots]);

  const graphData: GraphData[] = [];
  let previousPercentage: number = 0;

  sortedSnapshots.forEach(({ stats, valuation, postMoneyValuation, lifetimeInvestmentEquity }) => {
    const stakeholder = stats.stakeholders.find((stakeholder) => stakeholder.id === stakeholderId);

    if (!stakeholder) {
      return;
    }

    const currentPercentage = Math.round(stakeholder.votingPercentage * 100);

    const percentageChange =
      previousPercentage === 0
        ? currentPercentage * 100
        : Math.round(((currentPercentage - previousPercentage) / previousPercentage) * 100);

    graphData.push({
      date: format(new Date(valuation.date), 'dd/MM/yyyy'),
      postMoneyValuation: postMoneyValuation,
      totalRaised: lifetimeInvestmentEquity,
      name: valuation.name,
      percentage: currentPercentage,
      value: (stakeholder.issued * valuation.sharePrice) / 1000000,
      percentageChange,
      participation: stakeholder?.invested,
      label: `${format(new Date(valuation.date), 'dd/MM/yyyy')}|${valuation.name}`,
    });

    previousPercentage = currentPercentage;
  });

  return (
    <>
      <div
        className={twMerge(
          'flex min-h-[358px] flex-grow flex-col gap-3 overflow-hidden rounded-lg bg-gray-50 p-[2px] pt-3 lg:basis-[55%]',
          width < 660 && 'hidden',
        )}
      >
        <span className="pl-4 text-[10px] font-bold uppercase leading-4 tracking-[1px] text-gray-700">
          <AppFormattedMessage id={StringKey.EQUITY_AND_CAPITAL_CHANGE} />
        </span>

        <div className="flex h-full w-full gap-3 rounded-md bg-white px-5 py-6 shadow-sm">
          <div className="flex h-full max-w-[16px] items-center justify-center">
            <span className="-rotate-90 transform whitespace-nowrap text-label-sm font-[500] text-gray-500">
              <AppFormattedMessage id={StringKey.YOUR_ESTIMATED_VALUE_MILLION} />
            </span>
          </div>
          <div className="h-full w-full">
            <ResponsiveContainer height="100%" width="100%">
              <ComposedChart
                data={graphData}
                height={230}
                margin={{
                  top: 10,
                  right: 0,
                  bottom: 20,
                  left: 0,
                }}
                width={570}
              >
                <CartesianGrid horizontal={true} stroke="#F2F4F7" vertical={false} />
                <XAxis dataKey="label" tick={<CustomXAxisTick />} tickLine={false} />
                <YAxis
                  axisLine={false}
                  orientation="left"
                  stroke="#475467"
                  tick={{ fontSize: 10, fontWeight: 500 }}
                  tickLine={false}
                  tickMargin={8}
                  yAxisId="left"
                />
                <YAxis
                  axisLine={false}
                  domain={[0, 100]}
                  orientation="right"
                  stroke="#475467"
                  tick={{ fontSize: 10, fontWeight: 500 }}
                  tickFormatter={(value) => `${value}%`}
                  tickLine={false}
                  tickMargin={8}
                  yAxisId="right"
                />
                <Bar dataKey="value" fill="#2565C8" shape={CustomBar as any} yAxisId="left" />
                <Line
                  dataKey="percentage"
                  dot={{ fill: '#DC6803', stroke: '#DC6803', r: 3 }}
                  stroke="#DC6803"
                  strokeDasharray="5 5"
                  type="linear"
                  yAxisId="right"
                />
                <Tooltip
                  content={<CustomToolTip />}
                  cursor={{ stroke: '#D0D5DD', strokeDasharray: '5 5' }}
                />
              </ComposedChart>
            </ResponsiveContainer>
          </div>
          <div className="flex h-full max-w-[16px] items-center justify-center">
            <span className="-rotate-90 transform whitespace-nowrap text-label-sm font-[500] text-gray-500">
              <AppFormattedMessage id={StringKey.YOUR_EQUITY} /> (%)
            </span>
          </div>
        </div>
      </div>
    </>
  );
};
