import React, { FC } from 'react';
import { Control } from 'react-hook-form';

import { ChevronDownIcon } from '../../../assets/icons';
import { AppFormattedMessage } from '../../../components/AppFormattedMessage';
import {
  ComboboxButton,
  ComboboxOptions,
  FormCombobox,
  FormComboboxInput,
  FormComboboxOption,
} from '../../../components/Combobox';
import { ShareClass as ShareClassHook } from '../../../hooks';
import { StringKey } from '../../../lang';
import { ShareClass } from '../../../types/share-classes.types';
import { FormSchema } from './Validation';

export type ShareClassesItemsListProps = {
  data: ShareClass[];
  control: Control<FormSchema>;
  isLoading: boolean;
};

const ShareClassesItemsList: FC<ShareClassesItemsListProps> = ({ data, control, isLoading }) => {
  if (!data || isLoading)
    return (
      <span>
        <AppFormattedMessage id={StringKey.LOADING} />
      </span>
    );
  return (
    <>
      {data.map(({ name, id }, i) => (
        <FormComboboxOption
          control={control}
          key={`${name}_${i}`}
          name="stepOne.shareClass"
          value={{ id, name }}
        >
          <span className="text-sm text-gray-700">{name}</span>
        </FormComboboxOption>
      ))}
    </>
  );
};

export type ShareClassComboboxProps = {
  control: Control<FormSchema>;
  companyId: string;
};

export const ShareClassCombobox: FC<ShareClassComboboxProps> = ({ control, companyId }) => {
  const { shareClasses, isLoading } = ShareClassHook.useShareClasses({ companyId, filter: 'all' });

  return (
    <FormCombobox className="relative" control={control} name={'stepOne.shareClass'}>
      <div className="relative">
        <FormComboboxInput
          className={'bg-gray-900'}
          control={control}
          customValue={(value) => value?.name}
          icon={<ChevronDownIcon className="mt-2" />}
          name={'stepOne.shareClass'}
          placeholder={<AppFormattedMessage id={StringKey.SHARE_CLASS} />}
          readOnly
        />
        <ComboboxButton className="absolute left-0 top-0 z-1 h-full w-full" />
      </div>
      <ComboboxOptions className="w-full">
        <ShareClassesItemsList control={control} data={shareClasses || []} isLoading={isLoading} />
      </ComboboxOptions>
    </FormCombobox>
  );
};
