import { FC, useCallback, useMemo, useState } from 'react';

import { AppFormattedMessage } from '../../../components/AppFormattedMessage';
import Button from '../../../components/Button';
import { Checkbox } from '../../../components/Checkbox';
import { Radio, RadioGroup } from '../../../components/RadioGroup';
import { Switch } from '../../../components/Switch';
import { StringKey } from '../../../lang';
import { capTableStore } from '../../../storage/capTableStore';
import {
  columnTitle,
  ColumnVariant,
  customizeSortVariantTitleMap,
  detailsColumns,
  DetailsSortVariant,
  ViewBy,
} from '../../../types/snapshot.types';
import { CustomView } from './Details';

type DetailsCustomizeViewProps = {
  onSuccess: (settings: CustomView) => void;
  onCancel: () => void;
  viewSettings: CustomView;
  selectedForTab: DetailsSortVariant;
};

export type CustomViewSettings = Record<
  DetailsSortVariant,
  {
    viewBy: { type: boolean; stakeholders: boolean };
    columns: Record<ColumnVariant, boolean>;
    setAsDefaultView: boolean;
  }
>;

const defaultShareClassColumns = detailsColumns[DetailsSortVariant.BY_SHARE_CLASS].reduce(
  (acc, curr) => ({ ...acc, [curr]: false }),
  {} as Record<ColumnVariant, boolean>,
);

const defaultStakeholderColumns = detailsColumns[DetailsSortVariant.BY_STAKEHOLDER].reduce(
  (acc, curr) => ({ ...acc, [curr]: false }),
  {} as Record<ColumnVariant, boolean>,
);

export const DetailsCustomizeView: FC<DetailsCustomizeViewProps> = ({
  onCancel,
  onSuccess,
  viewSettings,
  selectedForTab,
}) => {
  const [selectedCustomViewTab, setSelectedCustomViewTab] =
    useState<DetailsSortVariant>(selectedForTab);

  const selectedShareClassColumns = useMemo(
    () =>
      viewSettings[DetailsSortVariant.BY_SHARE_CLASS].columns.reduce(
        (acc, curr) => ({ ...acc, [curr]: true }),
        {},
      ),
    [viewSettings],
  );

  const selectedStakeholdersColumns = useMemo(
    () =>
      viewSettings[DetailsSortVariant.BY_STAKEHOLDER].columns.reduce(
        (acc, curr) => ({ ...acc, [curr]: true }),
        {},
      ),
    [viewSettings],
  );

  const [customSettings, setCustomSettings] = useState<CustomViewSettings>({
    [DetailsSortVariant.BY_SHARE_CLASS]: {
      viewBy: viewSettings[DetailsSortVariant.BY_SHARE_CLASS].viewBy,
      setAsDefaultView: !!capTableStore.get()?.[DetailsSortVariant.BY_SHARE_CLASS],
      columns: { ...defaultShareClassColumns, ...selectedShareClassColumns },
    },
    [DetailsSortVariant.BY_STAKEHOLDER]: {
      viewBy: viewSettings[DetailsSortVariant.BY_STAKEHOLDER].viewBy,
      setAsDefaultView: !!capTableStore.get()?.[DetailsSortVariant.BY_STAKEHOLDER],
      columns: { ...defaultStakeholderColumns, ...selectedStakeholdersColumns },
    },
  });

  const customSettingSelectedTab = customSettings[selectedCustomViewTab];

  const handleChangeViewBy = (field: ViewBy, state: boolean) =>
    setCustomSettings((prev) => {
      const newState = {
        ...prev,
        [selectedCustomViewTab]: {
          ...prev[selectedCustomViewTab],
          viewBy: { ...prev[selectedCustomViewTab].viewBy, [field]: state },
        },
      };

      if (prev[selectedCustomViewTab].setAsDefaultView)
        saveCurrentCustomizationToLocalStorage(newState);

      return newState;
    });

  const handleChangeColumnView = (field: ColumnVariant, state: boolean) =>
    setCustomSettings((prev) => {
      const newState = {
        ...prev,
        [selectedCustomViewTab]: {
          ...prev[selectedCustomViewTab],
          columns: { ...prev[selectedCustomViewTab].columns, [field]: state },
        },
      };

      if (prev[selectedCustomViewTab].setAsDefaultView)
        saveCurrentCustomizationToLocalStorage(newState);

      return newState;
    });

  const saveCurrentCustomizationToLocalStorage = useCallback(
    (customSettings: CustomViewSettings) =>
      capTableStore.set({
        [selectedCustomViewTab]: {
          viewBy: customSettings[selectedCustomViewTab].viewBy,
          columns: Object.entries(customSettings[selectedCustomViewTab].columns)
            .filter(([_, state]) => state)
            .map(([field]) => field),
        },
      }),
    [selectedCustomViewTab],
  );

  const handleChangeAsDefaultView = useCallback(
    (state: boolean) => {
      setCustomSettings((prev) => ({
        ...prev,
        [selectedCustomViewTab]: { ...prev[selectedCustomViewTab], setAsDefaultView: state },
      }));

      state
        ? saveCurrentCustomizationToLocalStorage(customSettings)
        : capTableStore.set({ [selectedCustomViewTab]: null });
    },
    [customSettings, saveCurrentCustomizationToLocalStorage, selectedCustomViewTab],
  );

  const handleSuccess = useCallback(() => {
    onSuccess({
      [DetailsSortVariant.BY_SHARE_CLASS]: {
        viewBy: customSettings[DetailsSortVariant.BY_SHARE_CLASS].viewBy,
        columns: Object.entries(customSettings[DetailsSortVariant.BY_SHARE_CLASS].columns)
          .filter(([_, state]) => state)
          .map(([field]) => field),
      },
      [DetailsSortVariant.BY_STAKEHOLDER]: {
        viewBy: customSettings[DetailsSortVariant.BY_STAKEHOLDER].viewBy,
        columns: Object.entries(customSettings[DetailsSortVariant.BY_STAKEHOLDER].columns)
          .filter(([_, state]) => state)
          .map(([field]) => field),
      },
    });
  }, [customSettings, onSuccess]);

  const isAtLeastSelectedOneViewBy =
    customSettingSelectedTab.viewBy.stakeholders || customSettingSelectedTab.viewBy.type;

  return (
    <div className="flex w-[530px] flex-col gap-4 rounded bg-white p-6">
      <span className="border-b border-b-gray-100 pb-4 text-xl font-semibold text-gray-700">
        <AppFormattedMessage id={StringKey.CUSTOMIZE_VIEW} />
      </span>
      <div className="flex flex-col gap-2 border-b border-b-gray-100 pb-4">
        <span className="text-xs font-[450] text-gray-500">
          <AppFormattedMessage id={StringKey.BY} />
        </span>

        <div className="w-fit rounded-3xl border border-gray-200 p-1">
          <RadioGroup
            onChange={setSelectedCustomViewTab}
            value={selectedCustomViewTab}
            wrapperClassName="w-full grow flex-1"
          >
            {customizeSortVariantTitleMap.map(([graphKey, graphTitle]) => (
              <Radio
                className="h-8 !w-fit rounded-[32px] border-transparent bg-transparent !px-3 !py-2 text-xs font-[450] text-gray-700 data-[checked]:font-[550] data-[checked]:text-brand-25"
                key={graphKey}
                value={graphKey}
              >
                <span className="text-nowrap">
                  <AppFormattedMessage id={graphTitle} />
                </span>
              </Radio>
            ))}
          </RadioGroup>
        </div>
      </div>

      {selectedCustomViewTab === DetailsSortVariant.BY_STAKEHOLDER && (
        <div className="flex flex-col gap-4 border-b border-b-gray-100 pb-4">
          <span className="text-xs font-[450] text-gray-500">
            <AppFormattedMessage id={StringKey.VIEW_BY} />
          </span>

          <div className="mt-2 flex gap-2">
            <Switch
              checked={customSettingSelectedTab.viewBy.type}
              key={`${selectedCustomViewTab}view-by-__type_switch`}
              onChange={(state) => handleChangeViewBy(ViewBy.TYPE, state)}
            />
            <span className="text-xs font-[450] text-gray-700">
              <AppFormattedMessage id={StringKey.TYPE} />
            </span>
          </div>
          <div className="flex gap-2">
            <Switch
              checked={customSettingSelectedTab.viewBy.stakeholders}
              key={`${selectedCustomViewTab}view-by-stakeholders_switch`}
              onChange={(state) => handleChangeViewBy(ViewBy.STAKEHOLDERS, state)}
            />
            <span className="text-xs font-[450] text-gray-700">
              <AppFormattedMessage id={StringKey.STAKEHOLDERS} />
            </span>
          </div>

          {!isAtLeastSelectedOneViewBy && (
            <span className="text-xs font-[450] text-fireside-600">
              <AppFormattedMessage id={StringKey.SELECT_AT_LEAST_ONE_VIEW_BY} />
            </span>
          )}
        </div>
      )}

      <div className="flex flex-col gap-4 border-b border-b-gray-100 pb-4">
        <span className="text-xs font-[450] text-gray-500">
          <AppFormattedMessage id={StringKey.COLUMNS} />
        </span>

        <div className="grid grid-flow-row grid-cols-2 gap-4">
          {detailsColumns[selectedCustomViewTab].map(
            (columnKey) =>
              columnTitle[columnKey] && (
                <div className="mt-2 flex gap-2" key={`${columnKey}_${selectedCustomViewTab}`}>
                  <Switch
                    checked={customSettingSelectedTab.columns[columnKey]}
                    onChange={(state) => handleChangeColumnView(columnKey, state)}
                  />
                  <span className="text-xs font-[450] text-gray-700">
                    <AppFormattedMessage id={columnTitle[columnKey]} />
                  </span>
                </div>
              ),
          )}
        </div>
      </div>
      <div className="flex items-center justify-between">
        <div className="flex gap-2">
          <Checkbox
            checked={customSettingSelectedTab.setAsDefaultView}
            onChange={handleChangeAsDefaultView}
          />
          <span className="text-xs font-[450] text-gray-700">
            <AppFormattedMessage id={StringKey.SET_AS_DEFAULT_VIEW} />
          </span>
        </div>
        <div className="flex h-11 gap-4">
          <Button
            className="h-full w-fit rounded border border-gray-300 px-6 text-sm font-[450] text-gray-700"
            onClick={onCancel}
            styleType="NONE"
          >
            <AppFormattedMessage id={StringKey.CANCEL} />
          </Button>
          <Button
            className="h-full w-fit px-6 text-sm font-[550] text-white"
            disabled={!isAtLeastSelectedOneViewBy}
            onClick={handleSuccess}
          >
            <AppFormattedMessage id={StringKey.SAVE} />
          </Button>
        </div>
      </div>
    </div>
  );
};
