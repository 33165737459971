import React, { FC } from 'react';
import { Control, UseFormSetValue, useWatch } from 'react-hook-form';

import { AppFormattedMessage } from '../../../../components/AppFormattedMessage';
import { useFormat } from '../../../../hooks';
import { StringKey } from '../../../../lang';
import { FormSchema } from '../Validation';
import DayCombobox from './DayCombobox';
import MonthCombobox from './MonthCombobox';
import YearCombobox from './YearCombobox';

export type IncorporationCompanyDateSelectProps = {
  setValue: UseFormSetValue<FormSchema>;
  control: Control<FormSchema>;
  isNeedDefaultValue?: boolean;
};

const IncorporationCompanyDateSelect: FC<IncorporationCompanyDateSelectProps> = ({
  setValue,
  control,
  isNeedDefaultValue = false,
}) => {
  const { format } = useFormat();
  const { stepOne } = useWatch<FormSchema>({
    control,
  });
  const incorporationDate = stepOne?.company?.incorporationDate;

  const selectedDay = incorporationDate?.day;
  const selectedMonth = incorporationDate?.month;
  const selectedYear = incorporationDate?.year;

  return (
    <div className="flex w-full flex-col gap-3">
      <span className="text-xs font-[450] text-gray-500">
        <AppFormattedMessage id={StringKey.DATE_OF_INCORPORATION_OPTIONAL} />
      </span>
      <div className="flex w-full gap-4">
        <DayCombobox
          defaultValue={isNeedDefaultValue && selectedDay ? selectedDay : undefined}
          onSelect={(day) =>
            setValue('stepOne.company.incorporationDate.day', day, {
              shouldValidate: true,
            })
          }
          selectedMonth={selectedMonth}
          selectedYear={selectedYear}
        />
        <MonthCombobox
          defaultValue={
            isNeedDefaultValue && typeof selectedMonth === 'number'
              ? {
                  index: selectedMonth,
                  month: format(new Date(2024, selectedMonth, 1), 'MMMM'),
                }
              : undefined
          }
          onSelect={(month) =>
            setValue('stepOne.company.incorporationDate.month', month.index, {
              shouldValidate: true,
            })
          }
        />
        <YearCombobox
          defaultValue={isNeedDefaultValue && selectedYear ? selectedYear : undefined}
          onSelect={(year) =>
            setValue('stepOne.company.incorporationDate.year', year, {
              shouldValidate: true,
            })
          }
        />
      </div>
    </div>
  );
};

IncorporationCompanyDateSelect.displayName = 'IncorporationCompanySelect';

export default IncorporationCompanyDateSelect;
