import React, { FC, useCallback, useState } from 'react';
import { twMerge } from 'tailwind-merge';

import { AppFormattedMessage } from '../../../../components/AppFormattedMessage';
import Button from '../../../../components/Button';
import { SummaryItem } from '../../../../components/SummaryItem';
import { useFormat } from '../../../../hooks';
import { StringKey } from '../../../../lang';
import { EventFormType, EventTypeButtonTitle } from '../../../../types/events.types';
import { ChildrenFormProps } from '../type';

export type FinalStepProps = ChildrenFormProps;

export const FinalStep: FC<FinalStepProps> = ({
  handleCloseModal,
  nextFormStep,
  formData,
  prevFormStep,
  issuedShares,
}) => {
  const { initialStep, stepOne, optionalStep, stepTwo } = formData();
  const { format } = useFormat();

  const [clicked, setClicked] = useState<boolean>(false);

  const handleClick = () => {
    if (!clicked) {
      setClicked(true);
      nextFormStep();
    }
  };

  const calculateShares = useCallback(
    (index: number) => {
      const investment = optionalStep?.['fundraising-round']?.investmentItems?.[index]?.investment;
      const sharePrice = stepOne?.eventDetails?.['fundraising-round']?.event.sharePrice;

      if (investment && sharePrice) {
        return Number(investment / sharePrice).toLocaleString('en-US');
      }

      return 0;
    },
    [optionalStep, stepOne?.eventDetails],
  );

  const isRenderGrant =
    initialStep?.type === EventFormType.GRANT &&
    stepOne.eventDetails?.grant?.enabled &&
    stepOne.eventDetails?.grant?.grantItems;

  const isRenderFundraising =
    initialStep?.type === EventFormType.FUNDRAISING_ROUND &&
    stepOne.eventDetails?.['fundraising-round']?.enabled &&
    optionalStep?.['fundraising-round']?.investmentItems;

  const isRenderShareIssuance =
    initialStep?.type === EventFormType.SHARE_ISSUANCE &&
    stepOne.eventDetails?.['share-issuance']?.enabled &&
    optionalStep?.['share-issuance']?.issuanceItems;

  const isRenderBuyBack =
    initialStep?.type === EventFormType.BUYBACK &&
    stepOne.eventDetails?.buyback?.enabled &&
    stepOne.eventDetails?.buyback?.buyBackItems;

  const isRenderSecondaries =
    initialStep?.type === EventFormType.SECONDARIES &&
    stepOne.eventDetails?.secondaries?.enabled &&
    stepOne.eventDetails?.secondaries?.secondariesItems;

  const isRenderClassConversion =
    initialStep?.type === EventFormType.CONVERSION &&
    stepOne.eventDetails?.['conversion']?.enabled &&
    stepOne.eventDetails?.['conversion']?.classConversionItems;

  const isRenderValuation =
    initialStep?.type === EventFormType.VALUATION && stepOne.eventDetails?.valuation?.enabled;

  return (
    <>
      <div className="flex h-full flex-col gap-4 overflow-y-auto rounded-b-xl border-b border-gray-300 bg-white p-6 pt-0">
        {isRenderGrant && (
          <div className="flex h-fit flex-col gap-4">
            {stepOne?.eventDetails?.grant?.grantItems.map((item, index) => (
              <div className="flex h-fit flex-col gap-2 rounded-lg bg-gray-50 pt-2" key={index}>
                <span className="px-4 text-sm font-[550] text-gray-700">
                  {<AppFormattedMessage id={StringKey.GRANT} />} - {item.stakeholder.fullName}
                </span>
                <div className="rounded-b-lg border-[1px] border-gray-100 bg-white px-4 pb-3 pt-4">
                  <SummaryItem
                    className="border-b-[1px] border-gray-100 pt-0"
                    title={<AppFormattedMessage id={StringKey.DATE} />}
                    value={item.grantDate ? format(item.grantDate, 'dd/MM/yyyy') : '-'}
                  />
                  <SummaryItem
                    className="border-b-[1px] border-gray-100"
                    title={<AppFormattedMessage id={StringKey.STAKEHOLDER} />}
                    value={item.stakeholder.fullName || ''}
                  />
                  <SummaryItem
                    className="border-b-[1px] border-gray-100"
                    title={<AppFormattedMessage id={StringKey.EQUITY_PLAN} />}
                    value={item.sharePlan.name || ''}
                  />
                  <SummaryItem
                    className="border-b-[1px] border-gray-100"
                    title={<AppFormattedMessage id={StringKey.NO_OF_SHARES} />}
                    value={Number(item.shares).toLocaleString('en-US') || ''}
                  />
                  <SummaryItem
                    className="pb-0"
                    title="Attached Files"
                    value={
                      <div className="flex max-w-[220px] flex-col gap-2">
                        {item?.files?.some(({ docLink }) => docLink) ? (
                          item.files.map(
                            ({ doc, docLink }) =>
                              docLink && (
                                <a
                                  className="truncate text-xs font-[450] text-brand-700"
                                  href={docLink}
                                  key={docLink + doc.name}
                                  rel="noopener noreferrer nofollow"
                                  target="_blank"
                                >
                                  {doc.name}
                                </a>
                              ),
                          )
                        ) : (
                          <span className="truncate text-xs font-[450] text-gray-700">-</span>
                        )}
                      </div>
                    }
                  />
                </div>
              </div>
            ))}
          </div>
        )}

        {isRenderFundraising && (
          <div className="flex h-fit flex-col gap-4">
            <div className="flex h-fit flex-col gap-2 rounded-lg bg-gray-50 pt-2">
              <span className="px-4 text-sm font-[550] text-gray-700">
                <AppFormattedMessage id={StringKey.GENERAL} />
              </span>
              <div className="rounded-b-lg border-[1px] border-gray-100 bg-white px-4 pb-3 pt-4">
                <div className="flex w-full gap-3 border-b-[1px] border-gray-100 py-3">
                  <span className="w-[120px] flex-shrink-0 text-xs font-[450] text-gray-500">
                    <AppFormattedMessage id={StringKey.ROUND_STATUS} />:
                  </span>
                  <span
                    className={twMerge(
                      'rounded-2xl px-2 py-[2px] text-xs font-medium',
                      stepOne.eventDetails['fundraising-round']?.isOpen
                        ? 'bg-forest-50 text-forest-600'
                        : 'bg-fireside-50 text-fireside-600',
                    )}
                  >
                    {stepOne.eventDetails['fundraising-round']?.isOpen ? 'Open' : 'Closed'}
                  </span>
                </div>
                <SummaryItem
                  className="border-b-[1px] border-gray-100"
                  title={<AppFormattedMessage id={StringKey.ROUND_NAME} />}
                  value={stepOne.eventDetails['fundraising-round']?.event.name || ''}
                />
                <SummaryItem
                  className="border-b-[1px] border-gray-100"
                  title={
                    stepOne.eventDetails['fundraising-round']?.isOpen ? (
                      <AppFormattedMessage id={StringKey.OPEN_DATE} />
                    ) : (
                      <AppFormattedMessage id={StringKey.CLOSING_DATE} />
                    )
                  }
                  value={
                    stepOne.eventDetails['fundraising-round']?.date
                      ? format(stepOne.eventDetails['fundraising-round']?.date, 'dd/MM/yyyy')
                      : '-'
                  }
                />
                <SummaryItem
                  className="border-b-[1px] border-gray-100"
                  title={<AppFormattedMessage id={StringKey.SHARES_PRICE} />}
                  value={
                    Number(
                      stepOne.eventDetails['fundraising-round']?.event.sharePrice,
                    ).toLocaleString('en-US') || ''
                  }
                />
                <SummaryItem
                  className="pb-0"
                  title={<AppFormattedMessage id={StringKey.PRE_MONEY_VALUATION} />}
                  value={
                    (stepOne?.eventDetails?.['fundraising-round']?.event?.sharePrice
                      ? stepOne?.eventDetails?.['fundraising-round']?.event?.sharePrice *
                        issuedShares
                      : 0
                    ).toLocaleString('en-US') || ''
                  }
                />
              </div>
            </div>
            {optionalStep?.['fundraising-round']?.investmentItems.map((item, index) => (
              <div className="flex h-fit flex-col gap-2 rounded-lg bg-gray-50 pt-2" key={index}>
                <span className="px-4 text-sm font-[550] text-gray-700">
                  <AppFormattedMessage id={StringKey.INVESTMENT} /> - {item.stakeholder.fullName}
                </span>
                <div className="rounded-b-lg border-[1px] border-gray-100 bg-white px-4 pb-3 pt-4">
                  <SummaryItem
                    className="border-b-[1px] border-gray-100 pt-0"
                    title={<AppFormattedMessage id={StringKey.STAKEHOLDER} />}
                    value={item.stakeholder.fullName || ''}
                  />
                  <SummaryItem
                    className="border-b-[1px] border-gray-100"
                    title={<AppFormattedMessage id={StringKey.INVESTMENT} />}
                    value={Number(item.investment).toLocaleString('en-US') || ''}
                  />
                  <SummaryItem
                    className="border-b-[1px] border-gray-100"
                    title={<AppFormattedMessage id={StringKey.SHARE_CLASS} />}
                    value={item.shareClass.name || ''}
                  />
                  <SummaryItem
                    className="border-b-[1px] border-gray-100"
                    title={<AppFormattedMessage id={StringKey.NO_OF_SHARES} />}
                    value={calculateShares(index) || ''}
                  />
                  <SummaryItem
                    className="pb-0"
                    title={<AppFormattedMessage id={StringKey.INVEST_DATE} />}
                    value={item.investmentDate ? format(item.investmentDate, 'dd/MM/yyyy') : '-'}
                  />
                  <SummaryItem
                    className="pb-0"
                    title="Attached Files"
                    value={
                      <div className="flex max-w-[220px] flex-col gap-2">
                        {item?.files?.some(({ docLink }) => docLink) ? (
                          item.files.map(
                            ({ doc, docLink }) =>
                              docLink && (
                                <a
                                  className="truncate text-xs font-[450] text-brand-700"
                                  href={docLink}
                                  key={docLink + doc.name}
                                  rel="noopener noreferrer nofollow"
                                  target="_blank"
                                >
                                  {doc.name}
                                </a>
                              ),
                          )
                        ) : (
                          <span className="truncate text-xs font-[450] text-gray-700">-</span>
                        )}
                      </div>
                    }
                  />
                </div>
              </div>
            ))}
          </div>
        )}

        {isRenderShareIssuance && (
          <div className="flex h-fit flex-col gap-4">
            <div className="flex h-fit flex-col gap-2 rounded-lg bg-gray-50 pt-2">
              <span className="px-4 text-sm font-[550] text-gray-700">
                <AppFormattedMessage id={StringKey.GENERAL} />
              </span>
              <div className="rounded-b-lg border-[1px] border-gray-100 bg-white px-4 pb-3 pt-4">
                <SummaryItem
                  className="border-b-[1px] border-gray-100 pt-0"
                  title={<AppFormattedMessage id={StringKey.EVENT_NAME} />}
                  value={stepOne.eventDetails['share-issuance']?.event.name || ''}
                />
                <SummaryItem
                  className="border-b-[1px] border-gray-100"
                  title={<AppFormattedMessage id={StringKey.DATE} />}
                  value={
                    stepOne.eventDetails['share-issuance']?.date
                      ? format(stepOne.eventDetails['share-issuance']?.date, 'dd/MM/yyyy')
                      : '-'
                  }
                />
                <SummaryItem
                  className="pb-0"
                  title={<AppFormattedMessage id={StringKey.SHARES_PRICE} />}
                  value={
                    Number(
                      stepOne.eventDetails['share-issuance']?.event?.sharePrice,
                    ).toLocaleString('en-US') || ''
                  }
                />
              </div>
            </div>
            {optionalStep?.['share-issuance']?.issuanceItems.map((item, index) => (
              <div className="flex h-fit flex-col gap-2 rounded-lg bg-gray-50 pt-2" key={index}>
                <span className="px-4 text-sm font-[550] text-gray-700">
                  <AppFormattedMessage id={StringKey.ISSUANCE} /> - {item.stakeholder.fullName}
                </span>
                <div className="rounded-b-lg border-[1px] border-gray-100 bg-white px-4 pb-3 pt-4">
                  <SummaryItem
                    className="border-b-[1px] border-gray-100 pt-0"
                    title={<AppFormattedMessage id={StringKey.STAKEHOLDER} />}
                    value={item.stakeholder.fullName || ''}
                  />
                  <SummaryItem
                    className="border-b-[1px] border-gray-100"
                    title={<AppFormattedMessage id={StringKey.SHARE_CLASS} />}
                    value={item.shareClass.name || ''}
                  />
                  <SummaryItem
                    className="pb-0"
                    title={<AppFormattedMessage id={StringKey.NO_OF_SHARES} />}
                    value={item.shares || ''}
                  />
                </div>
              </div>
            ))}
          </div>
        )}

        {isRenderBuyBack && (
          <div className="flex h-fit flex-col gap-4">
            {stepOne?.eventDetails?.buyback?.buyBackItems.map((item, index) => (
              <div className="flex h-fit flex-col gap-2 rounded-lg bg-gray-50 pt-2" key={index}>
                <span className="px-4 text-sm font-[550] text-gray-700">
                  <AppFormattedMessage id={StringKey.STAKEHOLDER} /> - {item.stakeholder.fullName}
                </span>
                <div className="rounded-b-lg border-[1px] border-gray-100 bg-white px-4 pb-3 pt-4">
                  <SummaryItem
                    className="border-b-[1px] border-gray-100 pt-0"
                    title="Event Date"
                    value={item ? format(item.date, 'dd/MM/yyyy') : '-'}
                  />
                  <SummaryItem
                    className="border-b-[1px] border-gray-100"
                    title={<AppFormattedMessage id={StringKey.STAKEHOLDER} />}
                    value={item.stakeholder.fullName || ''}
                  />
                  <SummaryItem
                    className="border-b-[1px] border-gray-100"
                    title={<AppFormattedMessage id={StringKey.SHARE_CLASS} />}
                    value={item.shareClass.name || ''}
                  />
                  <SummaryItem
                    className="border-b-[1px] border-gray-100"
                    title={<AppFormattedMessage id={StringKey.NO_OF_SHARES} />}
                    value={Number(item.shares).toLocaleString('en-US') || ''}
                  />
                  <SummaryItem
                    className="pb-0"
                    title="Attached Files"
                    value={
                      <div className="flex max-w-[220px] flex-col gap-2">
                        {item?.files?.some(({ docLink }) => docLink) ? (
                          item.files.map(
                            ({ doc, docLink }) =>
                              docLink && (
                                <a
                                  className="truncate text-xs font-[450] text-brand-700"
                                  href={docLink}
                                  key={docLink + doc.name}
                                  rel="noopener noreferrer nofollow"
                                  target="_blank"
                                >
                                  {doc.name}
                                </a>
                              ),
                          )
                        ) : (
                          <span className="truncate text-xs font-[450] text-gray-700">-</span>
                        )}
                      </div>
                    }
                  />
                </div>
              </div>
            ))}
          </div>
        )}

        {isRenderSecondaries && (
          <div className="flex h-fit flex-col gap-4">
            {stepOne?.eventDetails?.secondaries?.secondariesItems.map((item, index) => (
              <div className="flex h-fit flex-col gap-2 rounded-lg bg-gray-50 pt-2" key={index}>
                <span className="px-4 text-sm font-[550] text-gray-700">
                  <AppFormattedMessage id={StringKey.STAKEHOLDER} /> -{' '}
                  {item.stakeholderFrom.fullName} to {item.stakeholderTo.fullName}
                </span>
                <div className="rounded-b-lg border-[1px] border-gray-100 bg-white px-4 pb-3 pt-4">
                  <SummaryItem
                    className="border-b-[1px] border-gray-100 pt-0"
                    title={<AppFormattedMessage id={StringKey.EVENT_DATE} />}
                    value={item.date ? format(item.date, 'dd/MM/yyyy') : '-'}
                  />
                  <SummaryItem
                    className="border-b-[1px] border-gray-100"
                    title={<AppFormattedMessage id={StringKey.FROM_STAKEHOLDER} />}
                    value={item.stakeholderFrom.fullName || ''}
                  />
                  <SummaryItem
                    className="border-b-[1px] border-gray-100"
                    title={<AppFormattedMessage id={StringKey.TO_STAKEHOLDER} />}
                    value={item.stakeholderTo.fullName || ''}
                  />
                  <SummaryItem
                    className="border-b-[1px] border-gray-100"
                    title={<AppFormattedMessage id={StringKey.SHARE_CLASS} />}
                    value={item.shareClass.name || ''}
                  />
                  <SummaryItem
                    className="border-b-[1px] border-gray-100"
                    title={<AppFormattedMessage id={StringKey.NO_OF_SHARES} />}
                    value={Number(item.shares).toLocaleString('en-US') || ''}
                  />
                  <SummaryItem
                    className="border-b-[1px] border-gray-100"
                    title={<AppFormattedMessage id={StringKey.SHARE_VALUE} />}
                    value={Number(item.shareValue).toLocaleString('en-US') || ''}
                  />
                  <SummaryItem
                    className="border-b-[1px] border-gray-100"
                    title={<AppFormattedMessage id={StringKey.TRANSFER_AMOUNT} />}
                    value={Number(item.shareValue * item.shares).toLocaleString('en-US') || ''}
                  />
                  <SummaryItem
                    className="pb-0"
                    title="Attached Files"
                    value={
                      <div className="flex max-w-[220px] flex-col gap-2">
                        {item?.files?.some(({ docLink }) => docLink) ? (
                          item.files.map(
                            ({ doc, docLink }) =>
                              docLink && (
                                <a
                                  className="truncate text-xs font-[450] text-brand-700"
                                  href={docLink}
                                  key={docLink + doc.name}
                                  rel="noopener noreferrer nofollow"
                                  target="_blank"
                                >
                                  {doc.name}
                                </a>
                              ),
                          )
                        ) : (
                          <span className="truncate text-xs font-[450] text-gray-700">-</span>
                        )}
                      </div>
                    }
                  />
                </div>
              </div>
            ))}
          </div>
        )}

        {isRenderClassConversion && (
          <div className="flex h-fit flex-col gap-4">
            {stepOne?.eventDetails?.['conversion']?.classConversionItems.map((item, index) => (
              <div className="flex h-fit flex-col gap-2 rounded-lg bg-gray-50 pt-2" key={index}>
                <span className="px-4 text-sm font-[550] text-gray-700">
                  Conversion - {item.stakeholder.fullName}
                </span>
                <div className="rounded-b-lg border-[1px] border-gray-100 bg-white px-4 pb-3 pt-4">
                  <SummaryItem
                    className="border-b-[1px] border-gray-100 pt-0"
                    title={<AppFormattedMessage id={StringKey.STAKEHOLDER} />}
                    value={item.stakeholder.fullName || ''}
                  />
                  <SummaryItem
                    className="border-b-[1px] border-gray-100"
                    title={<AppFormattedMessage id={StringKey.FROM_SHARE_CLASS} />}
                    value={item.shareClassFrom.name || ''}
                  />
                  <SummaryItem
                    className="border-b-[1px] border-gray-100"
                    title={<AppFormattedMessage id={StringKey.TO_SHARE_CLASS} />}
                    value={item.shareClassTo.name || ''}
                  />
                  <SummaryItem
                    className="border-b-[1px] border-gray-100"
                    title={<AppFormattedMessage id={StringKey.NO_OF_SHARES} />}
                    value={Number(item.shares).toLocaleString('en-US') || ''}
                  />
                  <SummaryItem
                    className="border-b-[1px] border-gray-100"
                    title={<AppFormattedMessage id={StringKey.SHARE_VALUE} />}
                    value={
                      Number(item.shares * item.shareClassFrom.conversionRatio || 1).toLocaleString(
                        'en-US',
                      ) || ''
                    }
                  />
                  <SummaryItem
                    className="pb-0"
                    title={<AppFormattedMessage id={StringKey.CONVERSION_RATIO} />}
                    value={`${item.shareClassFrom.conversionRatio}x`}
                  />
                </div>
              </div>
            ))}
          </div>
        )}

        {isRenderValuation && (
          <div className="flex h-fit flex-col gap-2 rounded-lg bg-gray-50 pt-2">
            <span className="px-4 text-sm font-[550] text-gray-700">Valuation</span>
            <div className="rounded-b-lg border-[1px] border-gray-100 bg-white px-4 pb-3 pt-4">
              <SummaryItem
                className="border-b-[1px] border-gray-100 pt-0"
                title={<AppFormattedMessage id={StringKey.EVENT_DATE} />}
                value={
                  stepOne.eventDetails?.valuation?.date
                    ? format(stepOne.eventDetails?.valuation?.date, 'dd/MM/yyyy')
                    : '-'
                }
              />
              <SummaryItem
                className="border-b-[1px] border-gray-100"
                title={<AppFormattedMessage id={StringKey.EVENT_NAME} />}
                value={stepOne.eventDetails?.valuation?.name}
              />
              <SummaryItem
                className="border-b-[1px] border-gray-100"
                title={<AppFormattedMessage id={StringKey.SHARES_PRICE} />}
                value={
                  Number(stepOne.eventDetails?.valuation?.sharePrice).toLocaleString('en-US') || ''
                }
              />
              <SummaryItem
                className="pb-0"
                title={<AppFormattedMessage id={StringKey.PRE_MONEY_VALUATION} />}
                value={
                  (stepOne?.eventDetails?.valuation?.sharePrice
                    ? stepOne.eventDetails.valuation.sharePrice * issuedShares
                    : 0
                  ).toLocaleString('en-US') || ''
                }
              />
            </div>
          </div>
        )}

        <div className="flex w-full flex-col rounded-lg border border-gray-100 bg-white">
          <div className={'flex gap-3 text-nowrap px-4 py-2'}>
            <span className="w-[120px] flex-shrink-0 text-xs font-[450] text-gray-500">
              <AppFormattedMessage id={StringKey.DOCUMENTS} />:
            </span>
            <div className="flex max-w-[220px] flex-col gap-2">
              {stepTwo?.files?.some(({ docLink }) => docLink) ? (
                stepTwo.files.map(
                  ({ doc, docLink }) =>
                    docLink && (
                      <a
                        className="truncate text-xs font-[450] text-brand-700"
                        href={docLink}
                        key={docLink + doc.name}
                        rel="noopener noreferrer nofollow"
                        target="_blank"
                      >
                        {doc.name}
                      </a>
                    ),
                )
              ) : (
                <span className="truncate text-xs font-[450] text-gray-700">-</span>
              )}
            </div>
          </div>
        </div>
        <div className="flex w-full flex-col rounded-lg border border-gray-100 bg-white">
          <div className="flex w-full gap-3 px-4 py-2">
            <span className="w-[120px] flex-shrink-0 text-xs font-[450] text-gray-500">
              {<AppFormattedMessage id={StringKey.ADDITIONAL_NOTES} />}:
            </span>
            <div className="max-w-[250px] break-words text-xs font-[450] text-gray-700">
              {stepTwo?.additionalNotes || '-'}
            </div>
          </div>
        </div>
      </div>

      <div className="flex h-9 w-full justify-between gap-3 px-6">
        <Button
          className="w-fit bg-transparent px-4 py-[6px] text-sm font-[450] text-gray-700"
          onClick={handleCloseModal}
          styleType="NONE"
          type="button"
        >
          <AppFormattedMessage id={StringKey.CANCEL} />
        </Button>
        <div className="flex gap-3">
          <Button
            className="h-full w-fit rounded border border-gray-100 bg-white px-3 py-[6px] text-sm font-[450] text-gray-700 shadow-xs"
            onClick={prevFormStep}
            styleType="NONE"
            type="button"
          >
            <AppFormattedMessage id={StringKey.BACK} />
          </Button>

          <Button
            className="h-full w-fit px-4 py-[6px] text-sm font-[550] text-white"
            disabled={clicked}
            onClick={handleClick}
            type="button"
          >
            <AppFormattedMessage id={StringKey.ADD} /> {EventTypeButtonTitle[initialStep?.type]}
          </Button>
        </div>
      </div>
    </>
  );
};
