import React, { FC } from 'react';
import { Control, UseFormSetValue, useWatch } from 'react-hook-form';

import { AppFormattedMessage } from '../../../../components/AppFormattedMessage';
import { useFormat } from '../../../../hooks';
import { StringKey } from '../../../../lang';
import { FormSchema } from '../Validation';
import DayCombobox from './DayCombobox';
import MonthCombobox from './MonthCombobox';
import YearCombobox from './YearCombobox';

export type PassportExpirySelectProps = {
  setValue: UseFormSetValue<FormSchema>;
  control: Control<FormSchema>;
  isNeedDefaultValue?: boolean;
};

const PassportExpiryDateSelect: FC<PassportExpirySelectProps> = ({
  setValue,
  control,
  isNeedDefaultValue = false,
}) => {
  const { format } = useFormat();
  const { stepOne } = useWatch<FormSchema>({
    control,
  });
  const passportExpiryDate = stepOne?.human?.passportExpiryDate;

  const selectedDay = passportExpiryDate?.day;
  const selectedMonth = passportExpiryDate?.month;
  const selectedYear = passportExpiryDate?.year;
  return (
    <div className="flex w-full flex-col gap-3">
      <span className="text-xs font-[450] text-gray-500">
        <AppFormattedMessage id={StringKey.PASSPORT_EXPIRY_DATE_OPTIONAL} />
      </span>
      <div className="flex w-full gap-4">
        <DayCombobox
          defaultValue={isNeedDefaultValue && selectedDay ? selectedDay : undefined}
          onSelect={(day) =>
            setValue('stepOne.human.passportExpiryDate.day', day, {
              shouldValidate: true,
            })
          }
          selectedMonth={selectedMonth}
          selectedYear={selectedYear}
        />
        <MonthCombobox
          defaultValue={
            isNeedDefaultValue && typeof selectedMonth === 'number'
              ? {
                  index: selectedMonth,
                  month: format(new Date(2024, selectedMonth, 1), 'MMMM'),
                }
              : undefined
          }
          onSelect={(month) =>
            setValue('stepOne.human.passportExpiryDate.month', month.index, {
              shouldValidate: true,
            })
          }
        />
        <YearCombobox
          defaultValue={isNeedDefaultValue && selectedYear ? selectedYear : undefined}
          maxYear={new Date().getFullYear() + 50}
          minYear={new Date().getFullYear()}
          onSelect={(year) =>
            setValue('stepOne.human.passportExpiryDate.year', year, {
              shouldValidate: true,
            })
          }
          sortAsc
        />
      </div>
    </div>
  );
};

PassportExpiryDateSelect.displayName = 'PassportExpiryDateSelect';

export default PassportExpiryDateSelect;
