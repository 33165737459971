import { z } from 'zod';

import { StringKey } from '../../../../lang';
import { SharePlanType } from '../../../../types/pool-plans.types';
import { TerminationCause } from '../../../../types/stakeholderTypes';
import { getTranslation } from '../../../../utils/getTranslation';

export const terminateForm = z.object({
  stakeholderItems: z
    .array(
      z.object({
        id: z.string().trim().min(1),
        terminationCause: z.nativeEnum(TerminationCause),
        terminationDate: z.coerce.date(),
        grantItems: z
          .array(
            z.object({
              id: z.string().trim().min(1),
              grantItem: z.object({
                plan: z.object({ type: z.nativeEnum(SharePlanType) }),
                numbersOfGrants: z.coerce.number().optional(),
              }),
              vestedSharesCount: z.coerce.number().optional(),
              exercisedCount: z.coerce.number().optional(),
              finalExerciseDate: z.coerce.date().optional(),
            }),
          )
          .optional(),
      }),
    )
    .min(1, { message: getTranslation(StringKey.REQUIRED) }),
  isAgree: z.boolean().refine((value) => value === true, {
    message: 'isAgree must be true',
  }),
  isSendEmails: z.boolean().optional(),
});

export const formSchema = z.object({
  terminateForm,
});

export type FormSchema = z.infer<typeof formSchema>;
