import { FC, ReactNode } from 'react';
import { twMerge } from 'tailwind-merge';

export type BadgeProps = {
  title: string | ReactNode;
  className?: string;
};

export const Badge: FC<BadgeProps> = ({ title, className }) => (
  <span
    className={twMerge(
      'w-fit rounded-full bg-forest-50 px-4 py-[2px] text-label-md font-medium text-forest-500',
      className,
    )}
  >
    {title}
  </span>
);
