import React, { FC } from 'react';
import { useNavigate } from 'react-router-dom';

import { ChevronDownIcon, CloseIcon } from '../../assets/icons';
import smilingFaceImage from '../../assets/images/smiling-face.png';
import { GetStarted } from '../../components/ActivityBar/GetStarted';
import { AppFormattedMessage } from '../../components/AppFormattedMessage';
import Button from '../../components/Button';
import { useSelectedCompany } from '../../hooks';
import { StringKey } from '../../lang';
import { showOnboardingPageState } from '../../storage/showOnboardingPageStorage';
import { PageRoute } from '../../types/pageTypes';

const Onboarding: FC = () => {
  const navigate = useNavigate();
  const { selectedCompany } = useSelectedCompany();

  const handleClose = () => {
    showOnboardingPageState.set(false);
    navigate(PageRoute.MY_COMPANIES);
  };

  return (
    <div className="flex h-full w-full flex-col items-center justify-center gap-3 overflow-y-auto">
      <div className="relative flex w-full max-w-[442px] flex-col items-center justify-center rounded-xl border-[1px] border-[#f2f2f2] p-6 shadow-buttonShadow">
        <Button
          className="absolute right-4 top-4 size-9 rounded-full shadow-md transition-colors hover:bg-gray-50"
          onClick={handleClose}
          styleType="NONE"
        >
          <CloseIcon />
        </Button>
        <span
          className="max-w-[380px] truncate !bg-clip-text text-4xl-mobile font-bold text-transparent lg:text-4xl"
          style={{
            background: 'linear-gradient(132.59deg, #475467 29.58%, #101828 84.27%)',
          }}
        >
          <AppFormattedMessage id={StringKey.WELCOME_ABOARD} />,
        </span>
        <span className="mt-2 text-sm font-[450] text-gray-600">
          Let’s get started with your CapQuest
        </span>
        <GetStarted companyId={selectedCompany?.id || ''} isOnboardingPage />
      </div>
      <div className="flex w-full max-w-[442px] items-center justify-between rounded-xl border-[1px] border-[#f2f2f2] bg-white px-6 py-3 shadow-buttonShadow">
        <div className="flex cursor-pointer items-center gap-4">
          <div className="flex !size-10 shrink-0 items-center justify-center rounded-lg border-[1px] border-gray-200">
            <img alt="" className="size-[20px]" src={smilingFaceImage} />
          </div>
          <a
            className="flex flex-col gap-2 text-start"
            href="https://calendly.com/marco-capquest/30min-capquest-intro"
            rel="noreferrer noopener nofollow"
            target="_blank"
          >
            <span className="text-sm font-[550] text-[#172335]">
              <AppFormattedMessage id={StringKey.HELP_ME_SETUP} />
            </span>
            <span className="text-label-md font-normal text-gray-600">
              Schedule a call, and we'll help you with your setup!
            </span>
          </a>
        </div>
        <Button
          className="h-fit w-fit -rotate-90 rounded p-1 transition-colors hover:bg-gray-100"
          styleType="NONE"
        >
          <ChevronDownIcon className="size-3" iconColor="#172335" />
        </Button>
      </div>
    </div>
  );
};

export default Onboarding;
