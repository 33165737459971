import { z } from 'zod';

import { StringKey } from '../../../lang';
import {
  CompanyAnnualRevenue,
  CompanyIndustry,
  CompanyNumberOfEmployees,
  CompanyType,
} from '../../../types/companyTypes';
import { getTranslation } from '../../../utils/getTranslation';

export const MAX_FILE_SIZE = 1024 * 1024 * 2;

export const ACCEPTED_IMAGE_MIME_TYPE = ['image/jpeg', 'image/svg+xml', 'image/svg', 'image/png'];

export const ACCEPTED_IMAGE_FORMATS = ['.jpeg', '.svg', '.png', '.jpg'];

export const imageSchemaMessage = 'Max size: 2MB-PNG, JPEG & SVG';

const imageSchema = z
  .custom<{ link?: never } & File>()
  .refine((files) => {
    return files?.size <= MAX_FILE_SIZE;
  }, imageSchemaMessage)
  .refine(
    (files) => ACCEPTED_IMAGE_MIME_TYPE.includes(files?.type),
    'Only .jpeg, .svg or .png formats are supported.',
  )
  .or(
    z.object({
      size: z.number(),
      type: z.string(),
      name: z.string(),
      link: z.string(),
    }),
  )
  .optional();

export const formSchema = z.object({
  name: z
    .string()
    .trim()
    .min(1, { message: getTranslation(StringKey.REQUIRED) }),
  currency: z.object(
    {
      name: z.string().min(1, { message: getTranslation(StringKey.REQUIRED) }),
      iso3Code: z.string(),
      countryIso2Code: z.string().optional(),
    },
    { message: getTranslation(StringKey.REQUIRED) },
  ),
  url: z
    .union([
      z
        .string()
        .startsWith('https://', {
          message: getTranslation(StringKey.INVALID_URL),
        })
        .url(),
      z.literal(''),
      z
        .string()
        .startsWith('http://', {
          message: getTranslation(StringKey.INVALID_URL),
        })
        .url(),
      z.string().regex(new RegExp(/^[a-zA-Z0-9][a-zA-Z0-9-]{1,61}[a-zA-Z0-9]\.[a-zA-Z]{2,}$/)),
    ])
    .default(''),
  type: z.nativeEnum(CompanyType),
  industry: z.nativeEnum(CompanyIndustry),
  logoImgSrc: imageSchema,
  registrationNumber: z.string().trim().optional(),
  annualRevenue: z.nativeEnum(CompanyAnnualRevenue),
  numberOfEmployees: z.nativeEnum(CompanyNumberOfEmployees),
});

export type FormSchema = z.infer<typeof formSchema>;
