import React, { FC } from 'react';
import { twMerge } from 'tailwind-merge';

import { IconProps } from './type';

export type CoinSwapIconProps = IconProps;

const CoinSwapIcon: FC<CoinSwapIconProps> = ({ iconColor = '#98A2B3', className, ...props }) => (
  <svg
    className={twMerge('shrink-0', className)}
    fill="none"
    height="24"
    viewBox="0 0 24 24"
    width="24"
    {...props}
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M6 6L8 4M8 4L6 2M8 4H6C3.79086 4 2 5.79086 2 8M18 18L16 20M16 20L18 22M16 20H18C20.2091 20 22 18.2091 22 16M10.189 6.5C10.8551 3.91216 13.2042 2 16 2C19.3137 2 22 4.68629 22 8C22 10.7957 20.0879 13.1449 17.5001 13.811M14 16C14 19.3137 11.3137 22 8 22C4.68629 22 2 19.3137 2 16C2 12.6863 4.68629 10 8 10C11.3137 10 14 12.6863 14 16Z"
      stroke={iconColor}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="1.5"
    />
  </svg>
);

export default CoinSwapIcon;
