import React, { FC, useState } from 'react';
import { twMerge } from 'tailwind-merge';

import { EditIcon } from '../../../../assets/icons';
import { AlertDialogWrapper } from '../../../../components/AlertDialog';
import { AppFormattedMessage } from '../../../../components/AppFormattedMessage';
import Button from '../../../../components/Button';
import { FormDatePicker } from '../../../../components/DatePicker';
import { DropDown } from '../../../../components/Dropdown';
import { SummaryItem } from '../../../../components/SummaryItem';
import { Switch } from '../../../../components/Switch';
import { useFormat, useModalState } from '../../../../hooks';
import { StringKey } from '../../../../lang';
import { DilutionCondition } from '../../../../types/pool-plans.types';
import { TerminationModal } from '../../TerminationModal';
import { ChildrenFormProps, EditShareClassStepVariant } from '../type';

export type EditPreviewProps = {
  onEditClick: (step: EditShareClassStepVariant) => void;
  previewMode?: true;
  handleTerminate: (date: Date) => void;
} & ChildrenFormProps;

export const EditPreview: FC<EditPreviewProps> = ({
  handleCloseModal,
  formData,
  onEditClick,
  handleTerminate,
  previewMode,
  isTerminated,
  terminationDate,
}) => {
  const [terminatingData, setTerminatingData] = useState<{
    isTerminating: boolean;
    date: Date | null;
  } | null>(null);
  const { stepOne, stepTwo } = formData();
  const { format } = useFormat();
  const handleDocumentClick = (link: string) => {
    window.open(link);
  };
  const {
    toggler: terminationModalToggler,
    isOpen: isOpenTerminationModal,
    handleOpenModal: handleOpenTerminationModal,
    handleCloseModal: handleCloseTerminationModal,
    handleSuccessModal: handleSuccessTerminationModalModal,
  } = useModalState({
    onSuccess: () => {
      if (!terminatingData?.date) return;
      handleTerminate(terminatingData.date);
      handleCloseModal();
    },
  });
  return (
    <>
      <AlertDialogWrapper
        control={{ onOpenChange: terminationModalToggler, open: isOpenTerminationModal }}
      >
        <TerminationModal
          onClose={handleCloseTerminationModal}
          onSuccess={handleSuccessTerminationModalModal}
          text="pool"
        />
      </AlertDialogWrapper>
      <div className="flex h-full flex-col gap-4 overflow-y-auto rounded-b-xl border-b border-gray-300 bg-white p-6 pt-0">
        <div className="flex h-fit flex-col gap-2 rounded-lg bg-gray-50 pt-2">
          <div className="flex w-full justify-between px-4">
            <span className="text-sm font-[550] text-gray-700">
              <AppFormattedMessage id={StringKey.GENERAL} />
            </span>
            {!previewMode && (
              <Button className="w-fit" onClick={() => onEditClick(1)} styleType="NONE">
                <EditIcon iconColor="#2565C8" />
              </Button>
            )}
          </div>
          <div className="flex flex-col divide-y divide-gray-100 rounded-b-lg border border-gray-100 bg-white p-4 pb-3">
            <SummaryItem
              className="pt-0"
              title={<AppFormattedMessage id={StringKey.POOL_NAME} />}
              value={stepOne?.name}
            />

            <SummaryItem
              className="py-3"
              title={<AppFormattedMessage id={StringKey.FULLY_DILUTED_SHARES} />}
              value={
                stepOne?.dillutedSharesCount &&
                Number(stepOne?.dillutedSharesCount).toLocaleString('en-US')
              }
            />
            <SummaryItem
              className="py-3"
              title={<AppFormattedMessage id={StringKey.SHARE_CLASS} />}
              value={stepOne?.shareClass?.name || '-'}
            />
            <SummaryItem
              className="py-3"
              title={<AppFormattedMessage id={StringKey.DATE} />}
              value={stepOne?.date ? format(stepOne?.date, 'dd/MM/yyyy') : '-'}
            />
            <SummaryItem
              className="pb-0"
              title={<AppFormattedMessage id={StringKey.DILUTION_CONDITION} />}
              value={
                stepOne?.dilutionCondition === DilutionCondition.INCLUDE_CAP_TABLE ? (
                  <AppFormattedMessage id={StringKey.INCLUDE_CAP_TABLE} />
                ) : (
                  <AppFormattedMessage id={StringKey.EXCLUDE_CAP_TABLE} />
                )
              }
            />
          </div>
        </div>
        <div className="flex w-full items-center justify-between rounded-lg border border-gray-100 bg-white px-4">
          <div className={'flex gap-3 text-nowrap py-2'}>
            <span className="w-[120px] flex-shrink-0 text-xs font-[450] text-gray-500">
              <AppFormattedMessage id={StringKey.DOCUMENTS} />:
            </span>
            <div className="flex max-w-[220px] flex-col gap-2">
              {(stepTwo?.files?.length || 0) > 0 ? (
                stepTwo?.files?.map(({ doc, docLink }) => (
                  <div
                    className={twMerge(
                      'truncate text-xs font-[450] text-gray-700',
                      previewMode && 'cursor-pointer text-brand-700 underline',
                    )}
                    key={docLink}
                    onClick={() => previewMode && handleDocumentClick(docLink)}
                  >
                    {doc?.name}
                  </div>
                ))
              ) : (
                <span className="truncate text-xs font-[450] text-gray-700">-</span>
              )}
            </div>
          </div>
          {!previewMode && (
            <Button className="w-fit" onClick={() => onEditClick(2)} styleType="NONE">
              <EditIcon iconColor="#2565C8" />
            </Button>
          )}
        </div>
        <div className="flex w-full items-start justify-between rounded-lg border border-gray-100 bg-white px-4 py-2">
          <div className="flex w-full gap-3">
            <span className="w-[120px] flex-shrink-0 text-xs font-[450] text-gray-500">
              {<AppFormattedMessage id={StringKey.ADDITIONAL_NOTES} />}:
            </span>
            <div
              className={twMerge(
                'max-w-[250px] break-words text-xs font-[450] text-gray-700',
                !previewMode ? 'max-w-[220px]' : 'max-w-[250px]',
              )}
            >
              {stepTwo?.additionalNotes || '-'}
            </div>
          </div>
          {!previewMode && (
            <Button className="w-fit" onClick={() => onEditClick(2)} styleType="NONE">
              <EditIcon iconColor="#2565C8" />
            </Button>
          )}
        </div>

        {!previewMode && !isTerminated && (
          <div className="flex w-full flex-col justify-center rounded-lg bg-fireside-25 px-1 py-1">
            <div className="flex gap-2 px-3 py-3">
              <Switch
                onChange={(state) =>
                  setTerminatingData(state ? { isTerminating: state, date: null } : null)
                }
              />
              <span className="text-sm font-[550] text-fireside-600">
                <AppFormattedMessage id={StringKey.TERMINATE_POOL} />
              </span>
            </div>
            {terminatingData && (
              <div className="flex w-full flex-col gap-3 rounded-md bg-white p-3 shadow-xs">
                <FormDatePicker
                  calendar={{ fromDate: new Date() }}
                  defaultMonth={terminatingData.date || undefined}
                  inputValue={terminatingData.date ? format(terminatingData.date, 'dd/MM/yy') : ''}
                  inputWrapperClassName="cursor-pointer"
                  onSelect={(date) => setTerminatingData({ isTerminating: true, date })}
                  placeholder={<AppFormattedMessage id={StringKey.DATE_OF_TERMINATION} />}
                  value={terminatingData?.date}
                />
                <DropDown
                  answer="Plan termination refers to the process of ending an employee stock option plan, stock purchase plan, or any other equity compensation plan."
                  className="rounded-lg bg-gray-50"
                  question={<AppFormattedMessage id={StringKey.POOL_TERMINATION_WORKS} />}
                />
              </div>
            )}
          </div>
        )}
        {!previewMode && isTerminated && terminationDate && (
          <div className="flex flex-col gap-3 p-3 pt-5">
            <div className="flex flex-col gap-1">
              <span className="text-sm font-[550] text-fireside-600">
                <AppFormattedMessage id={StringKey.TERMINATE_POOL} />
              </span>
              <div className="flex items-center gap-2">
                <span className="text-xs font-[450] text-gray-700">
                  <AppFormattedMessage id={StringKey.CURRENT_TERMINATION_DATE} />:
                </span>
                <span className="text-xs font-[450] text-fireside-600">
                  {terminationDate ? format(terminationDate, 'dd/MM/yy') : ''}
                </span>
              </div>
            </div>

            <div className="flex w-full flex-col gap-3 rounded-md bg-white p-3 shadow-xs">
              <FormDatePicker
                calendar={{ fromDate: new Date() }}
                inputValue={terminatingData?.date ? format(terminatingData.date, 'dd/MM/yy') : ''}
                inputWrapperClassName="cursor-pointer"
                onSelect={(date) => setTerminatingData({ isTerminating: true, date })}
                placeholder={<AppFormattedMessage id={StringKey.NEW_TERMINATION_DATE} />}
                value={terminatingData?.date}
              />
              <DropDown
                answer="Pool termination refers to the process of ending an employee stock option plan, stock purchase plan, or any other equity compensation plan."
                className="rounded-lg bg-gray-50"
                question={<AppFormattedMessage id={StringKey.POOL_TERMINATION_WORKS} />}
              />
            </div>
          </div>
        )}
      </div>

      <div
        className={twMerge(
          'flex h-9 w-full justify-start gap-3 px-6',
          previewMode && 'justify-end',
          terminatingData && 'justify-between',
        )}
      >
        {terminatingData && (
          <Button
            className="w-fit bg-transparent px-4 py-[6px] text-sm font-[450] text-gray-700"
            onClick={handleCloseModal}
            styleType="NONE"
            type="button"
          >
            <AppFormattedMessage id={StringKey.CANCEL} />
          </Button>
        )}
        <Button
          className={twMerge(
            'w-fit bg-transparent px-4 py-[6px] text-sm font-[450] text-gray-700',
            terminatingData && 'bg-fireside-600 text-white',
            previewMode && 'bg-brand-700 text-white',
          )}
          disabled={(terminatingData && !terminatingData.date) || false}
          onClick={() => {
            if (!terminatingData) return handleCloseModal();

            terminatingData.date && handleOpenTerminationModal();
          }}
          styleType="NONE"
          type="button"
        >
          {terminatingData ? (
            <AppFormattedMessage id={StringKey.TERMINATE_POOL} />
          ) : previewMode ? (
            <AppFormattedMessage id={StringKey.CLOSE} />
          ) : (
            <AppFormattedMessage id={StringKey.CANCEL} />
          )}
        </Button>
      </div>
    </>
  );
};
