import { StringKey } from '../../lang';
import { NotificationType } from '../../types/notification.types';

export enum ActivityType {
  RESOLVED = 'resolved',
  GET_STARTED = 'get-started',
}

export type ActivityVariant = ActivityType | NotificationType;

export const activityVariantTitle: Record<ActivityType | NotificationType, StringKey> = {
  [ActivityType.GET_STARTED]: StringKey.GET_STARTED,
  [NotificationType.ACTIVITY]: StringKey.ACTIVITY,
  [NotificationType.REQUEST]: StringKey.REQUESTS,
  [ActivityType.RESOLVED]: StringKey.RESOLVED,
};

export const activityVariantMap = Object.entries(activityVariantTitle);
